import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "antd";

import { removeNotification } from "../../../redux/notificationSlice";

const NotificationItem = ({ item }) => {
  const dispatch = useDispatch();
  return (
    <Alert
      style={{
        width: "max-content",
      }}
      message={item.message}
      type={item.type}
      showIcon
      closable
      afterClose={() => dispatch(removeNotification(item.id))}
    />
  );
};

const Notification = () => {
  const state = useSelector((state) => state.notifications);

  const notifications = state?.map((x) =>
    x ? <NotificationItem item={x} /> : null
  );

  return (
    <div
      style={{
        position: "fixed",
        top: "5rem",
        zIndex: "100",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: '.5rem'
      }}
      className='notification-container'
    >
      {notifications}
    </div>
  );
};

export default Notification;
