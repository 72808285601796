import { v4 as uuidv4 } from "uuid";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getResourcesAsync} from './resourceSlice'
import { returnDataArrayObject } from "../utils/arrayHelper";
import Axios from "axios";
import * as _ from 'lodash';
import moment from "moment";

import jwt from "jsonwebtoken";
const { REACT_APP_CONNECTION_INFO_BASE_URL } = process.env;
// const JWT_TOKEN = `Bearer ${localStorage.getItem("accessToken")}`;


export const saveDashboardAsync = createAsyncThunk(
  "editor/saveDashboard",
  async (payload, { getState }) => {
    const { editor } = getState();
    const resourceList = editor.resourceList;
    console.log("resourceList ====>>>", resourceList)
    const resourceSavingData = resourceList.map((rs) => {
      return {
        connectionData: rs.sources && rs.sources.length>0
          ? {
              sources: rs.sources.map((s) => {
                return {
                  id: s.resourceId || s.id,
                  uid: uuidv4(),
                };
              }),
            }
          : {
              id: rs.source.id,
              uid: uuidv4(),
            },
        id : rs.id,
        type : rs.type,
        widgetName: rs?.source?.widgetName,
        availableFields: rs.availableFields,
        joins: rs.joins ? rs.joins : null,
        availableFilters: {},
        layout: rs.layout,
        dataFilters: rs?.dataFilters ? rs.dataFilters : [],
        aggregation: rs?.aggregation ? rs.aggregation : null,
        aggGroupBuilders: rs.aggGroupBuilders ? rs.aggGroupBuilders : null,
        // groupedReportData: rs?.groupedReportData ? rs.groupedReportData : null,
        aggRowBuilders: rs?.aggRowBuilders ? rs.aggRowBuilders : [],
        customFilters: rs?.customFilters ? rs.customFilters : [],
        charts: rs?.charts ? rs.charts : {},
        allFields: rs?.allFields ? rs.allFields : []
      };
    });
    // console.log(
    //   "saveDashboardAsync resourceSavingData===>>",
    //   resourceSavingData
    // );

    const reqBody = {
      userID: localStorage.getItem("userID"),
      orgID: localStorage.getItem("orgID"),
      appID: localStorage.getItem("appID"),
      isSpecialUser: localStorage.getItem("IsSpecialUser"),
      publish: payload.publish,
      name: editor.dashboardConfig.boardName,
      resources: resourceSavingData,
      configDetails: editor.dashboardConfig,
      properties: payload.properties,
      appList: editor.dashboardConfig.appList,
      id: payload.id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: JSON.stringify(reqBody),
    };
    const response = await fetch(
      `${REACT_APP_CONNECTION_INFO_BASE_URL}/dashboard`,
      requestOptions
    );
    // .then(resp => {
    // })
    const data = await response.json();
    return data;
  }
);

export const fetchReportDataAsync = createAsyncThunk(
  'editor/details',
  async ({selectedResource, isCombined}) => {
      // console.log(" fetchReportData===>>>>", resources.selectedResource)
      if (
        selectedResource &&
        selectedResource.source 
      ) {
        // const response = await axios.get(resources.selectedResource.connectionData.baseUrl);
        // console.log(" fetchReportData===>>>>", selectedResource.source)
        try {
          const requestValues =
            selectedResource.source.connectionData.requestValues;
          const authInfo =
            selectedResource &&
            selectedResource.source.connectionData &&
            selectedResource.source.connectionData.authInfo;
          let urlParamSring = "";
          if (requestValues.urlParams.length > 0) {
            urlParamSring = urlParamSring + "?";
            for (let i = 0; i < requestValues.urlParams.length; i++) {
              if (
                requestValues.urlParams[i]["key"] !== "" &&
                requestValues.urlParams[i]["value"] !== ""
              ) {
                urlParamSring =
                  urlParamSring +
                  requestValues.urlParams[i]["key"] +
                  "=" +
                  requestValues.urlParams[i]["value"] +
                  "&";
              }
            }
            urlParamSring.slice(0, -1);
          }
  
          let headerObj = {};
          if (requestValues.requestHeaders.length > 0) {
            for (let i = 0; i < requestValues.requestHeaders.length; i++) {
              if (
                requestValues.requestHeaders[i]["key"] !== "" &&
                requestValues.requestHeaders[i]["value"] !== ""
              ) {
                headerObj[requestValues.requestHeaders[i]["key"]] =
                  requestValues.requestHeaders[i]["value"];
              }
            }
          }
  
          let bodyParamObj = {};
          // if (requestValues.authParams) {
          //     bodyParamObj = Object.assign({}, authParams);
          // }
          if (requestValues.bodyParams.length > 0) {
            for (let i = 0; i < requestValues.bodyParams.length; i++) {
              if (
                requestValues.bodyParams[i]["key"] !== "" &&
                requestValues.bodyParams[i]["value"] !== ""
              ) {
                bodyParamObj[requestValues.bodyParams[i]["key"]] =
                  requestValues.bodyParams[i]["value"];
              } else if ( requestValues.bodyParams[i]["key"] !== "" && requestValues.bodyParams[i]["dataType"] === "date") {
                if(requestValues.bodyParams[i]["dateConfig"]) {
                  let dateCf = requestValues.bodyParams[i]["dateConfig"];
                  if(dateCf.dateOption === "daysBefore") {
                    let dateVal = moment.utc().subtract(dateCf.timeUnits, 'day').startOf('day').format(dateCf.dateFormat);
                    bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
                  } else if (dateCf.dateOption === "today") {
                    let dateVal = moment().format(dateCf.dateFormat);
                    bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
                  }
                }
              }
            }
          }
          // // await Axios.get(resources.selectedResource.connectionData.baseUrl)
          if (authInfo.method === "jwt") {
            const timestamp = Math.floor(Date.now() / 1000) + 1000;
            const privateKey = authInfo.parameters.privateKey;
            var payload = {
              aud: authInfo.parameters.aud,
              exp: timestamp,
              iss: authInfo.parameters.iss,
              usertoken: localStorage.getItem('accessToken')
            };
            var signOptions = {
              algorithm: "RS256",
            };
  
            var privateKeyVal = privateKey
              ? privateKey.replace(/\\n/g, "\n")
              : null;
  
            if (privateKeyVal) {
              try {
                var token = jwt.sign(payload, privateKeyVal, signOptions);
                // setApiTestStatus("success")
  
                return  await Axios({
                  method: requestValues.httpMethod,
                  url:
                    selectedResource.source.connectionData.baseUrl +
                    urlParamSring,
                  data: JSON.parse(JSON.stringify(bodyParamObj)),
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + token,
                  },
                })
                  .then((response) => {
                    const reportData = response.data 
                      ? isCombined ? response.data.Result : response.data 
                      : response;
                    return reportData
                  })
                  .catch((error) => {
                    return []
                  });
              } catch (e) {
                return []
              }
            }
          } else {
            let httpReqConfig = {
              method:
                selectedResource.source.connectionData.requestValues.httpMethod,
              url: selectedResource.source.connectionData.baseUrl + urlParamSring,
            };
  
            if (requestValues.bodyParams.length > 0) {
              httpReqConfig.data = bodyParamObj;
            }
            if (requestValues.requestHeaders.length > 0) {
              httpReqConfig.headers = headerObj;
            }
            return Axios(httpReqConfig)
              .then((response) => {
                // console.log(response);
                // setReportDataObject(response.data ? response.data : response)
                const reportData = response.data 
                  ? isCombined ? response.data.Result : response.data
                  : response.Result;
                return reportData
              })
              .catch((error) => {
                return []
                // setReportDataObject(null)
                // console.log(error);
              });
          }
        } catch (error) {
          return []          // setReportDataObject(null)
          // console.log(error);
        }
      }
  })

export const getReportData = async (resource, dispatch, isCombined) => {
  const values = await dispatch(fetchReportDataAsync({
    selectedResource : {...resource, source : !isCombined ? resource.connectionData : resource }, isCombined } ))
  return values.payload
}

const getCombinedResourceData = async (resource, dispatch, resources, restApiResource) => {
  const sourceReportData = await getSources(resource, dispatch)

  const getSelectedApiResource = sourceReportData.map((item) => {
    return {
      id: item.id,
      name: item.name,
      resourceId: item.id,
      // selectedField: item.selectedField ? item.selectedField : null,
      // source: item.source ? item.source : null,
      reportData: item.reportData
    };
  })

  const joins = resource?.joins.map((join) => {
    return {
      id: join.id,
      resource1Id: join?.resource1Id || null,
      field1: join?.field1 || null,
      resource2Id: join?.resource2Id || null,
      field2: join?.field2 || null
    };
  });

    let reportArr = [];
    joins.forEach((li) => {
      let sets = [];
      getSelectedApiResource
        .find((r1) => r1.resourceId === li.resource1Id)
        ?.reportData.data.forEach((rd1) => {
          getSelectedApiResource
            .find((r2) => r2.resourceId === li.resource2Id)
            ?.reportData.data.forEach((rd2) => {
              if (rd1[li.field1] === rd2[li.field2]) {
                let oneData = {};
                let twoData = {};
                Object.keys(rd1).forEach((od) => {
                  oneData[
                    restApiResource?.payload?.data
                      .find((r) => r.id === li.resource1Id)
                      .name.replace(/\s/g, "_") +
                      "_" +
                      od
                  ] = rd1[od];
                });
                Object.keys(rd2).forEach((td) => {
                  twoData[
                    restApiResource?.payload?.data
                      .find((r) => r.id === li.resource2Id)
                      .name.replace(/\s/g, "_") +
                      "_" +
                      td
                  ] = rd2[td];
                });
                sets.push(Object.assign(oneData, twoData));
              }
            });
        });
      reportArr = _.merge(reportArr, sets);
    });
    return reportArr;
}

const getSources = async (resource, dispatch) => {
  return await Promise.all(resource.connectionData?.sources.map(async (source) => ({
    ...source,
    reportData : { data : await getReportData(source, dispatch, true) } 
  })))
};

export const getDashboardDetailsAsync = createAsyncThunk(
  'boards/getBoardById',
  async (payload, {dispatch}) => {
      const restApiResource = await dispatch(getResourcesAsync());
      const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/dashboard-details/${payload.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      const data = await response.json();
      const selectedResources = await Promise.all(data?.data.resources.map(async (resource, index) => {
        return {
          ...resource,
          id : index + 1,
          availableFields : resource.availableFields,
          dataFilters : resource.dataFilters || [],
          aggregation : resource.aggregation || null,
          aggGroupBuilders : resource.aggGroupBuilders || null,
          [resource.connectionData.id ? 'source' :'sources'] : resource.connectionData.id 
            ? resource.connectionData 
            : await Promise.all(resource.connectionData?.sources.map(async (source) => ({
              ...source,
              reportData : { data : await getReportData(source, dispatch, true) }
                    
            }))),
          widjet : resource.charts && Boolean(Object.values(resource.charts).length) ? 'chart' : 'table',
          reportData : resource.connectionData.id 
            ? await getReportData(resource, dispatch) 
            : await getCombinedResourceData(resource, dispatch, payload.resources, restApiResource)
        } 
    }));
     
      return {
        selectedResources : selectedResources,
        dashboardConfig : data?.data?.configDetails,
        properties: data?.data?.properties,
        selectedResource : Boolean(selectedResources.length) ? selectedResources[0] : {}
      }
  }
);

export const publishDashboardAsync = createAsyncThunk(
  "editor/publishDashboard",
  async (payload) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify({
        id: payload.id,
        publish: payload.publish,
      }),
    };
    const response = await fetch(
      `${REACT_APP_CONNECTION_INFO_BASE_URL}/publishdashboard`,
      requestOptions
    );
    // .then(resp => {
    // })
    const data = await response.json();
    // console.log("SAVE RESPONSE====>>", data)
    return data;
  }
);

export const editorSlice = createSlice({
  name: "editor",
  initialState: {
    resourceList: [],
    resourceListStatus: "default",
    selectedResource: {},
    dashboardConfig: null,
    properties: {},
    boardSavingState: { status: "DEFAULT" },
    boardPublishState: { status: "DEFAULT" },
    savedBoardId: null,
    deletedResourceID: null,
  },
  reducers: {
    addResouece: (state, action) => {
      let newResourceList = state.resourceList?[...state.resourceList]:[];
      let max = _.maxBy(newResourceList, 'id')

      newResourceList.push({
        id: max?.id ? max?.id + 1 : state.resourceList.length + 1,
        type: action.payload,
        source: {},
      });
      state.resourceList = newResourceList;
      state.addedResourceId = state.resourceList.length + 1;
    },
    removeResource: (state, action) => {
      let currentResourceList = [...state.resourceList];

      state.resourceList = currentResourceList.filter(
        (cr) => cr.id !== action.payload.id
      );

      state.selectedResource = {};
      state.deletedResourceID = action.payload.id;
    },
    resetResourceRemoveState: (state) => {
      state.deletedResourceID = null;
    },
    resetResourceAddStatus: (state) => {
      state.addedResourceId = null;
    },
    addResoueceList: (state, action) => {
      let newResourceList = [...state.resourceList, ...action.payload];
      // newResourceList.push({ id: (state.resourceList.length + 1), type: action.payload, source: {} })
      state.resourceList = newResourceList;
    },
    deleteResource: (state, action) => {
      state.resourceList = [...state.resourceList].filter(
        (r) => r.id !== action.payload.id
      );
    },
    updateReportData: (state, action) => {
      let updateResList = [...state.resourceList];

      const resource = updateResList.map((resource) => {
        if(resource.id === action.payload.id){
          return {
            ...resource,
            reportData: action.payload.reportData,
            sources: action.payload.sources
          }
        }
        return resource;
      });
      
      state.resourceList = resource;
      state.selectedResource = Object.assign({} ,state.selectedResource, 
        {reportData : action.payload.reportData, sources: action.payload.sources} );
    },
    updateResourceSource: (state, action) => {
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex(
        (ur) => ur.id === action.payload.id
      );
      //  console.log("updateIndex===>>", updateIndex)
      updateResList[updateIndex] = action.payload;
      updateResList[updateIndex]["availableFields"] =
        action.payload.reportData &&
        (action.payload.reportData.length > 0 ||
          Object.keys(action.payload.reportData)) &&
        returnDataArrayObject(action.payload.reportData).data &&
        returnDataArrayObject(action.payload.reportData).data.length
          ? Object.keys(
              returnDataArrayObject(action.payload.reportData).data[0]
            )
              .filter(
                (fl) =>
                  typeof returnDataArrayObject(action.payload.reportData)
                    .data[0][fl] === "string" ||
                  typeof returnDataArrayObject(action.payload.reportData)
                    .data[0][fl] === "number" ||
                  Array.isArray(
                    returnDataArrayObject(action.payload.reportData).data[0][fl]
                  ) === true
              )
              .map((ok, i) => {
                return {
                  id: i,
                  fieldName: ok,
                  checked: true,
                  dataType: typeof returnDataArrayObject(
                    action.payload.reportData
                  ).data[0][ok],
                };
              })
          : [];
      updateResList[updateIndex]["allFields"] =
        action.payload.reportData &&
        (action.payload.reportData.length > 0 ||
          Object.keys(action.payload.reportData)) &&
        returnDataArrayObject(action.payload.reportData).data &&
        returnDataArrayObject(action.payload.reportData).data.length
          ? Object.keys(
              returnDataArrayObject(action.payload.reportData).data[0]
            )
              .filter(
                (fl) =>
                  typeof returnDataArrayObject(action.payload.reportData)
                    .data[0][fl] === "string" ||
                  typeof returnDataArrayObject(action.payload.reportData)
                    .data[0][fl] === "number"
              )
              .map((ok, i) => {
                return {
                  id: i,
                  fieldName: ok,
                  checked: true,
                  dataType: typeof returnDataArrayObject(
                    action.payload.reportData
                  ).data[0][ok],
                };
              })
          : [];

      console.log("updateResList ====>>>", updateResList)
      state.resourceList = updateResList;
      state.selectedResource = action.payload;
    },

    
    modifyResourceSource: (state, action) => {
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex(
        (ur) => ur.id === action.payload.id
      );
      //  console.log("updateIndex===>>", updateIndex)
      updateResList[updateIndex] = action.payload;
      updateResList[updateIndex]["availableFields"] = action.payload
        .availableFields
        ? action.payload.availableFields
        : action.payload.reportData &&
          (action.payload.reportData.length > 0 ||
            Object.keys(action.payload.reportData)) &&
          returnDataArrayObject(action.payload.reportData).data &&
          returnDataArrayObject(action.payload.reportData).data.length
        ? Object.keys(returnDataArrayObject(action.payload.reportData).data[0])
            .filter(
              (fl) =>
                typeof returnDataArrayObject(action.payload.reportData).data[0][
                  fl
                ] === "string" ||
                typeof returnDataArrayObject(action.payload.reportData).data[0][
                  fl
                ] === "number"
            )
            .map((ok, i) => {
              return {
                id: i,
                fieldName: ok,
                checked: true,
                dataType: typeof returnDataArrayObject(
                  action.payload.reportData
                ).data[0][ok],
              };
            })
        : [];
      state.resourceList = updateResList;
      state.selectedResource = action.payload;
      updateResList[updateIndex]["reportData"] = action.payload.reportData
        ? action.payload.reportData
        : null;
    },
    updateResourceSourceAndFilter: (state, action) => {
      //console.log("updateResourceSourceAndFilter  <<>> action===>>", action)
      let updateResList = [...state.resourceList];

      let updateIndex = updateResList.findIndex(
        (ur) => ur.id === action.payload.selectedResource.id
      );
      // // updateResList[updateIndex] = action.payload.selectedResource

      // updateResList[updateIndex]["reportData"] = action.payload.reportData;
      // if(updateResList[updateIndex]["availableFields"].length===0) {
      // updateResList[updateIndex]["availableFields"] =
      //   action.payload.reportData &&
      //   (action.payload.reportData.length > 0 ||
      //     Object.keys(action.payload.reportData)) &&
      //   returnDataArrayObject(action.payload.reportData).data &&
      //   returnDataArrayObject(action.payload.reportData).data.length
      //     ? Object.keys(
      //         returnDataArrayObject(action.payload.reportData).data[0]
      //       )
      //         .filter(
      //           (fl) =>
      //             typeof returnDataArrayObject(action.payload.reportData)
      //               .data[0][fl] === "string" ||
      //             typeof returnDataArrayObject(action.payload.reportData)
      //               .data[0][fl] === "number"
      //         )
      //         .map((ok, i) => {
      //           return {
      //             id: i,
      //             fieldName: ok,
      //             checked: true,
      //             dataType: typeof returnDataArrayObject(
      //               action.payload.reportData
      //             ).data[0][ok],
      //           };
      //         })
      //     : [];
      // }
      updateResList[updateIndex]["dataFilters"] = action.payload.dataFilters;
      updateResList[updateIndex]["customFilters"] =
        action.payload.customFilters;

      state.resourceList = updateResList;

      let selected = Object.assign(updateResList[updateIndex]);

      // selected.dataFilters = action.payload.dataFilters
      state.selectedResource = selected;

      // console.log("updateDataFilters action ====>>>", action);
      // console.log("updateDataFilters state ====>>>", state);
      // let selected = Object.assign(state.selectedResource);
      // let reportRowData = returnDataArrayObject(selected['reportData'])
      // console.log("selected ====>>>", selected);
      // console.log("reportRowData ====>>>", reportRowData);
      // selected.dataFilters = action.payload.dataFilters
      // state.selectedResource = selected
      // let updateResList = [...state.resourceList];
      // let updateIndex = updateResList.findIndex(ur => ur.id === selected.id)
      // updateResList[updateIndex]['dataFilters'] = action.payload.dataFilters
    },
    updateSelectedResource: (state, action) => {

      state.selectedResource = {
        id: action.payload.id,
        type: action.payload.type,
        source: action.payload.source ? action.payload.source : {},
        sources: action.payload?.sources || [],
        joins: action.payload?.joins || [],
        reportData: action.payload.reportData
          ? action.payload.reportData
          : null,
        availableFields: action.payload.availableFields
          ? action.payload.availableFields
          : [], //action.payload.reportData && (action.payload.reportData.length > 0 || Object.keys(action.payload.reportData)) && returnDataArrayObject(action.payload.reportDataObject).data && returnDataArrayObject(action.payload.reportDataObject).data.length ? Object.keys(returnDataArrayObject(action.payload.reportDataObject).data[0]) : []
        allFields: action.payload.availableFields
          ? action.payload.availableFields
          : [],
        charts: action.payload?.charts || {},
        dataFilters: action.payload?.dataFilters || [],
        customFilters: action.payload?.customFilters || [],
        layout: action.payload?.layout,
        aggRowBuilders : action.payload?.aggRowBuilders || [],
        aggregation : {
          row : action.payload?.aggregation?.row || [] 
        },
        aggGroupBuilders : action.payload?.aggGroupBuilders || [] 
      };
    },
    updateSelectedResourceWidgetName: (state, action) => {
      // alert("SSSSS")
      const updatedResourceList = state.resourceList.map((rl) => {
        return {
          ...rl,
          source:
            rl.id === action.payload.id
              ? { ...rl.source, widgetName: action.payload.widgetName }
              : { ...rl.source },
        };
      });
      state.resourceList = updatedResourceList;
      state.selectedResource = {
        ...state.selectedResource,
        source: {
          ...state.selectedResource.source,
          widgetName: action.payload.widgetName,
        },
      };
    },

    updateSelectedFields: (state, action) => {
      let selected = Object.assign(state.selectedResource);
      // selected.availableFields = [...selected.availableFields.filter(af => af.id !== action.payload.id), ...[action.payload]]
      selected.availableFields[
        selected.availableFields.findIndex((s) => s.id === action.payload.id)
      ] = action.payload;
      state.selectedResource = selected;
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["availableFields"][
        updateResList[updateIndex]["availableFields"].findIndex(
          (s) => s.id === action.payload.id
        )
      ] = action.payload;
      state.resourceList = updateResList;
    },
    // updateAllFields: (state, action) => {
    //   console.log("updateSelectedFields Action==>>", action);
    //   let selected = Object.assign(state.selectedResource);
    //   selected.allFields = [
    //     ...selected.allFields,
    //     ...action.payload?.map((a) => ({
    //       id: uuidv4(),
    //       fieldName: a.displayName,
    //       dataType: a?.dataType ?? "number",
    //       isAggrCol: true,
    //       grouped: true,
    //       className: "aggr-col",
    //     })),
    //   ];
    //   state.selectedResource = selected;
    //   let updateResList = [...state.resourceList];
    //   let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
    //   updateResList[updateIndex]["allFields"] = [
    //     ...updateResList[updateIndex]["allFields"],
    //     ...action.payload?.map((a) => ({
    //       id: uuidv4(),
    //       fieldName: a.displayName,
    //       dataType: a?.dataType ?? "number",
    //       isAggrCol: true,
    //       grouped: true,
    //       className: "aggr-col",
    //     })),
    //   ];
    //   state.resourceList = updateResList;
    // },
    resetAggBuilders: (state) => {
      let selected = Object.assign(state.selectedResource);
      selected.aggRowBuilders = [];
      selected.aggregation = {};
      state.selectedResource = selected;
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["aggRowBuilders"] = [];
      updateResList[updateIndex]["aggregation"] = {};
      state.resourceList = updateResList;
    },
    resetGroupBuilders: (state) => {
      let selected = Object.assign(state.selectedResource);
      selected.aggGroupBuilders = null;
      selected.groupedReportData = [];
      selected.aggRowBuilders = null;
      selected.aggregation = null;
      selected.availableFields = [...selected.allFields];
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["aggGroupBuilders"] = null;
      updateResList[updateIndex]["groupedReportData"] = [];
      updateResList[updateIndex]["aggRowBuilders"] = null;
      updateResList[updateIndex]["aggregation"] = null;
      updateResList[updateIndex]["availableFields"] = [
        ...updateResList[updateIndex].allFields
      ];
      state.resourceList = updateResList;
      state.selectedResource = selected
    },
    updateGroupedSelectedFields: (state, action) => {
      let selected = Object.assign(state.selectedResource);
      // selected.availableFields = [...selected.availableFields.filter(af => af.id !== action.payload.id), ...[action.payload]]
      selected.availableFields = [...action.payload];
      state.selectedResource = selected;
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["availableFields"] = action.payload;
      state.resourceList = updateResList;
    },
    updateGroupedReportData: (state, action) => {
      let selected = Object.assign(state.selectedResource);

      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["availableFields"] = action.payload.fields;
      updateResList[updateIndex]["groupedReportData"] = action.payload.data;
      state.resourceList = updateResList;

      // selected.availableFields = [...selected.availableFields.filter(af => af.id !== action.payload.id), ...[action.payload]]
      selected.availableFields = action.payload.fields;
      selected["groupedReportData"] = action.payload.data;
      // selected["groupedReportData"] = [...action.payload.data];
      state.selectedResource = selected;
    },
    reOrderFields: (state, action) => {
      let selected = Object.assign(state.selectedResource);
      selected.availableFields = action.payload;
      state.selectedResource = selected;
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["availableFields"] = action.payload;
    },
    updateComponentPositions: (state, action) => {
      const resList = [...state.resourceList];
      const layout = action.payload.layout;
      if (action.payload.layout && action.payload.layout.length > 0) {
        const updateResList = resList.map((item, index) => {
          return Object.assign(item, {
            layout: {
              x: layout?.[index]?.x || 0,
              y: layout?.[index]?.y || 0,
              h: layout?.[index]?.h || 0,
              i: layout?.[index]?.i || 0,
              w: layout?.[index]?.w || 0,
            },
          });
        });
        state.resourceList = updateResList;
      }
    },
    updateDataFilters: (state, action) => {
      //  console.log("updateDataFilters action ====>>>", action);

      let selected = Object.assign(state.selectedResource);
      let reportRowData = returnDataArrayObject(selected["reportData"]);
      //  console.log("selected ====>>>", selected);
      //  console.log("reportRowData ====>>>", reportRowData);
      selected.dataFilters = action.payload.dataFilters;
      selected.applyFilters = action.payload.applyFilters
      state.selectedResource = selected;
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["dataFilters"] = action.payload.dataFilters;
      updateResList[updateIndex]["applyFilters"] = action.payload.applyFilters
      // updateResList[updateIndex] = Object.assign(updateResList[updateIndex], { 'reportData': [...reportRowData].filter(item => item[action.payload[0]['fieldName']] === action.payload[0]['value']) })
    },

    updateSelectedWidjet: (state, action) => {
      let selected = Object.assign(state.selectedResource);
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);

      if (
        updateResList[updateIndex]["widjet"] &&
        updateResList[updateIndex]["widjet"]
      ) {
        updateResList[updateIndex]["widjet"] = action.payload;
      } else {
        updateResList[updateIndex]["widjet"] = action.payload;
      }

      if (selected["widjet"] && selected["widjet"]) {
        selected["widjet"] = action.payload;
      } else {
        selected["widjet"] = action.payload;
      }

      state.resourceList = updateResList;
      state.selectedResource = selected;
    },

    updateChart: (state, action) => {
      let selected = Object.assign(state.selectedResource);
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);

      if (
        updateResList[updateIndex]["charts"] &&
        updateResList[updateIndex]["charts"]
      ) {
        updateResList[updateIndex]["charts"] = action.payload;
      } else {
        updateResList[updateIndex]["charts"] = action.payload;
      }

      if (selected["charts"] && selected["charts"]) {
        selected["charts"] = action.payload;
      } else {
        selected["charts"] = action.payload;
      }

      state.resourceList = updateResList;
      state.selectedResource = selected;
    },

    updateAggGroupBuilders: (state, action) => {
      let selected = Object.assign(state.selectedResource);
      let currentAvailableFields = selected.availableFields;
      selected.aggGroupBuilders = action.payload;
      selected.availableFields = currentAvailableFields;
      state.selectedResource = selected;
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["aggGroupBuilders"] = action.payload;
      state.resourceList = updateResList;
    },

    updateAggRowBuilders: (state, action) => {
      //  console.log("updateAggRowBuilders state ====>>>", state);
      let selected = Object.assign(state.selectedResource);
      let reportRowData = returnDataArrayObject(selected["reportData"]);
      // console.log("selected ====>>>", selected);
      // console.log("reportRowData ====>>>", reportRowData);
      // console.log("AggRowData===>>", action.payload);
      selected.aggRowBuilders = action.payload;
      state.selectedResource = selected;
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      updateResList[updateIndex]["aggRowBuilders"] = action.payload;
      // updateResList[updateIndex] = Object.assign(updateResList[updateIndex], { 'reportData': [...reportRowData].filter(item => item[action.payload[0]['fieldName']] === action.payload[0]['value']) })
      state.resourceList = updateResList;
    },

    updateAggColBuilders: (state, action) => {
      let selected = Object.assign(state.selectedResource);
      // selected.dataFilters = action.payload
      // state.selectedResource = selected
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);
      console.log("updateIndex ====>>>", updateIndex);
      if (
        updateResList[updateIndex]["aggregation"] &&
        updateResList[updateIndex]["aggregation"]["row"]
      ) {
        updateResList[updateIndex]["aggregation"]["row"] = action.payload;
      } else {
        updateResList[updateIndex]["aggregation"] = {
          row: action.payload,
        };
      }

      if (selected["aggregation"] && selected["aggregation"]["row"]) {
        selected["aggregation"]["row"] = action.payload;
      } else {
        selected["aggregation"] = {
          row: action.payload,
        };
      }
      // // updateResList[updateIndex] = Object.assign(updateResList[updateIndex], { 'reportData': [...reportRowData].filter(item => item[action.payload[0]['fieldName']] === action.payload[0]['value']) })
      state.resourceList = updateResList;
      state.selectedResource = selected;
    },
    addDashboardConfig: (state, action) => {
      //console.log("addDashboardConfig action====.>>>>", action)
      state.dashboardConfig = action.payload;
    },
    resetEditor: (state) => {
      //  console.log("action===updateSelectedResource==>>", action)
      state.selectedResource = null;
      state.resourceList = [];
      state.resourceListStatus = "default";
      // state.dashboardConfig = {}
    },
    resetAllEditorData: (state) => {
      state.selectedResource = null;
      state.resourceList = [];
      state.resourceListStatus = "default";
      state.dashboardConfig = null;
      state.boardSavingState = { status: "DEFAULT" };
      state.boardPublishState = { status: "DEFAULT" };
      state.savedBoardId = null;
    },
    resetBoardSavingState: (state) => {
      state.boardSavingState = { ...state.boardSavingState, status: "DEFAULT" };
    },
    resetBoardPublishState: (state) => {
      state.boardPublishState = { status: "DEFAULT" };
    },

    deleteFilters: (state, action) => {
      const { id, filterType } = action.payload;
      let selected = Object.assign(state.selectedResource);
      let updateResList = [...state.resourceList];
      let updateIndex = updateResList.findIndex((ur) => ur.id === selected.id);

      if (Boolean(updateResList[updateIndex][filterType]?.length)) {
        updateResList[updateIndex][filterType] = updateResList[updateIndex][
          filterType
        ].filter((i) => i.id !== id);
      }

      if (Boolean(selected?.[filterType]?.length)) {
        selected[filterType] = selected[filterType].filter((i) => i.id !== id);
      }

      state.resourceList = updateResList;
      state.selectedResource = selected;
    },

    updateDashboardName: (state, action) => {
      let currentConfig=Object.assign(state.dashboardConfig)
      currentConfig.boardName=action.payload
      state.dashboardConfig = currentConfig;
    },
  },
  extraReducers: {
    [saveDashboardAsync.pending]: (state) => {
      state.boardSavingState = { status: "PENDING" };
    },
    [saveDashboardAsync.fulfilled]: (state, action) => {
      if (action.payload.status === "BadRequest") {
        state.boardSavingState = { status: "BadRequest", ...action.payload };
      } else {
        state.boardSavingState = { status: "SUCCESS", ...action.payload };
        state.savedBoardId = action.payload.id;
      }
    },
    [saveDashboardAsync.rejected]: (state, action) => {
      state.boardSavingState = { status: "ERROR" };
    },
    [publishDashboardAsync.pending]: (state) => {
      state.boardPublishState = { status: "PENDING" };
    },
    [publishDashboardAsync.fulfilled]: (state, action) => {
      state.boardPublishState = { status: "SUCCESS", ...action.payload };
    },
    [publishDashboardAsync.rejected]: (state, action) => {
      state.boardPublishState = { status: "ERROR", ...action.payload };
    },
    [getDashboardDetailsAsync.fulfilled]: (state, action) => {
      state.resourceList = action.payload.selectedResources;
      state.dashboardConfig = action.payload.dashboardConfig;
      state.selectedResource = action.payload.selectedResource;
      state.properties = action.payload.properties;
    },
  },
});

export const {
  addResouece,
  removeResource,
  resetResourceRemoveState,
  resetResourceAddStatus,
  addResoueceList,
  deleteResource,
  updateResourceSource,
  modifyResourceSource,
  updateResourceSourceAndFilter,
  updateSelectedResource,
  updateSelectedResourceWidgetName,
  updateSelectedFields,
  updateGroupedSelectedFields,
  reOrderFields,
  updateComponentPositions,
  updateDataFilters,
  updateAggRowBuilders,
  updateAggColBuilders,
  addDashboardConfig,
  resetEditor,
  resetAllEditorData,
  updateChart,
  resetBoardSavingState,
  resetBoardPublishState,
  updateSelectedWidjet,
  deleteFilters,
  updateDashboardName,
  updateGroupedReportData,
  updateAggGroupBuilders,
  resetAggBuilders,
  resetGroupBuilders,
  updateReportData
  // updateAllFields,
} = editorSlice.actions;

export default editorSlice.reducer;
