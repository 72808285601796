import React from 'react';
import { Table } from 'antd';
import '../../components/ReportGrid/reportGrid.css';


class RowDataTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = { total: 0, result: [], fieldList: [] };

        // this.onCalculationValue = this.onCalculationValue.bind(this);
    }



    render() {

        const columns = this.props.availableFields ?
            this.props.availableFields.map((af, i) => {
                return {
                    title: af.fieldName ? af.fieldName : af.title ? af.title : '',
                    dataIndex: af.dataIndex ? af.dataIndex : af.title ? af.title : i,
                    key: `key${i}_${af.fieldName ? af.fieldName : af.title ? af.title : ''}`,
                }
            }
            )
            : this.props.reportData && this.props.reportData.data && Object.keys(this.props['reportData']['data'][0]).map((item) => {
                return ({
                    title: item,
                    dataIndex: item,
                    key: item,
                })
            })


        return (
            <div>
                {this.props.reportData && this.props.reportData.data ?
                    <Table columns={columns} dataSource={this.props.reportData.data} className='report-table' />
                    : null}
            </div>

        )
    }
}


export default RowDataTable; // expose the HelloWorld component to other modules
