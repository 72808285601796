import React, { useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "../views/Login";
import Home from "../views/Home";
import Dashboard from "../views/Dashboard";
import Editor from "../views/Editor";
import Resources from "../views/Resources";
import AddResource from "../views/AddResource";
import ReportView from "../views/ReportView";
// import Authenticate from '../views/Login/Authenticate';
// import ProtectedRoute from '../views/ProtectedRoute';
import { getCurrentUserAsync } from "../redux/userSlice";
import Groups from "../views/Groups";
import AuthorizedRoute from "./AuthorizedRoute";
import ScheduleReports from "../views/ScheduleReports";
import ScheduleLogsTable from "../views/ScheduleReports/ScheduleLogsTable";
import CreateSchedule from "../views/ScheduleReports/CreateSchedule";
import ShareReportForm from "../views/ScheduleReports/ShareReportForm";

function AppRoutes() {
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.user);

  const fetchCurrentUser = useCallback(() => {
    dispatch(getCurrentUserAsync());
  }, [dispatch]);

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  return (
    <Router>
      <Switch>
        <Route path='/login'>
          {userdata.user && !userdata.user.isSigned ? (
            <Login />
          ) : (
            <Redirect to='/' />
          )}
        </Route>
        <Route exact path='/editor/:id/'>
          <Editor />
        </Route>
        <Route exact path='/editor/:id/:selectedId'>
          <Editor />
        </Route>

        <Route path='/resources'>
            <Resources />
        </Route>

        {/* <ProtectedRoute path="/resources" component={Resources} /> */}
        <Route path='/add-resource'>
          <AddResource />
          {/* {(userdata.user && userdata.user.isSigned) ?
            <Resources />
            : <Redirect to="/login" />
          } */}
        </Route>

        <AuthorizedRoute
          path={`/groups`}
          isAuthorized={true || (userdata.user && userdata.user.isSigned)}
        >
          <Groups />
        </AuthorizedRoute>

        <AuthorizedRoute
          path={`/scheduling`}
          isAuthorized={true || (userdata.user && userdata.user.isSigned)}
        >
          <ScheduleReports />
        </AuthorizedRoute>

        <AuthorizedRoute
          path={`/create-schedule`}
          isAuthorized={true || (userdata.user && userdata.user.isSigned)}
        >
          <CreateSchedule />
        </AuthorizedRoute>

        <AuthorizedRoute
          path={`/share-schedule-report`}
          isAuthorized={true || (userdata.user && userdata.user.isSigned)}
        >
          <ShareReportForm />
        </AuthorizedRoute>

        <AuthorizedRoute
          path={`/schedule-logs`}
          isAuthorized={true || (userdata.user && userdata.user.isSigned)}
        >
          <ScheduleLogsTable />
        </AuthorizedRoute>

        <Route path='/report-view'>
          <ReportView />
        </Route>

        <Route path='/board/:boardId'>
          <Dashboard />
        </Route>
        <Route path='/report/:boardId'>
          <Dashboard />
        </Route>

        <Route path='/'>
          {userdata.user && userdata.user.isSigned ? (
            <Home />
          ) : (
            <Redirect to='/login' />
          )}
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRoutes;
