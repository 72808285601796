import React from "react";
import { connect } from "react-redux";
import { Divider, Table } from "antd";
import "./reportGrid.css";
import { getCurrentDate, getCurrentTime } from "../../utils/date-time";
import { DATE_FORMAT } from "../../constants/dateConstants";
import moment from "moment";
import { useEffect } from "react";
import AdvancedGroupingTable from "./AdvancedGroupingTable";
function dev(arr) {
  // Creating the mean with Array.reduce
  let mean =
    arr?.reduce((acc, curr) => {
      return acc + curr;
    }, 0) / arr?.length;

  // Assigning (value - mean) ^ 2 to every array item
  arr = arr?.map((k) => {
    return (k - mean) ** 2;
  });

  // Calculating the sum of updated array
  let sum = arr?.reduce((acc, curr) => acc + curr, 0);

  // Calculating the variance
  // let variance = sum / arr.length

  // Returning the Standered deviation
  return Math.sqrt(sum / arr?.length);
}

function median(values) {
  if (values?.length === 0) return 0;

  values?.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values?.length / 2);

  if (values?.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

function ver(list) {
  // var sum = 0;
  var total = list?.reduce(function (a, b) {
    return a + b;
  });
  // console.log(total);

  var mean = total / list?.length;

  // document.write("<p>mean: "+mean+"</p>");

  function var_numerator(value) {
    return (value - mean) * (value - mean);
  }
  var variance = list?.map(var_numerator);

  variance = variance?.reduce(function (a, b) {
    return a + b;
  });

  return variance / list?.length;
}

// var aggData = [
//     { "id": 1627206254638, "dataType": "text", "aggType": "sum", filterType: "equal", fieldName: "timepoint" },
//     { "id": 1627206260443, "dataType": "text", "aggType": "sum", filterType: "equal", fieldName: "seeing" },
//     { "id": 1627206260443, "dataType": "text", "aggType": "maximum", filterType: "equal", fieldName: "temp2m" },
//     { "id": 1627206260443, "dataType": "text", "aggType": "sum", filterType: "equal", fieldName: "temp2m" },
//     { "id": 1627206260443, "dataType": "text", "aggType": "median", filterType: "equal", fieldName: "lifted_index" }
// ]

//console.log(result);

class ReportGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      result: {
        sum: [],
        average: [],
      },
      fieldList: [],
    };

    // this.onCalculationValue = this.onCalculationValue.bind(this);
  }

  Capitalize(str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }

  onCalculationValue = (aggrigationtype, field) => {
    if (aggrigationtype === "sum") {
      return this.props?.reportData?.data
        ?.reduce((a, curr) => Number(a) + Number(curr[field]), 0)
        ?.toFixed(2);
    } else if (aggrigationtype === "average") {
      return (
        this.props?.reportData?.data?.reduce(
          (a, curr) => Number(a) + Number(curr[field]),
          0
        ) / this.props?.reportData?.data?.length
      )?.toFixed(2);
    } else if (aggrigationtype === "minimum") {
      return Math.min(
        ...this.props?.reportData?.data?.map((o) => Number(o[field]))
      );
    } else if (aggrigationtype === "maximum") {
      return Math.max(
        ...this.props?.reportData?.data?.map((o) => Number(o[field]))
      );
    } else if (aggrigationtype === "count") {
      return this.props?.reportData?.data?.length;
    } else if (aggrigationtype === "standarddeviation") {
      return dev(this.props?.reportData?.data?.map((o) => Number(o[field])));
    } else if (aggrigationtype === "variance") {
      return ver(this.props?.reportData?.data?.map((o) => Number(o[field])));
    } else if (aggrigationtype === "median") {
      return median(this.props?.reportData?.data?.map((o) => Number(o[field])));
    }
    return null;
  };

  componentDidMount() {
    if (this.props?.aggRowBuilders) {
      this.setState({
        result: this.props?.aggRowBuilders?.reduce((r, a) => {
          r[a?.aggType] = r[a?.aggType] || [];
          r[a?.aggType]?.push(a);
          return r;
        }, Object.create(null)),
      });
    } else {
    }
  }

  getNestedTableColumns = (items) => {
    if (Boolean(items?.length)) {
      return Object.keys(items[0])
        ?.filter(
          (item) =>
            typeof items[0][item] === "number" ||
            typeof items[0][item] === "string"
        )
        ?.map((item) => {
          return {
            title: item,
            dataIndex: item,
            key: item,
          };
        });
    }
    return "";
  };

  getNestedView = (value) => {
    return Array.isArray(value) ? (
      Boolean(value?.length) ? (
        <Table
          columns={this.getNestedTableColumns(value)}
          dataSource={value}
          className='report-table'
          pagination={false}
          checked={false}
        />
      ) : (
        ""
      )
    ) : (
      <div classname="ccccc">{value}</div>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.aggRowBuilders &&
      prevProps?.aggRowBuilders !== this.props?.aggRowBuilders
    ) {
      this.setState({
        result: this.props?.aggRowBuilders?.reduce((r, a) => {
          r[a?.aggType] = r[a?.aggType] || [];
          r[a?.aggType]?.push(a);
          return r;
        }, Object.create(null)),
      });
    }
    if (
      this.props?.availableFields &&
      this.props?.availableFields !== prevProps?.availableFields
    ) {
      const columns = this.props?.availableFields
        ? this.props?.availableFields?.map((af, i) => {
          return {
            title: af?.fieldDisplayName ?? af?.fieldName,
            dataIndex: af?.fieldName,
            key: `${af?.fieldName}_key${i}`,
            render: (text, val) =>
              af?.dataType === "date" ? (
                <div>{moment(text)?.format(af?.format || DATE_FORMAT)}</div>
              ) :
                af?.dataType === "float" ? (
                  <div>{Number(text).toFixed(af.decimalPoints ? af.decimalPoints : 2)}</div>
                ) :
                  (
                    this.getNestedView(text)
                  ),
          };
        })
        : this.props?.reportData &&
        this.props?.reportData?.data &&
        Object.keys(this.props["reportData"]["data"][0])
          ?.filter(
            (item) =>
              typeof this.props.reportData.data[0][item] === "number" ||
              typeof this.props.reportData.data[0][item] === "string" ||
              Array.isArray(this.props.reportData.data[0][item])
          )
          ?.map((item) => {
            return {
              title: item,
              dataIndex: item,
              key: item,
              render: (text, val) => this.getNestedView(text),
            };
          });
      this.setState({
        fieldList: [
          { title: "", dataIndex: Math.random(), key: Math.random() },
          ...columns,
        ],
      });
    }
  }

  matched = (dataArr, a) => {
    return dataArr?.filter((b) => a === b?.fieldName);
  };

  getSelectedFieldsOnly = (values) => {
    return this.props.availableFields?.map((availabeField) => {
      return values.includes(availabeField.dataIndex) && availabeField.dataIndex
    })
  }

  getFilterdValue() {
    return Object.keys(this.props.reportData.data[0])
      ?.filter(
        (item) => typeof this.props.reportData.data[0][item] ===
          "number" ||
          typeof this.props.reportData.data[0][item] ===
          "string"
      )
      ?.filter(
        (itm) => this.props.availableFields.findIndex(
          (aa) => aa.dataIndex === itm
        ) > -1
      );
  }

  render() {
    // if ( this.props.reportData && this.props.reportData.data && this.props.reportData.data.length === 0)  {
    //   return (<div></div>)
    // }

      let columns = this.props?.availableFields
        ? this.props.availableFields?.map((af, i) => {
          return {
            title: af?.fieldDisplayName ?? af?.fieldName ?? af?.title ?? "",
            dataIndex: af?.dataIndex ?? af?.title ?? i,
            key: `key${i}_${af?.fieldName ?? af?.title ?? ""}`,
            render: (text, val) => {
              return af.dataType === "date" ? (
                <div>{moment(text).format(af?.format || DATE_FORMAT)}</div>
              ) :
                af?.dataType === "float" ? (
                  <div>{Number(text).toFixed(af.decimalPoints ? af.decimalPoints : 2)}</div>
                ) : (
                  this.getNestedView(text)
                );
            },
          };
        })
        : this.props?.reportData &&
        this.props.reportData?.data &&
        Object.keys(this.props.reportData.data[0])
          ?.filter(
            (item) =>
              typeof this.props.reportData.data[0][item] === "number" ||
              typeof this.props.reportData.data[0][item] === "string" ||
              Array.isArray(this.props.reportData.data[0][item]) === true
          )
          ?.map((item) => {
            return {
              title: item,
              dataIndex: item,
              key: item,
              render: (text, val) => this.getNestedView(text),
            };
          });
      const displayColumns = [
        { title: "", dataIndex: Math.random(), key: Math.random(), render: (text) => <span classname="xxxxx">{text}</span> },
        ...columns,
      ];

      return (
        <div className='report-grid'>
          {this.props?.reportData?.data?.length ? (
            this.props?.aggRowBuilders && this.props?.aggRowBuilders.length>0 ? (
              <Table
                // columns={this.props.reportData.data ? displayColumns : []}
                columns={displayColumns}
                dataSource={this.props.reportData.data}
                summary={() => (
                  <Table.Summary fixed>
                    <>
                      {this.state?.result?.sum?.map((field, index) => (
                        <Table.Summary.Row className='tblTotal'>
                          {field ? (
                            <Table.Summary.Cell index={0} colSpan={1}>
                              <b>{field?.displayName ?? ""}</b>
                            </Table.Summary.Cell>
                          ) : (
                            ""
                          )}
                          {Object.keys(this.props.reportData.data[0]) &&
                            this.getSelectedFieldsOnly(this.getFilterdValue())
                              ?.map((a) => {
                                return field ? (
                                  this.matched([{ ...field }], a).length ? (
                                    <Table.Summary.Cell index={1}>
                                      <b>
                                        {this.onCalculationValue(
                                          this.matched([{ ...field }], a)[0]
                                            .aggType,
                                          this.matched([{ ...field }], a)[0]
                                            .fieldName
                                        )}
                                      </b>
                                    </Table.Summary.Cell>
                                  ) : (
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                  )
                                ) : null;
                              })}
                        </Table.Summary.Row>
                      ))}
                      {this.state.result?.average?.map((field, index) => (
                        <Table.Summary.Row className='tblTotal'>
                          <>
                            {field ? (
                              <Table.Summary.Cell index={0} colSpan={1}>
                                <b>{field?.displayName ?? ""}</b>
                              </Table.Summary.Cell>
                            ) : (
                              ""
                            )}
                          </>
                          <>
                            {this.props.reportData?.data?.[0] &&
                              Object.keys(this.props.reportData.data[0]) &&
                              this.getSelectedFieldsOnly(this.getFilterdValue())
                                //.filter(item => this.props.availableFields.filter(fn => fn.checked).find(ff => ff.fieldName===item))
                                .map((a) => {
                                  return field ? (
                                    this.matched([{ ...field }], a).length ? (
                                      <Table.Summary.Cell index={1}>
                                        <b>
                                          {this.onCalculationValue(
                                            this.matched([{ ...field }], a)[0]
                                              .aggType,
                                            this.matched([{ ...field }], a)[0]
                                              .fieldName
                                          )}
                                        </b>
                                      </Table.Summary.Cell>
                                    ) : (
                                      <Table.Summary.Cell
                                        index={1}
                                      ></Table.Summary.Cell>
                                    )
                                  ) : null;
                                })}
                          </>
                        </Table.Summary.Row>
                      ))}
                      {this.state.result?.minimum?.map((field, index) => (
                        <Table.Summary.Row className='tblTotal'>
                          {field ? (
                            <Table.Summary.Cell index={0} colSpan={1}>
                              <b>{field?.displayName ?? ""}</b>
                            </Table.Summary.Cell>
                          ) : (
                            ""
                          )}
                          {this.getSelectedFieldsOnly(this.getFilterdValue())
                            .map((a) => {
                              if (field) {
                                let matched = [{ ...field }].filter(
                                  (b) => a === b.fieldName
                                );
                                if (matched.length) {
                                  return (
                                    <Table.Summary.Cell index={1}>
                                      <b>
                                        {this.onCalculationValue(
                                          matched[0].aggType,
                                          matched[0].fieldName
                                        )}
                                      </b>
                                    </Table.Summary.Cell>
                                  );
                                } else {
                                  return (
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                  );
                                }
                              }
                            })}
                        </Table.Summary.Row>
                      ))}
                      {this.state.result?.maximum?.map((field, index) => (
                        <Table.Summary.Row className='tblTotal'>
                          {field ? (
                            <Table.Summary.Cell index={0} colSpan={1}>
                              <b>{field?.displayName ?? ""}</b>
                            </Table.Summary.Cell>
                          ) : (
                            ""
                          )}
                          {this.getSelectedFieldsOnly(this.getFilterdValue())
                            .map((a) => {
                              if (field) {
                                let matched = [{ ...field }].filter(
                                  (b) => a === b.fieldName
                                );
                                if (matched.length) {
                                  return (
                                    <Table.Summary.Cell index={1}>
                                      <b>
                                        {this.onCalculationValue(
                                          matched[0].aggType,
                                          matched[0].fieldName
                                        )}
                                      </b>
                                    </Table.Summary.Cell>
                                  );
                                } else {
                                  return (
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                  );
                                }
                              }
                            })}
                        </Table.Summary.Row>
                      ))}
                      {this.state.result?.count?.map((field, index) => (
                        <Table.Summary.Row className='tblTotal'>
                          {field ? (
                            <Table.Summary.Cell index={0} colSpan={1}>
                              <b>{field?.displayName ?? ""}</b>
                            </Table.Summary.Cell>
                          ) : (
                            ""
                          )}
                          {this.getSelectedFieldsOnly(this.getFilterdValue())
                            .map((a) => {
                              if (field) {
                                let matched = [{ ...field }].filter(
                                  (b) => a === b.fieldName
                                );
                                if (matched.length) {
                                  return (
                                    <Table.Summary.Cell index={1}>
                                      <b>
                                        {this.onCalculationValue(
                                          matched[0].aggType,
                                          matched[0].fieldName
                                        )}
                                      </b>
                                    </Table.Summary.Cell>
                                  );
                                } else {
                                  return (
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                  );
                                }
                              }
                            })}
                        </Table.Summary.Row>
                      ))}
                      {this.state.result?.standarddeviation?.map(
                        (field, index) => (
                          <Table.Summary.Row className='tblTotal'>
                            {field ? (
                              <Table.Summary.Cell index={0} colSpan={1}>
                                <b>{field?.displayName ?? ""}</b>
                              </Table.Summary.Cell>
                            ) : (
                              ""
                            )}
                            {this.getSelectedFieldsOnly(this.getFilterdValue())
                              .map((a) => {
                                if (field) {
                                  let matched = [{ ...field }].filter(
                                    (b) => a === b.fieldName
                                  );
                                  if (matched.length) {
                                    return (
                                      <Table.Summary.Cell index={1}>
                                        <b>
                                          {this.onCalculationValue(
                                            matched[0].aggType,
                                            matched[0].fieldName
                                          )}
                                        </b>
                                      </Table.Summary.Cell>
                                    );
                                  } else {
                                    return (
                                      <Table.Summary.Cell
                                        index={1}
                                      ></Table.Summary.Cell>
                                    );
                                  }
                                }
                              })}
                          </Table.Summary.Row>
                        )
                      )}
                      {this.state.result?.variance?.map((field, index) => (
                        <Table.Summary.Row className='tblTotal'>
                          {field ? (
                            <Table.Summary.Cell index={0} colSpan={1}>
                              <b>{field?.displayName ?? ""}</b>
                            </Table.Summary.Cell>
                          ) : (
                            ""
                          )}

                          {this.getSelectedFieldsOnly(this.getFilterdValue())
                            .map((a) => {
                              if (field) {
                                let matched = [{ ...field }].filter(
                                  (b) => a === b.fieldName
                                );
                                if (matched.length) {
                                  return (
                                    <Table.Summary.Cell index={1}>
                                      <b>
                                        {this.onCalculationValue(
                                          matched[0].aggType,
                                          matched[0].fieldName
                                        )}
                                      </b>
                                    </Table.Summary.Cell>
                                  );
                                } else {
                                  return (
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                  );
                                }
                              }
                            })}
                        </Table.Summary.Row>
                      ))}
                      {this.state.result?.median?.map((field, index) => (
                        <Table.Summary.Row className='tblTotal'>
                          {field ? (
                            <Table.Summary.Cell index={0} colSpan={1}>
                              <b>{field?.displayName ?? ""}</b>
                            </Table.Summary.Cell>
                          ) : (
                            ""
                          )}

                          {this.getSelectedFieldsOnly(this.getFilterdValue())
                            .map((a) => {
                              if (field) {
                                let matched = [{ ...field }].filter(
                                  (b) => a === b.fieldName
                                );
                                if (matched.length) {
                                  return (
                                    <Table.Summary.Cell index={1}>
                                      <b>
                                        {this.onCalculationValue(
                                          matched[0].aggType,
                                          matched[0].fieldName
                                        )}
                                      </b>
                                    </Table.Summary.Cell>
                                  );
                                } else {
                                  return (
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                  );
                                }
                              }
                            })}
                        </Table.Summary.Row>
                      ))}
                    </>
                  </Table.Summary>
                )}
              />
            ) : this.props?.aggGroupBuilders?.advanced?
                <AdvancedGroupingTable 
                columns={columns} 
                dataSource={this.props.reportData.data} 
                summaryData={[]} 
                tableID={0} 
                groupConfig={this.props.aggGroupBuilders}   
                />
                // :<div>Hello</div>
              :<Table
                columns={columns}
                dataSource={this.props.reportData.data}
                className='report-table'
              />
              
            
          ) : <Table
          columns={columns}
          dataSource={[]}
          className='report-table'
        />}
        </div>
      );
    }

  
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ReportGrid); // expose the HelloWorld component to other modules
