
import React, { useEffect, useState, useCallback } from 'react';
import { Divider } from 'antd';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import jwt from 'jsonwebtoken';
import ReportInfo from './ReportInfo'
// import ReportGrid from '../../components/ReportGrid';
import { getSelectedResourcesAsync } from '../../redux/resourceSlice';
import { returnDataArrayObject } from '../../utils/arrayHelper'
import RowDataTable from './RowDataTable';

export default function ReportContainer() {
    const { resourceId } = useParams();
    const dispatch = useDispatch();
    const resources = useSelector((state) => state.resources);
    const [reportDataObject, setReportDataObject] = useState(null);
    const [reportDataStatus, setReportDataStatus] = useState('default');
    const [availableFields, setAvailableFields] = useState([]);

    const fetchSelectedResourcesAsync = useCallback(() => {
        dispatch(getSelectedResourcesAsync(resourceId))
    }, [dispatch, resourceId]);

    useEffect(() => {
        // const subscription = fetchCurrentUser();
        // return () => {
        //   subscription.unsubscribe();
        // };
        fetchSelectedResourcesAsync()

    }, [fetchSelectedResourcesAsync]);

    // useEffect(() => {
    //     async function fetchData() {
    //         console.log("resourceId", resourceId)
    //         if (!resources.selectedResource) {

    //             dispatch(getSelectedResourcesAsync(resourceId))
    //         }
    //     }
    //     fetchData();
    // }, [resourceId]);

    // useEffect(() => {
    //     async function fetchReportData() {
    //         console.log("resourceId", resourceId)
    //         console.log(" resources.selectedResource.connectionData.requestValues===>>>>", resources.selectedResource)
    //         if (resources.selectedResource && resources.selectedResource.resoueceType === "Rest API") {
    //             // const response = await axios.get(resources.selectedResource.connectionData.baseUrl);  

    //             try {
    //                 const requestValues = resources.selectedResource && resources.selectedResource.connectionData && resources.selectedResource.connectionData.requestValues;
    //                 const authInfo = resources.selectedResource && resources.selectedResource.connectionData && resources.selectedResource.connectionData.authInfo;
    //                 let urlParamSring = ""
    //                 if (requestValues.urlParams.length > 0) {
    //                     urlParamSring = urlParamSring + '?';
    //                     for (let i = 0; i < requestValues.urlParams.length; i++) {
    //                         if (requestValues.urlParams[i]['key'] !== '' && requestValues.urlParams[i]['value'] !== '') {
    //                             urlParamSring = urlParamSring + requestValues.urlParams[i]['key'] + '=' + requestValues.urlParams[i]['value'] + '&'
    //                         }
    //                     }
    //                     urlParamSring.slice(0, -1)
    //                 }

    //                 let headerObj = {}
    //                 if (requestValues.requestHeaders.length > 0) {
    //                     for (let i = 0; i < requestValues.requestHeaders.length; i++) {
    //                         if (requestValues.requestHeaders[i]['key'] !== '' && requestValues.requestHeaders[i]['value'] !== '') {
    //                             headerObj[requestValues.requestHeaders[i]['key']] = requestValues.requestHeaders[i]['value']
    //                         }
    //                     }
    //                 }

    //                 let bodyParamObj = {}
    //                 // if (requestValues.authParams) {
    //                 //     bodyParamObj = Object.assign({}, authParams);
    //                 // }
    //                 if (requestValues.bodyParams.length > 0) {
    //                     for (let i = 0; i < requestValues.bodyParams.length; i++) {
    //                         if (requestValues.bodyParams[i]['key'] !== '' && requestValues.bodyParams[i]['value'] !== '') {
    //                             bodyParamObj[requestValues.bodyParams[i]['key']] = requestValues.bodyParams[i]['value']
    //                         }
    //                     }
    //                 }
    //                 // // await Axios.get(resources.selectedResource.connectionData.baseUrl)
    //                 if (authInfo.method === 'jwt') {
    //                     console.log("JWT PROCESS");
    //                     const timestamp = Math.floor(Date.now() / 1000) + 1000;
    //                     const privateKey = authInfo.parameters.privateKey
    //                     var payload = {
    //                         "aud": authInfo.parameters.aud,
    //                         "exp": timestamp,
    //                         "iss": authInfo.parameters.iss
    //                     };
    //                     var signOptions = {
    //                         "algorithm": "RS256"
    //                     };

    //                     var privateKeyVal = privateKey ? privateKey.replace(/\\n/g, '\n') : null;

    //                     if (privateKeyVal) {
    //                         try {
    //                             var token = jwt.sign(payload, privateKeyVal, signOptions);
    //                             console.log("Generated JWT authParams=====>>", token);
    //                             // setApiTestStatus("success")

    //                             await Axios({
    //                                 method: requestValues.httpMethod,
    //                                 url: resources.selectedResource.connectionData.baseUrl + urlParamSring,
    //                                 data: JSON.parse(JSON.stringify(bodyParamObj)),
    //                                 headers: {
    //                                     'Access-Control-Allow-Origin': '*',
    //                                     'Content-Type': 'application/json',
    //                                     Authorization: `Bearer ` + token,
    //                                 }
    //                             }).then((response) => {
    //                                 console.log(response.data);
    //                                 let resData = response.data ? response.data : response
    //                                 setReportDataStatus("success");
    //                                 let reportData=returnDataArrayObject(resData)
    //                                 setReportDataObject(reportData);
    //                                 if(reportData.data) {
    //                                 setAvailableFields(Object.keys(returnDataArrayObject(resData).data[0])
    //                                     .filter(fl => typeof (returnDataArrayObject(resData).data[0][fl]) === 'string' || typeof (returnDataArrayObject(resData).data[0][fl]) === 'number')
    //                                     .map((ok, i) => { return { id: i, fieldName: ok, checked: true, dataType: typeof (returnDataArrayObject(resData).data[0][ok]) } }))
    //                                 } else {
    //                                     setAvailableFields(null)
    //                                 }
    //                             })
    //                                 .catch((error) => {
    //                                     setReportDataStatus("error")
    //                                     setReportDataObject(null)
    //                                     console.log(error);
    //                                 });
    //                         } catch (e) {
    //                             console.log("JWT authPsign error=====>>", e);
    //                             setReportDataStatus("error")
    //                             setReportDataObject(null)
    //                         }


    //                     }
    //                 } else {
    //                     let httpReqConfig = {
    //                         method: requestValues.httpMethod,
    //                         url: resources.selectedResource.connectionData.baseUrl + urlParamSring
    //                     }

    //                     if (requestValues.bodyParams.length > 0) {
    //                         httpReqConfig.data = bodyParamObj;
    //                     }
    //                     if (requestValues.requestHeaders.length > 0) {
    //                         httpReqConfig.headers = headerObj;
    //                     }
    //                     Axios(httpReqConfig)
    //                         .then((response) => {
    //                             console.log(response.data);
    //                             let resData = response.data ? response.data : response
    //                             setReportDataStatus("success")
    //                             setReportDataObject(returnDataArrayObject(resData));
    //                             setAvailableFields(Object.keys(returnDataArrayObject(resData).data[0])
    //                                 .filter(fl => typeof (returnDataArrayObject(resData).data[0][fl]) === 'string' || typeof (returnDataArrayObject(resData).data[0][fl]) === 'number')
    //                                 .map((ok, i) => { return { id: i, fieldName: ok, checked: true, dataType: typeof (returnDataArrayObject(resData).data[0][ok]) } }))
    //                         })
    //                         .catch((error) => {
    //                             setReportDataStatus("error")
    //                             setReportDataObject(null)
    //                             console.log(error);
    //                         });
    //                 }
    //             } catch (error) {
    //                 setReportDataStatus("error")
    //                 setReportDataObject(null)
    //                 console.log(error);
    //             }
    //         }
    //     }
    //     // if (resources && resourceId) {
    //         // fetchReportData()
    //     // }
    // }, [resources, resourceId]);


    return (
        <>
            {resources.selectedResource ?
                <>
                    <ReportInfo selectedResource={resources.selectedResource} />
                    <Divider />
                    {/* <ReportGrid reportData={returnDataArrayObject(reportDataObject)} /> */}
                    {availableFields.length > 0 && reportDataObject ?
                        <RowDataTable availableFields={availableFields} reportData={reportDataObject} />
                        : null}
                </> :
                <div>Loading ...</div>
            }

            {reportDataStatus === "error" ?
                <div>Error</div> : null
            }
            {/* <Topic /> */}
        </>

    )
}
