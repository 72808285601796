import React from "react";
import _, { flatMap } from "lodash";
import {
  Input,
  Row,
  Col,
  Tabs,
  Radio,
  Typography,
  Drawer,
  Switch,
  message,
  Button,
  Space,
  Select,
} from "antd";
import {
  SaveOutlined,
  CloseOutlined,
  EyeOutlined,
  BugOutlined,
  InsertRowBelowOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  TableOutlined,
  BarChartOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import {
  AndroidFilled as AndroidFilledIcon,
  LogoutOutlined as LogoutIcon,
  UserOutlined as UserIcon,
  LeftOutlined as BackIcon
} from "@ant-design/icons";


import SplitPane from "react-split-pane";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Responsive, WidthProvider } from "react-grid-layout";
import ResourceEditor from "./ResourceEditor";
import ReportWidjet from "./ReportWidjet";
// import BottomNavigation from '@material-ui/core/BottomNavigation';
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import { EditOutlined } from '@ant-design/icons';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { DashboardLayout } from "ecologital-ui-library";
import {
  saveDashboardAsync,
  publishDashboardAsync,
  updateComponentPositions,
  addDashboardConfig,
  resetEditor,
  resetAllEditorData,
  resetBoardSavingState,
  resetBoardPublishState,
  updateSelectedWidjet,
  resetResourceRemoveState,
  getDashboardDetailsAsync,
  resetResourceAddStatus,
  updateDashboardName
} from "../../redux/editorSlice";
import "./editorStyles.css";
import { signout } from "../../redux/userSlice";
import MaterialDesignIcon from "../../components/common/MaterialDesignIcon";
import NoPermission from "../../components/common/NoPermission";
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const { Search } = Input;
const { TabPane } = Tabs;
const onSearch = (value) => console.log(value);
const { Paragraph } = Typography;

class ResizableHandles extends React.Component {
  static defaultProps = {
    className: "layout",
    rowHeight: 30,
    cols: { lg: 12, md: 6, sm: 6, xs: 4, xxs: 2 },
    verticalCompact: false,
    preventCollision: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      editorItems: [],
      currentBreakpoint: "lg",
      compactType: "horizontal",
      mounted: false,
      layouts: { lg: this.generateLayout() },
      newCounter: 0,
      activeKey: 2,
      styleClass: "parentgrid",
      splitPosition: 0.6 * window.innerHeight,
      selectedComponent: "",
      filters: true,
      fullscreen: false,
      refresh: false,
      autorefresh: false,
      refreshInterval: '5m',
      csvdownload: false,
      visible: false,
      value: "",
      layout: "",
      activeWidgetName: "table",
    };
    // const layout = this.generateLayout();

    // const layout = this.generateLayout();
    this.onPreview = this.onPreview.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.selectedId) {
      this.props.getDashboardDetailsAsync({ id: this.props.match.params.selectedId })
    }
    if (!this.props.dashboardConfig) {
      this.props.setDashboardConfig(
        JSON.parse(localStorage.getItem("currentDashboardConfig"))
      );
    }
  }

  componentWillUnmount() {
    this.props.resetAllEditorData();
  }

  changeReslutIds = () => {
    let maxValue = 0
    this.props.resourceList?.forEach(resource => {
      maxValue = Number(resource?.layout?.i) > maxValue ? Number(resource?.layout?.i) : maxValue
    });

    const resource = this.props.resourceList.map((resource, index) => {
      if (resource?.layout?.i) {
        return {
          i: resource.layout.i,
          x: resource.layout.x,
          y: resource.layout.y,
          w: resource.layout.w,
          h: resource.layout.h,
          component: resource.charts && Boolean(Object.values(resource.charts).length) ? 'chart' : 'table',
          reportData:
            resource &&
              resource.reportData
              ? resource.reportData
              : [],
          resourceId:
            resource && resource.id
              ? resource.id
              : null,
          source: resource,
        }
      }
    }).filter((resource) => resource);
    if (resource.length) {
      this.setState({
        layouts: { lg: resource },
        newCounter : Number(maxValue) + 1
      })
    }    
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.boardSavingState && this.props.boardSavingState.status) {
      if (this.props.boardSavingState.status === "SUCCESS") {
        message.success("Board successfully saved!");
        this.props.resetBoardSavingState();
      } else if (this.props.boardSavingState.status === "ERROR") {
        message.error("Oops, Board has not been saved!");
        this.props.resetBoardSavingState();
      } else if (this.props.boardSavingState.status === "BadRequest") {
        message.error(
          "Bad Request. Check your Configurations or sign in again to the reporting app"
        );
        this.props.resetBoardSavingState();
      }
    }

    if (this.props.boardPublishState && this.props.boardPublishState.status) {
      if (this.props.boardPublishState.status === "SUCCESS") {
        message.success("Board successfully published!");

        this.props.resetBoardPublishState();
      } else if (
        this.props.boardPublishState.status === "ERROR" ||
        this.props.boardPublishState.status === "BadRequest"
      ) {
        message.error("Oops, Board has not been publish!");
        this.props.resetBoardPublishState();
      }
    }

    if (this.props.lastDeletedResourceID && this.props.lastDeletedResourceID !== prevProps.lastDeletedResourceID) {
      let layoutData = [...this.state.layouts.lg]
      // alert(this.props.lastDeletedResourceID)
      let layoutDataArr = layoutData.filter((d, li) => this.props.lastDeletedResourceID !== (li + 1))
      this.setState({
        layouts: { lg: layoutDataArr.map((da, dIndex) => { return { ...da, id: dIndex + 1 } }) }
      })
      this.props.resetResourceRemoveState()
      this.changeReslutIds()
    }

    if(JSON.stringify(this.props.properties) !== JSON.stringify(prevProps.properties)) {
      this.setState({
        filters: this.props.properties.filters,
        fullscreen: this.props.properties.fullscreen,
        refresh: this.props.properties.refresh,
        autorefresh: this.props.properties.autorefresh,
        refreshInterval: this.props.properties.refreshInterval||'5m',
        csvdownload: this.props.properties.csvdownload,
      })
    }

    if ( !Boolean(prevProps?.resourceList?.length) 
      && this.props.resourceList !== prevProps.resourceList) {
      let maxValue = 0
      this.props.resourceList?.forEach(resource => {
      maxValue = Number(resource?.layout?.i) > maxValue ? Number(resource?.layout?.i) : maxValue
      });
      const resource = this.props.resourceList.map((resource) => {
        if (resource?.layout?.i) {
          return {
            i: resource.layout.i,
            x: resource.layout.x,
            y: resource.layout.y,
            w: resource.layout.w,
            h: resource.layout.h,
            component: resource.charts && Boolean(Object.values(resource.charts).length) ? 'chart' : 'table',
            reportData:
              resource &&
                resource.reportData
                ? resource.reportData
                : [],
            resourceId:
              resource && resource.id
                ? resource.id
                : null,
            source: resource,
          }
        }
      }).filter((resource) => resource);
      if (resource.length) {
        this.setState({
          layouts: { lg: resource },
          newCounter : maxValue + 1
        })
      }
    }
  }

  // static defaultProps = {
  //   className: "layout",
  //   rowHeight: 30,
  //   cols: { lg: 12, md: 6, sm: 6, xs: 4, xxs: 2 },
  //   verticalCompact: false,
  //   preventCollision: true,
  // };

  onLayoutChange = (layout) => {
    this.setState({ layout: layout });
    // layout.map(function (j, key, list) {
    //   const pageData = {
    //     width: j.w,
    //     height: j.h,
    //     x: j.x,
    //     y: j.y,
    //   }
    // })
    this.props.updateWidjetPositions(layout);
  };

  async onPreview() {
    await this.setState({
      visible: true,
    });
  }

  generateDOM(el) {
    console.log("el===>>", this.state.layouts.lg);
    return (
      <div key={el.i} data-grid={el} onClick={this.onBoxClick.bind(this, el)}>
        {!this.state.visible ? (
          <>
            <div
              className='hide-button'
              style={{ zIndex: 2 }}
              onClick={this.onPutItem.bind(this, el)}
            >
              <CloseOutlined style={{ fontSize: "12px" }} />
            </div>
            <ReportWidjet
              widjetName={el.component}
              reportData={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .reportData
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).reportData
                  : null
              }
              groupedReportData={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .groupedReportData
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).groupedReportData
                  : null
              }
              selectedWidgetName={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .source?.widgetName
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).source?.widgetName
                  : ""
              }
              availableFields={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .availableFields
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).availableFields
                  : null
              }
              allFields={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .allFields
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).allFields
                  : null
              }
              dataFilters={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .dataFilters
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).dataFilters
                  : null
              }
              aggregation={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .aggregation
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).aggregation
                  : null
              }
              aggRowBuilders={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .aggRowBuilders
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).aggRowBuilders
                  : null
              }
              aggGroupBuilders={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .aggGroupBuilders
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).aggGroupBuilders
                  : null
              }
              charts={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .charts
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).charts
                  : null
              }
              activeWidgetName={this.props.resourceList &&
                this.props.resourceList.length > 0 &&
                this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                this.props.resourceList.find((rl) => rl.id === el.resourceId)
                  .reportData
                ? this.props.resourceList.find(
                  (rl) => rl.id === el.resourceId
                ).widjet
                : null}
                selectedResourceId={el.resourceId}
                applyFilters={this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId).applyFilters?
                    this.props.resourceList.find((rl) => rl.id === el.resourceId).applyFilters : false}
            />
          </>
        ) : (
          <>
            <ReportWidjet
              widjetName={el.component}
              reportData={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .reportData
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).reportData
                  : null
              }
              groupedReportData={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .groupedReportData
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).groupedReportData
                  : null
              }
              selectedWidgetName={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .source?.widgetName
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).source?.widgetName
                  : ""
              }
              availableFields={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .availableFields
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).availableFields
                  : null
              }
              allFields={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .allFields
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).allFields
                  : null
              }
              dataFilters={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .dataFilters
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).dataFilters
                  : null
              }
              aggregation={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .aggregation
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).aggregation
                  : null
              }
              aggRowBuilders={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .aggRowBuilders
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).aggRowBuilders
                  : null
              }
              charts={
                this.props.resourceList &&
                  this.props.resourceList.length > 0 &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                  this.props.resourceList.find((rl) => rl.id === el.resourceId)
                    .charts
                  ? this.props.resourceList.find(
                    (rl) => rl.id === el.resourceId
                  ).charts
                  : null
              }
              activeWidgetName={this.props.resourceList &&
                this.props.resourceList.length > 0 &&
                this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                this.props.resourceList.find((rl) => rl.id === el.resourceId)
                  .reportData
                ? this.props.resourceList.find(
                  (rl) => rl.id === el.resourceId
                ).widjet
                : null}
              selectedResourceId={el.resourceId}
              applyFilters={this.props.resourceList.length > 0 &&
                this.props.resourceList.find((rl) => rl.id === el.resourceId) &&
                this.props.resourceList.find((rl) => rl.id === el.resourceId).applyFilters?
                  this.props.resourceList.find((rl) => rl.id === el.resourceId).applyFilters : false}
            />
          </>
        )}
      </div>
    );
  }

  onBoxClick = (el) => {
    console.log(el);
    this.setState({ activeKey: 1 }); // <- set key sent by the handler to the state
  };

  handleChange = (selectedkey) => {
    this.setState({ activeKey: selectedkey }); // <- set key sent by the handler to the state
    // console.log(selectedkey);
  };

  onCompactTypeChange = () => {
    const { compactType: oldCompactType } = this.state;
    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
          ? null
          : "horizontal";
    this.setState({ compactType });
  };

  onDrop = (layout, layoutItem, _event) => {
    console.log("All Props===>>>", this.props);
    this.setState({
      layouts: {
        lg: this.state.layouts.lg.concat({
          i: this.state.newCounter.toString(),
          x:
            this.props.dashboardConfig.boardType === "reports"
              ? 0
              : layoutItem.x,
          y:
            this.props.dashboardConfig.boardType === "reports"
              ? 0
              : layoutItem.y,
          w: this.props.dashboardConfig.boardType === "reports" ? 12 : 8,
          h: this.props.dashboardConfig.boardType === "reports" ? 12 : 8,
          component: this.state.components,
          reportData:
            this.props.selectedResource &&
              this.props.selectedResource.reportData
              ? this.props.selectedResource.reportData
              : [],
          resourceId:
            this.props.selectedResource && this.props.selectedResource.id
              ? this.props.selectedResource.id
              : null,
          source: this.props.selectedResource,
        }),
      },
      newCounter: this.state.newCounter + 1,
    });
  };

  onToggle(selection, val) {
    this.setState({
      [selection]: val,
    });
    // window.location.href = "/editor/preview"
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onPutItem = (item) => {
    this.setState((prevState) => {
      return {
        layouts: {
          ...prevState.layouts,
          [prevState.currentBreakpoint]: prevState.layouts[
            prevState.currentBreakpoint
          ].filter(({ i }) => i !== item.i),
        },
      };
    });
  };

  onGridClick(value) {
    if (value === "a") {
      this.setState({ styleClass: "parentgrid" });
    } else {
      this.setState({ styleClass: "parent" });
    }
  }

  onDragStart = (ev, id) => {
    console.log("ev ===<<<<>>>", ev);
    this.setState({ components: id });
  };

  generateLayout() {
    return _.map(_.range(0, 0), function (item, i) {
      // console.log(item)
      var y = Math.ceil(Math.random() * 4) + 1;
      return {
        x: Math.round(Math.random() * 5) * 2,
        y: Math.floor(i / 6) * y,
        w: 2,
        h: y,
        i: i.toString(),
        static: Math.random() < 0.05,
      };
    });
  }

  saveDashboardDetails() {
    // this.onPreview();
    if (this.props.boardSavingState.status === 'DEFAULT') {
      const id = this.props.match?.params?.selectedId || this.props.boardSavingState.id; //this.props.boardSavingState && this.props.boardSavingState.id ? [...this.props.boardSavingState.id][0] : null
      const properties = {
        filters: this.state.filters,
        fullscreen: this.state.fullscreen,
        refresh: this.state.refresh,
        autorefresh: this.state.autorefresh,
        refreshInterval: this.state.refreshInterval,
        csvdownload: this.state.csvdownload,
      };
      this.props.saveDashboardInfo({
        properties: properties,
        publish: false,
        id: id,
      });
    }
  }

  saveAndPublishDashboardDetails() {

    if (this.props.boardPublishState.status === 'DEFAULT') {
      this.props.publishDashboard({
        id: this.props.boardSavingState.id,
        publish: true,
      });
    }
  }

  handleLogOut = () => {
    // console.log("LogOut");
    this.props.logOut();
    this.props.history.push("/");
  };

  handleBannerChange = (event, newValue) => {
    //setValue(newValue);
    if (newValue === "refresh") {
      //  setAnchorEl(event.currentTarget);
      //  setPlaying(false);
    } else if (newValue === "autorun") {
      //  actionTimeline();
    } else if (newValue === "fullscreen") {
      // handle.enter();
    } else if (newValue === "reload") {
      // window.location="/report/invoice/"+localStorage.getItem('appid')
    } else if (newValue === "barchart") {
      // handleClickOpen();
      // setPlaying(false);
    } else {
      //  setPlaying(false);
      // handle.exit();
    }
  };

  handleSelectedWidgetNameChange = (name) => {
    this.setState({
      activeWidgetName: name,
    });
    this.props.updateSelectedWidjetName(name)
  };

  handleUpdateReportName = (e) => {
    this.props.updateDashboardName(e.target.value)
  }

  render() {
    const { activeKey, visible } = this.state;
    return (
      <>
        <DashboardLayout>
          <DashboardLayout.AppBar
            appTitleProps={{
              appTitle: "Witmeg Reporting",
              appSubtitle: "Report Engine",
              appLogoUrl:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG5Omn5Fd0IYMYavsfGgXhc2guce2dLKv01X7zxSkPcO6kE4ZiVoqre-VOGLDSHPpEAZE&usqp=CAU",
              onClick: () => {
                this.props.history.push("/");
              },
            }}
            pageTitleProps={{
              pageTitle: "Dynamic Reports",
              pageSubtitle: "Customize Easy",
              onClick: () => {
                console.log("PageTitle CLICKED");
              },
            }}
            userMenuProps={{
              avatarValue:
                JSON.parse(
                  localStorage.getItem("fullUserDetails")
                ).FirstName.charAt(0) +
                JSON.parse(
                  localStorage.getItem("fullUserDetails")
                ).LastName.charAt(0),
              avatarIcon: <AndroidFilledIcon />,
              title:
                JSON.parse(localStorage.getItem("fullUserDetails")).FirstName +
                " " +
                JSON.parse(localStorage.getItem("fullUserDetails")).LastName,
              subTitle: "JohnDoe@Gmail.com",
              menuItems: [
                {
                  menuTitle: "Log Out",
                  menuIcon: <LogoutIcon />,
                  onClick: () => {
                    console.log("UserMenu -> LogOut CLICKED");
                    this.handleLogOut();
                  },
                },
              ],
            }}
            settingsMenuProps={{
              menuItems: [
                {
                  menuTitle: "User Settings",
                  menuIcon: <UserIcon />,
                  onClick: () => {
                    console.log("SettingsMenu -> UserSettings CLICKED");
                  },
                },
              ],
            }}
            messageMenuProps={{
              messageList: [
                {
                  avatarValue: "AB",
                  title: "New Data Added",
                  subtitle: "Noodles Added To Inventory.",
                },
              ],
            }}
          />


          <DashboardLayout.MainContent
          // breadcrumbProps={{
          // 	breadcrumbRoutes: [
          // 		{ breadcrumbName: 'Home', breadcrumbIcon: <HomeIcon /> },
          // 	],
          // }}
          >

            {localStorage.getItem("IsSpecialUser") || this.props.currentAppPermissions && this.props.currentAppPermissions.indexOf("CreateDashboard") > -1 ?
              <>
                <SplitPane
                  split='horizontal'
                  defaultSize='60%'
                  onChange={(size) => {
                    this.setState({ splitPosition: size });
                  }}
                >
                  <Row style={{ marginTop: "72px" }}>
                    {/* <Col span={24}>
                  &nbsp;
                </Col>
                <Col span={24}>
                  &nbsp;
                </Col> */}
                    <Col span={24}>
                      {/* <Navbar bg="light" expand="lg">
                    <Navbar.Brand href="#home">Report editor</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Button variant="outline-success" onClick={this.onPreview} size="small" ><EyeOutlined style={{ fontSize: '20px' }} /> Preview</Button>&nbsp;
                      <Button variant="outline-success" onClick={() => { this.saveDashboardDetails(); }}><SaveOutlined style={{ fontSize: '20px' }} /> Save  </Button>&nbsp;
                      <Button variant="outline-success" onClick={() => { this.saveAndPublishDashboardDetails(); }} disabled={this.props.boardSavingState && this.props.boardSavingState.status && this.props.boardSavingState.status !== 'SUCCESS'}><PublishIcon style={{ fontSize: '20px' }} />Publish </Button>
                    </Navbar.Collapse>
                  </Navbar> */}

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div style={{ marginRight: "1rem" }}>
                        <Button type="link" onClick={()=>this.props.history.goBack()}>
                          <BackIcon />
                        </Button>

                          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                            Report / Dashboard Editor
                          </span>
                        </div>
                        <div>
                          <Space>
                            <Button icon={<EyeOutlined />} onClick={this.onPreview}>
                              Preview
                            </Button>
                            <Button
                              icon={<SaveOutlined />}
                              onClick={() => {
                                this.saveDashboardDetails();
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              icon={
                                <MaterialDesignIcon
                                  icon='publish'
                                  styles={{ fontSize: "12px", paddingRight: "4px" }}
                                />
                              }
                              onClick={() => {
                                this.saveAndPublishDashboardDetails();
                              }}
                            >
                              Publish
                            </Button>
                          </Space>
                        </div>
                        <div style={{ marginLeft: "5rem" }}>
                          <div style={{ fontSize: "16px", fontWeight:500, display:'flex', alignItems:'center' }}>
                            <div> Name:&nbsp;</div>
                            <Input value={this.props?.dashboardConfig?.boardName } onChange={this.handleUpdateReportName}/>

                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={20} className='MiddleCol'>
                      <div className='Split12' style={{ overflowY: 'auto' }}>
                        <ResponsiveReactGridLayout
                          {...this.props}
                          layout={this.state.layout}
                          onLayoutChange={this.onLayoutChange}
                          onDrop={this.onDrop}
                          droppingItem={this.props.item}
                          measureBeforeMount={false}
                          useCSSTransforms={this.state.mounted}
                          compactType={this.state.compactType}
                          preventCollision={true}
                          isDroppable={true}
                          isDraggable={
                            this.props.dashboardConfig &&
                              this.props.dashboardConfig.boardType &&
                              this.props.dashboardConfig.boardType === "reports"
                              ? false
                              : true
                          }
                        >
                          {_.map(this.state.layouts.lg, (el) =>
                            this.generateDOM(el)
                          )}
                        </ResponsiveReactGridLayout>
                      </div>
                    </Col>
                    <Col span={4}>
                      <Tabs
                        defaultActiveKey={"" + activeKey}
                        onChange={this.handleChange}
                        activeKey={"" + activeKey}
                        id='tab1'
                      >
                        <TabPane
                          tab={
                            <span>
                              <BugOutlined />
                              Properties
                            </span>
                          }
                          key='1'
                        >
                          <Tabs defaultActiveKey='1' type='card' size='small'>
                            <TabPane tab='Components' key='1'>
                              <Switch
                                size='small'
                                onClick={this.onToggle.bind(this, "refresh")}
                                checked={this.state.refresh}
                              />{" "}
                              Show refresh button <br />
                              <Switch
                                size='small'
                                onClick={this.onToggle.bind(this, "autorefresh")}
                                checked={this.state.autorefresh}
                              />{" "}
                              Show auto refresh
                              <br />
                              {this.state.autorefresh?
                                <div style={{padding:'6px'}}>
                                  Refresh : 
                                  <Select value={this.state.refreshInterval} onChange={(val)=> this.setState({refreshInterval:val})}>
                                    <Select.Option value="30s">30 Seconds</Select.Option>
                                    <Select.Option value="1m">1 Minute</Select.Option>
                                    <Select.Option value="5m">5 Minutes</Select.Option>
                                    <Select.Option value="10m">10 Minutes</Select.Option>
                                    <Select.Option value="1h">1 Hour</Select.Option>
                                    <Select.Option value="3h">3 Hours</Select.Option>
                                    <Select.Option value="never">Never</Select.Option>
                                  </Select>
                                </div>
                                :null
                              }
                              <Switch
                                size='small'
                                onClick={this.onToggle.bind(this, "fullscreen")}
                                checked={this.state.fullscreen}
                              />{" "}
                              Show full screen button
                              <br />
                              <Switch
                                size='small'
                                onClick={this.onToggle.bind(this, "csvdownload")}
                                checked={this.state.csvdownload}
                              />{" "}
                              Allow csv download
                              <br />
                              <Switch
                                size='small'
                                onClick={this.onToggle.bind(this, "filters")}
                                checked={this.state.filters}
                              />{" "}
                              Show filters
                              <br />
                            </TabPane>

                            <TabPane tab='Modules' key='2'>
                              to do
                            </TabPane>
                          </Tabs>
                        </TabPane>
                        <TabPane
                          tab={
                            <span>
                              <InsertRowBelowOutlined />
                              Insert
                            </span>
                          }
                          key='2'
                        >
                          <Tabs defaultActiveKey='1' type='card' size='small'>
                            <TabPane tab='Components' key='1'>
                              <Row>
                                <Col span={16}>
                                  <Search
                                    placeholder='Search modules'
                                    size='large'
                                    onSearch={onSearch}
                                    style={{ width: "100%" }}
                                  />
                                </Col>
                                <Col span={8}>
                                  <Radio.Group defaultValue='a' size='large'>
                                    <Radio.Button
                                      onClick={this.onGridClick.bind(this, "a")}
                                      value='a'
                                    >
                                      <UnorderedListOutlined />
                                    </Radio.Button>
                                    <Radio.Button
                                      onClick={this.onGridClick.bind(this, "b")}
                                      value='b'
                                    >
                                      <AppstoreOutlined />
                                    </Radio.Button>
                                  </Radio.Group>
                                </Col>
                              </Row>

                              <div className={this.state.styleClass}>
                                <div
                                  className={
                                    this.state.activeWidgetName === "table"
                                      ? "child active-widget"
                                      : "child"
                                  }
                                >
                                  <Paragraph>
                                    <div className='prediv'>
                                      <div
                                        className='droppable-element'
                                        draggable={
                                          this.state.activeWidgetName === "table"
                                        }
                                        unselectable='on'
                                        onDragStart={(e) =>
                                          this.onDragStart(e, "table")
                                        }
                                        onClick={() => this.handleSelectedWidgetNameChange('table')}
                                      >
                                        <TableOutlined
                                          style={{
                                            fontSize: "250%",
                                            color: "#08c",
                                          }}
                                        />
                                        <div>
                                          Table{" "}
                                          <span>
                                            Display data in tabular format{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Paragraph>
                                </div>
                                <div
                                  className={
                                    this.state.activeWidgetName === "chart"
                                      ? "child active-widget"
                                      : "child"
                                  }
                                >
                                  <Paragraph>
                                    <div className='prediv'>
                                      <div
                                        className='droppable-element'
                                        draggable={
                                          this.state.activeWidgetName === "chart"
                                        }
                                        unselectable='on'
                                        onDragStart={(e) =>
                                          this.onDragStart(e, "chart")
                                        }
                                        onClick={() => this.handleSelectedWidgetNameChange('chart')}
                                      >
                                        <BarChartOutlined
                                          style={{
                                            fontSize: "250%",
                                            color: "#08c",
                                          }}
                                        />
                                        <div>
                                          Chart{" "}
                                          <span>Visual your data into graph </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Paragraph>
                                </div>

                                {/* <div className="child">

                              <Paragraph>

                                <div className="prediv"><div
                                  className="droppable-element"
                                  draggable={true}
                                  unselectable="on"
                                  onDragStart={(e) => this.onDragStart(e, "image")}
                                >
                                  <FileImageOutlined style={{ fontSize: '250%', color: '#08c' }} />
                                  <div>Image <span>Display any image </span></div>
                                </div></div>
                              </Paragraph>

                            </div> */}
                              </div>
                            </TabPane>
                            <TabPane tab='Modules' key='2'>
                              Content of card tab 2
                            </TabPane>
                          </Tabs>
                        </TabPane>
                      </Tabs>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <ResourceEditor
                        splitPosition={this.state.splitPosition}
                        activeWidgetName={this.state.activeWidgetName}
                      />

                    </Col>
                  </Row>
                </SplitPane>
              </>
              : <NoPermission />
            }
          </DashboardLayout.MainContent>


        </DashboardLayout>

        <Drawer
          title={
            <>
              <Row>
                <Col span={24}>
                  <>
                    {/* <BottomNavigation value={this.state.value} onChange={handleBannerChange} >
                    {this.state.autorefresh ? <BottomNavigationAction label="Refresh" value="refresh" icon={<MaterialDesignIcon icon="refresh" />} /> : null}
                    {this.state.fullscreen ? <BottomNavigationAction label="Auto Run" value="autorun" icon={<MaterialDesignIcon icon="directions_run" />} /> : null}
                    {this.state.csvdownload ? <BottomNavigationAction label="Export to CSV" value="reload" icon={<MaterialDesignIcon icon="import_export" />} /> : null}
                    {this.state.refresh ? <BottomNavigationAction label="Reload Page" value="reload" icon={<MaterialDesignIcon icon="reply" />} /> : null}
                    {this.state.fullscreen ? <BottomNavigationAction label="Full Screen" value="fullscreen" icon={<MaterialDesignIcon icon="fullscreen" />} /> : null}
                  </BottomNavigation> */}

                    <div
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        padding: "12px",
                      }}
                    >
                      <Space size='large'>
                        {this.state.autorefresh ? (
                          <Button
                            type='link'
                            onClick={(e) =>
                              this.handleBannerChange(e, "refresh")
                            }
                            icon={
                              <MaterialDesignIcon
                                icon='refresh'
                                styles={{ fontSize: "24px" }}
                              />
                            }
                          />
                        ) : null}
                        {this.state.fullscreen ? (
                          <Button
                            type='link'
                            onClick={(e) =>
                              this.handleBannerChange(e, "autorun")
                            }
                            value=''
                            icon={
                              <MaterialDesignIcon
                                icon='directions_run'
                                styles={{ fontSize: "24px" }}
                              />
                            }
                          />
                        ) : null}
                        {this.state.csvdownload ? (
                          <Button
                            type='link'
                            onClick={(e) =>
                              this.handleBannerChange(e, "export")
                            }
                            icon={
                              <MaterialDesignIcon
                                icon='import_export'
                                styles={{ fontSize: "24px" }}
                              />
                            }
                          />
                        ) : null}
                        {this.state.refresh ? (
                          <Button
                            type='link'
                            onClick={() => window.location.reload(false)}
                            icon={
                              <MaterialDesignIcon
                                icon='reply'
                                styles={{ fontSize: "24px" }}
                              />
                            }
                          />
                        ) : null}
                        {this.state.fullscreen ? (
                          <Button
                            type='link'
                            onClick={(e) =>
                              this.handleBannerChange(e, "fullscreen")
                            }
                            label='Full Screen'
                            value='fullscreen'
                            icon={
                              <MaterialDesignIcon
                                icon='fullscreen'
                                styles={{ fontSize: "24px" }}
                              />
                            }
                          />
                        ) : null}
                      </Space>
                    </div>
                  </>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  {this.state.filters ? <>Filter section</> : null}
                </Col>
              </Row>
            </>
          }
          placement='top'
          height='100%'
          closable={true}
          onClose={this.onClose}
          visible={visible}
          key='top'
        >
          <div className='PreviewLayout'>
            <ResponsiveReactGridLayout
              layout={this.state.layout}
              onLayoutChange={this.onLayoutChange}
              {...this.props}
              onDrop={this.onDrop}
              droppingItem={this.props.item}
              measureBeforeMount={false}
              useCSSTransforms={this.state.mounted}
              compactType={this.state.compactType}
              preventCollision={true}
              isDroppable={true}
            >
              {_.map(this.state.layouts.lg, (el) => this.generateDOM(el))}
            </ResponsiveReactGridLayout>
          </div>
        </Drawer>

      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { editor, user } = state;
  return {
    selectedResource: editor.selectedResource,
    // reportData: editor.selectedResource.reportData,
    // currentSource: editor.selectedResource.source,
    resourceList: editor.resourceList,
    dashboardConfig: editor.dashboardConfig,
    boardSavingState: editor.boardSavingState,
    boardPublishState: editor.boardPublishState,
    savedBoardId: editor.savedBoardId ? editor.savedBoardId : null,
    currentAppPermissions: user.currentAppPermissions ? user.currentAppPermissions : [],
    lastDeletedResourceID: editor.deletedResourceID,
    addedResourceId: editor.addedResourceId,
    properties: editor.properties
  };
};
const mapDispatchToProps = (dispatch) => ({
  saveDashboardInfo: (data) => dispatch(saveDashboardAsync(data)),
  publishDashboard: (data) => dispatch(publishDashboardAsync(data)),
  updateWidjetPositions: (layout) =>
    dispatch(updateComponentPositions({ layout: layout })),
  setDashboardConfig: (details) => dispatch(addDashboardConfig(details)),
  resetEditor: () => dispatch(resetEditor()),
  resetAllEditorData: () => dispatch(resetAllEditorData()),
  logOut: () => dispatch(signout()),
  resetBoardSavingState: () => dispatch(resetBoardSavingState()),
  resetBoardPublishState: () => dispatch(resetBoardPublishState()),
  updateSelectedWidjetName: (data) => dispatch(updateSelectedWidjet(data)),
  resetResourceRemoveState: () => dispatch(resetResourceRemoveState()),
  resetResourceAddStatus: () => dispatch(resetResourceAddStatus()),
  getDashboardDetailsAsync: (data) => dispatch(getDashboardDetailsAsync(data)),
  updateDashboardName: (data) => dispatch(updateDashboardName(data))

});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResizableHandles));
