import React, { useEffect } from 'react';
// import { Row, Col } from 'antd';
import ReportGrid from '../../../../components/ReportGrid'
import { returnDataArrayObject } from '../../../../utils/arrayHelper';
import NotFound from '../../../../components/NotFound';
export default function ResourceRowData(props) {
    const reportDataArr = returnDataArrayObject(props.reportDataObject) ? returnDataArrayObject(props.reportDataObject) : [];
    // const availableFields= Object.keys(reportDataArr.data[0]).map((x, i) => {
    //     return {
    //     title: x,
    //           dataIndex: x,
    //           key: `x_key${i}`,
    //     }
    // })
    // useEffect(() => {
    //     console.log("props.reportDataObject===>>", props.reportDataObject)
    // }, [props.reportDataObject])


    if(props.reportDataObject===undefined) {
        return (<div><NotFound /></div>)
    }

        return (

            <div style={{ overflowY: 'auto', height: '30vh' }}>
                {returnDataArrayObject(props.reportDataObject) ?
                    <ReportGrid reportData={reportDataArr} />
                    : <div><NotFound /></div>
                }
            </div>

        )
    
}