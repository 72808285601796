import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import DashboardView from "../DashboardView";
import { Row, Col, Button, Input, Typography, Popover, Space, Drawer } from "antd";
import { CalendarOutlined, CloseCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FileDoneOutlined, MailOutlined, MessageOutlined, MoreOutlined, PlusOutlined, WhatsAppOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getResourcesAsync,
  getResourceTypesAsync,
} from "../../redux/resourceSlice";
import CustomTable from "../Common/CustomTable";
import "./reports-logs.css"
import PopoverMenu from "../Common/PopoverMenu";
import Timeline from "../Common/Timeline";
// import Loading from "../../components/common/Spinners/Loading";

const { Search } = Input;

export default function ScheduleReports() {
  const history = useHistory();
  const { Search } = Input;
  const [data, setData] = React.useState([]);

  const resources = useSelector((state) => state.resources);
  const groups = useSelector((state) => state.boards.groups);
  const dispatch = useDispatch();

  const onSearchColName = (value) => {
    if (value) {
      let FilteredData = resources.resourceData?.filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.resoueceType.toLowerCase().includes(value.toLowerCase())
      );
      return setData((state) => [...FilteredData]);
    } else {
      return fetchResources();
    }
  };

  const fetchResourceTypes = React.useCallback(() => {
    dispatch(getResourceTypesAsync());
  }, [dispatch]);

  React.useEffect(() => {
    fetchResourceTypes();
  }, []);

  const fetchResources = React.useCallback(() => {
    dispatch(getResourcesAsync());
  }, [dispatch]);

  React.useEffect(() => {
    fetchResources();
  }, []);

  React.useEffect(() => {
    if (resources.resourceData) {
      setData(() => [...resources.resourceData]);
    }
  }, [resources.resourceData]);


  const navigateToScheduleLogs = ()=>{
    history.push("/schedule-logs");
  }

  const menuContent = [
    {
      name: "Edit",
      icon: <EditOutlined/>
    },
    {
      name: "Delete",
      icon: <DeleteOutlined/>
    },
    {
      name: "Schedule Logs",
      icon: <FileDoneOutlined/>,
      handleClick: ()=> navigateToScheduleLogs()
    }
  ]

  const tableHeader = [
    {
      title: "Report Name",
      dataIndex:"reportName",
      key:"reportName",
      filters: [
        {
          text:"Daily Sales Report",
          value: "Daily Sales Report"
        },
        {
          text:"VAT Totals",
          value: "VAT Totals"
        }
      ],
      filterSearch: true
    },
    {
      title: "Frequency",
      dataIndex:"frequency",
      key:"frequency"
    },
    {
      title: "File Type",
      dataIndex:"fileType",
      key:"fileType"
    },
    {
      title: "Schedule Type",
      dataIndex:"scheduleType",
      key:"scheduleType",
      render: (data,row)=>{
        return(
          data === "Email" ? <div style={{display:'flex',alignItems:'center'}}><MailOutlined style={{fontSize:'14px',marginRight:'5px',color:'#FF4A75'}}/><div>Email</div></div> : 
          data === "SMS" ? <div style={{display:'flex',alignItems:'center'}}><MessageOutlined style={{fontSize:'14px',marginRight:'5px',color:'#8C8AE8'}}/><div>Email</div></div> : 
          data === "WhatsApp" ? <div style={{display:'flex',alignItems:'center'}}><WhatsAppOutlined style={{fontSize:'14px',marginRight:'5px',color:'#40C351'}}/><div>WhatsApp</div></div> : 
          <span>{data}</span>
        )
      }
    },
    {
      title: "Scheduled Date & Time",
      dataIndex:"scheduledDateNTime",
      key:"scheduledDateNTime"
    },
    {
      title: "Time Zone",
      dataIndex:"timeZone",
      key:"timeZone"
    },
    {
      title: "Status",
      dataIndex:"status",
      key:"status",
      render: (data,row)=>{
        return (
          data === "Draft" ? <span style={{color:"#50D63A"}}>{data}</span> : data === "Published" ? <span style={{color:"#039BE5"}}>{data}</span> : {data}
        )
      }
    },
    {
      title: "Action",
      dataIndex:"action",
      key:"action",
      render: () => {
        return (
          <Space size="middle">
            <div onClick={()=> setShowReportSummaryPanel(true)}><EyeOutlined style={{fontSize:'16px'}}/></div>
            <PopoverMenu popoverContent={menuContent} trigger="click" placement="right">
              <div style={{width:'fit-content'}}><MoreOutlined style={{fontSize:'16px'}}/></div>
            </PopoverMenu>
          </Space>
        )
      }
    }
  ];

  const tableData = [
    {
      key:1,
      reportName: "Daily Sales Reports",
      frequency: "Monthly",
      fileType: "PDF",
      scheduleType:"Email",
      scheduledDateNTime:"Week days at 10:00 am",
      timeZone:"ECT GMT+1:00",
      status: "Draft",
    },
    {
      key:2,
      reportName: "VAT Totals",
      frequency: "Weekly",
      fileType: "HTML",
      scheduleType:"SMS",
      scheduledDateNTime:"Every sunday at 10:00 am",
      timeZone:"ECT GMT+1:00",
      status: "Published",
    },
    {
      key:2,
      reportName: "VAT Totals",
      frequency: "Weekly",
      fileType: "xls",
      scheduleType:"WhatsApp",
      scheduledDateNTime:"Every sunday at 10:00 am",
      timeZone:"ECT GMT+1:00",
      status: "Published",
    }  
  ];

  const[multiSelectOptions,setMultiSelectOptions] = useState([
    {
      label:'Gihan.Moonasinghe@ecologital.com',
      value:'blue',
      iconName:'',                    
    },
    {
        label:'Akila.Nilakshi@ecologital.com',
        value:'black',
        iconName:''                    
    },
    {
        label:'Chanuga@ecologital.com',
        value:'red',
        iconName:''
    },
    {
        label:'Anupa@ecologital.com',
        value:'green',
        iconName:''
    }
  ]);

  const[selectedMultiOption,setSelectedMultiOption]= useState([]);

  const getMultiSelectedOptions = (e)=>{
    console.log("getMultiSelectedOptions", e);
  }

  const items = [
    {
      children: "Upcoming Schedules",
      dot: <CalendarOutlined className="timeline-clock-icon"/>,
    },
    {
      children: <>
        <p>05Th of January 2023</p>
        <p>10:00AM</p>
      </>
    },
    {
      children: <>
        <p>05Th of January 2023</p>
        <p>10:00AM</p>
      </>,
    }
  ];

  {/* Right panel visible state */}

  const [showReportSummaryPanel,setShowReportSummaryPanel] = useState(false);

  {/*Right Panel Header*/}

  const rightPanelHeader = (
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <label>Report Summary</label>
      <Space>
        <Button type="primary">Edit</Button>
        <Button type="secondary" onClick={()=>setShowReportSummaryPanel(false)}>Close</Button>
      </Space>
    </div>
  )

  {/*Navigate to Create Schedule Page*/}

  const navigateToCreateSchedulePage = ()=>{
    history.push("/create-schedule");
  }

  return (
    <DashboardView>
      <Row gutter={[16,32]}>
        <Col span={4}>
          <h2>Schedule Reports</h2>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={4}>
              <Search allowClear/>
            </Col>
            <Col span={4} style={{textAlign:'right'}}>
              <Button type="primary" icon={<PlusOutlined/>} onClick={()=> navigateToCreateSchedulePage()}>Create Schedule</Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <CustomTable 
            tableClass="custom-table"
            tableHeaders={tableHeader} 
            tableData={tableData}
            />
        </Col>
      </Row>   
      <Drawer 
        title={rightPanelHeader}
        placement="right" 
        visible={showReportSummaryPanel} 
        onClose={()=> setShowReportSummaryPanel(false)}
        closeIcon={false}
        // closeIcon={<CloseCircleFilled style={{position:'absolute',right:'300px',left:'20px',fontSize:'32px',color:'#434556'}}/>}
        width={500}>
          <div style={{width:'100%'}}>
            <Row>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={8}>
                    <label className="form-label">Report Name</label>
                  </Col>
                  <Col span={16}>
                    <label className="form-data">:  Daily Sales by Payment Type </label>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={8}>
                    <label className="form-label">Frequency</label>
                  </Col>
                  <Col span={16}>
                    <label className="form-data">:  Monthly</label>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={8}>
                    <label className="form-label">File Type</label>
                  </Col>
                  <Col span={16}>
                    <label className="form-data">:  PDF</label>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={8}>
                    <label className="form-label">Schedule Type</label>
                  </Col>
                  <Col span={16}>
                    <label className="form-data">:  <WhatsAppOutlined style={{color:'#40C351'}}/> WhatsApp</label>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={8}>
                    <label className="form-label">Time Zone</label>
                  </Col>
                  <Col span={16}>
                    <label className="form-data">:  IST GMT+5:30 </label>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={8}>
                    <label className="form-label">Created by</label>
                  </Col>
                  <Col span={16}>
                    <label className="form-data">:  Gihan Moonasinghe </label>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={8}>
                    <label className="form-label">User list</label>
                  </Col>
                  <Col span={16}>
                    <ul style={{listStyle:"none",padding:"0"}}>
                      <li className="form-data">   Dinojan</li>
                      <li className="form-data">   Shamla Mansoor</li>
                      <li className="form-data">   Shenthuran Raja</li>
                      <li className="form-data">   Gulam Mashood</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={8}>
                    <label className="form-label">Status</label>
                  </Col>
                  <Col span={16}>
                    <label className="form-data" style={{color:'#039BE5'}}>:  Published </label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        <Timeline items={items}/>
      </Drawer>  
    </DashboardView>
  );
}
