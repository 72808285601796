import { Input, Radio, Row, Col, DatePicker } from 'antd'
import React from 'react'
import "../reports-logs.css"

const DailyForm = () => {
    return (
        <div>
            <Row className='mb-16'>
                <Col span={16}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio value="day" className='mr-16'>Every</Radio>
                        <Input className='mr-16 w-20' />
                        <label>day(s)</label>
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={24}>
                    <Radio value="day" className='mr-16'>Every weekday</Radio>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={24}>
                    <h3>Range of Recurrence</h3>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={16}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio value="day" className='mr-16'>End by</Radio>
                        <DatePicker/>
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={16}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Radio value="day" className='mr-16'>After</Radio>
                        <Input className='mr-16 w-20'/>
                        <label>messages</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={16}>
                    <Radio>No end date</Radio>
                </Col>
            </Row>
        </div>
    )
}

export default DailyForm