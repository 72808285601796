import * as _ from 'lodash';
import moment from 'moment';

export const chartHelper = (array) => {
  const reg = /^\d*\.?\d*$/;

  return {
    dateFormatChange : (format) => {
      if(!reg.test(array[0]) && _.isDate(new Date(array[0])) && moment(array[0]).isValid()){
        return array.map((value) => moment(value).format(format))
      }else if(reg.test(array[0])){
        return array.map((value) => Number(Number(value).toFixed(2)))
      }
      return array;
    },

    numberFormat : (decimal) => {
      if(reg.test(array[0])){
        return array.map((value) => Number(Number(value).toFixed(decimal)))
      }
      return array;
    }
  }
  
};