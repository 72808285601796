import React, { Component } from "react";
import { connect } from "react-redux";
import { WidthProvider, Responsive } from "react-grid-layout";
import * as _ from "lodash";
import Axios from "axios";
import jwt from "jsonwebtoken";
import moment from "moment";
import { Spin } from "antd";
import BoardDataTableContainer from "./BoardDataTableContainer";
import { returnDataArrayObject } from "../../../utils/arrayHelper";
import BoardChartContainer from "./BoardChartContainer";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class ReportWidjets extends Component {

  static defaultProps = {
    className: "reportview-layout",
    rowHeight: 40,
    cols: { lg: 12, md: 6, sm: 6, xs: 4, xxs: 2 },
    verticalCompact: false,
    preventCollision: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      layouts: null,
      reportJoinData: [],
      fieldsToShow: [],
      updateCount: 0,

    };
  }

  componentDidMount() {
    if (this.props.board && this.props.board.resources) {
      this.fetchReport();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.board) !== JSON.stringify(this.props.board)) {
      // this.fetchReport();
    }
  }

  fetchReport = async () => {
    if (this.props.board && this.props.board.resources) {

      let layoutData = this.props.board.resources.map(function (j, key) {
        return {
          i: j?.layout?.i || 0,
          x: j?.layout?.x || 0,
          y: j?.layout?.y || 0,
          w: j?.layout?.w || 4,
          h: j?.layout?.h || 6,
          widgetType: j.charts && Boolean(Object.values(j.charts).length) ? "chart" : "table",
          chartConfig: j?.charts || null,
          resourceId: j?.resourceId || null,
        };
      });
      this.props.board.resources &&
        this.props.board.resources.forEach(async (resource, idx) => {
          if (
            resource.connectionData &&
            resource.connectionData &&
            resource.connectionData.sources &&
            resource.connectionData.sources.length > 0
          ) {
            layoutData[idx].reportData = {
              rawData: { data: resource.reportData },
              fieldsToShow: resource.availableFields,
              aggregation: resource.aggregation
                ? resource.aggregation
                : null,
              aggRowBuilders: resource.aggRowBuilders
                ? resource.aggRowBuilders
                : null,
              aggGroupBuilders: resource.aggGroupBuilders
                ? resource.aggGroupBuilders
                : null,
              groupedReportData: resource.groupedReportData
                ? resource.groupedReportData
                : null,
              widgetName: resource.widgetName
                ? resource.widgetName
                : resource.connectionData &&
                  resource.connectionData.name
                  ? resource.connectionData.name
                  : "",
              connectionData: resource.connectionData,
            };
            this.setState({
              fieldsToShow: [
                ...this.state.fieldsToShow,
                resource.availableFields,
              ],
              layouts: layoutData,
            });
          } else {
            if (
              resource.connectionData &&
              resource.connectionData &&
              resource.connectionData.resoueceType === "Rest API"
            ) {
              // const response = await axios.get(resource.connectionData.baseUrl);
              const requestValues =
                resource.connectionData.connectionData.requestValues;
              const authInfo = resource.connectionData.connectionData.authInfo;
              const customFilters = resource.customFilters
                ? resource.customFilters
                : null;

              let urlParamSring = "";
              if (requestValues.urlParams.length > 0) {
                urlParamSring = urlParamSring + "?";
                for (let i = 0; i < requestValues.urlParams.length; i++) {
                  if (
                    requestValues.urlParams[i]["key"] !== "" &&
                    requestValues.urlParams[i]["value"] !== ""
                  ) {
                    urlParamSring =
                      urlParamSring +
                      requestValues.urlParams[i]["key"] +
                      "=" +
                      requestValues.urlParams[i]["value"] +
                      "&";
                  }
                }
                urlParamSring.slice(0, -1);
              }

              let headerObj = {};
              if (requestValues.requestHeaders.length > 0) {
                for (let i = 0; i < requestValues.requestHeaders.length; i++) {
                  if (
                    requestValues.requestHeaders[i]["key"] !== "" &&
                    requestValues.requestHeaders[i]["value"] !== ""
                  ) {
                    headerObj[requestValues.requestHeaders[i]["key"]] =
                      requestValues.requestHeaders[i]["value"];
                  }
                }
              }

              let bodyParamObj = {};
              // if (requestValues.authParams) {
              //     bodyParamObj = Object.assign({}, authParams);
              // }
              if (requestValues.bodyParams.length > 0) {

                for (let i = 0; i < requestValues.bodyParams.length; i++) {
                  if (
                    requestValues.bodyParams[i]["key"] !== "" &&
                    requestValues.bodyParams[i]["value"] !== ""
                  ) {
                    bodyParamObj[requestValues.bodyParams[i]["key"]] =
                      requestValues.bodyParams[i]["value"];
                  } else if (requestValues.bodyParams[i]["key"] !== "" && requestValues.bodyParams[i]["dataType"] === "date") {
                    if (requestValues.bodyParams[i]["dateConfig"]) {
                      let dateCf = requestValues.bodyParams[i]["dateConfig"];
                      if (dateCf.dateOption === "daysBefore") {
                        let dateVal = moment.utc().subtract(dateCf.timeUnits, 'day').startOf('day').format(dateCf.dateFormat);
                        bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
                      } else if (dateCf.dateOption === "today") {
                        let dateVal = moment().format(dateCf.dateFormat);
                        bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
                      }
                    }
                  }
                }
              }

              if (customFilters && customFilters.length > 0) {
                customFilters.forEach((element) => {
                  if (element.parameterName !== "") {
                    if (element.parameterType === "query") {
                      if (urlParamSring === "") {
                        urlParamSring = urlParamSring + "?";
                      }
                      urlParamSring =
                        urlParamSring +
                        element.parameterName +
                        "=" +
                        element.value +
                        "&";
                    } else {
                      if(element.value) {
                        bodyParamObj[element.parameterName] = element.value;
                      }
                    }
                  }
                });
              }
              // // await Axios.get(resource.connectionData.baseUrl)

              if (authInfo.method === "jwt") {
                console.log("JWT PROCESS");
                const timestamp = Math.floor(Date.now() / 1000) + 1000;
                const privateKey = authInfo.parameters.privateKey;
                let userToken = localStorage.getItem('accessToken');
                var payload = {
                  aud: authInfo.parameters.aud,
                  exp: timestamp,
                  iss: authInfo.parameters.iss,
                  userToken: userToken
                };
                var signOptions = {
                  algorithm: "RS256",
                };

                var privateKeyVal = privateKey
                  ? privateKey.replace(/\\n/g, "\n")
                  : null;

                if (privateKeyVal) {
                  try {
                    var token = jwt.sign(payload, privateKeyVal, signOptions);
                    console.log("Generated JWT authParams=====>>", token);
                    // setApiTestStatus("success")
                    let httpConfig = null;

                    if (
                      requestValues.bodyParams.length > 0 &&
                      resource.connectionData.connectionData.requestValues
                        .httpMethod === "post"
                    ) {
                      httpConfig = {
                        method: "post",
                        // url: resource.connectionData.connectionData.baseUrl + urlParamSring,
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": "application/json",
                          Authorization: `Bearer ` + token,
                        },
                        body: JSON.stringify(bodyParamObj),
                      };
                      await fetch(
                        resource.connectionData.connectionData.baseUrl +
                        urlParamSring,
                        httpConfig
                      )
                        .then((data) => data.json())
                        .then((response) => {
                          console.log(
                            "=====================response=========response==========>>>>>>>",
                            response
                          );
                          if (response) {
                            layoutData[idx].reportData = {
                              rawData: response
                                ? returnDataArrayObject(response)
                                : null,
                              fieldsToShow: resource.availableFields
                                ? resource.availableFields
                                : null,
                              aggregation: resource.aggregation
                                ? resource.aggregation
                                : null,
                              aggRowBuilders: resource.aggRowBuilders
                                ? resource.aggRowBuilders
                                : null,
                              aggGroupBuilders: resource.aggGroupBuilders
                                ? resource.aggGroupBuilders
                                : null,
                              groupedReportData: resource.groupedReportData && Boolean(resource.groupedReportData.length)
                                ? resource.groupedReportData
                                : null,
                              widgetName: resource.widgetName
                                ? resource.widgetName
                                : resource.connectionData &&
                                  resource.connectionData.name
                                  ? resource.connectionData.name
                                  : "",
                              connectionData: resource.connectionData,
                            };
                            console.log("layoutData===>>", layoutData);
                            this.setState({
                              fieldsToShow: [
                                ...this.state.fieldsToShow,
                                resource.availableFields,
                              ],
                              layouts: layoutData,
                            });
                          }
                        })

                        // await Axios(httpConfig).then(async (response) => {
                        //     // rowDataArr.push(returnDataArrayObject(response.data)
                        //     if (response && response.data) {
                        //         layoutData[idx].reportData = {
                        //             rawData: response.data ? returnDataArrayObject(response.data) : null,
                        //             fieldsToShow: resource.availableFields ? resource.availableFields : null,
                        //             aggregation: resource.aggregation ? resource.aggregation : null,
                        //             aggRowBuilders: resource.aggRowBuilders ? resource.aggRowBuilders : null
                        //         }
                        //         console.log("layoutData===>>", layoutData)
                        //         this.setState({
                        //             fieldsToShow: [...this.state.fieldsToShow, resource.availableFields],
                        //             layouts: layoutData
                        //         })
                        //     }

                        //     // setReportDataStatus("success")
                        //     // setReportDataObject(response.data ? response.data : response)
                        // })
                        .catch((error) => {
                          // setReportDataStatus("error")
                          // setReportDataObject(null)
                          console.log(error);
                        });
                    }
                    // if (requestValues.requestHeaders.length > 0) {
                    //     httpConfig.headers = { ...httpConfig.headers, ...headerObj }

                    // }
                  } catch (e) {
                    console.log("JWT authPsign error=====>>", e);
                  }
                }
              } else {
                console.log("Report fetch=====>>");
                let httpConfig = {
                  method:
                    resource.connectionData.connectionData.requestValues
                      .httpMethod,
                  url:
                    resource.connectionData.connectionData.baseUrl +
                    urlParamSring,
                };
                if (requestValues.bodyParams.length > 0) {
                  httpConfig.body = bodyParamObj;
                }
                if (requestValues.requestHeaders.length > 0) {
                  httpConfig.headers = headerObj;
                }

                await Axios(httpConfig)
                  .then(async (response) => {
                    console.log("Report resp=====>>", response);
                    // rowDataArr.push(returnDataArrayObject(response.data)
                    layoutData[idx].reportData = {
                      rawData: returnDataArrayObject(response.data),
                      fieldsToShow: resource.availableFields,
                      aggregation: resource.aggregation
                        ? resource.aggregation
                        : null,
                      aggRowBuilders: resource.aggRowBuilders
                        ? resource.aggRowBuilders
                        : null,
                      aggGroupBuilders: resource.aggGroupBuilders
                        ? resource.aggGroupBuilders
                        : null,
                      groupedReportData: resource.groupedReportData
                        ? resource.groupedReportData
                        : null,
                      widgetName: resource.widgetName
                        ? resource.widgetName
                        : resource.connectionData &&
                          resource.connectionData.name
                          ? resource.connectionData.name
                          : "",
                      connectionData: resource.connectionData,
                    };
                    this.setState({
                      fieldsToShow: [
                        ...this.state.fieldsToShow,
                        resource.availableFields,
                      ],
                      layouts: layoutData,
                    });

                    // setReportDataStatus("success")
                    // setReportDataObject(response.data ? response.data : response)
                  })
                  .catch((error) => {
                    // setReportDataStatus("error")
                    // setReportDataObject(null)
                    console.log(error);
                  });
              }
            }
          }
        });
    }
  };

  fecthMultiResReport = async (resource, index) => {
    let dataList = [];
    resource.connectionData.sources.forEach((src) => {
      let layoutData = this.props.board.resources.map(function (j, key) {
        return {
          i: j.layout && j.layout.i ? j.layout.i : 0,
          x: j.layout && j.layout.x ? j.layout.x : 0,
          y: j.layout ? j.layout.y : 0,
          w: j.layout ? j.layout.w : 4,
          h: j.layout ? j.layout.h : 6,
          widgetType: j.charts && Boolean(Object.values(j.charts).length) ? "chart" : "table",
        };
      });

      layoutData[index].reportData = {
        rawData: resource.reportData,
        fieldsToShow: resource.availableFields
          ? resource.availableFields
          : null,
        aggregation: resource.aggregation
          ? resource.aggregation
          : null,
        aggRowBuilders: resource.aggRowBuilders
          ? resource.aggRowBuilders
          : null,
        aggGroupBuilders: resource.aggGroupBuilders
          ? resource.aggGroupBuilders
          : null,
        groupedReportData: resource.groupedReportData
          ? resource.groupedReportData
          : null,
        widgetName: resource.widgetName
          ? resource.widgetName
          : resource.connectionData &&
            resource.connectionData.name
            ? resource.connectionData.name
            : "",
        connectionData: resource.connectionData,
      };

      this.setState({
        reportJoinData: [
          ...this.state.reportJoinData,
          {
            reportData: { data: resource.reportData },
            resourceId: src.id,
            name: src.name,
          },
        ],
        fieldsToShow: [
          ...this.state.fieldsToShow,
          resource.availableFields,
        ],
        layouts: layoutData,
      });

      // if (src && src.resoueceType === "Rest API") {
      //   // const response = await axios.get(resource.connectionData.baseUrl);
      //   const requestValues = src.connectionData.requestValues;
      //   const authInfo = src.connectionData.authInfo;
      //   const customFilters = resource.customFilters
      //     ? resource.customFilters
      //     : null;

      //   let urlParamSring = "";
      //   if (requestValues.urlParams.length > 0) {
      //     urlParamSring = urlParamSring + "?";
      //     for (let i = 0; i < requestValues.urlParams.length; i++) {
      //       if (
      //         requestValues.urlParams[i]["key"] !== "" &&
      //         requestValues.urlParams[i]["value"] !== ""
      //       ) {
      //         urlParamSring =
      //           urlParamSring +
      //           requestValues.urlParams[i]["key"] +
      //           "=" +
      //           requestValues.urlParams[i]["value"] +
      //           "&";
      //       }
      //     }
      //     urlParamSring.slice(0, -1);
      //   }

      //   let headerObj = {};
      //   if (requestValues.requestHeaders.length > 0) {
      //     for (let i = 0; i < requestValues.requestHeaders.length; i++) {
      //       if (
      //         requestValues.requestHeaders[i]["key"] !== "" &&
      //         requestValues.requestHeaders[i]["value"] !== ""
      //       ) {
      //         headerObj[requestValues.requestHeaders[i]["key"]] =
      //           requestValues.requestHeaders[i]["value"];
      //       }
      //     }
      //   }

      //   let bodyParamObj = {};
      //   // if (requestValues.authParams) {
      //   //     bodyParamObj = Object.assign({}, authParams);
      //   // }
      //   if (requestValues.bodyParams.length > 0) {
      //     for (let i = 0; i < requestValues.bodyParams.length; i++) {
      //       if (
      //         requestValues.bodyParams[i]["key"] !== "" &&
      //         requestValues.bodyParams[i]["value"] !== ""
      //       ) {
      //         bodyParamObj[requestValues.bodyParams[i]["key"]] =
      //           requestValues.bodyParams[i]["value"];
      //       }
      //     }
      //   }

      //   if (customFilters && customFilters.length > 0) {
      //     customFilters.forEach((element) => {
      //       if (element.parameterName !== "") {
      //         if (element.parameterType === "query") {
      //           if (urlParamSring === "") {
      //             urlParamSring = urlParamSring + "?";
      //           }
      //           urlParamSring =
      //             urlParamSring +
      //             element.parameterName +
      //             "=" +
      //             element.value +
      //             "&";
      //         } else {
      //           bodyParamObj[element.parameterName] = element.value;
      //         }
      //       }
      //     });
      //   }
      //   // // await Axios.get(resource.connectionData.baseUrl)

      //   if (authInfo.method === "jwt") {
      //     console.log("JWT PROCESS");
      //     const timestamp = Math.floor(Date.now() / 1000) + 1000;
      //     const privateKey = authInfo.parameters.privateKey;
      //     var payload = {
      //       aud: authInfo.parameters.aud,
      //       exp: timestamp,
      //       iss: authInfo.parameters.iss,
      //     };
      //     var signOptions = {
      //       algorithm: "RS256",
      //     };

      //     var privateKeyVal = privateKey
      //       ? privateKey.replace(/\\n/g, "\n")
      //       : null;

      //     if (privateKeyVal) {
      //       try {
      //         var token = jwt.sign(payload, privateKeyVal, signOptions);
      //         console.log("Generated JWT authParams=====>>", token);
      //         // setApiTestStatus("success")
      //         let httpConfig = null;

      //         if (
      //           requestValues.bodyParams.length > 0 &&
      //           src.connectionData.requestValues.httpMethod === "post"
      //         ) {
      //           httpConfig = {
      //             method: "post",
      //             // url: resource.connectionData.connectionData.baseUrl + urlParamSring,
      //             headers: {
      //               "Access-Control-Allow-Origin": "*",
      //               "Content-Type": "application/json",
      //               Authorization: `Bearer ` + token,
      //             },
      //             body: JSON.stringify(bodyParamObj),
      //           };
      //           console.log("layoutData resource===>>", resource);
      //           fetch(src.connectionData.baseUrl + urlParamSring, httpConfig)
      //             .then((data) => data.json())
      //             .then((response) => {
      //               if (response) {
      //                 layoutData[index].reportData = {
      //                   rawData: response
      //                     ? returnDataArrayObject(response)
      //                     : null,
      //                   fieldsToShow: resource.availableFields
      //                     ? resource.availableFields
      //                     : null,
      //                   aggregation: resource.aggregation
      //                     ? resource.aggregation
      //                     : null,
      //                   aggRowBuilders: resource.aggRowBuilders
      //                     ? resource.aggRowBuilders
      //                     : null,
      //                   aggGroupBuilders: resource.aggGroupBuilders
      //                     ? resource.aggGroupBuilders
      //                     : null,
      //                   groupedReportData: resource.groupedReportData
      //                     ? resource.groupedReportData
      //                     : null,
      //                   widgetName: resource.widgetName
      //                     ? resource.widgetName
      //                     : resource.connectionData &&
      //                       resource.connectionData.name
      //                     ? resource.connectionData.name
      //                     : "",
      //                   connectionData: resource.connectionData,
      //                 };
      //                 console.log("layoutData resource===>>", resource);
      //                 this.setState({
      //                   reportJoinData: [
      //                     ...this.state.reportJoinData,
      //                     {
      //                       reportData: { data: [] },
      //                       resourceId: src.id,
      //                       name: src.name,
      //                     },
      //                   ],
      //                   fieldsToShow: [
      //                     ...this.state.fieldsToShow,
      //                     resource.availableFields,
      //                   ],
      //                   layouts: layoutData,
      //                 });
      //               }
      //             })

      //             // await Axios(httpConfig).then(async (response) => {
      //             //     // rowDataArr.push(returnDataArrayObject(response.data)
      //             //     if (response && response.data) {
      //             //         layoutData[idx].reportData = {
      //             //             rawData: response.data ? returnDataArrayObject(response.data) : null,
      //             //             fieldsToShow: resource.availableFields ? resource.availableFields : null,
      //             //             aggregation: resource.aggregation ? resource.aggregation : null,
      //             //             aggRowBuilders: resource.aggRowBuilders ? resource.aggRowBuilders : null
      //             //         }
      //             //         console.log("layoutData===>>", layoutData)
      //             //         this.setState({
      //             //             fieldsToShow: [...this.state.fieldsToShow, resource.availableFields],
      //             //             layouts: layoutData
      //             //         })
      //             //     }

      //             //     // setReportDataStatus("success")
      //             //     // setReportDataObject(response.data ? response.data : response)
      //             // })
      //             .catch((error) => {
      //               // setReportDataStatus("error")
      //               // setReportDataObject(null)
      //               console.log(error);
      //             });
      //         }
      //         // if (requestValues.requestHeaders.length > 0) {
      //         //     httpConfig.headers = { ...httpConfig.headers, ...headerObj }

      //         // }
      //       } catch (e) {
      //         console.log("JWT authPsign error=====>>", e);
      //       }
      //     }
      //   } else {
      //     console.log("Report fetch=====>>");
      //     let httpConfig = {
      //       method: src.connectionData.requestValues.httpMethod,
      //       url: src.connectionData.baseUrl + urlParamSring,
      //     };
      //     if (requestValues.bodyParams.length > 0) {
      //       httpConfig.body = bodyParamObj;
      //     }
      //     if (requestValues.requestHeaders.length > 0) {
      //       httpConfig.headers = headerObj;
      //     }

      //     Axios(httpConfig)
      //       .then(async (response) => {
      //         console.log("Report resp=====>>", response);
      //         // rowDataArr.push(returnDataArrayObject(response.data)
      //         // layoutData[index].reportData = {
      //         //     rawData: returnDataArrayObject(response.data),
      //         //     fieldsToShow: resource.availableFields,
      //         //     aggregation: resource.aggregation ? resource.aggregation : null,
      //         //     aggRowBuilders: resource.aggRowBuilders ? resource.aggRowBuilders : null
      //         // }
      //         // dataList.push({
      //         //     layouts: layoutData
      //         // })

      //         let reportArr = [];

      //         this.setState({
      //           reportJoinData: [
      //             ...this.state.reportJoinData,
      //             {
      //               reportData: returnDataArrayObject(response.data),
      //               resourceId: src.id,
      //               name: src.name,
      //             },
      //           ],
      //           fieldsToShow: [
      //             ...this.state.fieldsToShow,
      //             resource.availableFields,
      //           ],
      //           layouts: layoutData,
      //         });

      //         // setReportDataStatus("success")
      //         // setReportDataObject(response.data ? response.data : response)
      //       })
      //       .catch((error) => {
      //         // setReportDataStatus("error")
      //         // setReportDataObject(null)
      //         console.log(error);
      //       });
      //   }
      // }
    });
  };

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("++++getDerivedStateFromProps++++");
  //   console.log(this.props);
  //   if (prevProps.selectedResource !== this.props.selectedResource) {
  //       this.fetchReport()
  //   }
  //   if (
  //     prevState.reportJoinData !== this.state.reportJoinData &&
  //     this.state.reportJoinData[0].reportData
  //   ) {
  //     console.clear();
  //     const { reportJoinData } = this.state;
  //     console.log("+++++++++++++++++", reportJoinData);
  //     let layoutData = this.props.board.resources.map(function (j, key) {
  //       return {
  //         i: j.layout && j.layout.i ? j.layout.i : 0,
  //         x: j.layout && j.layout.x ? j.layout.x : 0,
  //         y: j.layout ? j.layout.y : 0,
  //         w: j.layout ? j.layout.w : 4,
  //         h: j.layout ? j.layout.h : 6,
  //         widgetType: j.charts && Boolean(Object.values(j.charts).length) ? "chart" : "table",
  //         resourceId: '1' || null,
  //       };
  //     });
  //     let reportArr = [];
  //     this.props.board.resources && Boolean(this.props.board.resources.length) &&
  //       this.props.board?.resources?.forEach(async (resource, idx) => {
  //         resource?.joins?.forEach((li) => {
  //           let sets = [];
  //           reportJoinData.find((r1) => r1.resourceId === li.resource1Id) &&
  //             reportJoinData
  //               .find((r1) => r1.resourceId === li.resource1Id)
  //               .reportData.data.forEach((rd1) => {
  //                 reportJoinData.find(
  //                   (r2) => r2.resourceId === li.resource2Id
  //                 ) &&
  //                   reportJoinData
  //                     .find((r2) => r2.resourceId === li.resource2Id)
  //                     .reportData.data.forEach((rd2) => {
  //                       if (rd1[li.field1] === rd2[li.field2]) {
  //                         let oneData = {};
  //                         let twoData = {};
  //                         Object.keys(rd1).forEach((od) => {
  //                           oneData[
  //                             reportJoinData
  //                               .find((r) => r.resourceId === li.resource1Id)
  //                               .name.replace(/\s/g, "_") +
  //                               "_" +
  //                               od
  //                           ] = rd1[od];
  //                         });
  //                         Object.keys(rd2).forEach((td) => {
  //                           twoData[
  //                             reportJoinData
  //                               .find((r) => r.resourceId === li.resource2Id)
  //                               .name.replace(/\s/g, "_") +
  //                               "_" +
  //                               td
  //                           ] = rd2[td];
  //                         });
  //                         sets.push(Object.assign(oneData, twoData));
  //                       }
  //                     });
  //               });
  //           reportArr = _.merge(reportArr, sets);

  //           layoutData[idx].reportData = {
  //             rawData: returnDataArrayObject(reportArr),
  //             fieldsToShow: resource.availableFields,
  //             aggregation: resource.aggregation ? resource.aggregation : null,
  //             aggRowBuilders: resource.aggRowBuilders
  //               ? resource.aggRowBuilders
  //               : null,
  //             aggGroupBuilders: resource.aggGroupBuilders
  //               ? resource.aggGroupBuilders
  //               : null,
  //             groupedReportData: resource.groupedReportData
  //               ? resource.groupedReportData
  //               : null,
  //             widgetName: resource.widgetName
  //               ? resource.widgetName
  //               : resource.connectionData && resource.connectionData.name
  //               ? resource.connectionData.name
  //               : "",
  //             connectionData: resource.connectionData,
  //           };
  //         });
  //       });

  //     this.props.board.resources &&
  //       this.props.board.resources.forEach(async (resource, idx) => {});
  //     this.setState({
  //       layouts: layoutData,
  //     });
  //   }
  //   return null;
  // }

  fetchReportData = async () => {
    let rowDataArr = [];
  };

  generateDOM(el) {
    console.log("generateDOM EL===>>", el);
    const i = el.i;
    return (
      <div key={`dc_${i}`} data-grid={el} style={{ width: "100px !important" }}>
        {el.reportData &&
          el.reportData.rawData &&
          el.reportData.fieldsToShow ? (
          <>
            {el.widgetType === "table" ? (
              <BoardDataTableContainer
                reportData={el.reportData?.groupedReportData && Boolean(el.reportData?.groupedReportData.length)
                  ? { data: el.reportData.groupedReportData } : el.reportData.rawData}
                dataFilters={
                  this.props.selectedResource &&
                    this.props.selectedResource.dataFilters
                    ? this.props.selectedResource.dataFilters
                    : null
                }
                selectedResource={this.props.selectedResource}
                availableFields={el.reportData.fieldsToShow}
                aggregation={el.reportData.aggregation}
                aggGroupBuilders={el.reportData.aggGroupBuilders}
                aggRowBuilders={el.reportData.aggRowBuilders}
                widgetName={el.reportData.widgetName}
                createdAt={this.props.board.createdAt}
                updatedAt={this.props.board.updatedAt}
                connectionData={el.reportData.connectionData}
                resource={el}
              />
            ) : (
              <BoardChartContainer
                reportData={el.reportData.rawData}
                dataFilters={
                  this.props.selectedResource &&
                    this.props.selectedResource.dataFilters
                    ? this.props.selectedResource.dataFilters
                    : null
                }
                selectedResource={this.props.selectedResource}
                availableFields={el.reportData.fieldsToShow}
                aggregation={el.reportData.aggregation}
                aggRowBuilders={el.reportData.aggRowBuilders}
                widgetName={el.reportData.widgetName}
                createdAt={this.props.board.createdAt}
                updatedAt={this.props.board.updatedAt}
                connectionData={el.reportData.connectionData}
                charts={el.chartConfig}
                resourceId={el.resourceId}
              />
            )}
          </>
        ) : (
          <div
            style={{
              padding: "40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className='report-widget'>
        
          {this.state.layouts ? (
            <ResponsiveReactGridLayout
              breakpoints={{ lg: 1681, md: 1024, sm: 768, xs: 320, xxs: 0 }}
              // className='layout'
              isDroppable={false}
              {...this.props}
              isResizable={false}
              isDraggable={false}
            >
              {_.map(this.state.layouts, (el, index) => this.generateDOM(el))}
            </ResponsiveReactGridLayout>
          ) : null}
         
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//     const { boards } = state;
//     return {
//         selectedBoard: boards.selectedBoard,
//     }
// }
// const mapDispatchToProps = (dispatch) => ({
//     getBoardByIdAsync: (data) => dispatch(getBoardByIdAsync(data)),
//     updateSelectedBoard: (data) => dispatch(updateSelectedBoard(data))
// })
export default connect(null, null)(ReportWidjets);
