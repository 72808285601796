import React, { useEffect } from "react";
import { v4 } from "uuid";
import {
  Row,
  Col,
  Select,
  Divider,
  Button,
  Tag,
  message,
  Typography,
} from "antd";
import { useDispatch } from "react-redux";

import {
  resetGroupBuilders,
  updateAggGroupBuilders,
} from "../../../../../redux/editorSlice";
import NotFound from "../../../../../components/NotFound";
import { returnDataArrayObject } from "../../../../../utils/arrayHelper";

const { Option } = Select;

function tagRender(props) {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

const GroupColumns = (props) => {
  const dispatch = useDispatch();
  const [builders, setBuilders] = React.useState({
    id: v4(),
    isGrouping: true,
    operator: "none",
    fieldNamesList: [],
    sumFieldNamesList: [],
    displayFieldNamesList: [],
    uniqueFieldNamesList: [],
  });

  useEffect(() => {
    if(props.selectedResource?.aggGroupBuilders && Boolean(Object.values(props.selectedResource?.aggGroupBuilders).length)){
      setBuilders({
        id: props.selectedResource?.aggGroupBuilders?.id,
        isGrouping: props.selectedResource?.aggGroupBuilders?.isGrouping,
        operator: props.selectedResource?.aggGroupBuilders?.operator,
        fieldNamesList: props.selectedResource?.aggGroupBuilders?.fieldNamesList || [],
        sumFieldNamesList: props.selectedResource?.aggGroupBuilders?.sumFieldNamesList || [],
        displayFieldNamesList: props.selectedResource?.aggGroupBuilders?.displayFieldNamesList || [],
        uniqueFieldNamesList: props.selectedResource?.aggGroupBuilders?.uniqueFieldNamesList || [ ],
      })
    }else{
      setBuilders({
        id: v4(),
        operator: "none",
        fieldNamesList: [],
        sumFieldNamesList: [],
        displayFieldNamesList: [],
        uniqueFieldNamesList: [],
      })
    }
    
  }, [props.selectedResource.aggGroupBuilders])

  const applyBuilders = (buildersArr) => {
    dispatch(updateAggGroupBuilders(buildersArr));
    let frame=document.querySelector('iframe');
    if(frame) {
      frame.remove()
    }
  };

  const resetBuilders = () => {
    dispatch(resetGroupBuilders());

  };

  return (
    <div>
      <Typography.Title level={4}>Grouping</Typography.Title>
      <Divider />
      {returnDataArrayObject(props.reportDataObject) ? (
        <div style={{ width: "100%" }}>
          <Row gutter={16} style={{ marginBottom: "12px" }}>
            <Col span={6}>
              <div>Field Names:</div>
              <Select
                mode='multiple'
                showArrow
                tagRender={tagRender}
                style={{ width: "100%" }}
                value={builders.fieldNamesList}
                options={props?.allFields?.map((af) => {
                  return { value: af.fieldName };
                })}
                onChange={(val) =>
                  setBuilders((b) => ({ ...b, fieldNamesList: val }))
                }
              />
            </Col>
            <Col span={6}>
              <div>Operation:</div>
              <Select
                style={{ width: "90%" }}
                value={builders.operator}
                defaultValue='none'
                onChange={(val) =>
                  setBuilders((b) => ({
                    ...b,
                    operator: val,
                  }))
                }
              >
                <Option value='none'>None</Option>
                <Option value='sum'>Sum</Option>
                <Option value='count'>Count</Option>
                <Option value='sum&count'>Sum & Count</Option>
              </Select>
            </Col>
            {builders.operator !== "none" ? (
              builders.operator === "count" ? (
                <Col span={8}>
                  <div>Display Fields:</div>
                  <Select
                    mode='multiple'
                    showArrow
                    tagRender={tagRender}
                    style={{ width: "100%" }}
                    value={builders.displayFieldNamesList}
                    options={props?.allFields
                      ?.filter((af) =>
                        builders.fieldNamesList?.includes(af.fieldName)
                      )
                      ?.map((af) => {
                        return { value: af.fieldName };
                      })}
                    onChange={(val) =>
                      setBuilders((b) => ({
                        ...b,
                        displayFieldNamesList: val,
                      }))
                    }
                  />
                </Col>
              ) : (
                <>
                  <Col span={6}>
                    <div>Fields to get Sum:</div>
                    <Select
                      mode='multiple'
                      showArrow
                      tagRender={tagRender}
                      style={{ width: "100%" }}
                      value={builders.sumFieldNamesList}
                      options={props?.allFields
                        ?.filter(
                          (af) =>
                            !builders.fieldNamesList?.includes(af.fieldName)
                        )
                        ?.filter((af) => af.dataType === "number")
                        ?.map((af) => {
                          return { value: af.fieldName };
                        })}
                      onChange={(val) =>
                        setBuilders((b) => ({
                          ...b,
                          sumFieldNamesList: val,
                        }))
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <div>Display Fields:</div>
                    <Select
                      mode='multiple'
                      showArrow
                      tagRender={tagRender}
                      style={{ width: "100%" }}
                      value={builders.displayFieldNamesList}
                      options={props?.allFields
                        ?.filter(
                          (af) =>
                            builders.fieldNamesList?.includes(af.fieldName) ||
                            af.dataType === "number"
                        )
                        ?.map((af) => {
                          return { value: af.fieldName };
                        })}
                      onChange={(val) =>
                        setBuilders((b) => ({
                          ...b,
                          displayFieldNamesList: val,
                        }))
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <div>Unique Fields (Optional):</div>
                    <Select
                      mode='multiple'
                      showArrow
                      tagRender={tagRender}
                      style={{ width: "100%" }}
                      value={builders.uniqueFieldNamesList}
                      options={props?.allFields
                        ?.filter(
                          (af) =>
                            !builders.fieldNamesList?.includes(af.fieldName)
                        )
                        ?.filter((af) => af.dataType !== "number")
                        ?.map((af) => {
                          return { value: af.fieldName };
                        })}
                      onChange={(val) =>
                        setBuilders((b) => ({
                          ...b,
                          uniqueFieldNamesList: val,
                        }))
                      }
                    />
                  </Col>
                </>
              )
            ) : null}
          </Row>
        </div>
      ) : (
        <div>
          <NotFound />
        </div>
      )}
      <Row gutter={16} style={{ marginTop: "0.5rem" }}>
        <Col span={3}>
          <Button type='primary' onClick={() => applyBuilders(builders)}>
            Apply
          </Button>
        </Col>
        <Col span={3}>
          <Button onClick={resetBuilders}>Reset</Button>
        </Col>
      </Row>
    </div>
  );
};

export default GroupColumns;
