import Axios from "axios";
import * as _ from 'lodash';
import jwt from "jsonwebtoken";
import {getResourcesAsync} from './resourceSlice'
import moment from "moment";

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const { REACT_APP_CONNECTION_INFO_BASE_URL } = process.env;

// const JWT_TOKEN = `Bearer ${localStorage.getItem('accessToken')}`;
export const getBoardsAsync = createAsyncThunk(
    'boards/getBoards',
    async (token) => {
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/dashboard`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
        const data = await response.json();
        return data;

    }
);

const getSources = async (resource, dispatch) => {
    return await Promise.all(resource.connectionData?.sources.map(async (source) => ({
      ...source,
      reportData : { data : await getReportData(source, dispatch, true) } 
    })))
  };

export const fetchReportDataAsync = createAsyncThunk(
    'editor/details',
    async ({selectedResource, isCombined}) => {
        // console.log(" fetchReportData===>>>>", resources.selectedResource)
        if (
          selectedResource &&
          selectedResource.source 
        ) {
          // const response = await axios.get(resources.selectedResource.connectionData.baseUrl);
          // console.log(" fetchReportData===>>>>", selectedResource.source)
          try {
            const requestValues =
              selectedResource.source.connectionData.requestValues;
            const authInfo =
              selectedResource &&
              selectedResource.source.connectionData &&
              selectedResource.source.connectionData.authInfo;
            let urlParamSring = "";
            if (requestValues.urlParams.length > 0) {
              urlParamSring = urlParamSring + "?";
              for (let i = 0; i < requestValues.urlParams.length; i++) {
                if (
                  requestValues.urlParams[i]["key"] !== "" &&
                  requestValues.urlParams[i]["value"] !== ""
                ) {
                  urlParamSring =
                    urlParamSring +
                    requestValues.urlParams[i]["key"] +
                    "=" +
                    requestValues.urlParams[i]["value"] +
                    "&";
                }
              }
              urlParamSring.slice(0, -1);
            }
    
            let headerObj = {};
            if (requestValues.requestHeaders.length > 0) {
              for (let i = 0; i < requestValues.requestHeaders.length; i++) {
                if (
                  requestValues.requestHeaders[i]["key"] !== "" &&
                  requestValues.requestHeaders[i]["value"] !== ""
                ) {
                  headerObj[requestValues.requestHeaders[i]["key"]] =
                    requestValues.requestHeaders[i]["value"];
                }
              }
            }
    
            let bodyParamObj = {};
            // if (requestValues.authParams) {
            //     bodyParamObj = Object.assign({}, authParams);
            // }
            if (requestValues.bodyParams.length > 0) {
              for (let i = 0; i < requestValues.bodyParams.length; i++) {
                // if (
                //   requestValues.bodyParams[i]["key"] !== "" &&
                //   requestValues.bodyParams[i]["value"] !== ""
                // ) {
                //   bodyParamObj[requestValues.bodyParams[i]["key"]] =
                //     requestValues.bodyParams[i]["value"];
                // }

                if (
                  requestValues.bodyParams[i]["key"] !== "" &&
                  requestValues.bodyParams[i]["value"] !== ""
                ) {
                  bodyParamObj[requestValues.bodyParams[i]["key"]] =
                    requestValues.bodyParams[i]["value"];
                } else if ( requestValues.bodyParams[i]["key"] !== "" && requestValues.bodyParams[i]["dataType"] === "date") {
                  if(requestValues.bodyParams[i]["dateConfig"]) {
                    let dateCf = requestValues.bodyParams[i]["dateConfig"];
                    if(dateCf.dateOption === "daysBefore") {
                      let dateVal = moment.utc().subtract(dateCf.timeUnits, 'day').startOf('day').format(dateCf.dateFormat);
                      bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
                    } else if (dateCf.dateOption === "today") {
                      let dateVal = moment().format(dateCf.dateFormat);
                      bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
                    }
                  }
                }
              }
            }
            // // await Axios.get(resources.selectedResource.connectionData.baseUrl)
            if (authInfo.method === "jwt") {
              const timestamp = Math.floor(Date.now() / 1000) + 1000;
              const privateKey = authInfo.parameters.privateKey;
              var payload = {
                aud: authInfo.parameters.aud,
                exp: timestamp,
                iss: authInfo.parameters.iss,
              };
              var signOptions = {
                algorithm: "RS256",
              };
    
              var privateKeyVal = privateKey
                ? privateKey.replace(/\\n/g, "\n")
                : null;
    
              if (privateKeyVal) {
                try {
                  var token = jwt.sign(payload, privateKeyVal, signOptions);
                  // setApiTestStatus("success")
    
                  return  await Axios({
                    method: requestValues.httpMethod,
                    url:
                      selectedResource.source.connectionData.baseUrl +
                      urlParamSring,
                    data: JSON.parse(JSON.stringify(bodyParamObj)),
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ` + token,
                    },
                  })
                    .then((response) => {
                      const reportData = response.data 
                        ? isCombined ? response.data.Result : response.data 
                        : response;
                      return reportData
                    })
                    .catch((error) => {
                      return []
                    });
                } catch (e) {
                  return []
                }
              }
            } else {
              let httpReqConfig = {
                method:
                  selectedResource.source.connectionData.requestValues.httpMethod,
                url: selectedResource.source.connectionData.baseUrl + urlParamSring,
              };
    
              if (requestValues.bodyParams.length > 0) {
                httpReqConfig.data = bodyParamObj;
              }
              if (requestValues.requestHeaders.length > 0) {
                httpReqConfig.headers = headerObj;
              }
              return Axios(httpReqConfig)
                .then((response) => {
                  // console.log(response);
                  // setReportDataObject(response.data ? response.data : response)
                  const reportData = response.data 
                    ? isCombined ? response.data.Result : response.data
                    : response.Result;
                  return reportData
                })
                .catch((error) => {
                  return []
                  // setReportDataObject(null)
                  // console.log(error);
                });
            }
          } catch (error) {
            return []          // setReportDataObject(null)
            // console.log(error);
          }
        }
    })

    const getCombinedResourceData = async (resource, dispatch, resources) => {
        const restApiResource = await dispatch(getResourcesAsync());
        const sourceReportData = await getSources(resource, dispatch)
      
        const getSelectedApiResource = sourceReportData.map((item) => {
          return {
            id: item.id,
            name: item.name,
            resourceId: item.id,
            // selectedField: item.selectedField ? item.selectedField : null,
            // source: item.source ? item.source : null,
            reportData: item.reportData
          };
        })
      
        const joins = resource?.joins.map((join) => {
          return {
            id: join.id,
            resource1Id: join?.resource1Id || null,
            field1: join?.field1 || null,
            resource2Id: join?.resource2Id || null,
            field2: join?.field2 || null
          };
        });
      
          let reportArr = [];
          joins.forEach((li) => {
            let sets = [];
            getSelectedApiResource
              .find((r1) => r1.resourceId === li.resource1Id)
              ?.reportData.data.forEach((rd1) => {
                getSelectedApiResource
                  .find((r2) => r2.resourceId === li.resource2Id)
                  ?.reportData.data.forEach((rd2) => {
                    if (rd1[li.field1] === rd2[li.field2]) {
                      let oneData = {};
                      let twoData = {};
                      Object.keys(rd1).forEach((od) => {
                        oneData[
                          restApiResource?.payload?.data
                            .find((r) => r.id === li.resource1Id)
                            .name.replace(/\s/g, "_") +
                            "_" +
                            od
                        ] = rd1[od];
                      });
                      Object.keys(rd2).forEach((td) => {
                        twoData[
                          restApiResource?.payload?.data
                            .find((r) => r.id === li.resource2Id)
                            .name.replace(/\s/g, "_") +
                            "_" +
                            td
                        ] = rd2[td];
                      });
                      sets.push(Object.assign(oneData, twoData));
                    }
                  });
              });
            reportArr = _.merge(reportArr, sets);
          });
          return reportArr;
      }
export const getReportData = async (resource, dispatch, isCombined) => {
    const values = await dispatch(fetchReportDataAsync({
      selectedResource : {...resource, source : !isCombined ? resource.connectionData : resource }, isCombined } ))
    return values.payload
  }

export const getBoardByIdAsync = createAsyncThunk(
    'boards/getBoardById',
    async (payload, {dispatch}) => {
      let token = localStorage.getItem('accessToken')
      if(token) {
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/dashboard-details/${payload.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
        const data = await response.json();
        const selectedResources = await Promise.all(data?.data.resources.map(async (resource, index) => {
            return {
              ...resource,
              // reportData : resource.connectionData.id 
              //   ? await getReportData(resource, dispatch) 
              //   : await getCombinedResourceData(resource, dispatch, payload.resources)
            } 
        }));
        return { data : Object.assign({}, data.data, { resources: selectedResources }) }
      }
    }
);

export const deleteBoardByIdAsync = createAsyncThunk(
    'boards/deleteBoardById',
    async (payload) => {
      let token = localStorage.getItem('accessToken')
      if(token) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        };
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/dashboard/${payload}`, requestOptions)
        const data = await response.json();
        return { ...data, id: payload.id };
      }
    }
)

export const getGroupsAsync = createAsyncThunk(
    'boards/getGroups',
    async (token) => {
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/group`, { headers: { Authorization:  `Bearer ${localStorage.getItem('accessToken')}`, } })
        const data = await response.json();
        return data;
    }
)

export const saveGroupAsync = createAsyncThunk(
    'boards/saveGoup',
    async (payload) => {
        console.log("payload====>>", payload)
        const groupSavingData = {
            title: payload.groupName,
            groupType: payload.groupType,
            isPuplic: payload.isPuplic ? true : false,
            isGlobal: payload.isGlobal ? true : false,
            sharedWith: payload.sharedWith,
            createdBySpecial: payload.createdBySpecial,
            children: payload.subGroups.map(c => { return { title: c } })
        }
        if(payload.id){
            groupSavingData.id = payload.id
        }
        const requestOptions = {
            method: `${payload.isEdit ? 'PUT' :'POST'}`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            body: JSON.stringify(groupSavingData)
        };
        console.log("requestOptions====>>", requestOptions)
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/group`, requestOptions)
        // .then(resp => {

        // })
        const data = await response.json();
        return data;
    }
)

export const deleteGroupAsync = createAsyncThunk(
    'boards/deleteGroup',
    async (payload) => {

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        };
        console.log("requestOptions====>>", requestOptions)
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/group/${payload.id}`, requestOptions)
        const data = await response.json();
        return { ...data, id: payload.id };
    }
)

export const boardSlice = createSlice({
    name: 'boards',
    initialState: {
        boardsData: [],
        boardsStatus: 'default',
        selectedBoard: null,
        groups: [],
        groupsStatus: "default",
        groupSavingState: "default",
        boardDeleteState: "default",
        groupDeletingState: "default"
    },
    reducers: {
        updateSelectedBoard: (state, action) => {
            state.selectedBoard = action.payload;
        },
        resetGoupSavingState: (state) => {
            state.groupSavingState = "default";
        },
        setPublishState: (state, action) => {
            state.selectedBoard.publish = action.payload
        },
        resetBoardData: (state) => {
            state.boardDeleteState = "default"
            state.selectedBoard = null
        },
        resetAllBoardData: (state) => {
            state.boardDeleteState = "default"
            state.selectedBoard = null
            state.boardsStatus = 'default'
            state.groupsStatus = "default"
            state.groupSavingState = "default"
            state.boardDeleteState = "default"
            state.groupDeletingState = "default"
        },
        resetGroupDeletingState: (state) => {
            state.groupDeletingState = "default"
        }
    },
    extraReducers: {
        [getBoardsAsync.pending]: (state, action) => {
            // console.log("action====>>", action)
            state.boardsData = [];
            state.boardsStatus = 'pending';
        },
        [getBoardsAsync.fulfilled]: (state, action) => {
            // console.log("action====>>", action)
            if (action.payload.success) {
                state.boardsData = action.payload.data;
                state.boardsStatus = 'fulfilled';
            } else {
                state.boardsData = [];
                state.boardsStatus = 'error';
            }
        },
        [getBoardsAsync.error]: (state, action) => {
            state.boardsData = action.payload;
            state.boardsStatus = 'error';
        },
        [getBoardByIdAsync.pending]: (state, action) => {
            state.selectedBoard = null
            state.boardsStatus = 'pending';
        },
        [getBoardByIdAsync.fulfilled]: (state, action) => {
            state.selectedBoard = action.payload.data;
            state.boardsStatus = 'success';
        },
        [getGroupsAsync.pending]: (state, action) => {
            // console.log("action====>>", action)
            state.groups = [];
            state.groupsStatus = 'pending';
        },
        [getGroupsAsync.fulfilled]: (state, action) => {
            // console.log("action====>>", action)
            if (action.payload.success) {
                state.groups = action.payload.data;
                state.groupsStatus = 'fulfilled';
            } else {
                state.groups = [];
                state.groupsStatus = 'error';
            }

        },
        [saveGroupAsync.pending]: (state, action) => {
            state.groupSavingState = "pending"
        },
        [saveGroupAsync.fulfilled]: (state, action) => {
            state.groupSavingState = "success"
        },
        [saveGroupAsync.error]: (state, action) => {
            state.groupSavingState = "error"
        },

        [deleteGroupAsync.pending]: (state, action) => {
            state.groupDeletingState = "pending"
        },
        [deleteGroupAsync.fulfilled]: (state, action) => {
            state.groupDeletingState = "success"
            state.groups = state.groups.filter(g => g.id !== action.payload.id)
        },
        [deleteGroupAsync.error]: (state, action) => {
            state.groupDeletingState = "error"
        },

        [deleteBoardByIdAsync.pending]: (state, action) => {
            state.boardDeleteState = "pending"
        },
        [deleteBoardByIdAsync.fulfilled]: (state, action) => {
            state.boardDeleteState = "success"
        },
        [deleteBoardByIdAsync.error]: (state, action) => {
            state.boardDeleteState = "error"
        }
    },
});

export const { updateSelectedBoard, resetGoupSavingState, setPublishState, resetBoardData, resetAllBoardData, resetGroupDeletingState } = boardSlice.actions;

export default boardSlice.reducer;