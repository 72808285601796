import React, { useState, useEffect } from 'react';
import { Form, Input, Radio, Mentions, Button, Row, Col, Divider, Alert, Spin, Select, message } from 'antd'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { saveGroupAsync, getGroupsAsync, resetGoupSavingState } from "../../redux/boardSlice"
import MaterialDesignIcon from '../common/MaterialDesignIcon';
import './AddBoardGroup.css';
const { Option } = Mentions;

const AddBoardGroup = (props) => {
    // const [loading, setLoading] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [groupNamerror, setGroupNamerror] = useState(false);
    const [groupType, setGoupType] = useState(null);
    const [groupTypeError, setGroupTypeError] = useState(false);
    const [radioVal, setRadioVal] = useState(1);
    const [sharedListShow, setSharedListShow] = useState(true);
    const [usersList, setUsersList] = useState([]);
    const [userIdList, setUserIdList] = useState([]);
    const [subGroupNames, setSubGroupNames] = useState([""]);
    const [selectedGroupDetails, setSelectedGroupDetails] = useState({});
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    let history = useHistory();

    const boardSelector = useSelector((state) => state.boards);

    const onChangeRadio = e => {
        if (e.target.value === 1) {
            setRadioVal(1)
            setSharedListShow(true)
        } else if (e.target.value === 2) {
            setRadioVal(2)
            setSharedListShow(false)
        } else {
            setRadioVal(3)
            setSharedListShow(false)
        }
    };

    useEffect(() => {
        if (props.selectedGroup) {
            const { title, groupType, sharedWith } = props.selectedGroup
            setGroupName(title)
            setGoupType(groupType)
            // setUsersList(sharedWith)
            setUserIdList(sharedWith)
            form.setFieldsValue({
                groupname: title,
                grouptype: groupType
            })
        }
    }, [props.selectedGroup])

    useEffect(() => {
        async function saveGroupSuccess() {
            if (boardSelector.groupSavingState === 'success') {
                // setLoading(false)
                setGroupName("")
                setUsersList([]);
                setUserIdList([]);
                // setSubGroupNames([]);
                // await dispatch(getGroupsAsync());
                await dispatch(resetGoupSavingState())
                setTimeout(() => {
                    props.handleCloseModal()
                    history.push('/groups');
                }, 1000);

            }
        }
        saveGroupSuccess()
    }, [boardSelector]);

    // React.useEffect(() => {
    //     return () => {
    //         alert("DSDSDS")
    //         setGroupName("")
    //         setUsersList([]);
    //         setUserIdList([]);
    //     };
    // }, []);

    const onSelectSharableUsers = (e) => {
        console.log("onSelectSharableUsers===>>", e);
        let usersArr = usersList.map(x => x)
        usersArr.push(e.value)

        setUsersList([...new Set(usersArr)])


        let userIds = [...userIdList]
        userIds.push(e.data.UserID)
        setUserIdList([...new Set(userIds)])
    }

    const addGroups = () => {
        setSubGroupNames([...subGroupNames, ""])
    }

    const setSubGroupName = (val, index) => {
        setSubGroupNames(subGroupNames.map((g, i) => i === index ? val : g))
    }

    const removeSubGoup = (id) => {
        setSubGroupNames(subGroupNames.filter((p, i) => id !== i))
    }

    const saveGroupInfo = () => {
        // setLoading(true)
        if (groupName === "") {
            setGroupNamerror(true)
            // setLoading(false)
            return
        }

        if (!groupType) {
            setGroupTypeError(true)
            return
        }

        let postData = {
            groupName: groupName,
            groupType: groupType,
            isPuplic: radioVal === 2,
            isGlobal: radioVal === 3,
            createdBySpecial: localStorage.getItem('IsSpecialUser') ? localStorage.getItem('IsSpecialUser') : false,
            sharedWith: sharedListShow ? userIdList : [],
            subGroups: subGroupNames.filter(sg => sg.trim() !== ""),
        }
        if (props.selectedGroup && props.selectedGroup.id) {
            postData.id = props.selectedGroup.id
            postData.isEdit = true
        }
        dispatch(saveGroupAsync(postData))
    }
    return (
        <div>
            <Form
                name="customized_form_controls"
                initialValues={selectedGroupDetails}
                form={form}
            >
                <Form.Item
                    name={["groupname"]}
                    rules={[{ required: true, message: 'Please input board name!' }]}
                    validateStatus={groupNamerror ? "error" : ""}
                >
                    <Input placeholder='Group Name' defaultValue="" value={groupName} onChange={(e) => { setGroupName(e.target.value); setGroupNamerror(false) }} disabled={props.isViewOnly} />
                </Form.Item>
                <Row>
                    <Col span={6}>Group Type</Col>
                    <Col span={18}>
                        <Form.Item
                            name="grouptype"
                            rules={[{ required: true, message: 'Please select group type!' }]}
                            validateStatus={groupTypeError ? "error" : ""}
                        >
                            <Select style={{ width: '100%' }} placeholder="Select Goup Type" defaultValue={null} value={groupType} onChange={(val) => { setGroupTypeError(false); setGoupType(val) }} disabled={props.isViewOnly}>
                                <Select.Option value="dashboard">Dashboard</Select.Option>
                                <Select.Option value="report">Report</Select.Option>
                                {/* <Select.Option value="resource">Resource</Select.Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="report-radiobtn-container">

                            <Radio.Group value={radioVal} onChange={onChangeRadio} disabled={props.isViewOnly}>
                                <Radio value={1}><div style={{ display: 'flex', alignItems: 'center' }}><MaterialDesignIcon icon='fingerprint' /><span>&nbsp;Private</span></div></Radio>
                                <Radio value={2}><div style={{ display: 'flex', alignItems: 'center' }}><MaterialDesignIcon icon='groups' /><span>&nbsp;Public</span></div></Radio>
                                {localStorage.getItem('IsSpecialUser') === "true" ?
                                    <Radio value={3}><div style={{ display: 'flex', alignItems: 'center' }}><MaterialDesignIcon icon='public' /><span>&nbsp;Global</span></div></Radio>
                                    : null}
                            </Radio.Group>

                        </div>
                    </Col>
                </Row>

                {/* <Radio><div style={{display:'flex', alignItems:'center'}}><MaterialDesignIcon icon='fingerprint' /> <span>Private</span></div></Radio> */}




                {sharedListShow ?
                    <div>
                        <div>Share With</div>
                        <Mentions
                            style={{ width: '100%' }}
                            id="users"
                            // onChange={this.onChangeSharableUsers}
                            onSelect={onSelectSharableUsers}
                            disabled={props.isViewOnly}
                        // defaultValue={props.users && userIdList ? userIdList.map(x => props.users.find(y => x === y.UserID).UserID) : ""}
                        >
                            {props.users ?
                                props.users?.map(user => {
                                    return (
                                        <Option key={`board_user__${user.UserID}`} data={user} value={user?.UserDetails?.UserName} onClick={() => this.onClickSharableUser(user)} disabled={props.isViewOnly}>
                                            {user?.UserDetails?.UserName || '-'}
                                        </Option>
                                    )
                                })
                                : <Option>-Not Available-</Option>
                            }


                        </Mentions>
                    </div>
                    : <br />
                }
                <br />


                {/* <div>Sub Groups</div> */}
                {subGroupNames.map((sg, index) => {
                    return (
                        <Row key={'sg_' + index} >
                            <Col span={6}>{index === 0 ? "Sub Groups" : ""}</Col>
                            <Col span={18}>
                                <Input.Group compact>
                                    <Input style={{ width: '100%' }} placeholder="Sub Group Name" value={sg} onChange={(e) => setSubGroupName(e.target.value, index)} prefix={<div>{index + 1}.&nbsp;</div>} suffix={<CloseOutlined onClick={() => removeSubGoup(index)} />} disabled={props.isViewOnly} />
                                    {(index === (subGroupNames.length - 1) && !props.isViewOnly) ?
                                        <Button type="dashed" onClick={() => addGroups()} block icon={<PlusOutlined />} style={{ marginTop: '12px', alignItems: 'center' }} disabled={props.isViewOnly}>
                                            Add new
                                        </Button>
                                        : null}
                                </Input.Group>
                            </Col>
                        </Row>
                    )
                })}

                {!props.isViewOnly ?
                    <>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>

                            <Button type="primary" loading={boardSelector.groupSavingState === 'pending'} style={{ width: '160px' }} onClick={saveGroupInfo}>Save Group</Button>

                            {(() => {
                                switch (boardSelector.groupSavingState) {
                                    case 'success':
                                        return message.success("Group saved successfully!");
                                    // return (
                                    //     <Alert message="Saving Group data Success" type="success" showIcon />
                                    // );
                                    case 'error':
                                        return message.error("Group not saved!");
                                    // return (
                                    //     <Alert message="Saving Group data Failed" type="error" showIcon />
                                    // );
                                    case 'pending':
                                        return (
                                            <div><Spin /></div>
                                        );
                                    default:
                                        return null;
                                }
                            })()}
                        </div>
                    </>

                    : null}

                {/* <Form.Item
                name="groupname"
                label="Group Name"
                rules={[{ message: 'Please input board name!' }]}
            >
                <Input placeholder="New Blank Board" placeholder='Sub Group Name' value={groupName} onChange={(e) => { setSubGroupName(e.target.value) }} />
                <Button ype="dashed" onClick={() => addGroups()} block icon={<PlusOutlined />}>
                    Add new
                </Button>
            </Form.Item> */}
            </Form>
        </div>
    );
}

export default AddBoardGroup;
