import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { chartHelper } from "../../../utils/chartHelper";
import * as _ from 'lodash';
import { DATE_FORMAT } from "../../../constants/dateConstants";
import { returnDataArrayObject } from "../../../utils/arrayHelper";

const BoardChartContainer = ({ reportData, charts, availableFields, dataFilters, resourceId, selectedResource, aggregation }) => {

  const [selectedValues, setSelectedValues] = useState([])
  const [selectedFilters, setDataFilters] = useState([]);

  const [displayData, setDisplayData] = useState([]);
  const [fieldNames, setFieldNames] = useState([]);

  useEffect(() => {
    if(dataFilters && selectedResource.resourceId === charts.resourceId){
      setDataFilters(dataFilters);
    }
  }, [dataFilters])

  const groupBy = (list, props) => {
    return (list || [])?.reduce((a, b) => {
      (a[b[props]] = a[b[props]] || []).push(b);
      return a;
    }, {});
  };

  var getTotal = (values, fieldName) => Object.keys(values).reduce((a, r) => {
    a[r] = values[r].reduce((acc, val) => acc + Number(val[fieldName]), 0)
    return a;
  }, {});

  const getValueArray = (values) => Object.keys(values).map(val => ({ name: val, value: values[val] }))

  const filterByDate = (filter, date, format) => {
    if (filter.filterType === 'between') {
      return moment(date).isBetween(moment(filter.value1), moment(filter.value2));
    } else if (filter.filterType === 'lte') {
      return moment(moment(date).format(format || DATE_FORMAT)).isSameOrBefore(moment(filter.value).format(format || DATE_FORMAT));
    } else if (filter.filterType === 'lt') {
      return moment(moment(date).format(format || DATE_FORMAT)).isBefore(moment(filter.value).format(format || DATE_FORMAT));
    } else if (filter.filterType === 'gte') {
      return moment(moment(date).format(format || DATE_FORMAT)).isSameOrAfter(moment(filter.value).format(format || DATE_FORMAT));
    } else if (filter.filterType === 'gt') {
      return moment(moment(date).format(format || DATE_FORMAT)).isAfter(moment(filter.value).format(format || DATE_FORMAT));
    } else {
      return moment(moment(date).format(format || DATE_FORMAT)).isSame(moment(filter.value).format(format || DATE_FORMAT))
    }
  }

  const filterByNumber = (filter, value) => {
    if (filter.filterType === 'between') {
      return value >= Number(filter.value1) && value <= Number(filter.value2)
    } else if (filter.filterType === 'lte') {
      return value <= Number(filter.value);
    } else if (filter.filterType === 'lt') {
      return value < Number(filter.value);
    } else if (filter.filterType === 'gte') {
      return value >= Number(filter.value);
    } else if (filter.filterType === 'gte') {
      return value >= Number(filter.value);
    } else if (filter.filterType === 'gt') {
      return value > Number(filter.value);
    } else {
      return value === Number(filter.value)
    }
  };

  const filterByString = (filter, value) => {
    return value === filter.value
  }

  const filterValues = (field) => {
    let results = reportData.data || []
    selectedFilters?.forEach((selectedFilter) => {
      if (field !== selectedFilter.fieldName) {
        results = results.filter((value) => {
          if (selectedFilter.dataType === 'date') {
            const format  = availableFields?.find(
              (f) => f?.fieldName === selectedFilter.fieldName
            )?.format
            return filterByDate(selectedFilter, value[selectedFilter.fieldName], format)
          }
          if (selectedFilter.dataType === 'number') {
            return filterByNumber(selectedFilter, value[selectedFilter.fieldName])
          }
          if (selectedFilter.dataType === 'string') {
            return filterByString(selectedFilter, value[selectedFilter.fieldName])
          }
        });
      };
    })

    return results;
  };

  const formatValues = (array, fieldName) => {
    const reg = /^\d*\.?\d*$/;
    if (array && Boolean(array.length)) {
      if (!reg.test(array[0][fieldName]) && _.isDate(new Date(array[0][fieldName])) && moment(array[0][fieldName]).isValid()) {
        return array.map((value) => Object.assign({}, value, { [fieldName]: moment(value[fieldName])
          .format(availableFields?.find(availbleField => availbleField.fieldName === fieldName)?.format || "YYYY-MM-DD") }))
      }
    }
    return array;
  }

  useEffect(() => {
    // const selectedResults = Boolean(selectedFilters?.length) ? filterValues(charts?.fieldName?.fieldName) : reportData.data;
    const fieldDataFilter = selectedFilters?.find(filter => filter.fieldName === charts?.fieldName?.fieldName)

    if (charts?.fieldName?.fieldName && charts?.groupBy?.fieldName) {
      selectedFilters?.length && fieldDataFilter && Boolean(Object.values(fieldDataFilter).length)
        ? setSelectedValues(
          getValueArray(
            getTotal(
              groupBy(formatValues(displayData, charts?.groupBy?.fieldName), charts?.groupBy?.fieldName)
              , charts?.fieldName?.fieldName
            )).filter(value => filterByNumber(fieldDataFilter, value.value)))
        :
        setSelectedValues(
          getValueArray(
            getTotal(
              groupBy(formatValues(displayData, charts?.groupBy?.fieldName), charts?.groupBy?.fieldName)
              , charts?.fieldName?.fieldName
            )));
    }
  }, [charts, selectedFilters, displayData])


  useEffect(() => {
    let aggregatedData = [];
    let reportDataArr = JSON.parse(JSON.stringify(returnDataArrayObject(reportData))).data; //displayData.data.map(x => x)
    const selectedResults = Boolean(dataFilters?.length) ? filterValues(charts?.fieldName?.fieldName) : reportDataArr || reportData;
    if (
      availableFields &&
      aggregation &&
      aggregation.row &&
      aggregation.row.length > 0
    ) {
      
      Object.preventExtensions(selectedResults);

      selectedResults.forEach((rd, i) => {
        console.log("aggregation DataTable=>", aggregation);

        aggregation.row.forEach((ag, j) => {
          if (ag.rowAggregation[0].operator === "add_c") {
            console.log("====if addc===", rd[ag.rowAggregation[0].fieldName]);
            selectedResults[i][ag?.displayName] =
              Number(rd[ag.rowAggregation[0].fieldName]) +
              Number(ag.rowAggregation[0].const_1);
            console.log(
              selectedResults[i][ag.displayName]
            );
          } else if (ag.rowAggregation[0].operator === "multiply_c") {
            selectedResults[i][ag?.displayName] =
              Number(rd[ag.rowAggregation[0].fieldName]) *
              Number(ag.rowAggregation[0].const_1);
          } else if (ag.rowAggregation[0].operator === "add") {
            selectedResults[i][ag?.displayName] =
              Number(rd[ag.rowAggregation[0].fieldName]) +
              Number(selectedResults[i][ag.rowAggregation[0].field_2]);
          } else if (ag.rowAggregation[0].operator === "multiply") {
            selectedResults[i][ag?.displayName] =
              Number(rd[ag.rowAggregation[0].fieldName]) *
              Number(selectedResults[i][ag.rowAggregation[0].field_2]);
          } else if (ag.rowAggregation[0].operator === "sum") {
            selectedResults[i][ag?.displayName] = ag.rowAggregation[0].fieldNamesList
              .reduce((a, b) => {
                return a + Number(selectedResults[i][b]);
              }, 0)
              .toFixed(2);
          } else if (ag.rowAggregation[0].operator === "avg") {
            selectedResults[i][ag?.displayName] = (
              ag.rowAggregation[0].fieldNamesList.reduce((a, b) => {
                return a + Number(selectedResults[i][b]);
              }, 0) / ag.rowAggregation[0].fieldNamesList.length
            ).toFixed(2);
          } else if (ag.rowAggregation[0].operator === "func") {
            if (ag.rowAggregation[0].calculationStr) {
              console.log(
                "rd[ag.rowAggregation[0]====>>",
                ag.rowAggregation[0],
                "<<<<>>>>",
                rd[ag.rowAggregation[0]["seeing"]]
              );
              let fcn = ag.rowAggregation[0].calculationStr
                .replaceAll(" ", "")
                .replaceAll("@", "Number(rd['") // {field1} - {field2}        @field1 - @field2
                .replaceAll("$", "'])"); // Number(rd['field1']) - Number(rd['field2'])
              selectedResults[i][ag?.displayName] = Number(eval(fcn)).toFixed(2);
              return;
            }
          }
        });
      });


      setDisplayData(selectedResults);
    } else {
      setDisplayData(selectedResults);
    }
  }, [availableFields, aggregation]);


  const formatDate = (array) => {
    return chartHelper(array).numberFormat(2)
  }

  const fetchChart = (chartType) => {
    const options = {
      chart: {
        toolbar: {
          show: false
        },
        height: 350,
        type: chartType ? chartType.toLowerCase() : 'bar',
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: charts?.chartName,
      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      yaxis: {
        title: {
          text: charts?.fieldName?.fieldName
        }
      },
      xaxis: {
        categories: formatDate(selectedValues?.map((value) => (value.name))),
        title: {
          text: charts?.groupBy?.fieldName
        }
      }
    }

    return (
      <ReactApexChart options={
        options
      } series={[{
        data: formatDate(selectedValues?.map((value) => (value.value)))
      }]} type={chartType.toLowerCase()} height={350} />
    )
  }

  return (
    <>
      {charts?.chartType === 'Pie' ?
        <div id="chart">
          <ReactApexChart options={
            {
              chart: {
                width: 380,
                type: 'pie',
              },
              title: {
                text: charts?.chartName,
              },
              labels: formatDate(selectedValues?.map((value) => (value.name))) || [],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            }
          } series={formatDate(selectedValues?.map((value) => (value.value)))} type="pie" height={350} />
        </div>
        :charts?.chartType && fetchChart(charts?.chartType)}
    </>
  )

}

export default BoardChartContainer