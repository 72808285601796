import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { v4 } from "uuid";
import ReportGrid from "../../../components/ReportGrid";
import { returnDataArrayObject } from "../../../utils/arrayHelper";
import {
  getSumOfGroupByMultiFields,
  groupingByMultipleFields,
} from "../../../utils/group-helpers";
import {
  reOrderFields,
  updateGroupedReportData,
} from "../../../redux/editorSlice";
import { DATE_FORMAT } from "../../../constants/dateConstants";

export default function DataTableContainer(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.editor);
  const [displayData, setDisplayData] = useState({
    data: props.reportData && Boolean(returnDataArrayObject(props.reportData).data)
      ? returnDataArrayObject(props.reportData).data
      : [],
  });
  const [fieldNames, setFieldNames] = useState([]);


  useEffect(() => {
    if (props.reportData && Boolean(returnDataArrayObject(props.reportData).data)) {
      setDisplayData({ data: returnDataArrayObject(props.reportData).data });
    }
  }, [props.reportData])

  const getGroupedData = (reportData) => {
    let groupByKeys = props.aggGroupBuilders?.fieldNamesList ?? [];
    let sumKeys = props.aggGroupBuilders?.sumFieldNamesList ?? [];
    let result = getSumOfGroupByMultiFields(reportData, groupByKeys, sumKeys);
    let count = groupingByMultipleFields(reportData, function (item) {
      return groupByKeys?.map((key) => item[key]);
    });

    result = result?.map((obj) =>
      Object.fromEntries(
        Object.entries(obj)?.filter(
          (o) =>
            props.aggGroupBuilders?.displayFieldNamesList?.includes(o[0]) ||
            props.aggGroupBuilders?.uniqueFieldNamesList?.includes(o[0])
        )
      )
    );

    if (result && result[0]) {
      console.log(" state?.selectedResource?.aggGroupBuilders?.displayFieldNamesList", state?.selectedResource?.aggGroupBuilders?.displayFieldNamesList)

      let columns = state?.selectedResource?.aggGroupBuilders?.displayFieldNamesList ?
        state?.selectedResource?.availableFields.filter(a => state?.selectedResource?.aggGroupBuilders?.displayFieldNamesList.findIndex(b => b === a.fieldName) > -1).map(z => { return { ...z, grouped: true } })
        : Object.keys(result[0]).map((val, i) => ({
          id: i,
          fieldName: val,
          checked:
            state?.selectedResource?.availableFields?.find(
              (af) => af.fieldName === val
            )?.checked ?? true,
          dataType: state?.selectedResource?.allFields?.find(
            (af) => af.fieldName === val
          )?.dataType,
          format: state?.selectedResource?.allFields?.find(
            (af) => af.fieldName === val
          )?.format,
          grouped: true,
        }));

      if (props.aggGroupBuilders?.operator === 'sum&count' || props.aggGroupBuilders?.operator === 'count') {
        count = count?.map((c) => c?.length);

        columns.push({
          id: v4(),
          fieldName: "Count",
          checked:
            state?.selectedResource?.availableFields?.find(
              (af) => af.fieldName === "Count"
            )?.checked ?? true,
          dataType: "number",
          grouped: true,
        });

        result = result.map((res, i) => ({ ...res, 'Count': count[i] }))

        return {
          data: result?.length ? result : [],
          fields: columns?.length ? columns : [],
        };
      } else {
        return {
          data: result?.length ? result : [],
          fields: columns?.length ? columns : [],
        };
      }
    }
  };

  useEffect(() => {
    if (props.aggGroupBuilders && !(_.isEmpty(props.aggGroupBuilders))) {
      if (props.reportData.Result && returnDataArrayObject(props.reportData)?.data) {
        if(props.aggGroupBuilders.advanced) {
          const { data, fields } = getGroupedData(
            returnDataArrayObject(props.reportData)?.data
          );
          if(fields.length>0 || (data[0] && Object.keys(data[0]).length > 0)) {
            dispatch(updateGroupedReportData({ fields:fields, data:returnDataArrayObject(props.reportData)?.data }));
            setDisplayData({ data });
          }

          
        } else {
          const { data, fields } = getGroupedData(
            returnDataArrayObject(props.reportData)?.data
          );
          if(fields.length>0 || (data[0] && Object.keys(data[0]).length > 0)) {
            dispatch(updateGroupedReportData({ fields, data }));
            setDisplayData({ data });
          }
        }
        
      }
    } else {
      setDisplayData(returnDataArrayObject(props.reportData));
    }
  }, [props.aggGroupBuilders, props.reportData]);

  useEffect(() => {
    let aggregatedData = [];
    if (
      props.availableFields &&
      props.aggregation &&
      props.aggregation.row &&
      props.aggregation.row.length > 0
    ) {
      let reportData = JSON.parse(JSON.stringify(returnDataArrayObject(props.reportData))).data; //displayData.data.map(x => x)
      Object.preventExtensions(reportData);

      reportData.forEach((rd, i) => {

        props.aggregation.row.forEach((ag, j) => {
          if (ag.rowAggregation[0].operator === "add_c") {
            reportData[i][ag?.displayName] =
              Number(rd[ag.rowAggregation[0].fieldName]) +
              Number(ag.rowAggregation[0].const_1);
          } else if (ag.rowAggregation[0].operator === "multiply_c") {
            reportData[i][ag?.displayName] =
              Number(rd[ag.rowAggregation[0].fieldName]) *
              Number(ag.rowAggregation[0].const_1);
          } else if (ag.rowAggregation[0].operator === "add") {
            reportData[i][ag?.displayName] =
              Number(rd[ag.rowAggregation[0].fieldName]) +
              Number(reportData[i][ag.rowAggregation[0].field_2]);
          } else if (ag.rowAggregation[0].operator === "multiply") {
            reportData[i][ag?.displayName] =
              Number(rd[ag.rowAggregation[0].fieldName]) *
              Number(reportData[i][ag.rowAggregation[0].field_2]);
          } else if (ag.rowAggregation[0].operator === "sum") {
            reportData[i][ag?.displayName] = ag.rowAggregation[0].fieldNamesList
              .reduce((a, b) => {
                return a + Number(reportData[i][b]);
              }, 0)
              .toFixed(2);
          } else if (ag.rowAggregation[0].operator === "avg") {
            reportData[i][ag?.displayName] = (
              ag.rowAggregation[0].fieldNamesList.reduce((a, b) => {
                return a + Number(reportData[i][b]);
              }, 0) / ag.rowAggregation[0].fieldNamesList.length
            ).toFixed(2);
          } else if (ag.rowAggregation[0].operator === "func") {
            if (ag.rowAggregation[0].calculationStr) {
              let fcn = ag.rowAggregation[0].calculationStr
                .replaceAll(" ", "")
                .replaceAll("@", "Number(rd['") // {field1} - {field2}        @field1 - @field2
                .replaceAll("$", "'])"); // Number(rd['field1']) - Number(rd['field2'])
              reportData[i][ag?.displayName] = Number(eval(fcn)).toFixed(2);
              return;
            }
          }
        });
      });

      let availableFieldsSet =
        props.availableFields &&
        props.availableFields
          .filter((x) => x.checked)
          .map((af, i) => {
            return {
              title: af.fieldName,
              dataIndex: af.fieldName,
              key: af.fieldName,
              dataType: af?.dataType,
              format: af.format,
              fieldDisplayName: af?.fieldDisplayName || null

            };
          });

      props?.aggregation?.row?.forEach((a, k) => {
        availableFieldsSet.push({
          title: a.displayName,
          dataIndex: a.displayName,
          key: a.displayName,
          dataType: a?.dataType ?? "number",
          isAggrCol: true,
          className: "aggr-col",
          fieldDisplayName: a.fieldDisplayName || null,
          render: (text) => <span className="xxxxx">{text}</span>
        });
      });

      setFieldNames(availableFieldsSet);
      setDisplayData({ data: reportData });
      aggregatedData = reportData;
    } else {
      if (props.groupedReportData?.length) {
        setDisplayData({ data: props.groupedReportData });
        aggregatedData = props.groupedReportData;
      } else {
        setDisplayData(returnDataArrayObject(props.reportData));
        aggregatedData = returnDataArrayObject(props.reportData)?.data;
      }

      if (props.availableFields) {
        let fieldNamesList =
          props.availableFields &&
          props.availableFields
            .filter((x) => x.checked)
            .map((af, i) => {
              return {
                title: af.fieldName,
                dataIndex: af.fieldName,
                key: af.fieldName,
                dataType: af?.dataType,
                format: af.format,
                fieldDisplayName: af.fieldDisplayName || null,
                render: (text) => <span className="xxxxx">{text}</span>
              };
            });

        setFieldNames(fieldNamesList);
      }
    }

    // if (props.dataFilters && props.dataFilters.length > 0) {
    //   for (let i = 0; i < props.dataFilters.length; i++) {
    //     const selectedFieldName = props.dataFilters[i].fieldName;
    //     const format = props?.availableFields?.find(
    //       (f) => f?.fieldName === selectedFieldName
    //     )?.format;

    //     if (props.dataFilters[i].filterType === "gt") {
    //       if (props.dataFilters[i].dataType === "number") {
    //         aggregatedData = aggregatedData?.filter(
    //           (rd) =>
    //             rd[props.dataFilters[i]["fieldName"]] >
    //             props.dataFilters[i]["value"]
    //         );
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       } else if (
    //         props.dataFilters[i].dataType === "string" ||
    //         props.dataFilters[i].dataType === "date"
    //       ) {
    //         aggregatedData = aggregatedData?.filter((rd) =>
    //           new Date(rd[props.dataFilters[i]["fieldName"]]) &&
    //             new Date(props.dataFilters[i]["value"])
    //             ? moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
    //               format || DATE_FORMAT
    //             )).isAfter(moment(props.dataFilters[i]["value"]).format(
    //               format || DATE_FORMAT
    //             ))
    //             : null
    //         );
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       }
    //     } else if (props.dataFilters[i].filterType === "gte") {
    //       if (props.dataFilters[i].dataType === "number") {
    //         aggregatedData = aggregatedData?.filter(
    //           (rd) =>
    //             rd[props.dataFilters[i]["fieldName"]] >=
    //             props.dataFilters[i]["value"]
    //         );
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       } else if (
    //         props.dataFilters[i].dataType === "string" ||
    //         props.dataFilters[i].dataType === "date"
    //       ) {
    //         aggregatedData = aggregatedData.filter((rd) =>
    //           new Date(rd[props.dataFilters[i]["fieldName"]]) &&
    //             new Date(props.dataFilters[i]["value"])
    //             ? moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
    //               format || DATE_FORMAT
    //             )).isSameOrAfter(moment(props.dataFilters[i]["value"]).format(
    //               format || DATE_FORMAT
    //             ))
    //             : null
    //         );
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       }
    //     } else if (props.dataFilters[i].filterType === "lt") {
    //       if (props.dataFilters[i].dataType === "number") {
    //         aggregatedData = aggregatedData?.filter(
    //           (rd) =>
    //             rd[props.dataFilters[i]["fieldName"]] <
    //             props.dataFilters[i]["value"]
    //         );
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       } else if (
    //         props.dataFilters[i].dataType === "string" ||
    //         props.dataFilters[i].dataType === "date"
    //       ) {
    //         aggregatedData = aggregatedData?.filter((rd) =>
    //           new Date(rd[props.dataFilters[i]["fieldName"]]) &&
    //             new Date(props.dataFilters[i]["value"])
    //             ? moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
    //               format || DATE_FORMAT
    //             )).isBefore(moment(props.dataFilters[i]["value"]).format(
    //               format || DATE_FORMAT
    //             ))
    //             : null
    //         );
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       }
    //     } else if (props.dataFilters[i].filterType === "lte") {
    //       if (props.dataFilters[i].dataType === "number") {
    //         aggregatedData = aggregatedData?.filter(
    //           (rd) =>
    //             rd[props.dataFilters[i]["fieldName"]] <=
    //             props.dataFilters[i]["value"]
    //         );
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       } else if (
    //         props.dataFilters[i].dataType === "string" ||
    //         props.dataFilters[i].dataType === "date"
    //       ) {
    //         setDisplayData({
    //           data: aggregatedData?.filter((rd) =>
    //             new Date(rd[props.dataFilters[i]["fieldName"]]) &&
    //               new Date(props.dataFilters[i]["value"])
    //               ? moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
    //                 format || DATE_FORMAT
    //               )).isSameOrBefore(moment(props.dataFilters[i]["value"]).format(
    //                 format || DATE_FORMAT
    //               ))
    //               : null
    //           ),
    //         });
    //       }
    //     } else if (props.dataFilters[i].filterType === "between") {
    //       if (
    //         props.dataFilters[i].dataType === "string" ||
    //         props.dataFilters[i].dataType === "date"
    //       ) {
    //         aggregatedData = aggregatedData?.filter((rd) => {
    //           return new Date(rd[props.dataFilters[i]["fieldName"]]) &&
    //             new Date(rd[props.dataFilters[i]["fieldName"]]) &&
    //             new Date(props.dataFilters[i]["value2"]) &&
    //             new Date(props.dataFilters[i]["value1"])
    //             ? moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
    //               format || DATE_FORMAT
    //             )).isBetween(
    //               moment(props.dataFilters[i]["value1"]).subtract(1, "days").format(format || DATE_FORMAT),
    //               moment(props.dataFilters[i]["value2"]).add(1, "days").format(format || DATE_FORMAT)
    //             )
    //             : null;
    //         });
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       } else if (props.dataFilters[i].dataType === "number") {
    //         aggregatedData = aggregatedData?.filter(
    //           (rd) =>
    //             rd[props.dataFilters[i]["fieldName"]] <=
    //             props.dataFilters[i]["value2"] &&
    //             rd[props.dataFilters[i]["fieldName"]] >=
    //             props.dataFilters[i]["value1"]
    //         );
    //         setDisplayData({
    //           data: aggregatedData,
    //         });
    //       }
      //   } else {
      //     const reg = /^\d*\.?\d*$/;
      //     if (
      //       aggregatedData && aggregatedData[0] &&
      //       !reg.test(aggregatedData[0][selectedFieldName]) &&
      //       _.isDate(new Date(aggregatedData[0][selectedFieldName])) &&
      //       moment(aggregatedData[0][selectedFieldName]).isValid()
      //     ) {
      //       aggregatedData = aggregatedData?.filter(
      //         (rd) =>
      //           moment(rd[props.dataFilters[i]["fieldName"]]).format(
      //             format || DATE_FORMAT
      //           ) ===
      //           moment(props.dataFilters[i]["value"]).format(
      //             format || DATE_FORMAT
      //           )
      //       );
      //     } else {
      //       aggregatedData = aggregatedData?.filter(
      //         (rd) =>
      //           String(rd[props.dataFilters[i]["fieldName"]]) ===
      //           String(props.dataFilters[i]["value"])
      //       );
      //     }
      //     setDisplayData({
      //       data: aggregatedData,
      //     });
      //   }
      // }
    // }
  }, [
    props.dataFilters,
    props.aggregation,
    props.rowAggregation,
    props.availableFields,
  ]);

  return (
    <div style={{ background: "#fff", minHeight: "180px" }}>
      {/* <h5>Table Data</h5> */}
      <div>
        <ReportGrid
          reportData={displayData}
          availableFields={fieldNames}
          aggRowBuilders={props.aggRowBuilders}
          aggGroupBuilders={props.aggGroupBuilders}
        />
      </div>
    </div>
  );
}
