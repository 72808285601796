import React, { useState } from 'react';
import { Form, Input, Button, Divider, Row, Col, Select, Alert, Spin } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import Axios from 'axios';
import BasicAuthForm from './BasicAuthForm';
import AuthOForm from './AuthOForm';
// import { testConnectionConnection } from '../../api/connectRestAPI'
const { REACT_APP_CONNECTION_INFO_BASE_URL } = process.env;
const GraphQLForm = (props) => {
    const [apiName, setApiName] = useState("");
    const [apiNameError, setApiNameError] = useState(false);
    const [apiUrl, setApiUrl] = useState("");
    const [apiTestStatus, setApiTestStatus] = useState("");
    const [apiSaveStatus, setApiSaveStatus] = useState("");
    const [urlParams, setUrlParams] = useState([{ key: '', value: '' }]);
    const [headers, setHeaders] = useState([{ key: '', value: '' }]);
    const [bodyParams, setBodyParams] = useState([{ key: '', value: '' }]);
    const [authMethod, setauthMethod] = useState('none');
    const [httpMethod, setHttpMethod] = useState('get');
    const [authParams, setAuthParams] = useState(null);
    // const [auth0Params, setAuth0Params] = useState(null)

    const authOptions = [
        { id: 0, name: 'None', value: 'none' },
        { id: 1, name: 'Basic Auth', value: 'basic' },
        { id: 2, name: 'Auth0', value: 'auth0' }
    ]
    // const onFinish = (values: any) => {
    //     console.log('Success:', values);
    // };

    // const onFinishFailed = (errorInfo: any) => {
    //     console.log('Failed:', errorInfo);
    // };

    const addUrlParam = () => {
        setUrlParams([...urlParams, { key: '', value: '' }])
    };

    const removeUrlParam = (index) => {
        const params = [...urlParams]
        setUrlParams(params.filter((p, i) => index !== i))
    };

    const onChangeUrlParamKey = (event, index, key) => {
        const params = [...urlParams]
        params[index][key] = event.target.value;
        setUrlParams(params)
    }

    const addHeader = () => {
        setHeaders([...headers, { key: '', value: '' }])
    };

    const removeHeader = (index) => {
        const headersList = [...headers]
        setHeaders(headersList.filter((p, i) => index !== i))
    };

    const onChangeHeaderKey = (event, index, key) => {
        const headersList = [...headers]
        headersList[index][key] = event.target.value;
        setHeaders(headersList)
    }

    const addBodyParams = () => {
        setBodyParams([...bodyParams, { key: '', value: '' }])
    };

    const removeBodyParams = (index) => {
        const headersList = [...bodyParams]
        setBodyParams(headersList.filter((p, i) => index !== i))
    };

    const onChangeBodyParamsKey = (event, index, key) => {
        const bodyParamsList = [...bodyParams]
        bodyParamsList[index][key] = event.target.value;
        setBodyParams(bodyParamsList)
    }

    const testConnection = async () => {
        setApiTestStatus("pending");
        setApiSaveStatus("");
        if (apiName === "") {
            setApiNameError(true)
        }

        let urlParamSring = ""
        if (urlParams.length > 0) {
            urlParamSring = urlParamSring + '?';
            for (let i = 0; i < urlParams.length; i++) {
                if (urlParams[i]['key'] !== '' && urlParams[i]['value'] !== '') {
                    urlParamSring = urlParamSring + urlParams[i]['key'] + '=' + urlParams[i]['value'] + '&'
                }
            }
            urlParamSring.slice(0, -1)
        }

        let headerObj = {}
        if (headers.length > 0) {
            for (let i = 0; i < headers.length; i++) {
                if (headers[i]['key'] !== '' && headers[i]['value'] !== '') {
                    headerObj[headers[i]['key']] = headers[i]['value']
                }
            }
        }

        let bodyParamObj = {}
        if (authParams) {
            bodyParamObj = Object.assign({}, authParams);
        }
        if (bodyParams.length > 0) {
            for (let i = 0; i < bodyParams.length; i++) {
                if (bodyParams[i]['key'] !== '' && bodyParams[i]['value'] !== '') {
                    bodyParamObj[bodyParams[i]['key']] = bodyParams[i]['value']
                }
            }
        }

        let sendUrl = apiUrl + urlParamSring
        try {
            await Axios({
                method: httpMethod,
                url: sendUrl,
                data: bodyParamObj,
                headers: headerObj
            }).then((response) => {
                setApiTestStatus("success");
            })
                .catch((error) => {
                    setApiTestStatus("error")
                });
        } catch (error) {
            setApiTestStatus("error")
        }

    }

    const handleSaveConnection = async () => {
        const API_INFO_URL = `${REACT_APP_CONNECTION_INFO_BASE_URL}/resource`;
        const JWT_TOKEN = `Bearer ${localStorage.getItem('accessToken')}`;
        setApiTestStatus("");
        setApiSaveStatus("pending");
        if (apiName === "") {
            setApiNameError(true)
            return
        }
        // if (apiUrl === "") {
        //     setApi(true)
        // }
        console.log("apiUrl", apiUrl)

        let postData = {
            name: apiName,
            icon: props.resourceType.icon,
            category: props.resourceType.category,
            description: props.resourceType.description,
            isActive: true,
            resoueceType: 'Rest API',
            connectionData: {
                name: apiName,
                baseUrl: apiUrl,
                authInfo: {
                    method: authMethod,
                    parameters: authParams ? authParams : {}
                },
                requestValues: {
                    httpMethod: httpMethod,
                    urlParams: urlParams.filter(qp => qp.key !== '' && qp.value !== ''),
                    requestHeaders: headers.filter(hp => hp.key !== '' && hp.value !== ''),
                    bodyParams: bodyParams.filter(bp => bp.key !== '' && bp.value !== '')
                }
            }
        }

        try {
            await Axios.post(API_INFO_URL, postData, { headers: { Authorization: JWT_TOKEN } })
                .then((response) => {
                    setApiSaveStatus("success")
                    setTimeout(() => {
                        props.handleClose()
                    }, 1500);
                })
                .catch((error) => {
                    setApiSaveStatus("error")
                });
        } catch (error) {
            setApiSaveStatus("error")
        }


    }

    return (
        <div style={{}}>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="basic"
            // initialValues={{ remember: true }}
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            >

                < Form.Item style={{ marginBottom: '8px' }}
                    label="Name"
                    name="apinameName"
                    rules={[{ required: true, message: 'Please input API name!' }]}
                    validateStatus={apiNameError ? "error" : ""}
                >
                    <Input value={apiName} onChange={(e) => { setApiName(e.target.value); setApiNameError(false) }} />
                </Form.Item>


                <Divider />

                < Form.Item style={{ marginBottom: '8px' }}
                    label="Base URL"
                    name="baseURL"
                    rules={[{ required: true, message: 'Please input yBase URL' }]}
                >
                    <Input
                        value={apiUrl}
                        onChange={(e) => setApiUrl(e.target.value)} />
                </Form.Item>


                <div style={{ marginTop: '16px' }}>
                    {urlParams.map((urlParam, index) => (
                        <Row key={'url_param_' + index} gutter={4}>
                            <Col span={6}>{index === 0 ? "URL Parameters" : ""}</Col>
                            <Col span={18}>
                                <Input.Group compact>
                                    <Input style={{ width: '40%' }} placeholder="Key" value={urlParams[index].key} onChange={(e) => { onChangeUrlParamKey(e, index, 'key') }} />
                                    <Input style={{ width: '60%' }} placeholder="Value" value={urlParams[index].value} onChange={(e) => { onChangeUrlParamKey(e, index, 'value') }} addonAfter={<CloseOutlined onClick={() => removeUrlParam(index)} />} />
                                    {index === (urlParams.length - 1) ?
                                        <Button ype="dashed" onClick={() => addUrlParam()} block icon={<PlusOutlined />}>
                                            Add new
                                        </Button>
                                        : null}
                                </Input.Group>

                            </Col>
                        </Row>
                    ))}
                </div>


                <div style={{ marginTop: '16px' }}>
                    {headers.map((header, index) => (
                        <Row key={'header_' + index} >
                            <Col span={6}>{index === 0 ? "Headers" : ""}</Col>
                            <Col span={18}>
                                <Input.Group compact>
                                    <Input style={{ width: '40%' }} placeholder="Key" value={headers[index].key} onChange={(e) => { onChangeHeaderKey(e, index, 'key') }} />
                                    <Input style={{ width: '60%' }} placeholder="Value" value={headers[index].value} onChange={(e) => { onChangeHeaderKey(e, index, 'value') }} addonAfter={<CloseOutlined onClick={() => removeHeader(index)} />} />
                                    {index === (headers.length - 1) ?
                                        <Button ype="dashed" onClick={() => addHeader()} block icon={<PlusOutlined />}>
                                            Add new
                                        </Button>
                                        : null}
                                </Input.Group>


                                {/* <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    < Form.Item style={{ marginBottom: '8px' }}
                                        name={'headerKey_' + (index + 1)}
                                    >
                                        <Input placeholder="Key" value={headers[index].key} onChange={(e) => { onChangeHeaderKey(e, index, 'key') }} />
                                    </Form.Item>
                                    < Form.Item style={{ marginBottom: '8px' }}
                                        name={'headerValue_' + (index + 1)}
                                    >
                                        <Input placeholder="Value" value={headers[index].value} onChange={(e) => { onChangeHeaderKey(e, index, 'value') }} />
                                    </Form.Item>
                                    {index > 0 ?
                                        <MinusCircleOutlined onClick={() => removeHeader(index)} />
                                        : null}
                                </Space> */}
                            </Col>
                        </Row>
                    ))}
                    {/* <Row gutter={0}>
                        <Col span={6} offset={4}>
                            < Form.Item style={{ marginBottom: '8px' }}>

                                <Button ype="dashed" onClick={() => addHeader()} block icon={<PlusOutlined />}>
                                    Add new
                                </Button>

                            </Form.Item>
                        </Col>
                    </Row> */}
                </div>

                <div style={{ marginTop: '16px' }}>
                    < Form.Item style={{ marginBottom: '8px' }}
                        label="HTTP Method"
                        name="httpMethodName"
                        rules={[{ required: true, message: 'Please input API name!' }]}
                        validateStatus={apiNameError ? "error" : ""}
                    >
                        <Select onChange={(e) => setHttpMethod(e)} defaultValue="get" >
                            <Select.Option value='get'>GET</Select.Option>
                            <Select.Option value='post'>POST</Select.Option>
                        </Select>
                    </Form.Item>
                </div>


                {httpMethod === 'post' ?
                    <div style={{ marginTop: '16px' }}>
                        {bodyParams.map((header, index) => (
                            <Row key={'bodyParam_' + index} >
                                <Col span={6}>{index === 0 ? "Extra Body Values" : ""}</Col>
                                <Col span={18}>
                                    <Input.Group compact>
                                        <Input style={{ width: '40%' }} placeholder="Key" value={bodyParams[index].key} onChange={(e) => { onChangeBodyParamsKey(e, index, 'key') }} />
                                        <Input style={{ width: '60%' }} placeholder="Value" value={bodyParams[index].value} onChange={(e) => { onChangeBodyParamsKey(e, index, 'value') }} addonAfter={<CloseOutlined onClick={() => removeBodyParams(index)} />} />
                                        {index === (bodyParams.length - 1) ?
                                            <Button ype="dashed" onClick={() => addBodyParams()} block icon={<PlusOutlined />}>
                                                Add new
                                            </Button>
                                            : null}
                                    </Input.Group>
                                </Col>
                            </Row>
                        ))}
                    </div>
                    : null}

                <div style={{ marginTop: '16px' }}>
                    <Form.Item style={{ marginBottom: '8px' }} label="Authentication" validateFirst={authMethod}>
                        <Select onChange={(e) => { setauthMethod(e); setAuthParams(null) }} defaultValue="none">
                            {authOptions.map((opt) => {
                                return (
                                    <Select.Option key={'auth_opt_' + opt.id} value={opt.value}>{opt.name}</Select.Option>
                                )
                            })}

                        </Select>
                    </Form.Item>
                </div>

                {(() => {
                    switch (authMethod) {
                        case 'basic':
                            return (
                                <BasicAuthForm handleChangeValues={setAuthParams} />
                            );
                        case 'auth0':
                            return (
                                <AuthOForm handleChangeValues={setAuthParams} />
                            );
                        default:
                            return <div></div>
                    }
                })()}

                <Divider />
                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <Button htmlType="submit" onClick={testConnection} loading={apiTestStatus === "pending"}>
                            Test Connection
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={handleSaveConnection} loading={apiSaveStatus === "pending"}>
                            Create Resource
                        </Button>
                    </div>
                    {/* <div>{apiTestStatus === "success" ? 
                        <Alert message="Test Connection Success" type="success" showIcon /> 
                        :apiTestStatus === "error" ? 
                            <Alert message="Test Connection Failed" type="error" showIcon /> 
                            : ''}
                        </div> */}
                    {(() => {
                        switch (apiTestStatus) {
                            case 'success':
                                return (
                                    <Alert message="Test Connection Success" type="success" showIcon />
                                );
                            case 'error':
                                return (
                                    <Alert message="Test Connection Failed" type="error" showIcon />
                                );
                            case 'pending':
                                return (
                                    <div><Spin /></div>
                                );
                            default:
                                return <div></div>
                        }
                    })()}

                    {(() => {
                        switch (apiSaveStatus) {
                            case 'success':
                                return (
                                    <Alert message="Saving API data Success" type="success" showIcon />
                                );
                            case 'error':
                                return (
                                    <Alert message="Saving API data Failed" type="error" showIcon />
                                );
                            case 'pending':
                                return (
                                    <div><Spin /></div>
                                );
                            default:
                                return <div></div>
                        }
                    })()}

                </div>

            </Form>

        </div>
    );
};

export default GraphQLForm;
