
import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { Divider } from 'antd';
import DashboardView from '../DashboardView';
import NotFound from '../../components/NotFound';
import ReportContainer from './ReportContainer';


export default function ReportView() {
    let match = useRouteMatch();

    return (
        <DashboardView>
            <div>
                <h2>Report View</h2>
                <Divider />
            </div>

            <Switch>
                <Route path={`${match.path}/:resourceId`}>
                    <>
                        <ReportContainer />
                    </>
                </Route>
                <Route path={match.path}>
                    <NotFound />
                </Route>
            </Switch>
        </DashboardView>
    )
}

// function Topic() {
//     let { resourceId } = useParams();
//     return <h3>Requested topic ID: {resourceId}</h3>;
// }