function isArray(obj) {
    return !!obj && obj.constructor === Array;
}

function isObject(obj) {
    return typeof obj === 'object' && obj !== null
}


export function returnDataArrayObject(resObj, availableFields) {
    let data = [];
    if (isObject(resObj)) {

        if (isArray(resObj)) {
            // let formattedData  = resObj.map(ro => {})

            return { data: resObj }
        } else {
            const fieldNames = Object.keys(resObj)
            fieldNames.forEach(nm => {
                if (isArray(resObj[nm])) {
                    data = [...data, ...resObj[nm]];
                    return;
                }
            })

            return (
                { data: data }
            )
        }

    }

}