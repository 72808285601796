import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Select, Button, AutoComplete, Checkbox } from 'antd'
import { CloseOutlined } from '@ant-design/icons';
import { v4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

import {
    resetGroupBuilders,
    updateAggGroupBuilders,
} from "../../../../../redux/editorSlice";

const { Option } = Select;

export default function GroupingEditor(props) {
    const dispatch = useDispatch();
    const { availableFields, aggGroupBuilders } = props;

    const [isAdvanced, setIsAdvanced] = useState(false)
    const [fieldNames, setFieldNames] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [tempRow, setTempRow] = useState({ value: '' })
    const [selectedColumns, setSelectedColumns] = useState([])
    const [tempColumn, setTempColumn] = useState({ value: '' })
    const [selectedValues, setSelectedValues] = useState([])
    const [tempValue, setTempValue] = useState({ value: '' })
    const [builders, setBuilders] = useState({
        id: v4(),
        isGrouping: true,
        operator: "none",
        fieldNamesList: [],
        sumFieldNamesList: [],
        displayFieldNamesList: [],
        uniqueFieldNamesList: [],
        advanced: isAdvanced
    });

    useEffect(() => {
        setFieldNames(availableFields.map((af) => {
            return {
                ...af,
                value: af.fieldName,
            }
        }))
        console.log("Available Field NAmes=====>>", availableFields)
    }, [availableFields])

    useEffect(() => {
        if(aggGroupBuilders && aggGroupBuilders.selectedRows && aggGroupBuilders.selectedColumns && aggGroupBuilders.selectedValues) {
        setIsAdvanced(aggGroupBuilders.advanced)
        setSelectedRows(aggGroupBuilders.selectedRows)
        setSelectedColumns(aggGroupBuilders.selectedColumns)
        setSelectedValues(aggGroupBuilders.selectedValues)
        }
    }, [aggGroupBuilders])
    

    useEffect(() => {
        let availableFieldNames = availableFields.map((af) => { return { ...af, value: af.fieldName } }).filter(a => selectedRows.indexOf(x => x.fieldName !== a.fieldName) && selectedColumns.indexOf(y => y.fieldName !== a.fieldName))
        console.log("availableFieldNames ======>>>>>", availableFieldNames)
    }, [fieldNames, selectedRows, selectedColumns, availableFields])


    const handleOnDragEnd = (result) => {
        console.log("DRAG RESULTS===>>>", result)
        if (!result.destination) return;
    }

    const handleAddRow = () => {
        if (selectedRows.findIndex(x => x.value === tempRow.value) === -1 && tempRow.value !== "") {
            setSelectedRows([...selectedRows, ...[Object.assign(tempRow, { config: { orderBy: 'ASC', sortBy: '', showTotals: false } })]])
        }
        setTempRow({ value: '' })
    }

    const handleAddColumn = () => {
        if (selectedColumns.findIndex(x => x.value === tempRow.value) === -1 && tempColumn.value !== "") {
            setSelectedColumns([...selectedColumns, ...[Object.assign(tempColumn, { config: { orderBy: 'ASC', sortBy: '' , showTotals: false } })]])
        }
        setTempRow({ value: '' })
    }

    const handleAddValue = () => {
        if (selectedValues.findIndex(x => x.value === tempValue.value) === -1 && tempValue.value !== "") {
            setSelectedValues([...[Object.assign(tempValue, { config: { func: 'sum', showas: 'value' } })]])
        }
        setTempRow({ value: '' })
    }

    const HandleApplyGrouping = () => {
        if(isAdvanced) {
            let groupings = {
                id: v4(),
                isGrouping: true,
                operator: "none",
                fieldNamesList: selectedRows.map(x => x.fieldName),
                selectedRows: selectedRows,
                selectedColumns: selectedColumns,
                selectedValues: selectedValues,
                sumFieldNamesList: selectedColumns.map(y => y.fieldName),
                displayFieldNamesList: [...selectedRows.map(x => x.fieldName), ...selectedColumns.map(y => y.fieldName)],
                uniqueFieldNamesList: selectedRows.map(x => x.fieldName),
                advanced: isAdvanced
            }
            dispatch(updateAggGroupBuilders(groupings));
        } else {
            let groupings = {
                id: v4(),
                isGrouping: true,
                operator: "none",
                fieldNamesList: selectedRows.map(x => x.fieldName),
                sumFieldNamesList: selectedColumns.map(y => y.fieldName),
                displayFieldNamesList: [...selectedRows.map(x => x.fieldName), ...selectedColumns.map(y => y.fieldName)],
                uniqueFieldNamesList: selectedRows.map(x => x.fieldName),
                advanced: isAdvanced
            }
            dispatch(updateAggGroupBuilders(groupings));
        }
    }

    const removeRow = (r) => {
        setSelectedRows(selectedRows.filter(sr => sr.fieldName !== r.fieldName))
    }

    const removeColumn = (c) => {
        setSelectedColumns(selectedColumns.filter(sr => sr.fieldName !== c.fieldName))
    }

    const removeValues = () => {
        setSelectedValues([])
    }

    const resetBuilders = () => {
        setSelectedRows([])
        setSelectedColumns([])
        setTempColumn({ value: '' })
        setTempRow({ value: '' })
        dispatch(resetGroupBuilders());
    };

    const onChangeAdvancedGrouping = (e) => {
        setIsAdvanced(e.target.checked)
    };

    const handleChngeRowOrderby = (index, obj, val) => {
        setSelectedRows(selectedRows.map((sr, i) => {
            return {
                ...sr,
                config: {
                    orderBy: index === i ? val :  sr.config.orderBy,
                    sortBy: sr.config.sortBy,
                    showTotals: sr.checked.showTotals
                }
            }

        }))
    }

    const handleChngeRowSortby = (index, obj, val) => {
        debugger
        setSelectedRows(selectedRows.map((sr, i) => {
            return {
                ...sr,
                config: {
                    orderBy: sr.config.orderBy,
                    sortBy: index === i ? val : sr.config.sortBy,
                    showTotals: sr.checked.showTotals
                }
            }

        }))
    }

    const onChangeShowRowTotals = (index, obj, val) => {
        setSelectedRows(selectedRows.map((sr, i) => {
            return {
                ...sr,
                config: {
                    orderBy: sr.config.orderBy,
                    sortBy: sr.config.sortBy,
                    showTotals: index === i ?val:sr.checked.showTotals
                }
            }

        }))
    }
    const handleChngeColumnOrderby = (index, obj, val) => {
        setSelectedColumns(selectedColumns.map((sr, i) => {
            return {
                ...sr,
                config: {
                    orderBy: index === i ? val : sr.config.orderBy,
                    sortBy: sr.config.sortBy,
                    showTotals: sr.config.showTotals
                }
            }
        }))
    }

    const handleChngeColumnSortby = (index, obj, val) => {
        setSelectedColumns(selectedColumns.map((sr, i) => {
            return {
                ...sr,
                config: {
                    orderBy: sr.config.orderBy,
                    sortBy: index === i ? val : sr.config.sortBy,
                    showTotals: sr.config.showTotals
                }
            }
        }))
    }

    const onChangeShowColumnTotals = (index, obj, val) => {
        setSelectedColumns(selectedColumns.map((sr, i) => {
            return {
                ...sr,
                config: {
                    orderBy: sr.config.orderBy,
                    sortBy: sr.config.sortBy,
                    showTotals: index === i ?val:sr.checked.showTotals
                }
            }

        }))
    }

    const handleChangeGroupFunc = (val, index) => {
        setSelectedValues(selectedValues.map((sv, i) => {
            return {
                ...sv,
                config: {
                    func: val,
                    showas: sv.config.showas
                }
            }
        }))
    }

    const handleChangeGroupShowAs = (val, index) => {
        setSelectedValues(selectedValues.map((sv, i) => {
            return {
                ...sv,
                config: {
                    func: sv.config.func,
                    showas: val
                }
            }
        }))
    }



    return (
        <div style={{ marginBottom: '24px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Checkbox onChange={onChangeAdvancedGrouping} checked={isAdvanced}>Advanced Grouping</Checkbox>
                </div>
                <div>
                    {selectedColumns.length > 0 && selectedRows.length > 0 ?
                        <>
                            <Button onClick={HandleApplyGrouping}>Apply</Button>
                            &nbsp;
                            <Button onClick={resetBuilders}>Reset</Button>
                        </>
                        : null}
                </div>
            </div>
            <Row gutter={16}>
                <Col span={8}>
                    <div style={{ border: '1px solid #aaa' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', alignItems: 'center' }}>
                            <div>Rows</div>
                            <div>
                                <AutoComplete
                                    // value={tempRow.value || ''}
                                    options={fieldNames}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    style={{
                                        width: 140,
                                    }}
                                    onChange={(value) => { console.log("VAL+++>>", value) }}
                                    onSelect={(value, option) => { setTempRow(option) }}
                                    placeholder="Rows"
                                />
                            </div>
                            <Button onClick={handleAddRow}>Add</Button>

                        </div>
                        {selectedRows.map((sr, i) => (
                            <div style={{ margin: '8px', border: '1px solid #bbb' }} key={`sr_${i}`}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', allignItems: 'baseline' }}>
                                    <div>
                                        <b>{sr.fieldName}</b>
                                    </div>
                                    <div><Button type="link" icon={<CloseOutlined />} danger size="small" onClick={() => removeRow(sr)}></Button></div>
                                </div>
                                {isAdvanced ?
                                    <div>
                                        <div style={{ display: 'flex', padding: '8px', justifyContent: 'space-between' }}>
                                            <div>
                                                <div>
                                                    <label>Order By</label>
                                                </div>
                                                <div>
                                                    <Select style={{ minWidth: '100px' }} value={sr.config.orderBy} onChange={(val) => { handleChngeRowOrderby(i, sr, val) }}>
                                                        <Option>ASC</Option>
                                                        <Option>DESC</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <label>Sort By</label>
                                                </div>
                                                <div>
                                                    <Select style={{ minWidth: '100px' }} onChange={(val) => { handleChngeRowSortby(i, sr, val) }}>
                                                        {fieldNames.map(f => (
                                                            <Option value={f.fieldName}>{f.fieldName}</Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{padding:'8px'}}>
                                            <Checkbox onChange={(e) =>onChangeShowRowTotals(i, sr, e.target.checked)} checked={sr.config.showTotals}>ShowTotal</Checkbox>
                                        </div>
                                    </div> : null}

                            </div>

                        ))}

                    </div>
                </Col>

                <Col span={8}>
                    <div style={{ border: '1px solid #aaa' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', alignItems: 'center' }}>
                            <div>Column</div>
                            <div>
                                <AutoComplete
                                    // value={tempColumn.value || ''}
                                    options={isAdvanced?fieldNames:fieldNames.filter(f => f.dataType === 'number')}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    style={{
                                        width: 140,
                                    }}
                                    onChange={(value) => { console.log("VAL+++>>", value) }}
                                    onSelect={(value, option) => { setTempColumn(option) }}
                                    placeholder="Columns"
                                />
                            </div>
                            <Button onClick={handleAddColumn}>Add</Button>

                        </div>
                        {selectedColumns.map((sr, i) => (

                            <div style={{ margin: '8px', border: '1px solid #bbb' }} key={`sc_${i}`}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', allignItems: 'baseline' }}>
                                    <div>
                                        <b>{sr.fieldName}</b>
                                    </div>
                                    <div><Button type="link" icon={<CloseOutlined />} danger size="small" onClick={() => removeColumn(sr)}></Button></div>
                                </div>
                                {isAdvanced ?
                                <div>
                                    <div style={{ display: 'flex', padding: '8px', justifyContent: 'space-between' }}>
                                        <div>
                                            <div>
                                                <label>Order By</label>
                                            </div>
                                            <div>
                                                <Select style={{ minWidth: '100px' }} value={sr.config.orderBy} onChange={(val) => handleChngeColumnOrderby(i, sr, val)}>
                                                    <Option>ASC</Option>
                                                    <Option>DESC</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>Sort By</label>
                                            </div>
                                            <div>
                                                <Select style={{ minWidth: '100px' }} onChange={(val) => handleChngeColumnSortby(i, sr, val)} >
                                                    {fieldNames.map(f => (
                                                        <Option value={f.fieldName}>{f.fieldName}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </div> 
                                    <div style={{padding:'8px'}}>
                                            <Checkbox onChange={(e) =>onChangeShowColumnTotals(i, sr, e.target.checked)} checked={sr.config.showTotals}>ShowTotal</Checkbox>
                                        </div>
                                </div>: null}
                            </div>

                        ))}
                    </div>
                </Col>
                {isAdvanced ?
                    <Col span={8}>
                        <div style={{ border: '1px solid #aaa' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', alignItems: 'center' }}>
                                <div>Value</div>
                                <div>
                                    <AutoComplete
                                        // value={tempColumn.value || ''}
                                        options={fieldNames.filter(f => f.dataType === 'number')}
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        style={{
                                            width: 140,
                                        }}
                                        onChange={(value) => { console.log("VAL+++>>", value) }}
                                        onSelect={(value, option) => { setTempValue(option) }}
                                        placeholder="Value"
                                    />
                                </div>
                                <Button onClick={handleAddValue}>Add</Button>
                            </div>

                            {selectedValues.map((sv, i) => (

                                <div style={{ margin: '8px', border: '1px solid #bbb' }} key={`sc_${i}`}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', allignItems: 'baseline' }}>
                                        <div>
                                            <b>{sv.fieldName}</b>
                                        </div>
                                        <div><Button type="link" icon={<CloseOutlined />} danger size="small" onClick={() => removeValues(sv)}></Button></div>
                                    </div>
                                    <div style={{ display: 'flex', padding: '8px', justifyContent: 'space-between' }}>
                                        <div>
                                            <div>
                                                <label>Function</label>
                                            </div>
                                            <div>
                                                <Select style={{ minWidth: '100px' }} onChange={(val) => handleChangeGroupFunc(val, i)}>
                                                    <Option value="sum">Sum</Option>
                                                    {/* <Option value="count">Count</Option>
                                                    <Option value="average">Average</Option>
                                                    <Option value="max">Max</Option>
                                                    <Option value="min">Min</Option> */}
                                                </Select>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>Show As</label>
                                            </div>
                                            <div>
                                                <Select style={{ minWidth: '100px', maxWidth: '140px' }} onChange={(val) => handleChangeGroupShowAs(val.i)}>
                                                    <Option value="value">Value</Option>
                                                    <Option value="percentage_row">Percentage Of Row</Option>
                                                    <Option value="percentage_col">Percentage Of Column</Option>
                                                    <Option value="percentage_total">Percentage Total</Option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div style={{ border: '1px solid #aaa' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', alignItems: 'center' }}>
                            <div>Values</div>
                            <div><Input /></div>
                            <Button onClick={handleAddColumn}>Add</Button>

                        </div>
                        <div style={{ margin: '8px', border: '1px solid #bbb', display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
                            <div>
                                <b>Field Name</b>
                            </div>
                            <div>X</div>
                        </div>
                    </div> */}
                    </Col>
                    : null}

                {/* <Col span={6}> */}
                {/* <div style={{border:'1px solid #aaa'}}>
                    <div style={{display:'flex', justifyContent:'space-between', padding:'8px', alignItems:'center'}}>
                        <div>Values</div>
                        <div><Input /></div>
                        <Button>Add</Button>
                        
                    </div>
                    <div style={{margin: '8px', border:'1px solid #bbb', display:'flex', justifyContent:'space-between', padding:'8px'}}>
                            <div>
                                <b>Field Name</b>
                            </div>
                            <div>X</div>
                        </div>
                </div> */}
                {/* <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId='list'>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{ overflowY: "auto", height: "30vh", border: '1px solid red' }}
                                >
                                    {availableFields.filter(a => a.checked).map((af, index) => (
                                        <Draggable key={`draggable_${index}`} draggableId={af.id} index={index}>
                                            {(drprovided) => (
                                                <div
                                                    ref={drprovided.innerRef}
                                                    {...drprovided.draggableProps}
                                                    {...drprovided.dragHandleProps}
                                                >
                                                    <div style={{ padding: '12px', marginBottom: '12px', border: '1px solid #ccc' }}>
                                                        {af.fieldName}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext> */}
                {/* </Col> */}
            </Row>
        </div>
    )
}
