import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import resourceReducer from "./resourceSlice";
import editorSlice from "./editorSlice";
import boardSlice from "./boardSlice";
import notificationSlice from "./notificationSlice";

export default configureStore({
  reducer: combineReducers({
    user: userReducer,
    resources: resourceReducer,
    editor: editorSlice,
    boards: boardSlice,
    notifications: notificationSlice,
  }),
});
