import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;


function CustomFilters(props) {
    const [resources, setResources] = useState(props.selectedBoard.resources);

    const updateInputValue = (res, fliter, value) => {
        // let currentResources = resources.map(r => r);
        // console.log("currentResources", currentResources)
        // let updateResIndex = currentResources.findIndex(cr => cr.connectionData.id === res.connectionData.id)

        // let currentFilter = [...currentResources[updateResIndex].customFilters]
        // let updateFilterIndex = currentFilter.findIndex(cf => cf.id === fliter.id)
        // currentFilter[updateFilterIndex] = {
        //     dataType: fliter.dataType,
        //     id: fliter.id,
        //     parameterName: fliter.parameterName,
        //     parameterType: fliter.parameterType,
        //     value: value
        // };

        let updateRes = resources.map((crs, i) => {
            return (
                {
                    ...crs,
                    customFilters: crs.connectionData.id === res.connectionData.id ?
                        crs.customFilters.map(cf => {
                            return ({
                                ...cf,
                                value: cf.id === fliter.id ? (cf.dataType==='number'?Number(value):value) : cf.dataType==='number'?Number(cf.value):cf.value
                            })
                        })
                        : crs.customFilters
                }
            )
        })
        setResources(updateRes)
        // currentResources[updateResIndex].customFilters[updateFilterIndex] = currentFilter;
        console.log("updateRes Updated", updateRes)
        // console.log("updateResIndex", updateResIndex)
        // console.log("res, fliter, valu", res, fliter, value)

    }

    return (

        <>
            {resources.map((r, i) => {
                return (

                    r.customFilters ?
                        <>
                            <div style={{ borderBottom: '1px solid #eee', marginBottom: '8px' }}>
                                <b>{r.connectionData.name}</b>
                            </div>
                            <Row>
                                {r.customFilters && r.customFilters.map((cf, j) => {
                                    return (
                                        <React.Fragment key={'res_row_' + i + '_f_' + j}>
                                            <Col span={3}>
                                                <div>{cf.parameterName}</div>
                                            </Col>
                                            <Col span={4}>
                                                {(() => {
                                                    switch (cf.dataType) {
                                                        case "number":
                                                            return (
                                                                <Input defaultValue={cf.value} onChange={(e) => updateInputValue(r, cf, e.target.value)} type={cf.dataType==="number"?'Number':'Text'}/>
                                                            );
                                                        case "strArray":
                                                           return (
                                                            <Input 
                                                                    defaultValue={cf.value} 
                                                                    onChange={(e) => updateInputValue(r, cf, e.target.value.split(",").filter(v => v!==""))}
                                                                />
                                                            );
                                                        case "numArray":
                                                            return (
                                                                <Input 
                                                                        defaultValue={cf.value} 
                                                                        onChange={(e) => updateInputValue(r, cf, e.target.value.split(",").filter(v => v!=="")).map(y=>Number(y))}
                                                                    />
                                                                );
                                                        case "date":
                                                            return (
                                                              <div>
                                                                <DatePicker
                                                                  onChange={(date) =>
                                                                    updateInputValue(r, cf, date.format("yyyy-MM-DD[T]HH:mm:ss.SSSZ"))
                                                                  }
                                                                  value={moment(cf.value)}
                                                                />
                                                              </div>
                                                            );
                                                        default: 
                                                            return (
                                                                <Input defaultValue={cf.value} onChange={(e) => updateInputValue(r, cf, e.target.value)} type={cf.dataType==="number"?'Number':'Text'}/>
                                                            )
                                                        }
                                                })()}
                                                
                                            </Col>
                                            <Col span={1}>
                                                <div>&nbsp;</div>
                                            </Col>
                                        </React.Fragment>
                                    )
                                })}

                            </Row>


                            {/* <Row>
                        {r.customFilters && r.customFilters.map((cf, j) => {
                            return (
                            <React.Fragment key={'res_row_' + i + '_f_' + j}>

                            <Col span={3}>
                            <div>{cf.parameterName}</div>
                            </Col>
                            <Col span={4}>
                            <Input defaultValue={cf.value} onChange={(e) => updateInputValue(r, cf, e.target.value)} />
                            </Col>
                            <Col span={1}>
                            <div>&nbsp; </div>
                            </Col>
                            </React.Fragment>
                        )
                        })}

                            </Row> */}

                            <Row>
                                <Col span={3}>
                                    <Button onClick={() => props.onApplyFilter(r)}>Apply</Button>
                                </Col>
                            </Row>

                        </>
                        : (<></>)


                )
            })}

        </ >
    );

}


export default CustomFilters;
