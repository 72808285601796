import React from "react";
import GroupColumns from "./GroupColumns";
import GroupingEditor from "./GroupingEditor";

const GroupRows = (props) => {
  const [fields, setFields] = React.useState([]);
  const [allFields, setAllFields] = React.useState([]);

  React.useEffect(() => {
    console.log("PROPS GroupRows====>>>", props);
    let availableFieldsSet = props?.selectedResource?.availableFields?.map(
      (af) => af
    );

    props?.selectedResource?.aggregation?.row?.forEach((a, k) => {
      availableFieldsSet.push({
        fieldName: a.displayName,
        checked: true,
        dataType: 'number',
        id: Math.floor(Math.random() * 10000) + 1,
      });
    });
    console.log("GroupRows AvailableFieldsSet====>>>", availableFieldsSet);
    setFields(availableFieldsSet);
  }, [props]);

  // React.useEffect(() => {
  //   console.log("PROPS GroupRows====>>>", props);
  //   let allFieldsSet = props?.selectedResource?.allFields?.map(
  //     (af) => af
  //   );

  //   props?.selectedResource?.aggregation?.row?.forEach((a, k) => {
  //     allFieldsSet.push({
  //       fieldName: a.displayName,
  //       checked: true,
  //       dataType: 'number',
  //       id: Math.floor(Math.random() * 10000) + 1,
  //     });
  //   });
  //   console.log("GroupRows AvailableFieldsSet====>>>", allFieldsSet);
  //   setAllFields(allFieldsSet);
  // }, [props]);

  return (
    <div style={{ overflowY: "auto", height: "35vh"}}>
      <GroupingEditor 
        availableFields={props?.selectedResource?.allFields}
        aggGroupBuilders={props.selectedResource?.aggGroupBuilders}
      />
      {/* <GroupColumns
        reportDataObject={props.reportDataObject}
        selectedResource={props.selectedResource}
        availableFields={fields}
        allFields={props?.selectedResource?.allFields}
        updateSelectedFields={props.updateSelectedFields}
        getSelectedConnection={props.getSelectedConnection}
      /> */}
    </div>
  );
};

export default GroupRows;
