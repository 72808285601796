import { useEffect, useState } from 'react'
import { Form, Button } from 'antd'
import { useDispatch, useSelector } from "react-redux";
import * as _ from 'lodash';

import { SelectOption } from '../../../../../components/common/SelectOption/SelectOption';
import { InputField } from '../../../../../components/common/InputField/InputField';
import { updateChart } from "../../../../../redux/editorSlice";

const Chart = ({ selectedResource }) => {
  const [values, seValues] = useState({});
  const [availableYaxisValues, setAvailableYaxisValues] = useState([])
  const [availableXaxisValues, setAvailableXaxisValues] = useState([])

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if(selectedResource.availableFields) {
    const chartDetails = selectedResource?.charts || {};
    form.setFieldsValue(Object.assign({}, {
      chartName: chartDetails?.chartName || null,
      chartType: chartDetails?.chartType || null,
      groupBy: chartDetails?.groupBy?.id,
      fieldName: chartDetails?.fieldName?.id
    }));
    seValues(Object.assign({}, values, { chartName: chartDetails?.chartType }))

    let colBuilders = [];

    if (selectedResource && selectedResource.aggregation && selectedResource.aggregation.row) {
      let fieldsLength=selectedResource?.availableFields.length;
      colBuilders = selectedResource.aggregation.row.map((r, i) => {
        return {
          checked: true,
          dataType: "number",
          fieldName: r.displayName,
          id: (fieldsLength+i),
          colBuilder: r
        }
      })
    }

    setAvailableYaxisValues([...selectedResource?.availableFields?.filter(field => field.dataType === 'number'), ...colBuilders])
    setAvailableXaxisValues([...selectedResource?.availableFields, ...colBuilders])
  }
  }, [selectedResource])

  const handleOnFinish = (chartDetails) => {
    const selectedFiled = availableYaxisValues.find(value => value.id === chartDetails.fieldName)
    const selectdGroupBy = availableXaxisValues.find(value => value.id === chartDetails.groupBy)
    dispatch(updateChart(Object.assign({}, chartDetails, { fieldName: selectedFiled, groupBy: selectdGroupBy, chartName: selectedResource?.source?.widgetName })));
  };

  const handleValueChange = (name, value) => {
    seValues(Object.assign({}, values, { [name]: value }))
  }

  return (
    <div style={{ overflowY: "auto", height: "30vh" }}>
      <Form
        form={form}
        onFinish={handleOnFinish}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        {/* <InputField
          placeholder={'Chart Name'}
          name={'chartName'}
          label={'Chart Name'}
        /> */}

        <SelectOption
          placeholder={'Chart Type'}
          values={['Bar', 'Line', 'Pie']}
          name={'chartType'}
          label={'Chart Type'}
          handleOnSelect={(type) => handleValueChange('chartName', type)}
        />
        {values?.chartName === 'Pie' ?
          <>
            <SelectOption
              placeholder={'Field Name'}
              values={availableYaxisValues}
              valueName={'id'}
              labelName={'fieldName'}
              name={'fieldName'}
              label={'Field Name'}
            />
            <SelectOption
              placeholder={'Field Name'}
              values={selectedResource?.availableFields}
              valueName={'id'}
              labelName={'fieldName'}
              name={'groupBy'}
              label={'Group By'}
            />

          </> :
          <>
            <SelectOption
              placeholder={'X Value'}
              values={availableXaxisValues}
              valueName={'id'}
              labelName={'fieldName'}
              name={'groupBy'}
              label={'X axis'}
            />
            <SelectOption
              placeholder={'Y Value'}
              values={availableYaxisValues}
              valueName={'id'}
              labelName={'fieldName'}
              name={'fieldName'}
              label={'Y axis'}
            />
          </>
        }
        {/* <SelectOption
        placeholder={'Options'}
        values={['Sum']}
        name={'options'}
      />   */}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
};

export default Chart;
