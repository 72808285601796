import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { Row, Col, Select, Button, Input, Tag, Mentions, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { returnDataArrayObject } from "../../../../../utils/arrayHelper";
import NotFound from "../../../../../components/NotFound";
import {
  resetAggBuilders,
  updateAggColBuilders,
  updateSelectedFields,
} from "../../../../../redux/editorSlice";
const { Option } = Select;

function tagRender(props) {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

export default function ColBuilder(props) {
  const [builders, setBuilders] = useState([]);
  const dispatch = useDispatch();

  const setBuilderItem = () => {
    setBuilders((b) => [
      ...b,
      {
        id: v4(),
        rowAggregation: [
          {
            id: v4(),
            fieldName: "none",
            operator: "none",
          },
        ],
      },
    ]);
  };

  const deleteBuilderItem = (item) => {
    setBuilders(builders.filter((f) => f.id !== item.id));
  };

  useEffect(() => {
    const rows = props.selectedResource?.aggregation?.row || [];
    if(Boolean(rows.length)){
      let currentBuilders = rows.map((bd) => {
        return {
              id: bd.id,
              displayName: bd.displayName,
              rowAggregation: bd.rowAggregation,
            };
      });
      setBuilders(currentBuilders);
    }
    if(!Boolean(props?.selectedResource?.aggregation?.row.length)){
      setBuilders([]);
    }
  }, [props?.selectedResource?.aggregation?.row])

  const updateBuilder = (item, key, val) => {
    if (val !== "") {
      let currentBuilders = builders.map((bd) => {
        return bd.id === item.id
          ? {
              id: bd.id,
              displayName:
                key === "displayName"
                  ? val
                  : bd.displayName,
              rowAggregation: item.rowAggregation,
            }
          : {...bd};
      });

      console.log("updateBuilder Call=>", currentBuilders);
      setBuilders(currentBuilders);
    }
  };

  const updateBuilderByIndex = (item, key, val, ra, i) => {
    if (val !== null || val !== "none") {
      const ubd = builders.map((b) => {
        return b.id === item.id ? {
          id: b.id,
          displayName: b.displayName,
          rowAggregation:
              b.rowAggregation.map((bra) => {
                  return bra.id === ra.id ? {
                    id: bra.id,
                    operator: key === "operator"
                        ? val
                        : bra.operator,
                    fieldNamesList: key === "fieldNamesList"
                        ? val
                        : bra.fieldNamesList,
                    fieldName: key === "fieldName"
                        ? val
                        : bra.fieldName,
                    const_1: key === "const_1"
                        ? val
                        : bra.const_1,
                    calculationStr: key === "calculationStr"
                        ? val
                        : bra.calculationStr
                  } : { ...bra };
                })
        } : { ...b };
      });
      console.log("updateBuilderByIndex Call=>", ubd);
      setBuilders(ubd);
    }
  };

  const applyBuilders = (filterArray) => {
    dispatch(updateAggColBuilders(filterArray));
    message.info("Builders have been successfully applied!");
  };

  const clearBuilders = () => {
    dispatch(resetAggBuilders());
    props.resetBuilders();
    setBuilders((s) => []);
    message.info("Builders have been removed!");
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {returnDataArrayObject(props.reportDataObject) ? (
        <div style={{ width: "100%" }}>
          {builders.map((fi) => {
            return (
              <Row
                key={"fil_" + fi.id}
                gutter={16}
                style={{ marginTop: "16px" }}
              >
                <Col span={4}>
                  <div>Display Name:</div>
                  <div>
                    <Input
                      onChange={(e) =>
                        updateBuilder(fi, "displayName", e.target.value)
                      }
                      value={fi.displayName}
                    />
                  </div>
                </Col>

                {fi.rowAggregation.map((ra, n) => {
                  return (
                    <React.Fragment key={`art_${fi.id}_${n}`}>
                      <Col span={6}>
                        <div>Operation:</div>
                        <div>
                          <Select
                            style={{ width: "90%" }}
                            value={ra.operator}
                            onChange={(val) => {
                              updateBuilderByIndex(fi, "operator", val, ra, n);
                            }}
                            defaultValue='none'
                          >
                            <Option value='none' key='none'>
                              None
                            </Option>
                            <Option value='sum' key='add'>
                              Sum
                            </Option>
                            <Option value='avg' key='avg'>
                              Average
                            </Option>
                            <Option value='func' key='func'>
                              Function
                            </Option>
                            <Option value='add_c' key='add_c'>
                              Add Constant(+)
                            </Option>
                            <Option value='multiply_c' key='multiply_c'>
                              Multiply Constant(x)
                            </Option>
                          </Select>
                        </div>
                      </Col>

                      {ra.operator && ra.operator !== "none" ? (
                        ra.operator &&
                        (ra.operator === "add_c" ||
                          ra.operator === "multiply_c") ? (
                          <>
                            <Col span={6}>
                              <div>Field Name:</div>
                              <div>
                                <Select
                                  style={{ width: "90%" }}
                                  placeholder='Select Data Field'
                                  value={ra.fieldName}
                                  defaultValue={'none'}
                                  onChange={(val) =>
                                    updateBuilderByIndex(
                                      fi,
                                      "fieldName",
                                      val,
                                      ra,
                                      n
                                    )
                                  }
                                >
                                  <Option value='none' key='none'>
                                    None
                                  </Option>
                                  {props?.availableFields?.map(
                                      (af, j) => {
                                        return (
                                          <Option
                                            value={af.fieldName}
                                            key={fi.id + "_1_" + j + "_" + n}
                                          >
                                            {af.fieldName}
                                          </Option>
                                        );
                                      }
                                    )}
                                </Select>
                              </div>
                            </Col>
                            <Col span={6}>
                              <div>Constant Value:</div>
                              <div>
                                <Input
                                  type='number'
                                  onChange={(e) =>
                                    updateBuilderByIndex(
                                      fi,
                                      "const_1",
                                      e.target.value,
                                      ra,
                                      n
                                    )
                                  }
                                />
                              </div>
                            </Col>
                          </>
                        ) : ra.operator &&
                          (ra.operator === "sum" || ra.operator === "avg") ? (
                          <Col span={6}>
                            <div>Field Names:</div>
                            <div>
                              <Select
                                mode='multiple'
                                showArrow
                                tagRender={tagRender}
                                style={{ width: "100%", minWidth: "200px" }}
                                value={ra.fieldNamesList?.map(i => i)}
                                options={props.availableFields?.map((af) => {
                                  return { value: af.fieldName };
                                })}
                                onChange={(val) =>
                                  updateBuilderByIndex(
                                    fi,
                                    "fieldNamesList",
                                    val,
                                    ra,
                                    n
                                  )
                                }
                              />
                            </div>
                          </Col>
                        ) : ra.operator && ra.operator === "func" ? (
                          <Col span={8}>
                            <div>Function:</div>
                            <div>
                              <Mentions
                                style={{ width: "100%" }}
                                onChange={(v) =>
                                  updateBuilderByIndex(
                                    fi,
                                    "calculationStr",
                                    v,
                                    ra,
                                    n
                                  )
                                }
                                placeholder='@field1 + @field2...'
                                value={ra.calculationStr?ra.calculationStr:''}
                              >
                                {props.availableFields
                                  ?.filter((af) => af.dataType === "number" || af.dataType === "float")
                                  ?.map((af) => (
                                    <Mentions.Option
                                      key={af.fieldName}
                                      value={`${af.fieldName}$`}
                                    >
                                      {af.fieldName}
                                    </Mentions.Option>
                                  ))}
                              </Mentions>
                            </div>
                            <div>Ex: {`@field1$ - @field2$..`}</div>
                          </Col>
                        ) : null
                      ) : null}
                    </React.Fragment>
                  );
                })}
                <Col span={1} offset={1}>
                  <div>&nbsp;</div>
                  <div>
                    <Button
                      type='text'
                      icon={<CloseOutlined />}
                      onClick={() => deleteBuilderItem(fi)}
                    />
                  </div>
                </Col>
              </Row>
            );
          })}

          <Row style={{ marginTop: "16px", marginBottom: "32px" }} gutter={16}>
            <Col span={4}>
              <Button onClick={setBuilderItem}>Add New +</Button>
            </Col>

            <Col span={3}>
              <Button type='primary' onClick={() => applyBuilders(builders)}>
                Apply
              </Button>
            </Col>
          </Row>
          <hr />
          <Row gutter={16} style={{ marginBlock: "2rem" }}>
            <Col span={5}>
              <Button onClick={() => clearBuilders()}>Reset</Button>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <NotFound />
        </div>
      )}
    </div>
  );
}
