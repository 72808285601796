import React from "react";

import AppRoutes from "./routes/AppRoutes";
import Notification from "./components/common/Notification/Notification";
import "./App.css";

function App() {
  return (
    <>
      <Notification />
      <AppRoutes />
    </>
  );
}

export default App;
