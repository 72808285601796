import { Form, Select, Tooltip } from "antd";
import PropTypes from "prop-types";


const { Option } = Select;

export const SelectOption = ({
  label,
  name,
  size,
  color,
  rules,
  isMultiple,
  placeholder,
  values,
  valueName,
  labelName,
  handleOnSelect,
  handleOnDeselect,
  disabled,
  tooltip,
  ...props
}) => {
  return (
    <Form.Item
      label={
        tooltip ? (
          <>
            {label}
            <Tooltip title={tooltip}>
              <span>
                {/* <img src={infoCLientIcon} className="icon-tooltip" /> */}
              </span>
            </Tooltip>
          </>
        ) : (
          label
        )
      }
      name={name}
      rules={rules}
      // className={["select-option", `select-option--${size}`, `${color}`].join(
      //   " "
      // )}
    >
      <Select
        mode={isMultiple ? "multiple" : "single"}
        placeholder={placeholder}
        dropdownClassName={color}
        onSelect={handleOnSelect}
        onDeselect={handleOnDeselect}
        showSearch
        filterOption={(input, values) =>
          values.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        {...props}
      >
        {values?.map((value, index) => {
          return (
            <Option key={index} value={valueName ? value[valueName] : value}>
              {labelName ? value[labelName] : value}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

SelectOption.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
  placeholder: PropTypes.string,
  values: PropTypes.array,
  valueName: PropTypes.string,
  labelName: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
  color: PropTypes.oneOf(["provider", "client", "sg"]),
  handleOnSelect: PropTypes.func,
  handleOnDeselect: PropTypes.func,
  disabled: PropTypes.bool,
};

SelectOption.defaultProps = {
  name: "",
  label: "",
  isMultiple: false,
  placeholder: "",
  values: [],
  size: "large",
  valueName: "",
  labelName: "",
  handleOnSelect: undefined,
  handleOnDeselect: undefined,
  disabled: false,
  tooltip: false,
};
