import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Tree } from 'antd';
import {
    DownOutlined,
    DashboardOutlined,
    FolderOutlined,
    // FrownOutlined,
    // MehOutlined,
    // FrownFilled
} from '@ant-design/icons';
import './accmenuStyles.css';
import { updateSelectedBoard } from '../../redux/boardSlice';

// const treeData = [
//     {
//         title: 'parent 1',
//         key: '0-0',
//         icon: <FolderOutlined />,
//         children: [
//             {
//                 title: 'leaf',
//                 key: '0-0-0',
//                 icon: <DashboardOutlined />,
//             },
//             {
//                 title: 'leaf',
//                 key: '0-0-1',
//                 icon: <DashboardOutlined />,
//             },
//         ],
//     },
//     {
//         title: 'parent 2',
//         key: '2-0',
//         icon: <FolderOutlined />,
//         children: [
//             {
//                 title: 'leaf',
//                 key: '2-0-0',
//                 icon: <DashboardOutlined />,
//             },
//             {
//                 title: 'leaf',
//                 key: '2-0-1',
//                 icon: <DashboardOutlined />,
//             },
//         ],
//     },
//     {
//         title: 'parent 3',
//         key: '3-0',
//         icon: <FolderOutlined />,
//         children: [
//             {
//                 title: 'leaf',
//                 key: '3-0-0',
//                 icon: <DashboardOutlined />,
//             },
//             {
//                 title: 'leaf',
//                 key: '3-0-1',
//                 icon: <DashboardOutlined />,
//             },
//         ],
//     },
// ];

function AccMenu(props) {
    let history = useHistory();
    let dispatch = useDispatch();
    const [treeDataList, setTreeDataList] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(['0-0-0']);
    const [expandedKeys, setExpandedKeys] = useState(['0-0-0']);
    const params = useParams()
    useEffect(() => {
        if (props.dashboardGroups && props.dashboardList) {
            let menuData = props.dashboardGroups.map((dg, i) => {
                return {
                    title: dg.title,
                    key: `${i}`,
                    icon: <FolderOutlined />,
                    children: [...dg.children.map((c, j) => {
                        return {
                            title: c.title,
                            icon: <FolderOutlined />,
                            key: `main_${i}_sub_${j}`,
                            children: props.dashboardList.filter(item => item.configDetails.groupId === dg.id && item.configDetails.selectedSubGoupId === c.id).map((dl, k) => {
                                return (
                                    {
                                        key: `${dl.id}`,
                                        title: dl.name,
                                        icon: <DashboardOutlined />,
                                        data: dl
                                        // menuTitle: (<div style={{ minWidth: '140px' }}> <Button style={{ textAlign: 'left' }} onClick={() => { this.props.history.push("/board/" + dl.id); this.props.addSelectedBoard(dl); }} type="text" block icon={<Dashboard />}>{dl.name}</Button></div>),
                                        // menuTitle: dl.name,
                                        // menuIcon: <Dashboard />,

                                    }
                                )
                            })
                        }
                    }),
                    ...props.dashboardList.filter(item => item.configDetails.groupId === dg.id && item.configDetails.selectedSubGoupId === "none").map((dl, k) => {
                        return (
                            {
                                key: `${dl.id}`,
                                title: dl.name,
                                icon: <DashboardOutlined />,
                                data: dl
                                // menuTitle: (<div style={{ minWidth: '140px' }}> <Button style={{ textAlign: 'left' }} onClick={() => { this.props.history.push("/board/" + dl.id); this.props.addSelectedBoard(dl); }} type="text" block icon={<Dashboard />}>{dl.name}</Button></div>),
                                // menuTitle: dl.name,
                                // menuIcon: <Dashboard />,

                            }
                        )
                    })
                    ]
                    // children: [
                    //     {
                    //         title: 'leaf',
                    //         key: `${i}-0-0`,
                    //         icon: <DashboardOutlined />,
                    //     },
                    //     {
                    //         title: 'leaf',
                    //         key: `${i}-0-1`,
                    //         icon: <DashboardOutlined />,
                    //     },
                    // ],
                }
            })

            setTreeDataList(menuData)
        }
        if (params && params.boardId) {
            setSelectedKeys([params.boardId]);
            setExpandedKeys([params.boardId]);
        } else {
            // setSelectedKeys(params.boardId);
            setExpandedKeys([0])
        }

    }, [props]);

    const handleShowDashboard = (keys, info) => {
        console.log("CLICKED on SIDEBAR Menu", keys, info)
        if (info.node.data) {
            const selectedBoard = info.node.data;

            dispatch(updateSelectedBoard(selectedBoard))
            if (selectedBoard.configDetails.boardType === 'dashboard') {
                history.push("/board/" + selectedBoard.id);
            } else {
                history.push("/report/" + selectedBoard.id);
            }
        }
        // history.push("/board/" + e[0]);
        // dispatch(updateSelectedBoard(props.dashboardList.find(d => d.id === e[0])))
        // this.props.addSelectedBoard(dl)
    }
    return (
        <>
            {
                treeDataList.length > 0 ?
                    <Tree
                        showIcon
                        defaultSelectedKeys={selectedKeys}
                        defaultExpandedKeys={expandedKeys}
                        switcherIcon={<DownOutlined />}
                        treeData={treeDataList}
                        style={{ backgroundColor: 'none' }}
                        onSelect={handleShowDashboard}
                    />
                    : null
            }
        </>
    )

}
export default AccMenu;
