import React from 'react';
import { Row, Col, Input, Button, Divider, Avatar, Menu, message, Dropdown } from 'antd';
import { DownOutlined, CloseOutlined } from '@ant-design/icons';
export default function ResourceList(props) {



    const menu = (
        <Menu>
            <Menu.Item key="resmenu_1" onClick={() => { props.addNewResource('Rest API') }}>
                Rest Api
            </Menu.Item>
            <Menu.Item key="resmenu_2" onClick={() => { props.addNewResource('Combined Resource') }}>
                Combined Resource
            </Menu.Item>
            <Menu.Item key="resmenu_3">
                SQL database
            </Menu.Item>
            <Menu.Item key="resmenu_4">
                NoSql Database
            </Menu.Item>
        </Menu>
    );
    return (

        <div style={{ padding: '8px' }}>
            <Row gutter={6}>
                <Col span={16}><Input type="text" /></Col>
                <Col span={8}>
                    <Dropdown overlay={menu}>
                        <div><Button type='primary' block>New<DownOutlined /></Button></div>

                    </Dropdown>
                </Col>
            </Row>
            <Divider />
            <div style={{ overflowY: 'auto', height: '30vh' }}>
                {props.resourceList.map((item, i) => {
                    return (
                        <div
                            key={`res_q_${i}`}
                            style={{ paddingTop: '8px', paddingBottom: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer', backgroundColor: props.selectedResource && props.selectedResource.id === item.id ? '#efefff' : '#fafafa' }}
                        >
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '4px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '90%' }} onClick={() => props.setSelected(item)}>

                                        <span>{item.id}. {item.type ? item.type : 'Combined Resource'}</span>
                                        <Avatar size="small">{item['type'] ? item['type'].split(/\s/).reduce((response, word) => response += word.slice(0, 1), '') : 'CR'}</Avatar>
                                    </div>
                                    <div>

                                        <Button type="link" danger icon={<CloseOutlined style={{ fontSize: '12px' }} />} onClick={() => props.removeResourceItem(item)}></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div >

    )
}