import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Avatar, Image } from 'antd';
import { findIndex, indexOf } from 'lodash-es';
// import { getAppsAsync } from '../../redux/userSlice';


function AddApps(props) {
    const [appList, setAppList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    // const [selectedItem, setSelectedItem] = useState(null);
    const userdata = useSelector((state) => state.user);

    useEffect(() => {
        if (userdata.apps && userdata.apps.Result) {
            setAppList(userdata.apps.Result)
        }
    }, [userdata]);

    const onSelectApps = (app) => {
        if (props.multiSelectEnabled) {
            let appList = [...selectedList];
            if (selectedList.findIndex(x => x === app.ApplicationID) > -1) {
                setSelectedList(selectedList.filter(item => item !== app.ApplicationID))
            } else {
                appList.push(app.ApplicationID)
                setSelectedList([...new Set(appList)])
            }
        } else {
            setSelectedList([app.ApplicationID]);
            // setSelectedItem(app.ApplicationID)
        }

    }

    return (

        <div style={{ maxWidth: '960px' }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {appList.map((r, i) => {
                    return (
                        <Col key={`app_li_${i}`} className="gutter-row" span={8} style={{ marginBottom: '24px' }}>
                            <Button block size="large" onClick={() => { onSelectApps(r) }} style={{ border: selectedList.findIndex(x => x === r.ApplicationID) > -1 ? '2px solid #00ee88' : 'none' }}>
                                <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '24px', padding: '4px' }}>
                                    <div style={{ marginRight: '8px' }}>
                                        <Avatar
                                            src={<Image src={r.AppImage} />}
                                        />
                                    </div>
                                    <div>{r.Name}</div>
                                </div>
                            </Button>
                        </Col>
                    )
                })}

            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={8} offset={16} style={{ marginBottom: '24px' }}>
                    <Button block size="large" type="primary" onClick={() => { props.onFinishSelect(selectedList, props.multiSelectEnabled) }}>
                        Next
                    </Button>
                </Col>
            </Row>
        </div>



    )
}

export default AddApps;