import React, { Children } from 'react';
import { Popover } from 'antd';
import "./common.css"

const PopoverMenu = (props) => {
    const { popoverContent } = props;

    const handlePopoverContent = ()=>{
        return(<div className='content-wrapper'>
            {popoverContent.map((cont)=>{
                return(
                    <div className='content-item' onClick={cont.handleClick}>
                        {cont.icon}
                        <span className='ml-16'>{cont.name}</span>
                    </div>
                )
            })}
        </div>
    )}
    return (
        <Popover content={()=>handlePopoverContent()} {...props} overlayClassName="menu-overlay">
            {props.children}
        </Popover>
    )
}

export default PopoverMenu