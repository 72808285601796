import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  Modal,
  Alert,
  Popconfirm,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateSelectedResource,
  deletetResourceAsync,
  resetResourceDeleteStatus
} from "../../redux/resourceSlice";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import RestApiForm from "../../components/resourceForms/RestApiForm";
import MongoDBForm from "../../components/resourceForms/MongoDBForm";
import GraphQLForm from "../../components/resourceForms/GraphQLForm";
export default function ResourceList(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.user);
  const resourcesData = useSelector((state) => state.resources);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedResourceType, setSelectedResourceType] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [isViewOnly, setIsViewOnly] = useState(false);

  const xxx=localStorage.getItem('IsSpecialUser')
  console.log("xxx", xxx, typeof(xxx))

  useEffect(() => {
    if(resourcesData.resourceDeleteStatus==="success") {
      message.success("Resource has been successfully deleted!");
      dispatch(resetResourceDeleteStatus())
    }
  }, [resourcesData.resourceDeleteStatus])

  const showModal = (rd, isView) => {
    setSelectedResource(rd);
    setIsModalVisible(true);
    setSelectedResourceType(
      props.resourceTypes.find((rt) => rt.name === rd.resoueceType)
    );
    setIsViewOnly(isView);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const confirmDelete = (e, res) => {
    console.log(e);
    dispatch(deletetResourceAsync(res));
  };

  const cancelDelete = (e) => {
    console.log(e);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedResourceType(null);
  };

  const showReportView = async (resource) => {
    console.log("Selected resource===>>", resource);
    await dispatch(updateSelectedResource(resource));
    history.push("/report-view/" + resource.id);
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <Row>
        <Col span={12}>
          <div>
            <b>Name</b>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <b>Connection Type</b>
          </div>
        </Col>
        {localStorage.getItem("IsSpecialUser") ? (
          <Col span={6}>
            <div>
              <b>Action</b>
            </div>
          </Col>
        ) : null}
      </Row>
      <Divider />
      <>
        {props.resourceData &&
          props.groups &&
          props.resourceData.map((rd, i) => {
            return (
              <>
                <Row
                  key={`rti_${i}`}
                  style={{
                    marginBottom: "8px",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  <Col span={12}>
                    <div>
                      <Button type='link' onClick={() => showReportView(rd)}>
                        {rd.name}
                      </Button>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>{rd.resoueceType}</div>
                  </Col>
                  {localStorage.getItem("IsSpecialUser") ? (
                    <Col span={6}>
                      <div>
                        <Button
                          icon={<EditOutlined />}
                          type='link'
                          disabled={rd.isWitmegApi && (localStorage.getItem('IsSpecialUser')==='false') }
                          onClick={() => {
                            showModal(rd, false);
                          }}
                        ></Button>
                        <Button
                          icon={<EyeOutlined />}
                          type='link'
                          onClick={() => {
                            showModal(rd, true);
                          }}
                        ></Button>
                        {}
                        <Popconfirm
                          title='Are you sure to delete this resource?'
                          onConfirm={(e) => confirmDelete(e, rd)}
                          onCancel={cancelDelete}
                          okText='Yes'
                          cancelText='No'
                          disabled={rd.isWitmegApi && (localStorage.getItem('IsSpecialUser')==='false')}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            type='link'
                            disabled={rd.isWitmegApi && (localStorage.getItem('IsSpecialUser')==='false')}
                          ></Button>
                        </Popconfirm>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </>
            );
          })}

        {isModalVisible ? (
          <Modal
            title={`Configure ${selectedResource.resoueceType}`}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={768}
          >
            {(() => {
              switch (selectedResourceType.formName) {
                case "RestApiForm":
                  return (
                    <RestApiForm
                      resourceType={selectedResourceType}
                      handleClose={handleCloseModal}
                      appList={[selectedResource.appId]}
                      appsMultiSelectEnabled={props.appsMultiSelectEnabled}
                      usersData={usersData}
                      groupList={props.groups}
                      selectedResource={selectedResource}
                      isViewOnly={isViewOnly}
                    />
                  );
                case "MongoDBForm":
                  return (
                    <MongoDBForm
                      resourceType={selectedResourceType}
                      handleClose={handleCloseModal}
                      appList={[selectedResource.appId]}
                      appsMultiSelectEnabled={props.appsMultiSelectEnabled}
                      usersData={usersData}
                      groupList={props.groups}
                      isViewOnly={isViewOnly}
                    />
                  );
                case "GraphQLForm":
                  return (
                    <GraphQLForm
                      resourceType={selectedResourceType}
                      handleClose={handleCloseModal}
                      appList={[selectedResource.appId]}
                      appsMultiSelectEnabled={props.appsMultiSelectEnabled}
                      usersData={usersData}
                      groupList={props.groups}
                      isViewOnly={isViewOnly}
                    />
                  );
                default:
                  return (
                    <div>
                      <Alert
                        message={`${selectedResourceType.name} Configuration Not Allowed`}
                        description={`Currently not allowed to configure ${selectedResourceType.name}.`}
                        type='info'
                      />
                    </div>
                  );
              }
            })()}
          </Modal>
        ) : null}
      </>
    </div>
  );
}
