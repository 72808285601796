
import React from 'react';
import { Row, Col } from 'antd'

export default function ReportInfo(props) {
    return (
        <div>
            <h3>Report Info</h3>
            <Row>
                <Col span={3}><h4>Name</h4></Col>
                <Col span={6}><span>{props.selectedResource.name}</span> </Col>

                <Col span={3}><h4>Resource Type</h4></Col>
                <Col span={9}><span>{props.selectedResource.resoueceType}</span> </Col>
            </Row>
        </div>

    )
}
