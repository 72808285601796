import React, { useState } from 'react';
import { Form, Input } from 'antd';

export default function AuthOForm(props) {
    const [domainName, setDomainName] = useState('');
    const [clientID, setClientID] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [domainNameError, setDomainNameError] = useState(false);
    const [clientIDError, setClientIDError] = useState(false);
    const [clientSecretError, setClientSecretError] = useState(false);

    const handleChangeDomainName = (e) => {
        setDomainName(e.target.value);
        setDomainNameError(false)

        props.handleChangeValues({
            domainName: domainName,
            clientID: clientID,
            clientSecret: clientSecret
        });
    }

    const handleChangeClientID = (e) => {
        setClientID(e.target.value);
        setClientIDError(false);
        props.handleChangeValues({
            domainName: domainName,
            clientID: clientID,
            clientSecret: clientSecret
        });
    }

    const handleChangeClientSecret = (e) => {
        setClientSecret(e.target.value);
        setClientSecretError(false);
        props.handleChangeValues({
            domainName: domainName,
            clientID: clientID,
            clientSecret: clientSecret
        });
    }

    return (
        <div>
            < Form.Item style={{ marginBottom: '8px' }}
                label="Auth Domain"
                name="domainName"
                rules={[{ required: true, message: 'Please input username!' }]}
                validateStatus={domainNameError ? "error" : ""}
            >
                <Input value={domainName} onChange={handleChangeDomainName} disabled={props.isViewOnly}/>
            </Form.Item>
            < Form.Item style={{ marginBottom: '8px' }}
                label="Auth Client ID"
                name="domainName"
                rules={[{ required: true, message: 'Please input username!' }]}
                validateStatus={clientIDError ? "error" : ""}
            >
                <Input value={clientID} onChange={handleChangeClientID} disabled={props.isViewOnly} />
            </Form.Item>
            < Form.Item style={{ marginBottom: '8px' }}
                label="Auth Client Secret"
                name="clientSecret"
                rules={[{ required: true, message: 'Please input password!' }]}
                validateStatus={clientSecretError ? "error" : ""}
            >
                <Input value={clientSecret} onChange={handleChangeClientSecret} disabled={props.isViewOnly}/>
            </Form.Item>
        </div>
    )
}