
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Alert, Modal } from 'antd';
import { Link, Route, useRouteMatch, Redirect, useHistory } from "react-router-dom";

import DashboardView from '../DashboardView';
import ResourceTypesList from './ResourceTypesList';
import AddApps from '../../components/AddApps';
import RestApiForm from '../../components/resourceForms/RestApiForm';
import MongoDBForm from '../../components/resourceForms/MongoDBForm';
import GraphQLForm from '../../components/resourceForms/GraphQLForm';
import NoPermission from '../../components/common/NoPermission';

export default function AddResource() {
    const isAppsMultiSelectEnabled = false;
    const [appList, setAppList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addedApps, setAddedApps] = useState(false);
    const [selectedResourceType, setSelectedResourceType] = useState(null);

    const { path } = useRouteMatch();
    const history = useHistory();

    const usersData = useSelector((state) => state.user);
    const groups = useSelector((state) => state.boards.groups);


    const organizationList = [
        {
            "OrganisationID": "5eb537c660c090232c0d807b",
            "Name": "Best Foods Whole Sale1"
          },
          {
            "OrganisationID": "5ec3c4c72ca923bd5e8a6640",
            "Name": "South Harrow Food and Wine"
          },
          {
            "OrganisationID": "5ec5f702dcd86fe64a959475",
            "Name": "Ecologital12"
          },
          {
            "OrganisationID": "5ec5f81bdcd86fe64a95947e",
            "Name": "Ecologital123"
          },
          {
            "OrganisationID": "5ec5f96cdcd86fe64a959486",
            "Name": "Ecologital1122"
          },
          {
            "OrganisationID": "5ed9b1661be0ae9b446d5bcd",
            "Name": "Best Foods New"
          },
          {
            "OrganisationID": "5ed9c0181be0ae9b446d5bd4",
            "Name": "Ecologital"
          },
          {
            "OrganisationID": "5ed9d1ac1be0ae9b446d5be1",
            "Name": "Ecologital12258"
          },
          {
            "OrganisationID": "5ed9d4661be0ae9b446d5be9",
            "Name": "Ecologital223"
          },
          {
            "OrganisationID": "5ed9d5911be0ae9b446d5bf1",
            "Name": "Ecologital12345"
          },
          {
            "OrganisationID": "5ed9d5db1be0ae9b446d5bf9",
            "Name": "Ecologital333"
          },
          {
            "OrganisationID": "5ed9e6831be0ae9b446d5c01",
            "Name": "KRISHNA CASH AND CARRY"
          },
          {
            "OrganisationID": "5f005e18abbc59fbdd589a33",
            "Name": "Select Super Market - West Croydon"
          },
          {
            "OrganisationID": "5f006023abbc59fbdd589a3b",
            "Name": "Mini Super Store"
          },
          {
            "OrganisationID": "5f0061a0abbc59fbdd589a43",
            "Name": "T and O Store"
          },
          {
            "OrganisationID": "5f006250abbc59fbdd589a4b",
            "Name": "Clark Store"
          },
          {
            "OrganisationID": "5f0435c247913a61cc420a28",
            "Name": "Ecologital Dev"
          },
          {
            "OrganisationID": "5f05e7f454a5908787dccee8",
            "Name": "Ecologital Dev Payment"
          },
          {
            "OrganisationID": "5f05e81156c85fb55f80f759",
            "Name": "Ecologital Dev Payment2"
          },
          {
            "OrganisationID": "5f19647bc70bf4bf64c0f37b",
            "Name": "Shankar-Retail"
          },
          {
            "OrganisationID": "5f19683fc70bf4bf64c0f383",
            "Name": "Bestfoods Whole Sale"
          },
          {
            "OrganisationID": "5f4f334754a5908787dccf09",
            "Name": "Test"
          },
          {
            "OrganisationID": "5f58962fdf41239122095743",
            "Name": "Suren Pvt Ltd"
          },
          {
            "OrganisationID": "5f59e004df4123912209574b",
            "Name": "Testq"
          }
    ]

    const setSelectedResourceTypeForm = (resourceType) => {
        console.log("resourceType===>>", resourceType)
        setSelectedResourceType(resourceType);
        // if (resourceType.formName === "RestApiForm") {
        //     showModal();
        // } else {
        //     message.warning(`Can't and ${resourceType.name}`);
        // }
        setIsModalVisible(true);
    }

    const handleFinishSelectApps = (apps) => {
        setAppList(apps)
        setAddedApps(!addedApps);
        history.push(`${path}/resource`)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedResourceType(null)
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    if(usersData.currentAppPermissions && usersData.currentAppPermissions.indexOf("CreateResource")===-1 || !localStorage.getItem("IsSpecialUser")) {
        return (
            <DashboardView>
                <NoPermission />
            </DashboardView>
        )
    }

    return (
        <DashboardView>
            <div>
                <Route path={`${path}/apps`}>
                    <Row>
                        <Col span={18}>
                            <h2>Add Apps</h2>
                        </Col>
                        <Col>
                            <AddApps multiSelectEnabled={isAppsMultiSelectEnabled} onFinishSelect={handleFinishSelectApps} />
                        </Col>
                    </Row>
                </Route>
                <Route path={`${path}/resource`}>
                    <div>
                       <Row>
                           <Col span={18}>
                               <h2>Add Resources</h2>
                           </Col>
                           <Col span={6}>
                               <Button type="link"><Link to="resources">Resources</Link></Button>
                           </Col>
                       </Row>
                       <ResourceTypesList 
                            appList={appList} 
                            appsMultiSelectEnabled={isAppsMultiSelectEnabled}
                            setSelectedResourceTypeForm={setSelectedResourceTypeForm} 
                        />
                    </div>
                </Route>
                <Route exact path={`${path}/`}>
                    <Redirect to={`${path}/apps`} />
                </Route>
                {
                    // addedApps ?
                    //     <div>
                    //         <Row>
                    //             <Col span={18}>
                    //                 <h2>Add Resources</h2>
                    //             </Col>
                    //             <Col span={6}>
                    //                 <Button type="link"><Link to="resources">Resources</Link></Button>
                    //             </Col>
                    //         </Row>
                    //         <ResourceTypesList appList={appList} appsMultiSelectEnabled={isAppsMultiSelectEnabled} />
                    //     </div>
                    //     : <Row>
                    //         <Col span={18}>
                    //             <h2>Add Apps</h2>
                    //         </Col>
                    //         <Col>
                    //             <AddApps multiSelectEnabled={isAppsMultiSelectEnabled} onFinishSelect={handleFinishSelectApps} />
                    //         </Col>
                    //     </Row>
                }

            {isModalVisible ?
                <Modal title={`Configure ${selectedResourceType.name}`} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} width={996}>
                    {(() => {
                        switch (selectedResourceType.formName) {
                            case 'RestApiForm':
                                return (
                                    <RestApiForm resourceType={selectedResourceType} handleClose={handleCloseModal} appList={appList} appsMultiSelectEnabled={isAppsMultiSelectEnabled} usersData={usersData} groupList={groups} organizationList={organizationList}/>
                                );
                            case 'MongoDBForm':
                                return (
                                    <MongoDBForm resourceType={selectedResourceType} handleClose={handleCloseModal} appList={appList} appsMultiSelectEnabled={isAppsMultiSelectEnabled} usersData={usersData} groupList={groups} />
                                );
                            case 'GraphQLForm':
                                return (
                                    <GraphQLForm resourceType={selectedResourceType} handleClose={handleCloseModal} appList={appList} appsMultiSelectEnabled={isAppsMultiSelectEnabled} usersData={usersData} groupList={groups} />
                                );
                            default:
                                return <div>
                                    <Alert
                                        message={`${selectedResourceType.name} Configuration Not Allowed`}
                                        description={`Currently not allowed to configure ${selectedResourceType.name}.`}
                                        type="info"
                                    />
                                </div>
                        }
                    })()}


                </Modal>
                : null}
            </div>
        </DashboardView>
    )
}