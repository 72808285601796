import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, message, Modal, Select } from 'antd';
import { Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { signin } from '../../redux/userSlice';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";

import EcouiItemsChecker from '../../components/EcouiItemsChecker';
const { REACT_APP_OrgRegistryApi, REACT_APP_OrgjwtAud, REACT_APP_OrgjwtIss, REACT_APP_OrgjwtPrivate, REACT_APP_OrgjwtExp,
    // REACT_APP_APP_REGISTRY_API, REACT_APP_APP_REGISTRY_ISS, REACT_APP_APP_REGISTRY_AUD, REACT_APP_APP_REGISTRY_PRIVATE_KEY 
} = process.env;
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const { Option } = Select;
// const children = [];

function onChange(value) {

    localStorage.setItem('orgID', value.split("*")[0]);

    //  console.log(`selected ${value.split("*")[0]}`);
}

const Login = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    // let userResponse = undefined;
    var accessDec = undefined;
    var refreshDec = undefined;
    const dispatch = useDispatch();
    const [sussess, setSuccess] = useState(false);

    const [username, setUsername] = useState("");

    const [userInfo, setUserInfo] = useState([]);
    const [tokenInfo, setTokenInfo] = useState({AccessToken:'' , RefreshToken:'' })
    const [permissionList, setPermissionList] = useState([]);

    let bdy = {};


    
    const showModal = () => {
        setIsModalVisible(true);

    };
    const handleOk = () => {
        setIsModalVisible(false);

        dispatch(signin({ username: username, isSigned: true, userData: '', accessToken: tokenInfo.AccessToken, refreshToken: tokenInfo.RefreshToken, permissions:permissionList }));
        setSuccess(true);

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const LoadLocations = (organizations, token) => {

        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
            "aud": REACT_APP_OrgjwtAud,
            "exp": timestamp,
            "iss": REACT_APP_OrgjwtIss,
            "usertoken": token
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = REACT_APP_OrgjwtPrivate;
        var jwttoken = jwt.sign(payload, privateKey.replace(/\\n/g, '\n'), signOptions);

        const vrequestOptions = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + jwttoken,
            }
        };
        var Locationsarr = []
        organizations.forEach((organization) => {


            fetch(`${REACT_APP_OrgRegistryApi}locations/` + organization.OrganisationID, vrequestOptions)
                .then(lresponse => lresponse.json())
                .then(function (ldata) {
                    //  console.log(ldata.Result)
                    Locationsarr = Locationsarr.concat(ldata.Result)
                })


        })

        setTimeout(function () {

            // console.log(Locationsarr)
            localStorage.setItem('children', JSON.stringify(Locationsarr));

            showModal();

        }, 3000);



    }

    const onFinish = async (values) => {
        debugger;

        var bdy = { UserName: values.username, Password: values.password };

        message.loading('Please wait... Validating your login', 2.5)

        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
            "aud": REACT_APP_OrgjwtAud,
            "exp": timestamp,
            "iss": REACT_APP_OrgjwtIss
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = REACT_APP_OrgjwtPrivate.replace(/\\n/g, '\n');



        var token = jwt.sign(payload, privateKey, signOptions);
        

        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + token,
            },
            body: JSON.stringify(bdy)
        };

        //  console.log(values)

        // fetch(`${REACT_APP_APP_REGISTRY_API}`, requestOptions)
        //     .then(response => response.json())
        //     .then((resp) => {
        //         console.log("APPS resp===>>", resp)
        //     });

        await fetch(`${REACT_APP_OrgRegistryApi}v1/permission/org/user/login`, requestOptions)
            .then(response => response.json())
            .then((resp) => {

                try {
                    const userResponse = resp.Result
                    const permissionList = resp.Permissions
                    accessDec = jwt.decode(userResponse.AccessToken);
                    refreshDec = jwt.decode(userResponse.RefreshToken);
                    Cookies.set('infinity', userResponse.AccessToken, { expires: new Date(accessDec.exp * 1000) });
                    Cookies.set('embose', userResponse.RefreshToken, { expires: new Date(refreshDec.exp * 1000) });
                    localStorage.setItem('refreshToken', userResponse.RefreshToken);
                    localStorage.setItem('accessToken', userResponse.AccessToken);
                    localStorage.setItem('permissionList', JSON.stringify(permissionList))
                    message.info('Please wait.. fetching your details.', 2.5)

                    // window.location.href = "/dashboard/" + localStorage.getItem('appid')

                    const expireTime = new Date().getTime() + (REACT_APP_OrgjwtExp * 1000)
                    setTokenInfo(userResponse);
                    setPermissionList(permissionList)

                    var payload = {
                        "aud": REACT_APP_OrgjwtAud,
                        "exp": expireTime,
                        "iss": REACT_APP_OrgjwtIss,
                        "usertoken": userResponse.AccessToken
                    };


                    var signOptions = {
                        "algorithm": "RS256"
                    };

                    var privateKey = REACT_APP_OrgjwtPrivate;
                    var token = jwt.sign(payload, privateKey.replace(/\\n/g, '\n'), signOptions);

                    const vrequestOptions = {
                        method: 'GET',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ` + token,
                        }
                    };

                    var decoded = jwt_decode(userResponse.AccessToken);

                    console.log("decoded===>>", decoded)

                    fetch(`${REACT_APP_OrgRegistryApi}user/` + decoded.userid, vrequestOptions)
                        .then(response => response.json())
                        .then(function (data) {

                            console.log(data.Result)
                            localStorage.setItem('appid', 'witmegreport');
                            localStorage.setItem('loggedin', true);
                            localStorage.setItem('userID', data.Result.UserID);
                            localStorage.setItem('IsSpecialUser', data.Result.IsSpecialUser);
                            localStorage.setItem('fullUserDetails', JSON.stringify(data.Result));

                            fetch(`${REACT_APP_OrgRegistryApi}registereduser/user/` + decoded.userid, vrequestOptions)
                                .then(vresponse => vresponse.json())
                                .then(function (vdata) {
                                    console.log("vdata===>>", vdata)
                                    if (vdata.Result.length > 1) {
                                        message.info('Please wait.. fetching your organization details.', 2.5)
                                        LoadLocations(vdata.Result, userResponse.AccessToken)
                                    } else {
                                        localStorage.setItem('appID', data.Result.UserID + "-app");
                                        localStorage.setItem('orgID', vdata.Result[0].OrganisationID);
                                        localStorage.getItem('appID', vdata.Result[0].OrganisationID);
                                        dispatch(signin({ username: username, isSigned: true, userData: accessDec, accessToken: userResponse.AccessToken, refreshToken: userResponse.RefreshToken }));
                                        setSuccess(true);
                                    }


                                })

                        })


                } catch (err) {
                    message.error('Invalid Login!!  Username/Password is invalid.');
                }
            })
            .catch((error) => {
                message.error('Invalid Login!!  Username/Password is invalid.');
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if (sussess) {
        return <Redirect to='/' />;
    }



    return (
        <EcouiItemsChecker>
            <div style={{}}>

                <div style={{ maxWidth: '480px', margin: 'auto', marginTop: '20vh', backgroundColor: '#eeefff', padding: '24px' }}>

                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input value={username} onChange={(e) => { setUsername(e.target.value) }} />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                SignIn
                            </Button>
                        </Form.Item>
                    </Form>


                    <Modal title="Organization Location Selection" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>You're belongs to more than one Organization.
                            Please select your Organization location to view the reports</p>


                        <Select
                            style={{ width: 200 }}
                            onChange={onChange}
                            placeholder="Select a location"
                        >
                            {localStorage.getItem('children') ? (
                                JSON.parse(localStorage.getItem('children')).map(location =>
                                    <Option value={location.OrganisationID + '*' + location.Name}>{location.Name}</Option>
                                )
                            ) : ('')
                            }

                        </Select>

                    </Modal>


                </div>
            </div>
        </EcouiItemsChecker>
    );
};

export default Login;
