const groupArr = (array, key) => {
  return array.map((obj) => objectFilter(obj, key)).reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const objectFilter = (obj, key) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      (item) => typeof item[1] === "number" || item[0] === key
    )
  );
};

export const groupedSum = (key, a) => {
  let result = {};
  let ans = {};

  // console.log("TYPE OF KEY", typeof key, key);
  for (var i = 0; i < a.length; ++i) {
    for (var obj in a[i]) {
      // console.log(obj.toString() === key.toString());
      ans[obj] =
        ans[obj] && a[i][obj] !== key && typeof a[i][obj] === "number"
          ? ans[obj] + a[i][obj]
          : a[i][obj];
    }
  }
  result = { ...result, [key]: ans };
  return result;
};

export const groupBy = (array, key) => {
  const arr = groupArr(array, key);
  let result = Object.entries(arr).map((item) => groupedSum(item[0], item[1]));
  return result.map((x) => x[Object.keys(x)[0]]);
};

export const groupByCount = (array, key) => groupArr(array, key);

export const groupByFiledName = (array, key) => groupArr(array, key);

// var list = [
//   { name: "1", lastname: "foo1", age: "16" },
//   { name: "2", lastname: "foo", age: "13" },
//   { name: "3", lastname: "foo1", age: "11" },
//   { name: "4", lastname: "foo", age: "11" },
//   { name: "5", lastname: "foo1", age: "16" },
//   { name: "6", lastname: "foo", age: "16" },
//   { name: "7", lastname: "foo1", age: "13" },
//   { name: "8", lastname: "foo1", age: "16" },
//   { name: "9", lastname: "foo", age: "13" },
//   { name: "0", lastname: "foo", age: "16" },
// ];

export const groupingByMultipleFields = (array, f) => {
  let groups = {};
  array.forEach(function (o) {
    let group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map((group) => {
    return groups[group];
  });
}



export const getSumOfGroupByMultiFields = (arr, groupKeys, sumKeys) => {
  let hash = Object.create(null),
    grouped = [];
  arr.forEach(function (o) {
    let key = groupKeys
      .map(function (k) {
        return o[k];
      })
      .join("|");
    if (!hash[key]) {
      hash[key] = Object.keys(o).reduce((result, key) => {
        result[key] = o[key];
        if (sumKeys.includes(key)) result[key] = 0;
        return result;
      }, {}); //map_(o) //{ shape: o.shape, color: o.color, used: 0, instances: 0 };
      grouped.push(hash[key]);
    }
    sumKeys.forEach(function (k) {
        if(typeof o[k] === 'number') {
          return hash[key][k] += o[k];
        } else {
          return hash[key][k]
        }
    });
  });
  return grouped;
};
