import React, { useState, useEffect } from "react";
import {
  List,
  Modal,
  Select,
  Input,
  InputNumber,
  Row,
  Col,
  Divider,
  Menu,
  Dropdown,
  AutoComplete,
  Spin
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DownOutlined } from "@ant-design/icons";
import { returnDataArrayObject } from "../../../../utils/arrayHelper";
import {
  DragOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import NotFound from "../../../../components/NotFound";

const { Option } = Select;
const { Search } = Input;

export default function ResourceFilters(props) {
  const [visible, setVisible] = useState(false);
  const [searchColNameKey, setSearchColNameKey] = useState("");

  const [data, setData] = useState([]);
  const [clickedHideAll, setClickedHideAll] = useState(false);
  const dateFormatOptions = [
    { value: 'YYYY-MM-DD' },
    { value: 'YYYY-MM-DD HH:mm'}
  ]

  //console.log("returnDataArrayObject(props.reportDataObject)", props);

  function onChangeCheckBoxVal(field, e) {
    props.updateSelectedFields({
      id: field.id,
      fieldName: field.fieldName,
      checked: e.target.checked,
      dataType: field.dataType,
      format: field.format,
      decimalPoints: field.decimalPoints
    });
    console.log(`checked = ${e.target.checked}`);
  }

  function handleTypeChange(field, value) {
    if (value === "link") {
      setVisible(true);
    }
    props.updateSelectedFields({
      id: field.id,
      fieldName: field.fieldName,
      checked: field.checked,
      dataType: value,
      format: field.format,
      decimalPoints: value==="float"?2:null

    });
  }

  function handleDateFormatChange(field, e) {
    console.log("EEE===>>>", e)
    props.updateSelectedFields({
      id: field.id,
      fieldName: field.fieldName,
      checked: field.checked,
      dataType: field.dataType,
      format: e,
      decimalPoints: field.decimalPoints
    });
  }

  function handleDecimalPointsChange(field, value) {
    props.updateSelectedFields({
      id: field.id,
      fieldName: field.fieldName,
      checked: field.checked,
      dataType: field.dataType,
      format: field.format,
      decimalPoints: value
    });
  }

  const setDisplayNameFieldVisibility = (selectedIndex, isChecked) => {
    const displayValues = data.find((displayValue, index) => index === selectedIndex);
    const field = {
      ...displayValues,
      isDisplayNameVisible : isChecked,
      fieldDisplayName : isChecked && displayValues.fieldDisplayName ? displayValues.fieldDisplayName : null
    }
    props.updateSelectedFields(field);
  }

  const handleDisplayNameChange = (field, displayName) => {
    props.updateSelectedFields({
      ...field,
      fieldDisplayName: displayName
    });
  }

  const reportDataFields =
    returnDataArrayObject(props.reportDataObject) &&
      returnDataArrayObject(props.reportDataObject).data[0]
      ? returnDataArrayObject(props.reportDataObject).data[0]
      : null;

  const reorder = (list, startIndex, endIndex) => {
    console.log("list", list);
    console.log("startIndex", startIndex);
    console.log("endIndex", endIndex);

    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onHandleMoveTop = (fields) => {
    const categories = reorder(
      props.availableFields,
      props.availableFields?.findIndex((x) => x.id === fields.id),
      0
    );
    props.reOrderFields(categories);
    setData(categories);
  };

  const onHandleMoveBottom = (fields) => {
    const categories = reorder(
      props.availableFields,
      props.availableFields?.findIndex((x) => x.id === fields.id),
      props.availableFields.length - 1
    );
    props.reOrderFields(categories);
    setData(categories);
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const categories = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    props.reOrderFields(categories);
    setData(categories);
  }

  useEffect(() => {
    if (props.availableFields) {
      setData(props.availableFields?.filter((item) => item.fieldName.toLowerCase().includes(searchColNameKey.toLowerCase())))
    }
  }, [props.availableFields]);

  const onSearchColName = (value) => {
    console.log("Search Value ===>>", value);
    if (value) {
      let FilteredData = data?.filter((item) =>
        item.fieldName.toLowerCase().includes(value.toLowerCase())
      );
      setData((state) => [...FilteredData]);
    } else {
      setData(props.availableFields);
    }
  };

  const onChangeCheckAll = (e) => {
    console.log("Check All ===>>", e);
    setClickedHideAll(!clickedHideAll);
    if (data && reportDataFields) {
      props.availableFields.forEach((field, index) => {
        props.updateSelectedFields({
          id: field.id,
          fieldName: field.fieldName,
          checked: clickedHideAll,
          dataType: field.dataType,
          format: field.format,
          decimalPoints: field.decimalPoints
        });
      });
    }
  };

  const searchColNameByKey = (e) => {
    setSearchColNameKey(e.target.value);
    // if (e.key === 'Enter') {
    onSearchColName(e.target.value)
    // }
  }

  if(props.reportDataStatus === "pending") {
    return (
      <div style={{padding:'48px 24px', display:'flex', justifyContent:'center'}}>
        <Spin />
      </div>
      
    )
  }

  return (
    <div style={{ padding: "8px" }}>
      <Modal
        title='URL prefix'
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <p>Enter the URL prefix</p>
        <Input placeholder='URL prefix' />
      </Modal>

      <div style={{ borderBottom: "1px solid #eee", paddingBottom: "12px" }}>
        Columns
      </div>

      <div
        style={{
          borderBottom: "1px solid #eee",
          padding: "4px",
          background: "#fff",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={1}></Col>
          <Col span={4}>
            <label class='check-text'>
              <input
                type='checkbox'
                checked={clickedHideAll}
                onChange={(e) => onChangeCheckAll(e)}
              />
              <EyeOutlined className='unchecked' />
              <EyeInvisibleOutlined className='checked' />
            </label>
          </Col>
          <Col span={17}>
            <div>
              <Search
                placeholder='input search text'
                value={searchColNameKey}
                onChange={searchColNameByKey}
                onSearch={onSearchColName}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
        </Row>
      </div>

      {data && reportDataFields ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ overflowY: "auto", height: "30vh" }}
              >
                {data.map((fields, index) => (
                  <Draggable
                    draggableId={fields.id.toString()}
                    index={index}
                    key={fields.id}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <List.Item
                          className='draggable raggable-item'
                          style={{
                            margin: "0 0 .5rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <label class='check-text'>
                              <input
                                type='checkbox'
                                checked={fields.checked}
                                onChange={(e) => onChangeCheckBoxVal(fields, e)}
                              />
                              <EyeInvisibleOutlined className='unchecked' />
                              <EyeOutlined className='checked' />
                            </label>
                            <DragOutlined className='icon' />
                            <List.Item.Meta
                              title={fields.fieldName}
                              className='grabbable'
                            />
                            <Dropdown overlayStyle={{cursor: 'pointer'}}
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key='0'
                                    onClick={() => onHandleMoveTop(fields)}
                                  >
                                    Move to top
                                  </Menu.Item>
                                  <Menu.Divider />
                                  <Menu.Item
                                    key='1'
                                    onClick={() => onHandleMoveBottom(fields)}
                                  >
                                    Move to bottom
                                  </Menu.Item>
                                  <Menu.Divider />
                                  <Menu.Item
                                    key='1'
                                  >
                                     <input
                                      type='checkbox'
                                      checked={fields.isDisplayNameVisible}
                                      onChange={(e) => setDisplayNameFieldVisibility(index, e.target.checked)}
                                    />
                                    Set display name
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={["click"]}
                            >
                              <span
                                className='ant-dropdown-link'
                                onClick={(e) => e.preventDefault()}
                                style={{ marginRight: "5px" }}
                              >
                                <DownOutlined style={{cursor: 'pointer'}} />
                              </span>
                            </Dropdown>
                          </Row>
                          <Row style={{ width: "100%" }}>
                            <Select
                              defaultValue={
                                fields.dataType ? fields.dataType : typeof reportDataFields[fields.fieldName]
                              }
                              onChange={(val) => handleTypeChange(fields, val)}
                              size='small'
                              style={{ width: "100%" }}
                            >
                              <Option key='daf_opt_1' value='number'>
                                Number
                              </Option>
                              <Option key='daf_opt_2' value='float'>
                                Float
                              </Option>
                              <Option key='daf_opt_3' value='int'>
                                Integer
                              </Option>
                              <Option key='daf_opt_4' value='string'>
                                String
                              </Option>
                              <Option key='daf_opt_5' value='link'>
                                Link
                              </Option>
                              <Option key='daf_opt_6' value='date'>
                                Date
                              </Option>
                              <Option key='daf_opt_7' value='boolean'>
                                Boolean
                              </Option>
                              <Option key='daf_opt_8' value='object'>
                                Object
                              </Option>
                              <Option key='daf_opt_9' value='array'>
                                Array
                              </Option>
                              <Option key='daf_opt_10' value='null'>
                                NULL
                              </Option>
                              <Option key='daf_opt_8' value='undefined'>
                                Undefined
                              </Option>
                            </Select>
                          </Row>
                          {
                            fields.dataType === 'date' ?
                              <Row>
                                <AutoComplete
                                  // value={dateFormat}
                                  style={{ width: '100%' }}
                                  placeholder='Date Format'
                                  options={dateFormatOptions}
                                  onChange={(e) =>handleDateFormatChange(fields, e)}
                                  value={fields.format}
                                />
                              </Row>
                              : null                            
                          }
                          {
                            fields.dataType === 'float' ?
                            <Row>
                            <Select
                              defaultValue={2}
                              onChange={(val) => handleDecimalPointsChange(fields, val)}
                              size='small'
                              style={{ width: "100%" }}
                            >
                              <Option key='dec_opt_1' value={1}>
                                1
                              </Option>
                              <Option key='dec_opt_2' value={2}>
                                2
                              </Option>
                              <Option key='dec_opt_3' value={3}>
                                3
                              </Option>
                              <Option key='dec_opt_4' value={4}>
                                4
                              </Option>
                              <Option key='dec_opt_5' value={5}>
                                5
                              </Option>
                              <Option key='dec_opt_6' value={6}>
                                6
                              </Option>
                            </Select>
                        </Row>
                              : null                            
                          }
                          {
                            fields.isDisplayNameVisible && 
                            <Row>
                                <Input 
                                  placeholder="Display name"
                                  onChange={(e) => handleDisplayNameChange(fields, e.target.value)} 
                                  value={fields.fieldDisplayName}/>
                            </Row>
                          }
                        </List.Item>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div>
          <NotFound />
        </div>
      )}
    </div>
  );
}
