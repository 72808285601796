import React from 'react';
import { Table } from 'antd';

const CustomTable = (props) => {
    const { tableData, tableHeaders, tableClass, hasExpandedRows } = props;
    return (
        <Table 
            className={tableClass}
            columns={tableHeaders}
            dataSource={tableData}
            expandable={ hasExpandedRows ?
                {
                expandedRowRender: (record)=> (
                    <div>
                        {record.expandedData}
                    </div>
                ),
                rowExpandable: (record) => record.expandedData ? true : false,
                expandRowByClick: true
            } : null}
            />
    )
}

export default CustomTable;