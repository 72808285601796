import {
    AndroidFilled as AndroidFilledIcon,
    LogoutOutlined as LogoutIcon,
    UserOutlined as UserIcon,
    HomeOutlined as HomeIcon,
    SettingOutlined as SettingsIcon,
    CloudServerOutlined as CloudServer,
    DashboardOutlined as Dashboard,
    AppstoreAddOutlined as AppstoreAdd,
    TableOutlined,
    FolderOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
import { DashboardLayout } from 'ecologital-ui-library';

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { Modal, Radio, Mentions, Form, Input, Button, Select, Row, Col, message } from 'antd';
import { signout, getAppsAsync, setOtherUsers } from '../redux/userSlice';
import { addDashboardConfig } from '../redux/editorSlice';
import { getBoardsAsync, updateSelectedBoard, getGroupsAsync } from '../redux/boardSlice';

import jwt from 'jsonwebtoken';
import AddApps from '../components/AddApps';
// import BoardGoups from '../components/BoardGoups';
import AddBoardGroup from '../components/BoardGoups/AddBoardGroup';
import AccMenu from '../components/AccMenu';
import MaterialDesignIcon from '../components/common/MaterialDesignIcon';

const { Option } = Mentions;
const { REACT_APP_OrgRegistryApi, REACT_APP_OrgjwtAud, REACT_APP_OrgjwtIss, REACT_APP_OrgjwtPrivate } = process.env;


const ORG_LIST=[
    {
        "OrganisationID": "5eb537c660c090232c0d807b",
        "Name": "Best Foods Whole Sale1"
      },
      {
        "OrganisationID": "5ec3c4c72ca923bd5e8a6640",
        "Name": "South Harrow Food and Wine"
      },
      {
        "OrganisationID": "5ec5f702dcd86fe64a959475",
        "Name": "Ecologital12"
      },
      {
        "OrganisationID": "5ec5f81bdcd86fe64a95947e",
        "Name": "Ecologital123"
      },
      {
        "OrganisationID": "5ec5f96cdcd86fe64a959486",
        "Name": "Ecologital1122"
      },
      {
        "OrganisationID": "5ed9b1661be0ae9b446d5bcd",
        "Name": "Best Foods New"
      },
      {
        "OrganisationID": "5ed9c0181be0ae9b446d5bd4",
        "Name": "Ecologital"
      },
      {
        "OrganisationID": "5ed9d1ac1be0ae9b446d5be1",
        "Name": "Ecologital12258"
      },
      {
        "OrganisationID": "5ed9d4661be0ae9b446d5be9",
        "Name": "Ecologital223"
      },
      {
        "OrganisationID": "5ed9d5911be0ae9b446d5bf1",
        "Name": "Ecologital12345"
      },
      {
        "OrganisationID": "5ed9d5db1be0ae9b446d5bf9",
        "Name": "Ecologital333"
      },
      {
        "OrganisationID": "5ed9e6831be0ae9b446d5c01",
        "Name": "KRISHNA CASH AND CARRY"
      },
      {
        "OrganisationID": "5f005e18abbc59fbdd589a33",
        "Name": "Select Super Market - West Croydon"
      },
      {
        "OrganisationID": "5f006023abbc59fbdd589a3b",
        "Name": "Mini Super Store"
      },
      {
        "OrganisationID": "5f0061a0abbc59fbdd589a43",
        "Name": "T and O Store"
      },
      {
        "OrganisationID": "5f006250abbc59fbdd589a4b",
        "Name": "Clark Store"
      },
      {
        "OrganisationID": "5f0435c247913a61cc420a28",
        "Name": "Ecologital Dev"
      },
      {
        "OrganisationID": "5f05e7f454a5908787dccee8",
        "Name": "Ecologital Dev Payment"
      },
      {
        "OrganisationID": "5f05e81156c85fb55f80f759",
        "Name": "Ecologital Dev Payment2"
      },
      {
        "OrganisationID": "5f19647bc70bf4bf64c0f37b",
        "Name": "Shankar-Retail"
      },
      {
        "OrganisationID": "5f19683fc70bf4bf64c0f383",
        "Name": "Bestfoods Whole Sale"
      },
      {
        "OrganisationID": "5f4f334754a5908787dccf09",
        "Name": "Test"
      },
      {
        "OrganisationID": "5f58962fdf41239122095743",
        "Name": "Suren Pvt Ltd"
      },
      {
        "OrganisationID": "5f59e004df4123912209574b",
        "Name": "Testq"
      },
      {
        "OrganisationID": "5f59e3d9df41239122095753",
        "Name": "Ecologital_npos"
      },
      {
        "OrganisationID": "5f59f61adf4123912209575b",
        "Name": "Ssueen"
      },
      {
        "OrganisationID": "5f8985ffdf41239122095763",
        "Name": "Casipillai"
      },
      {
        "OrganisationID": "5f9113f9df4123912209576b",
        "Name": "Ecologital NPOS"
      }
]
class DashboardView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            visible: false,
            sharedListShow: true,
            rtype: '',
            dashboardName: '',
            boardNamerror: false,
            users: [],
            usersList: [],
            userIdList: [],
            sharedOrgIdList: [],
            addedApps: false,
            isAppsMultiSelectEnabled: true,
            appList: [],
            groupMenuVissible: false,
            selectedGoup: null,
            goupError: false,
            selectedSubGoupId: "none",
            isGroupModalVisible: false,
        };
    }


    GetUserDetails = async (UserDetails) => {


        const timestamp = Math.floor(Date.now() / 1000) + 1000;

        var payload = {
            "aud": REACT_APP_OrgjwtAud,
            "exp": timestamp,
            "iss": REACT_APP_OrgjwtIss,
            "usertoken": localStorage.getItem('accessToken')
        };


        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = REACT_APP_OrgjwtPrivate;
        var token = jwt.sign(payload, privateKey.replace(/\\n/g, '\n'), signOptions);


        const result = [];

        const requestOptions2 = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + token,
            },
            body: JSON.stringify({
                "UserID": UserDetails.UserID
            })
        };

        return await fetch(
            REACT_APP_OrgRegistryApi + 'v1/permission/org/user/userid',
            requestOptions2,
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.Status) {
                    result.push({ ...UserDetails, UserDetails: data.Result[0] });
                }
                return result[0];
            })
            .catch((error) => {
                //window.location.href="/login/logout"
            });

    }

    componentDidMount() {
        this.props.getBoards(this.props.accessToken);
        this.props.getGroups(this.props.accessToken);
        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
            "aud": REACT_APP_OrgjwtAud,
            "exp": timestamp,
            "iss": REACT_APP_OrgjwtIss,
            "usertoken": localStorage.getItem('accessToken')
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = REACT_APP_OrgjwtPrivate;
        var token = jwt.sign(payload, privateKey.replace(/\\n/g, '\n'), signOptions);

        const vrequestOptions = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + token,
            }
        };

        fetch(`${REACT_APP_OrgRegistryApi}registeredusers/` + localStorage.getItem('orgID'), vrequestOptions)
            .then(lresponse => lresponse.json())
            .then(async (odata) => {
                //  console.log(odata);
                const users = await Promise.all(
                    odata.Result.map(
                        async (UserID) => await this.GetUserDetails(UserID, odata.Result),
                    ),
                );
                let validUsers = users.filter(u => u)

                this.setState({ users: validUsers });
                this.props.setOtherUsers(validUsers);

                console.log(users);
            });
        this.props.getApps()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.accessToken !== this.props.accessToken) {
            this.props.getBoards(this.props.accessToken);
            this.props.getGroups(this.props.accessToken);
        }
    }

    handleLogOut = () => {
        // console.log("LogOut");
        this.props.logOut();
        this.props.history.push("/");
        // window.location.href = "/"
    };
    showModal = (type) => {
        this.setState({
            visible: true,
            value: 1,
            rtype: type
        });
    };

    handleOk = e => {

        if (this.state.dashboardName === "") {
            this.setState({
                boardNamerror: true
            })
        } else if (!this.state.selectedGoup) {
            this.setState({
                goupError: true
            })
            return
        } else {
            this.setState({
                visible: false,
            });
            const configData = {
                boardType: this.state.rtype,
                boardName: this.state.dashboardName,
                isPuplic: this.state.sharedListShow,
                isGlobal: this.state.value===3,
                shareWith: this.state.sharedListShow ? this.state.userIdList : [],
                appList: this.state.appList,
                groupId: this.state.selectedGoup.id,
                selectedSubGoupId: this.state.selectedSubGoupId
            };
            localStorage.setItem('currentDashboardConfig', JSON.stringify(configData));
            this.props.setDashboardConfig(configData)
            if (this.state.rtype === 'reports') {
                this.props.history.push("/editor/reports")
                // window.location.href = "/editor/reports"
            } else {
                this.props.history.push("/editor/dashboard")
            }
        }

    };
    handleCancel = e => {
        //console.log(e);
        this.setState({
            visible: false,
        });
    };

    onChangeRadio = e => {
        console.log(e.target.value)
        if (e.target.value === 2) {
            this.setState({
                sharedListShow: false,
                value: 2
            });
        } else if (e.target.value === 3) {
            this.setState({
                sharedListShow: false,
                value: 3
            });
        } else {
            this.setState({
                sharedListShow: true,
                value: 1
            });
        }
    };


    onSelectSharableUsers = e => {
        let users = [...this.state.usersList]
        users.push(e.value)
        this.setState({
            usersList: [...new Set(users)]
        })

        let userIds = [...this.state.userIdList]
        userIds.push(e.data.UserDetails.UserID)
        this.setState({
            userIdList: [...new Set(userIds)]
        })
    }

    onSelectSharableOrganizations = e => {
        let orgs = [...this.state.sharedOrgIdList]
        orgs.push(e.value)
        this.setState({
            sharedOrgIdList: [...new Set(orgs)]
        })
    }

    // onClickSharableUsers = userObj => {
    //     console.log("onClickSharableUsers===>>", userObj);
    //     let users = [...this.state.userIdList]
    //     users.push(userObj.UserDetails.UserID)
    //     this.setState({
    //         userIdList: [...new Set(users)]
    //     })
    // }

    onClickSharableOrganizations = orgObj => {
        console.log("onClickSharableOrganizations===>>", orgObj);
        let orgs = [...this.state.sharedOrgIdList]
        orgs.push(orgObj.OrganisationID)
        this.setState({
            sharedOrgIdList: [...new Set(orgs)]
        })
    }

    handleFinishSelectApps = (apps) => {
        this.setState({ appList: apps, addedApps: apps.length > 0 ? true : false })
    }

    onSelectBoardInGroups = (board) => {
        this.props.addSelectedBoard(board)
    }

    handleVisibleChange = groupMenuVissible => {
        this.setState({ groupMenuVissible });
    };

    onChangeGoupSearch = (value) => {
        console.log(`selected ${value}`);
        this.setState({
            selectedGoup: [...this.props.dashboardGroups, ...this.props.reportGroups].find(gd => gd.id === value),
            goupError: false
        })
    }

    onBlurGoupSearch = () => {
        console.log('blur');
    }

    onFocusGoupSearch = () => {
        console.log('focus');
    }

    onSearchGoupSearch = (val) => {
        console.log('search:', val);
    }

    handleOkGpModal = () => {
        this.setState({
            isGroupModalVisible: false
        })
    }

    handleCancelGpModal = () => {
        this.setState({
            isGroupModalVisible: false
        })
    }

    handleAddGroup = () => {
        if (localStorage.getItem("IsSpecialUser") || this.props.currentAppPermissions.indexOf("CreateResource") > -1) {
            this.setState({
                isGroupModalVisible: true
            })
        } else {
            message.warning("You don't have permissions to create groups")
        }

    }

    handleAddResource = () => {
        if (localStorage.getItem("IsSpecialUser") || this.props.currentAppPermissions.indexOf("CreateResource") > -1) {
            this.props.history.push("/add-resource");
        } else {
            message.warning("You don't have permissions to create groups")
        }
    }

    handleAddDashboard = (boardType) => {
        if (localStorage.getItem("IsSpecialUser") || this.props.currentAppPermissions.indexOf("CreateDashboard") > -1) {
            this.showModal(boardType);
        } else {
            message.warning("You don't have permissions to create dashboards/reports")
        }

    }

    render() {
        return (
            <div className='DashboardPage'>
                <DashboardLayout
                    loaderProps={{
                        isLoading: this.props.boardsStatus === 'pending',
                        showDashboard: this.props.boardsStatus !== 'pending', // Preventing, Any dashboard content is not rendered until all fetched.
                        // isError: isMainPageActionsError,

                        appLogoUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG5Omn5Fd0IYMYavsfGgXhc2guce2dLKv01X7zxSkPcO6kE4ZiVoqre-VOGLDSHPpEAZE&usqp=CAU',
                        // errorMsg: isMainPageActionsError,
                        // onErrorTryAgainFn: () => this.runPreFetchActions(),
                    }}>

                    <DashboardLayout.AppBar
                        appTitleProps={{
                            appTitle: 'Witmeg Reporting',
                            appSubtitle: 'Report Engine',
                            appLogoUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG5Omn5Fd0IYMYavsfGgXhc2guce2dLKv01X7zxSkPcO6kE4ZiVoqre-VOGLDSHPpEAZE&usqp=CAU',
                            onClick: () => { this.props.history.push("/"); }
                        }}
                        pageTitleProps={{
                            pageTitle: 'Dynamic Reports',
                            pageSubtitle: 'Customize Easy',
                            onClick: () => {
                                console.log('PageTitle CLICKED');
                            },
                        }}
                        userMenuProps={{
                            avatarValue: JSON.parse(localStorage.getItem('fullUserDetails')).FirstName.charAt(0) + JSON.parse(localStorage.getItem('fullUserDetails')).LastName.charAt(0),
                            avatarIcon: <AndroidFilledIcon />,
                            title: JSON.parse(localStorage.getItem('fullUserDetails')).FirstName + ' ' + JSON.parse(localStorage.getItem('fullUserDetails')).LastName,
                            subTitle: 'JohnDoe@Gmail.com',
                            menuItems: [
                                {
                                    menuTitle: 'Log Out',
                                    menuIcon: <LogoutIcon />,
                                    onClick: () => {
                                        console.log('UserMenu -> LogOut CLICKED');
                                        this.handleLogOut()
                                    },
                                },
                            ],
                        }}
                        settingsMenuProps={{
                            menuItems: [
                                {
                                    menuTitle: 'User Settings',
                                    menuIcon: <UserIcon />,
                                    onClick: () => {
                                        console.log('SettingsMenu -> UserSettings CLICKED');
                                    },
                                },
                            ],
                        }}
                        messageMenuProps={{
                            messageList: [
                                {
                                    avatarValue: 'AB',
                                    title: 'New Data Added',
                                    subtitle: 'Noodles Added To Inventory.',
                                },
                            ],
                        }}
                    />
                    <DashboardLayout.SideBar
                        mainMenuProps={{
                            menuConfig: [
                                {
                                    menuGroupTitle: '',
                                    groupEntries: [
                                        {
                                            menuItems: [
                                                {
                                                    isSubMenu: false,
                                                    menuTitle: 'Home',
                                                    menuIcon: <HomeIcon title="Home" />,
                                                    onClick: () => { this.props.history.push("/"); },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    menuGroupTitle: 'Main',
                                    groupEntries: [
                                        {
                                            menuItems: [
                                                {
                                                    isSubMenu: true,
                                                    menuTitle: 'Add New',
                                                    menuIcon: <AppstoreAdd title="Add New" />,
                                                    subMenuItems: [
                                                        {
                                                            // menuTitle: 'Group',
                                                            menuTitle: (<div style={{ minWidth: '180px' }}> <Button style={{ textAlign: 'left' }}
                                                                onClick={this.handleAddGroup} type="text" block icon={<FolderOutlined />} >Group</Button></div>),
                                                            // menuIcon: <TableOutlined />,
                                                            // onClick: () => { this.showModal('reports'); },
                                                        },
                                                        {
                                                            // menuTitle: 'Resource',
                                                            menuTitle: (<div style={{ minWidth: '180px' }}> <Button style={{ textAlign: 'left' }} onClick={this.handleAddResource} type="text" block icon={<CloudServer />}>Resource</Button></div>),
                                                            // menuIcon: <CloudServer />,
                                                        },
                                                        {
                                                            // menuTitle: 'Dashboard',
                                                            menuTitle: (<div style={{ minWidth: '180px' }}> <Button style={{ textAlign: 'left' }} onClick={() => this.handleAddDashboard('dashboard')} type="text" block icon={<Dashboard />}>Report / Dashboard</Button></div>),
                                                            // menuIcon: <Dashboard />,
                                                            // onClick: () => { this.showModal('dashboard'); },
                                                        },
                                                        // {
                                                        //     // menuTitle: 'Reports',
                                                        //     menuTitle: (<div style={{ minWidth: '180px' }}> <Button style={{ textAlign: 'left' }} onClick={() => { this.handleAddDashboard('reports'); }} type="text" block icon={<TableOutlined />}>Reports</Button></div>),
                                                        //     // menuIcon: <TableOutlined />,
                                                        // },
                                                    ],
                                                },
                                                {
                                                    isSubMenu: true,
                                                    menuTitle: 'Report / Dashboard',
                                                    menuIcon: <Dashboard title="Dashboards" />,
                                                    subMenuItems: [
                                                        {
                                                            menuTitle: (
                                                                <div style={{ width: '100%', minWidth: '200px', margin: '-18px' }}>
                                                                    <AccMenu dashboardGroups={this.props.dashboardGroups} dashboardList={this.props.dashboardList} />
                                                                </div>
                                                            )
                                                        }
                                                    ]

                                                },
                                                // {
                                                //     isSubMenu: true,
                                                //     menuTitle: 'Reports',
                                                //     menuIcon: <TableOutlined title="Reports" />,
                                                //     subMenuItems: [
                                                //         {
                                                //             menuTitle: (
                                                //                 <div style={{ width: '100%', minWidth: '200px', margin: '-18px' }}>
                                                //                     <AccMenu dashboardGroups={this.props.reportGroups} dashboardList={this.props.reportList} />
                                                //                 </div>
                                                //             )
                                                //         }
                                                //     ]

                                                // },

                                                {
                                                    isSubMenu: false,
                                                    menuTitle: 'Groups',
                                                    menuIcon: <FolderOutlined title="Groups" />,
                                                    onClick: () => { this.props.history.push("/groups"); },
                                                }

                                            ],
                                        }
                                    ],
                                },
                                {
                                    menuGroupTitle: 'Settings',
                                    groupEntries: [
                                        {
                                            menuItems: [
                                                {
                                                    isSubMenu: false,
                                                    menuTitle: 'Resources',
                                                    menuIcon: <CloudServer title="Resources" />,
                                                    onClick: () => { this.props.history.push("/resources"); },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    menuGroupTitle: 'Settings',
                                    groupEntries: [
                                        {
                                            menuItems: [
                                                {
                                                    isSubMenu: false,
                                                    menuTitle: 'Account Settings',
                                                    menuIcon: <SettingsIcon />,
                                                    onClick: () => { },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    menuGroupTitle: '',
                                    groupEntries: [
                                        {
                                            menuItems: [
                                                {
                                                    isSubMenu: false,
                                                    menuTitle: 'Log Out',
                                                    menuIcon: <LogoutIcon />,
                                                    onClick: () => { this.handleLogOut() },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    menuGroupTitle: '',
                                    groupEntries: [
                                        {
                                            menuItems: [
                                                {
                                                    isSubMenu: false,
                                                    menuTitle: 'Report Schedule',
                                                    menuIcon: <TableOutlined title="Report Schedule" />,
                                                    onClick: () => { this.props.history.push("/scheduling"); },
                                                },
                                            ],
                                        },
                                    ],
                                }
                            ],
                        }}
                    />
                    < DashboardLayout.MainContent
                    // breadcrumbProps={{
                    // 	breadcrumbRoutes: [
                    // 		{ breadcrumbName: 'Home', breadcrumbIcon: <HomeIcon /> },
                    // 	],
                    // }}
                    >
                        {this.props.children}
                    </DashboardLayout.MainContent >
                </DashboardLayout >
                <Modal

                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText={this.state.rtype === 'reports' ? 'Create report' : 'Create board'}
                    okButtonProps={{
                        style: {
                            display: this.state.addedApps ? "inline-flex" : "none",
                        },
                    }}
                    cancelButtonProps={{
                        style: {
                            display: this.state.addedApps ? "inline-flex" : "none",
                        },
                    }}
                    width={720}
                >
                    {this.state.addedApps ?
                        <>
                            <h2>Create new {this.state.rtype === 'reports' ? 'report' : 'board'}</h2>

                            <Form.Item

                                name="boardname"
                                rules={[{ required: true, message: 'Please input board name!' }]}
                                validateStatus={this.state.boardNamerror ? "error" : ""}
                            >
                                <Input placeholder={this.state.rtype === 'reports' ? 'Report Name' : 'Report or Dashboard Name'} value={this.state.dashboardName} onChange={(e) => { this.setState({ dashboardName: e.target.value, boardNamerror: false }) }} />
                            </Form.Item>

                            <Form.Item
                                name="accesstype"
                                rules={[{ required: true, message: 'Please select access type!' }]}
                            >
                                <div className='report-radiobtn-container'>
                                    <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
                                        <Radio value={1}><div style={{ display: 'flex', alignItems: 'center' }}><MaterialDesignIcon icon='fingerprint' /> <span>Private</span></div></Radio>
                                        <Radio value={2}><div style={{ display: 'flex', alignItems: 'center' }}><MaterialDesignIcon icon='groups' /><span>Public</span></div></Radio>
                                        {localStorage.getItem('IsSpecialUser') === "true" ?
                                            <Radio value={3}><div style={{ display: 'flex', alignItems: 'center' }}><MaterialDesignIcon icon='public' /><span>&nbsp;Global</span></div></Radio>
                                            : null}
                                    </Radio.Group>
                                </div>
                                {/* <p><span id="privatemsg">Only visible to you</span></p> */}

                                {this.state.sharedListShow ? (
                                    <>
                                        <Row>
                                            <Col span={6}><div>Organization</div></Col>
                                            <Col span={18}>
                                                <Mentions
                                                    style={{ width: '100%' }}
                                                    id="users"
                                                    // onChange={this.onChangeSharableUsers}
                                                    // onSelect={this.onSelectSharableUsers}
                                                    onSelect={this.onSelectSharableOrganizations}
                                                >

                                                    {
                                                        // this.state.users ? (
                                                        //     this.state.users.map(user =>

                                                        //         <Option data={user} value={user.UserDetails.FirstName} onClick={() => this.onClickSharableUser(user)}>{user.UserDetails.FirstName} {user.UserDetails.LastName}</Option>
                                                        //     )
                                                        // ) 
                                                        // : (
                                                        //     <></>
                                                        // )

                                                        ORG_LIST.map((org, i) => {
                                                            return (<Option data={org}  value={org.Name} onClick={() => this.onClickSharableOrganizations(org)}>{org.Name}</Option>)
                                                        })
                                                    }


                                                </Mentions>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><div>Organization</div></Col>
                                            <Col span={6}>
                                                <div>
                                                <Input placeholder="small size" prefix={<CloseCircleOutlined fill="#ddd"/>} />
                                                

                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div>
                                                    <Select>
                                                        <Select.Option>Group1</Select.Option>
                                                        <Select.Option>Group2</Select.Option>
                                                    </Select>
                                                    
                                                </div>
                                            </Col>
                                            
                                        </Row>
                                    </>

                                ) : (
                                    <></>
                                )}
                            </Form.Item>

                            {this.state.value === 3 ?
                                <>

                                    <Row>
                                        <Col span={6}><div>Group Name (Required) :</div></Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name="mainGroupName"
                                                rules={[{ required: true, message: 'Please select goup!' }]}
                                                validateStatus={this.state.goupError ? "error" : ""}
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    placeholder="Select Goup"
                                                    optionFilterProp="children"
                                                    onChange={this.onChangeGoupSearch}
                                                    onFocus={this.onFocusGoupSearch}
                                                    onBlur={this.onBlurGoupSearch}
                                                    onSearch={this.onSearchGoupSearch}
                                                    // filterOption={(input, option) =>
                                                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    // }
                                                >
                                                    {this.state.rtype === "reports" ?
                                                        this.props.reportGroups.filter(r => r.isGlobal).map((dg, i) => {
                                                            return (<Select.Option key={dg.id} value={dg.id}>{dg.title}</Select.Option>)
                                                        })
                                                        : this.props.dashboardGroups.filter(r => r.isGlobal).map((dg, i) => {
                                                            return (<Select.Option key={dg.id} value={dg.id}>{dg.title}</Select.Option>)
                                                        })

                                                    }

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {this.state.selectedGoup && this.state.selectedGoup.children && this.state.selectedGoup.children.length > 0 ?
                                        <Row>
                                            <Col span={6}><div>Sub Group Name (Optional) :</div></Col>
                                            <Col span={18}>
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    placeholder="Select sub group"
                                                    optionFilterProp="children"
                                                    onChange={(val) => this.setState({ selectedSubGoupId: val })}
                                                    // onFocus={this.onFocusGoupSearch}
                                                    // onBlur={this.onBlurGoupSearch}
                                                    // onSearch={this.onSearchGoupSearch}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Select.Option key="no_sub" value="none">None</Select.Option>
                                                    {this.state.selectedGoup.children.map(c => {
                                                        return (<Select.Option key={c.id} value={c.id}>{c.title}</Select.Option>)
                                                    })}

                                                </Select>
                                            </Col>
                                        </Row>
                                        : null
                                    }
                                </>
                                : 
                                <>

                                    <Row>
                                        <Col span={6}><div>Group Name (Required) :</div></Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name="mainGroupName"
                                                rules={[{ required: true, message: 'Please select goup!' }]}
                                                validateStatus={this.state.goupError ? "error" : ""}
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    placeholder="Select Goup"
                                                    optionFilterProp="children"
                                                    onChange={this.onChangeGoupSearch}
                                                    onFocus={this.onFocusGoupSearch}
                                                    onBlur={this.onBlurGoupSearch}
                                                    onSearch={this.onSearchGoupSearch}
                                                    // filterOption={(input, option) =>
                                                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    // }
                                                >
                                                    {this.state.rtype === "reports" ?
                                                        this.props.reportGroups.filter(r => !r.isGlobal).map((dg, i) => {
                                                            return (<Select.Option key={dg.id} value={dg.id}>{dg.title}</Select.Option>)
                                                        })
                                                        : this.props.dashboardGroups.filter(r => !r.isGlobal).map((dg, i) => {
                                                            return (<Select.Option key={dg.id} value={dg.id}>{dg.title}</Select.Option>)
                                                        })

                                                    }

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {this.state.selectedGoup && this.state.selectedGoup.children && this.state.selectedGoup.children.length > 0 ?
                                        <Row>
                                            <Col span={6}><div>Sub Group Name (Optional) :</div></Col>
                                            <Col span={18}>
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    placeholder="Select sub group"
                                                    optionFilterProp="children"
                                                    onChange={(val) => this.setState({ selectedSubGoupId: val })}
                                                    // onFocus={this.onFocusGoupSearch}
                                                    // onBlur={this.onBlurGoupSearch}
                                                    // onSearch={this.onSearchGoupSearch}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Select.Option key="no_sub" value="none">None</Select.Option>
                                                    {this.state.selectedGoup.children.map(c => {
                                                        return (<Select.Option key={c.id} value={c.id}>{c.title}</Select.Option>)
                                                    })}

                                                </Select>
                                            </Col>
                                        </Row>
                                        : null
                                    }
                                </>
                                }
                        </>
                        : <>
                            <div>
                                <h2>Add Apps</h2>
                            </div>
                            <div>
                                <AddApps multiSelectEnabled={this.state.isAppsMultiSelectEnabled} onFinishSelect={this.handleFinishSelectApps} />
                            </div>
                        </>
                    }

                </Modal>

                <Modal
                    title="Add New Group"
                    visible={this.state.isGroupModalVisible}
                    onOk={this.handleOkGpModal}
                    onCancel={this.handleCancelGpModal}
                    footer={null}
                    okText='Save Group'
                    width={720}
                >
                    <AddBoardGroup users={this.state.users} handleCloseModal={this.handleCancelGpModal} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { boards, user } = state;
    return {
        boardsStatus: boards.boardsData.boardsStatus,
        dashboardList: boards.boardsData.filter(bd => bd.configDetails.boardType === 'dashboard'),
        reportList: boards.boardsData.filter(bd => bd.configDetails.boardType === 'reports'),
        dashboardGroups: boards.groups ? boards.groups.filter(gd => gd.groupType === 'dashboard').sort((a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)) : null,
        reportGroups: boards.groups ? boards.groups.filter(gd => gd.groupType === 'report').sort((a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)) : null,
        currentAppPermissions: user.currentAppPermissions ? user.currentAppPermissions : [],
        accessToken: user?.user?.accessToken
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        logOut: () => dispatch(signout()),
        setDashboardConfig: (details) => dispatch(addDashboardConfig(details)),
        getBoards: (t) => dispatch(getBoardsAsync(t)),
        addSelectedBoard: (board) => dispatch(updateSelectedBoard(board)),
        getApps: () => dispatch(getAppsAsync()),
        setOtherUsers: (usersList) => dispatch(setOtherUsers(usersList)),
        getGroups: (t) => dispatch(getGroupsAsync(t))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardView));
