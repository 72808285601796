import React from 'react'

export default function NoPermission() {
    return (
        <div style={{padding:'40px', textAlign: 'center'}}>
            <h3 style={{color:'red'}}>You don't have permission to access this route</h3>
            
        </div>
    )
}
