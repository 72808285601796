import { Input, Radio, Row, Col, DatePicker, Checkbox, Select } from 'antd'
import React from 'react'
import CustomSelect from '../../Common/CustomSelect'
import "../reports-logs.css";
import data from "../data/data.json";

const YearlyForm = () => {

    const monthsList = data.months;

    const weekList = data.weekdays;

    return (
        <div>
            <Row className='mb-16'>
                <Col span={20}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label className='no-word-break mr-16'>Recur every</label>
                        <Input className='mr-16 w-20' />
                        <label className='no-word-break mr-16'>Year(s)</label>
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={24}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio value="day" className='mr-16'>On</Radio>
                        <Select className='mr-16 w-50' options={monthsList}/>
                        <Input className='mr-16 w-50' />
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={24}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio value="day" className='mr-16 no-word-break'>On the</Radio>
                        <Select options={[]} className='mr-16 w-50'/>
                        <Select className='mr-16 w-50' options={weekList}/>
                        <label className='no-word-break mr-16'>of</label>
                        <Select className='mr-16 w-50' options={monthsList}/>
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={24}>
                    <h3>Range of Recurrence</h3>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={16}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio value="day" className='mr-16'>End by</Radio>
                        <DatePicker/>
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={16}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Radio value="day" className='mr-16'>After</Radio>
                        <Input className='mr-16 w-20'/>
                        <label>messages</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={16}>
                    <Radio>No end date</Radio>
                </Col>
            </Row>
        </div>
    )
}

export default YearlyForm;