import React, { useState } from 'react';
import { Form, Input } from 'antd';

export default function BasicAuthForm(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleChangeUsername = (e) => {
        setUsername(e.target.value);
        setUsernameError(false)

        props.handleChangeValues({
            username: username,
            password: password
        });
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
        props.handleChangeValues({
            username: username,
            password: password
        })
    }

    return (
        <div>
            < Form.Item style={{ marginBottom: '8px' }}
                label="Basic Auth Userame"
                name="baseAuthUsernameName"
                rules={[{ required: true, message: 'Please input username!' }]}
                validateStatus={usernameError ? "error" : ""}
            >
                <Input value={username} onChange={handleChangeUsername} disabled={props.isViewOnly}/>
            </Form.Item>
            < Form.Item style={{ marginBottom: '8px' }}
                label="Basic Auth PAssword"
                name="baseAuthPassword"
                rules={[{ required: true, message: 'Please input password!' }]}
                validateStatus={passwordError ? "error" : ""}
            >
                <Input value={password} onChange={handleChangePassword} disabled={props.isViewOnly}/>
            </Form.Item>
        </div>
    )
}