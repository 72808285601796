import { MailOutlined, MessageOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Input, Radio, Row, Space, TimePicker } from 'antd'
import React, { useState } from 'react'
import CustomSelect from '../Common/CustomSelect'
import DashboardView from '../DashboardView'
import DailyForm from './RecurrencePatternsForms/DailyForm'
import MonthlyForm from './RecurrencePatternsForms/MonthlyForm'
import WeeklyForm from './RecurrencePatternsForms/WeeklyForm'
import YearlyForm from './RecurrencePatternsForms/YearlyForm';
import data from "./data/data.json";

const CreateSchedule = () => {

  const [publishers,setPublishers] = useState([
    {
      label:'Gihan Moonasinghe',
      value:'gihanMoonasinghe',
      iconName:''                   
    },
    {
      label:'Akila Nilakshi',
      value:'akilaNilakshi',
      iconName:''
    },
    {
      label:'Chanuga Tharindu',
      value:'chanugeTharindu',
      iconName:''
    }
  ]);

  const handlePublisher = (e)=>{
    console.log("selected Publisher",e);
  }

  const [recipients,setRecipients] = useState([
    {
      label:'Buddika',
      value:'buddika',
      iconName:''
    },
    {
      label:'Shamla Mansoor',
      value:'shamlaMansoor',
      iconName:''
    },
    {
      label:'Gulam Mashood',
      value:'gulamMashood',
      iconName:''
    },
    {
      label:'Dinojan',
      value:'dinojan',
      iconName:''
    },
    {
      label:'Shenthuran Rajah',
      value:'shenthuranRajah',
      iconName:''
    },
    {
      label:'kokulansritharan@gmail.com',
      value:'kokulansritharan@gmail.com',
      iconName:''
    }
  ]);

  const handleRecipients = (e)=>{
    console.log("handleRecipients",e);
  }

  const fileTypes = data.fileTypes;

  const[recurrencePattern,selectedRecurrencePattern] = useState("daily");

  const handleRecurrencePattern = ()=>{
    switch (recurrencePattern) {
      case "daily":
        return <DailyForm/>;
      case "weekly":
        return <WeeklyForm/>;
      case "monthly":
        return <MonthlyForm/>;
      case "yearly":
        return <YearlyForm/>;    
      default:
        return <div>No Recurrence Pattern Selected</div>
        break;
    }
  }

  return (
    <DashboardView>
      <div style={{border:'1px solid rgba(0, 0, 0, 0.1)',borderRadius:'4px',padding:'25px'}}>
        <Row gutter={[0,24]}>
          <Col span={24}>
            <h2>Create Schedule</h2>
          </Col>
          <Col span={12}>
            <Row className='mb-24'gutter={[0,24]}>
              <Col span={24}>
                <label className='form-label'>Delivery Type</label>
              </Col>
              <Col span={24}>
                <Radio.Group>
                  <Radio value="email"><MailOutlined style={{ color: '#FF4A75', marginRight: '10px',fontSize:'18px'}} />Email</Radio>
                  <Radio value="whatsapp"><WhatsAppOutlined style={{ color: '#40C351', marginRight: '10px',fontSize:'18px' }} />WhatsApp</Radio>
                  <Radio value="sms"><MessageOutlined style={{ color: '#8C8AE8', marginRight: '10px',fontSize:'18px' }} />SMS</Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>Publisher</label>
              </Col>
              <Col span={16}>
                <CustomSelect 
                  hasFormatLabel
                  options={publishers}
                  getSelectedOptions={handlePublisher}/>
              </Col>
            </Row>
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>To</label>
              </Col>
              <Col span={16}>
                <CustomSelect 
                  isMulti
                  hasFormatLabel
                  options={recipients}
                  getSelectedOptions={handleRecipients}/>
              </Col>
            </Row>
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>Vertical</label>
              </Col>
              <Col span={16}>
                <CustomSelect 
                  options={[]}
                  getSelectedOptions={(e)=>console.log(e)}/>
              </Col>
            </Row> 
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>Application</label>
              </Col>
              <Col span={16}>
                <CustomSelect 
                  options={[]}
                  getSelectedOptions={(e)=>console.log(e)}/>
              </Col>
            </Row>  
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>Report</label>
              </Col>
              <Col span={16}>
                <CustomSelect 
                  options={[]}
                  getSelectedOptions={(e)=>console.log(e)}/>
              </Col>
            </Row> 
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>Subject</label>
              </Col>
              <Col span={16}>
                <Input/>
              </Col>
            </Row> 
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>Message</label>
              </Col>
              <Col span={16}>
                <Input.TextArea rows="4"/>
              </Col>
            </Row> 
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>File Type</label>
              </Col>
              <Col span={16}>
                <CustomSelect 
                  options={fileTypes}
                  getSelectedOptions={(e)=>console.log(e)}/>
              </Col>
            </Row> 
            <Row className='mb-24'>
              <Col span={8}>
                <label className='form-label'>Time Zone</label>
              </Col>
              <Col span={16}>
                <CustomSelect 
                  options={[]}
                  getSelectedOptions={(e)=>console.log(e)}/>
              </Col>
            </Row> 
          </Col>
          <Col span={24}>
            <h3>Recurring Time & Date</h3>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={6}>
                <label className='form-label'>Starts</label>
              </Col>
              <Col span={6}>
                <DatePicker/>
              </Col>
              <Col span={6}>
                <label className='form-label'>Time</label>
              </Col>
              <Col span={6}>
                <TimePicker/>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <h3>Recurrence Pattern</h3>
          </Col>
          <Col span={16}>
            <Row gutter={[32,0]}>
              <Col span={4} style={{borderRight:'1px solid #000'}}>
                <div style={{display:'flex',flexDirection:'column',verticalAlign:'start'}}>
                  <div className='mb-16'><Radio value="daily" checked={recurrencePattern === "daily" ? true : false} onChange={(e)=> selectedRecurrencePattern(e.target.value)}>Daily</Radio></div>
                  <div className='mb-16'><Radio value="weekly" checked={recurrencePattern === "weekly" ? true : false} onChange={(e)=> selectedRecurrencePattern(e.target.value)}>Weekly</Radio></div>
                  <div className='mb-16'><Radio value="monthly" checked={recurrencePattern === "monthly" ? true : false} onChange={(e)=> selectedRecurrencePattern(e.target.value)}>Monthly</Radio></div>
                  <div className='mb-16'><Radio value="yearly" checked={recurrencePattern === "yearly" ? true : false} onChange={(e)=> selectedRecurrencePattern(e.target.value)}>Yearly</Radio></div>
                </div>
              </Col>
              <Col span={20}>              
                {handleRecurrencePattern( )}   
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <label>Summary <span style={{color:'#128912',marginLeft:'10px'}}>Daily at 09.30 AM (IST GMT+5:30)</span></label>
          </Col>
          <Col span={24} style={{textAlign:'right'}}>
            <Space size="middle">
              <Button type="primary">Schedule</Button>
              <Button>Cancel</Button>
            </Space>
          </Col>
        </Row>
      </div>
    </DashboardView>
  )
}

export default CreateSchedule