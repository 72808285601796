import React, { useState } from 'react';
import { Form, Input, Button, Radio, Divider, Alert, Spin } from 'antd';

import Axios from 'axios';

// import { testConnectionConnection } from '../../api/connectRestAPI'
const { REACT_APP_CONNECTION_INFO_BASE_URL } = process.env;
const MongoDBForm = (props) => {
    const [form] = Form.useForm();
    const [apiName, setApiName] = useState("");
    const [apiNameError, setApiNameError] = useState(false);
    const [apiHost, setApiHost] = useState("");
    const [apiTestStatus, setApiTestStatus] = useState("");
    const [apiSaveStatus, setApiSaveStatus] = useState("");
    const [connectionType, setConnectionType] = useState('standard');
    const [portId, setPortId] = useState(null);
    const [databaseName, setDatabaseName] = useState("");
    const [databaseUsername, setDatabaseUsername] = useState("");
    const [databasePassword, setDatabasePassword] = useState("");
    // const [isConnectInSsl, setisConnectInSsl] = useState(false);
    // const [isSelfSigned, setIsSelfSigned] = useState(false);
    // const [isEnableSshTunnel, setIsEnableSshTunnel] = useState(false);

    const testConnection = async () => {
        setApiTestStatus("pending");
        setApiSaveStatus("");
    }

    const handleSaveConnection = async () => {
        const API_INFO_URL = `${REACT_APP_CONNECTION_INFO_BASE_URL}/resource`;
        const JWT_TOKEN = `Bearer ${localStorage.getItem('accessToken')}`;
        setApiTestStatus("");
        setApiSaveStatus("pending");
        if (apiName === "") {
            setApiNameError(true)
            return
        }
        // if (apiUrl === "") {
        //     setApi(true)
        // }
        console.log("apiUrl", apiHost)

        let postData = {
            name: apiName,
            icon: props.resourceType.icon,
            category: props.resourceType.category,
            description: props.resourceType.description,
            isActive: true,
            resoueceType: 'MongoDB',
            connectionData: {
                host: apiHost,
                port: portId || '',
                databaseName: databaseName,
                databaseUsername: databaseUsername,
                databasePassword: databasePassword,
                requestValues: {
                    // httpMethod: httpMethod,
                    // urlParams: urlParams.filter(qp => qp.key !== '' && qp.value !== ''),
                    // requestHeaders: headers.filter(hp => hp.key !== '' && hp.value !== ''),
                    // bodyParams: bodyParams.filter(bp => bp.key !== '' && bp.value !== '')
                }
            }
        }

        try {
            await Axios.post(API_INFO_URL, postData, { headers: { Authorization: JWT_TOKEN } })
                .then((response) => {
                    console.log(response.data);
                    setApiSaveStatus("success")
                    setTimeout(() => {
                        props.handleClose()
                    }, 1500);
                })
                .catch((error) => {
                    setApiSaveStatus("error")
                    console.log(error);
                });
        } catch (error) {
            setApiSaveStatus("error")
            console.log(error);
        }


    }

    const onFormLayoutChange = ({ connectionType }) => {
        console.log("VAl", connectionType)
        setConnectionType(connectionType);
    };

    return (
        <div style={{}}>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="basic"
                connectionType={connectionType}
                form={form}
                initialValues={{
                    connectionType: connectionType,
                }}
                onValuesChange={onFormLayoutChange}
            // initialValues={{ remember: true }}
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            >

                <Form.Item style={{ marginBottom: '8px' }}
                    label="Name"
                    name="apinameName"
                    rules={[{ required: true, message: 'Please input API name!' }]}
                    validateStatus={apiNameError ? "error" : ""}
                >
                    <Input value={apiName} onChange={(e) => { setApiName(e.target.value); setApiNameError(false) }} />
                </Form.Item>


                <Divider />

                <Form.Item style={{ marginBottom: '8px' }}
                    label="Host"
                    name="host"
                    rules={[{ required: true, message: 'Please input host URL' }]}
                >
                    <Input
                        value={apiHost}
                        onChange={(e) => setApiHost(e.target.value)} />
                </Form.Item>


                <Form.Item label="Connection format" name="connectionType">
                    <Radio.Group value={connectionType}>
                        <Radio.Button value="standard">Standard connection</Radio.Button>
                        <Radio.Button value="dns">DNS seed list connection</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item style={{ marginBottom: '8px' }}
                    label="Port"
                    name="port"
                    rules={[{ required: true, message: 'Please input host URL' }]}
                >
                    <Input
                        value={portId}
                        onChange={(e) => setPortId(e.target.value)} />
                </Form.Item>

                <Form.Item style={{ marginBottom: '8px' }}
                    label="Database Name"
                    name="databaseName"
                >
                    <Input
                        value={databaseName}
                        onChange={(e) => setDatabaseName(e.target.value)} />
                </Form.Item>

                <Form.Item style={{ marginBottom: '8px' }}
                    label="Database Userame"
                    name="databaseUsername"
                >
                    <Input
                        value={databaseName}
                        onChange={(e) => setDatabaseUsername(e.target.value)} />
                </Form.Item>

                <Form.Item style={{ marginBottom: '8px' }}
                    label="Database Password"
                    name="databasePassword"
                >
                    <Input
                        value={databasePassword}
                        onChange={(e) => setDatabasePassword(e.target.value)}
                        type='password' />
                </Form.Item>



                <Divider />
                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <Button htmlType="submit" onClick={testConnection} loading={apiTestStatus === "pending"}>
                            Test Connection
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={handleSaveConnection} loading={apiSaveStatus === "pending"}>
                            Create Resource
                        </Button>
                    </div>
                    {/* <div>{apiTestStatus === "success" ? 
                        <Alert message="Test Connection Success" type="success" showIcon /> 
                        :apiTestStatus === "error" ? 
                            <Alert message="Test Connection Failed" type="error" showIcon /> 
                            : ''}
                        </div> */}
                    {(() => {
                        switch (apiTestStatus) {
                            case 'success':
                                return (
                                    <Alert message="Test Connection Success" type="success" showIcon />
                                );
                            case 'error':
                                return (
                                    <Alert message="Test Connection Failed" type="error" showIcon />
                                );
                            case 'pending':
                                return (
                                    <div><Spin /></div>
                                );
                            default:
                                return <div></div>
                        }
                    })()}

                    {(() => {
                        switch (apiSaveStatus) {
                            case 'success':
                                return (
                                    <Alert message="Saving Database connection data Success" type="success" showIcon />
                                );
                            case 'error':
                                return (
                                    <Alert message="Saving Database connection data Failed" type="error" showIcon />
                                );
                            case 'pending':
                                return (
                                    <div><Spin /></div>
                                );
                            default:
                                return <div></div>
                        }
                    })()}

                </div>

            </Form>

        </div>
    );
};

export default MongoDBForm;
