import React from 'react';

const MaterialDesignIcon = (props) => {
	const { icon, styles } = props;
	return (
		<i className='material-icons ant-menu-item-icon' style={styles}>
			{icon}
		</i>
	);
};

export default MaterialDesignIcon;
