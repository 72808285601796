import React from 'react';
import { Timeline as AntTimeline } from 'antd';

const Timeline = (props) => {

    const { items } = props;

    return (
        <AntTimeline items={items}>
            {items.map((oneItem)=>{
                return(
                    <AntTimeline.Item dot={oneItem.dot || null}>{oneItem.children}</AntTimeline.Item>
                )
            })}
        </AntTimeline>
    )
}

export default Timeline;