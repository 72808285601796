import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const { REACT_APP_CONNECTION_INFO_BASE_URL } = process.env;

const JWT_TOKEN = `Bearer ${localStorage.getItem('accessToken')}`;
export const getResourcesAsync = createAsyncThunk(
    'resources/getResources', 
    async () => {

        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/resource`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
        const data = await response.json();
        // const { user }= getState()
        // console.log("USER=====>>>", user);
        return data;

    }
);

export const getSelectedResourcesAsync = createAsyncThunk(
    'resources/getSelectedResources',
    async (id) => {
        // console.log("action.payload===>>", id)
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/resource/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
        const data = await response.json();
        return data;

    }
);

export const getResourceTypesAsync = createAsyncThunk(
    'resources/getResourceTypes',
    async () => {
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/resource-type`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
        const data = await response.json();
        return data;

    }
);

export const deletetResourceAsync = createAsyncThunk(
    'resources/deletetResource',
    async (payload) => {
        console.log("payload====>>", payload)

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': JWT_TOKEN,
            },
        };
        console.log("requestOptions====>>", requestOptions)
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/resource/${payload.id}`, requestOptions)
        const data = await response.json();
        return { ...data, id: payload.id };
    }
    // async () => {
    //     const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/resource`, { headers: { Authorization: JWT_TOKEN } })
    //     const data = await response.json();
    //     return data;

    // }
);


export const resourceSlice = createSlice({
    name: 'resources',
    initialState: { resourceData: [], resourceStatus: 'default', resourceTypes: [], resourceTypeStatus: 'default', resourceDeleteStatus: 'default' },
    reducers: {
        updateSelectedResource: (state, action) => {
            // const userdata = action.payload;
            state.selectedResource = action.payload;
        },
        clearSelectedResource: (state) => {
            state.selectedResource = null;
        },
        resetResourceDeleteStatus: (state) => {
            state.resourceDeleteStatus = 'default';
        },
        resetResources: (state) => {
            state.resourceData = [];
        }
    },
    extraReducers: {
        [getResourcesAsync.pending]: (state, action) => {
            // console.log("action====>>", action)
            state.resourceData = [];
            state.resourceStatus = 'pending';
        },
        [getResourcesAsync.fulfilled]: (state, action) => {
            // console.log("action====>>", action)
            if (action.payload.success) {
                state.resourceData = action.payload.data;
                state.resourceStatus = 'fulfilled';
            } else {
                state.resourceData = [];
                state.resourceStatus = 'error';
            }

        },
        [getResourcesAsync.error]: (state, action) => {
            state.data = action.payload;
            state.resourceStatus = 'error';
        },

        [getSelectedResourcesAsync.pending]: (state, action) => {
            // console.log("action====>>", action)
            state.selectedResource = null;
        },
        [getSelectedResourcesAsync.fulfilled]: (state, action) => {
            // console.log("action====>>", action)
            if (action.payload.success) {
                state.selectedResource = action.payload.data[0];
                state.selectedResourceError = null;
            } else {
                state.selectedResource = {};
                state.selectedResourceError = 'Data Loading Errror';
            }

        },

        [getResourceTypesAsync.pending]: (state, action) => {
            // console.log("action====>>", action)
            state.resourceTypes = [];
            state.resourceTypeStatus = 'pending';
        },
        [getResourceTypesAsync.fulfilled]: (state, action) => {
            // console.log("action====>>", action)
            if (action.payload.success) {
                state.resourceTypes = action.payload.data;
                state.resourceTypeStatus = 'fulfilled';
            } else {
                state.resourceTypes = [];
                state.resourceTypeStatus = 'error';
            }

        },
        [getResourceTypesAsync.error]: (state, action) => {
            state.data = action.payload;
            state.resourceTypeStatus = 'error';
        },

        [deletetResourceAsync.pending]: (state, action) => {
            // console.log("action====>>", action)
            state.resourceDeleteStatus = 'pending';
        },
        [deletetResourceAsync.fulfilled]: (state, action) => {
            // console.log("action====>>", action)
            if (action.payload.success) {
                state.resourceDeleteStatus = 'success';
                state.resourcePayload = action.payload;
                state.resourceData = state.resourceData.filter(rsd => rsd.id !== action.payload.id)
            } else {
                state.resourceDeleteStatus = 'error';
            }

        },
        [deletetResourceAsync.error]: (state, action) => {
            state.resourceDeleteStatus = 'error';
        }
    },
});

export const { updateSelectedResource, clearSelectedResource, resetResourceDeleteStatus, resetResources } = resourceSlice.actions;

export default resourceSlice.reducer;