import React, { useState, useEffect } from "react";
import { Select, Row, Col, Radio, Space, Button, Divider } from "antd";
import Axios from "axios";
import jwt from "jsonwebtoken";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { returnDataArrayObject } from "../../../../utils/arrayHelper";
import { updateResourceSource, updateReportData } from "../../../../redux/editorSlice";
import { DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;

export default function CombinedResourceSelector(props) {
  // const [combinedData, setCombinedData] = useState([]);

  /* Multi Res Support */
  const [apiResources, setApiResources] = useState([
    { id: Math.floor(Math.random() * 1000000) + 1, name: "Resource 1" },
    { id: Math.floor(Math.random() * 1000000) + 1, name: "Resource 2" },
  ]);
  const [selectedResources, setSelectedResources] = useState([]);

  const [joins, setJoins] = useState([
    {
      id: Math.floor(Math.random() * 1000000) + 1,
      resource1Id: null,
      field1: null,
      resource2Id: null,
      field2: null,
    },
  ]);

  const [apiList, setApiList] = useState([]);

  const dispatch = useDispatch();
  // const resources = useSelector((state) => state.resources);
  const editorState = useSelector((state) => state.editor);

  useEffect(() => {
    if (props.connections) {
      setApiList(props.connections);
    }
    // if (props.connections && props.dashboardConfig) {
    //     setApiList(props.connections.filter(c => c.groupId === props.dashboardConfig.groupId))
    // }
  }, [props.connections]);


  useEffect(() => {
    if(props.selectedResource?.sources && Boolean(Object.values(props.selectedResource.sources).length)){
      const getSelectedApiResource = props.selectedResource.sources.map((item) => {
        return {
          id: item.id,
          name: item.name,
          resourceId: item.resourceId || item.id,
          // selectedField: item.selectedField ? item.selectedField : null,
          // source: item.source ? item.source : null,
          reportData: item.reportData
        };
      })
      setApiResources(getSelectedApiResource);
    
    const joins = props.selectedResource?.joins?.map((join) => {
      return {
        id: join.id,
        resource1Id: join?.resource1Id || null,
        field1: join?.field1 || null,
        resource2Id: join?.resource2Id || null,
        field2: join?.field2 || null
      };
    });
    setJoins(joins);
  }else{
    setApiResources([
      { id: Math.floor(Math.random() * 1000000) + 1, name: "Resource 1" },
      { id: Math.floor(Math.random() * 1000000) + 1, name: "Resource 2" },
    ]);

    setJoins([
      {
        id: Math.floor(Math.random() * 1000000) + 1,
        resource1Id: null,
        field1: null,
        resource2Id: null,
        field2: null,
      },
    ])
  }
    
  }, [props.selectedResource.sources])

  const fetchResourceData = async (resData, resourceId) => {
    let res = null;
    const authInfo = resData.connectionData.authInfo;
    try {
      const requestValues = resData.connectionData.requestValues;
      let urlParamSring = "";
      if (requestValues.urlParams.length > 0) {
        urlParamSring = urlParamSring + "?";
        for (let i = 0; i < requestValues.urlParams.length; i++) {
          if (
            requestValues.urlParams[i]["key"] !== "" &&
            requestValues.urlParams[i]["value"] !== ""
          ) {
            urlParamSring =
              urlParamSring +
              requestValues.urlParams[i]["key"] +
              "=" +
              requestValues.urlParams[i]["value"] +
              "&";
          }
        }
        urlParamSring.slice(0, -1);
      }

      let headerObj = {};
      if (requestValues.requestHeaders.length > 0) {
        for (let i = 0; i < requestValues.requestHeaders.length; i++) {
          if (
            requestValues.requestHeaders[i]["key"] !== "" &&
            requestValues.requestHeaders[i]["value"] !== ""
          ) {
            headerObj[requestValues.requestHeaders[i]["key"]] =
              requestValues.requestHeaders[i]["value"];
          }
        }
      }

      let bodyParamObj = {};
      // if (requestValues.authParams) {
      //     bodyParamObj = Object.assign({}, authParams);
      // }
      if (requestValues.bodyParams.length > 0) {
        for (let i = 0; i < requestValues.bodyParams.length; i++) {
          if (
            requestValues.bodyParams[i]["key"] !== "" &&
            requestValues.bodyParams[i]["value"] !== ""
          ) {
            bodyParamObj[requestValues.bodyParams[i]["key"]] =
              requestValues.bodyParams[i]["value"];
          }
        }
      }

      if (authInfo.method === "jwt") {
        console.log("JWT PROCESS");
        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        const privateKey = authInfo.parameters.privateKey;
        var payload = {
          aud: authInfo.parameters.aud,
          exp: timestamp,
          iss: authInfo.parameters.iss,
        };
        var signOptions = {
          algorithm: "RS256",
        };

        var privateKeyVal = privateKey
          ? privateKey.replace(/\\n/g, "\n")
          : null;

        if (privateKeyVal) {
          try {
            var token = jwt.sign(payload, privateKeyVal, signOptions);
            console.log("Generated JWT authParams=====>>", token);

            await Axios({
              method: resData.connectionData.requestValues.httpMethod,
              url: resData.connectionData.baseUrl + urlParamSring,
              data: bodyParamObj,
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + token,
              },
            })
              .then((response) => {
                res = {
                  success: true,
                  data: returnDataArrayObject(
                    response.data ? response.data : response
                  ),
                };
              })
              .catch((error) => {
                res = {
                  success: false,
                  error: error,
                };
              });
          } catch (error) {
            res = {
              success: false,
              error: error,
            };
          }
          return res;
        }
      } else {
        await Axios({
          method: resData.connectionData.requestValues.httpMethod,
          url: resData.connectionData.baseUrl + urlParamSring,
          data: bodyParamObj,
          headers: headerObj,
        })
          .then((response) => {
            res = {
              success: true,
              data: returnDataArrayObject(
                response.data ? response.data : response
              ),
            };
          })
          .catch((error) => {
            res = {
              success: false,
              error: error,
            };
          });
      }
    } catch (error) {
      res = {
        success: false,
        error: error,
      };
    }
    return res;
  };

  /* Start Handle Multiple API resources */
  const addRestApiReource = () => {
    setApiResources([
      ...apiResources,
      {
        id: Math.floor(Math.random() * 1000000) + 1,
        name: "Resource " + (apiResources.length + 1),
      },
    ]);
  };

  const removeResource = (resource) => {
    setApiResources(apiResources.filter((item) => item.id !== resource.id));

    setJoins(
      joins.map((j) => {
        return {
          id: j.id,
          resource1Id:
          j.resource1Id === resource.id
              ? null
              : j.resource1Id,
          field1: j.resource1Id === resource.id
          ? null
          : j.field1,
          resource2Id:
            j.resource2Id === resource.id
            ? null
            : j.resource2Id,
          field2: j.resource2Id === resource.id
            ? null
            : j.field2,
        };
      })

      // joins.filter((j) => {
      //   return j.resource1Id !== resource.id && j.resource2Id !== resource.id
      // })
    );
  };

  const handleConnectionChange = async (resId, resource) => {
    // setResourceOneId(resId)
    console.log("resId===>", resId);
    console.log("resource===>1", resource);
    setSelectedResources([...new Set([...selectedResources, resId])]);

    let currentReportData = await fetchResourceData(
      props.connections.find((c) => c.id === resId),
      resId
    );
    console.log("=========currentReportData=========", currentReportData);

    if (currentReportData.success) {
      const selectedApiValues = apiList.find(api => api.id === resId);

      setApiResources(
        apiResources.map((item) => {
          return {
            id: item.id === resource.id ? resId : item.id ,
            name: item.id === resource.id ? selectedApiValues?.name : item.name,
            resourceId:
              item.id === resource.id
                ? resId
                : item.resourceId
                ? item.resourceId
                : null,
            selectedField: item.selectedField ? item.selectedField : null,
            source: item.source ? item.source : null,
            reportData:
              item.id === resource.id
                ? currentReportData.data
                : item.reportData
                ? item.reportData
                : null,
          };
        })
      );
    }
    // setResourceOne({})
    // if (resourceTwoId) {
    // props.getSelectedConnection(selectedResources)
    // }
  };

  const handleJoinTableChange = (e, join, resname) => {
    setJoins(
      joins.map((j) => {
        return {
          id: j.id,
          resource1Id:
            j.id === join.id && resname === "resource1Id"
              ? e
              : j.resource1Id
              ? j.resource1Id
              : null,
          field1: null,
          resource2Id:
            j.id === join.id && resname === "resource2Id"
              ? e
              : j.resource2Id
              ? j.resource2Id
              : null,
          field2: null,
        };
      })
    );
  };

  const addNewLink = () => {
    setJoins([
      ...joins,
      {
        id: Math.floor(Math.random() * 1000000) + 1,
        resource1Id: null,
        field1: null,
        resource2Id: null,
        field2: null,
      },
    ]);
  };

  const onSelectJoinField = (e, join, field) => {
    setJoins(
      joins.map((j) => {
        return {
          id: j.id,
          resource1Id: j.resource1Id ? j.resource1Id : null,
          field1:
            j.id === join.id && field === "field1"
              ? e
              : j.field1
              ? j.field1
              : null,
          resource2Id: j.resource2Id ? j.resource2Id : null,
          field2:
            j.id === join.id && field === "field2"
              ? e
              : j.field2
              ? j.field2
              : null,
        };
      })
    );
  };

  const onApplyJoins = () => {
    let joinedData = apiResources.map((ar) => ar.reportData.data);
    console.log("joinedData============>>>>>>>>>>>", joinedData);

    let reportArr = [];
    joins.forEach((li) => {
      let sets = [];
      apiResources
        .find((r1) => r1.resourceId === li.resource1Id)
        .reportData.data.forEach((rd1) => {
          apiResources
            .find((r2) => r2.resourceId === li.resource2Id)
            .reportData.data.forEach((rd2) => {
              if (rd1[li.field1] === rd2[li.field2]) {
                let oneData = {};
                let twoData = {};
                Object.keys(rd1).forEach((od) => {
                  oneData[
                    apiList
                      .find((r) => r.id === li.resource1Id)
                      .name.replace(/\s/g, "_") +
                      "_" +
                      od
                  ] = rd1[od];
                });
                Object.keys(rd2).forEach((td) => {
                  twoData[
                    apiList
                      .find((r) => r.id === li.resource2Id)
                      .name.replace(/\s/g, "_") +
                      "_" +
                      td
                  ] = rd2[td];
                });
                sets.push(Object.assign(oneData, twoData));
              }
            });
        });
      reportArr = _.merge(reportArr, sets);
    });

    console.log("Report Data List");
    console.log(reportArr);
    dispatch(
      updateResourceSource({
        sources: apiResources,
        joins: joins,
        reportData: reportArr,
        id: editorState.selectedResource.id,
        type: "Combined Resource",
      })
    );
  };

  const removeJoins = (joinId) => {
    setJoins(joins.filter(join => join.id !== joinId))
  }

  /* End Handle Multiple API resources */
  return (
    <div style={{ height: "30vh", overflowY: "auto", width: "100%" }}>
      <Row>
        <Col span={14}>
          <div style={{ display: "inline-block", width: "100%" }}>
            {apiResources.map((ar, i) => {
              return (
                <div
                  style={{ width: "412px", display: "inline-block" }}
                  key={`api_res_${i}`}
                >
                  <div
                    style={{ padding: "8px", borderRight: "1px solid #eee" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{ width: "100px", marginRight: "16px" }}
                      >{`Resource ${i + 1}`}</div>
                      <Select
                        style={{ width: "100%" }}
                        value={ar.resourceId}
                        onChange={(e) => handleConnectionChange(e, ar)}
                      >
                        {apiList &&
                          apiList.map((con, j) => {
                            return (
                              <Option key={"m_i_" + j} value={con.id}>
                                {con.name}
                              </Option>
                            );
                          })}
                      </Select>
                      <Button danger onClick={() => removeResource(ar)}>
                        x
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 16,
                      }}
                    >
                      <div style={{ width: "100px", marginRight: "16px" }}>
                        API URL
                      </div>
                      <div>
                        {ar.resource &&
                          ar.resource.source &&
                          ar.resource.source.connectionData &&
                          ar.resource.source.connectionData.baseUrl}
                      </div>
                    </div>
                    <div>
                      <hr />
                      <div style={{ marginBottom: "8px" }}>
                        Select a field to merge resources
                      </div>
                      <Space direction='vertical'>
                        {ar &&
                        ar.reportData &&
                        (ar.reportData !== undefined ||
                          ar.reportData !== null) &&
                        ar.reportData.data ? (
                          <>
                            {Object.keys(ar.reportData.data[0]).map((r, i) => {
                              return (
                                <div key={"res1_key_" + i}>
                                  {r} ({typeof ar.reportData.data[0][r]})
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </Space>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* <div style={{ width: '320px', borderRight: '1px solid #eee' }}>
                    <div style={{ padding: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            <div style={{ width: '100px', marginRight: '16px' }}>Resource 2</div>
                            {/* <Dropdown overlay={menu} trigger={['click']}>

                                <Input placeholder="Basic usage" onClick={e => e.preventDefault()} />

                            </Dropdown> *}
                            <Select style={{ width: '100%' }} value={resourceTwoId} onChange={(e) => handleConnectionTwoChange(e)}>
                                {
                                    resourceListTwo && resourceListTwo.map((con, j) => {
                                        return (
                                            <Option key={"m_i_" + j} value={con.id}>
                                                {con.name}
                                            </Option>
                                        )
                                    })
                                }

                            </Select>

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                            <div style={{ width: '100px', marginRight: '16px' }}>API URL</div>
                            <div>{resourceTwo && resourceTwo.source && resourceTwo.source.connectionData && resourceTwo.source.connectionData.baseUrl}</div>
                        </div>
                        <div>
                            <hr />
                            <div style={{ marginBottom: '8px' }}>Select a field to merge resources</div>

                            <Radio.Group onChange={(e) => { onChangeRadioTwo(e) }}>
                                <Space direction="vertical">
                                    {resourceTwo && resourceTwo.reportData && (resourceTwo.reportData !== undefined || resourceTwo.reportData !== null) && resourceTwo.reportData.data ?
                                        <>
                                            {
                                                Object.keys(resourceTwo.reportData.data[0]).map((r, i) => {
                                                    return (
                                                        <Radio value={r} key={'res2_key_' + i}>{r}</Radio>
                                                    )
                                                })
                                            }
                                        </>
                                        : null
                                    }
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>

                </div> */}
            <div
              style={{
                width: "120px",
                paddingTop: "150px",
                display: "inline-block",
              }}
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button onClick={addRestApiReource}>+ Add New</Button>
              </div>
            </div>
          </div>
        </Col>
        <Col span={10}>
          <div>
            <b>Joins</b>
          </div>
          <Divider />
          {joins?.map((j, idx) => {
            return (
              <Row
                key={`join_${idx}`}
                style={{ borderBottom: "1px solid #666", marginTop: "8px" }}
              >
                <Col span={12}>
                  <div>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={j.resource1Id}
                      onChange={(e) =>
                        handleJoinTableChange(e, j, "resource1Id")
                      }
                    >
                      {apiList &&
                        apiList.filter(api => apiResources.find(apiResource => apiResource.id === api.id))
                          .filter((al) => al.id !== j.resource2Id)
                          .map((con, j) => {
                            return (
                              <Option key={"li_m_i1_" + j} value={con.id}>
                                {con.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </div>
                  <br />
                  <div>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder='Select Field Name'
                      onChange={(e) => onSelectJoinField(e, j, "field1")}
                      value={j.field1}
                    >
                      {j.resource1Id &&
                      apiResources &&
                      apiResources.find(
                        (r) => r.resourceId === j.resource1Id
                      ) &&
                      Object.keys(
                        apiResources.filter(
                          (r) => r.resourceId === j.resource1Id
                        )[0]
                      )
                        ? Object.keys(
                            apiResources.filter(
                              (r) => r.resourceId === j.resource1Id
                            )[0].reportData.data[0]
                          ).map((field, z) => {
                            return (
                              <Option key={`f1_opt_${z}`} value={field}>
                                {field}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={j.resource2Id}
                      onChange={(e) =>
                        handleJoinTableChange(e, j, "resource2Id")
                      }
                    >
                      {apiList &&
                        apiList.filter(api => apiResources.find(apiResource => apiResource.id === api.id))
                          .filter((al) => al.id !== j.resource1Id)
                          .map((con, j) => {
                            return (
                              <Option key={"li_m_i2_" + j} value={con.id}>
                                {con.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </div>
                  <br />

                  <div>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder='Select Field'
                      onChange={(e) => onSelectJoinField(e, j, "field2")}
                      value={j.field2}
                    >
                      {j.resource2Id &&
                      apiResources &&
                      apiResources.find(
                        (r) => r.resourceId === j.resource2Id
                      ) &&
                      Object.keys(
                        apiResources.filter(
                          (r) => r.resourceId === j.resource2Id
                        )[0]
                      )
                        ? Object.keys(
                            apiResources.filter(
                              (r) => r.resourceId === j.resource2Id
                            )[0].reportData.data[0]
                          ).map((field, z) => {
                            return (
                              <Option key={`f2_opt_${z}`} value={field}>
                                {field}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </div>
                  <br />
                </Col>
                {idx !== 0 && <Button onClick={() => removeJoins(j.id)}><DeleteOutlined/></Button> }
              </Row>
            );
          })}
          <Divider />
          <div>
            <Space>
              <Button onClick={addNewLink}>+Add Link</Button>
              <Button type='primary' onClick={onApplyJoins}>
                {" "}
                Apply{" "}
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  );
}
