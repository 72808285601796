import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ChartContainer from './ChartContainer';
import DataTableContainer from './DataTableContainer';

export default function ReportWidjet(props) {
    const [widgetName, setWidgetName] = useState(props.widjetName)
    const selectedWidget = useSelector(state => state?.editor?.selectedResource?.widjet || null)
    useEffect(() => {
        if (selectedWidget && selectedWidget === props.activeWidgetName) {
            setWidgetName(props.activeWidgetName)
        }
    }, [props.activeWidgetName, selectedWidget])

    return (
        <>
            {props?.reportData ?
                <div style={{ position: 'absolute', width: '100%' }}>
                    {(() => {
                        switch (widgetName) {
                            case 'table':
                                return (
                                    <DataTableContainer reportData={props.reportData} availableFields={props.availableFields} allFields={props.allFields} dataFilters={props.dataFilters} aggRowBuilders={props.aggRowBuilders} aggregation={props.aggregation} aggGroupBuilders={props.aggGroupBuilders} groupedReportData={props.groupedReportData} selectedResourceId={props.selectedResourceId} applyFilters={props.applyFilters}/>
                                );
                            case 'chart':
                                return (
                                    <ChartContainer reportData={props.reportData?.Result || props.reportData} availableFields={props.availableFields} dataFilters={props.dataFilters} aggRowBuilders={props.aggRowBuilders} aggregation={props.aggregation} charts={props.charts} selectedWidgetName={props.selectedWidgetName} selectedResourceId={props.selectedResourceId} applyFilters={props.applyFilters} />
                                );
                            default:
                                return (
                                    <div>
                                        blank widjet
                                    </div>
                                )
                        }
                    })()}


                </div>
                : <div>XXXX</div>}
        </>

    )
}