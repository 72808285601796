import React, { useState, useEffect } from 'react'
import { Input, Select, DatePicker, AutoComplete } from 'antd';

export default function DateConfigSelect(props) {
    const [dateOption, setDateOption] = useState('daysBefore');
    const [timeUnits, setTimeUnits] = useState(1);
    const [fixedDate, setFixedDate] = useState(null);
    const [dateFormat, setDateFormat] = useState('YYYY-MM-DDTHH:mm:ss.SS[Z]');

    useEffect(() => {
        console.log("props.dateConfig", props.dateConfig)
        if(props.dateConfig) {
            setDateOption(props.dateConfig.dateOption);
            setTimeUnits(props.dateConfig.timeUnits)
            setFixedDate(props.dateConfig.fixedDate)
            setDateFormat(props.dateConfig.dateFormat)
        }
    }, [props.dateConfig])
    

    const dateFormatOptions = [
        {value:'YYYY-MM-DD'},
        {value:'YYYY-MM-DDTHH:mm:ss.SS[Z]'},
        {value:'YYYY-MM-DDThh:mm:ss.Z'}
    ]

    const handleStDateOption = (val) => {
        console.log("handleStDateOption===>>", val)
        setDateOption(val)
        props.handleSetDateOptions({
            dateOption: val,
            timeUnits: 1,
            fixedDate: fixedDate?fixedDate.format(dateFormat):null,
            dateFormat: dateFormat
        })
    }

    const handleSetTimeUnits = (e) => {
        setTimeUnits(Number(e.target.value))
        props.handleSetDateOptions({
            dateOption: dateOption,
            timeUnits: Number(e.target.value),
            fixedDate: fixedDate?fixedDate.format(dateFormat):null,
            dateFormat: dateFormat
        })
    }

    const handleSetFixedDate = (e) => {
        console.log("handleSetFixedDate===>>>", e)
        setFixedDate(e)
        props.handleSetDateOptions({
            dateOption: dateOption,
            timeUnits: timeUnits,
            fixedDate: e.format(dateFormat),
            dateFormat: dateFormat
        })
    }

    const handleDateFormatChange = (e) => {
        console.log("EEE===>>>", e)
        setDateFormat(e)
        props.handleSetDateOptions({
            dateOption: dateOption,
            timeUnits: timeUnits,
            fixedDate: fixedDate?fixedDate.format(e):null,
            dateFormat: e
        })
    }

    return (
        <div style={{ width: '100%' }}>
            <Select style={{ width: '30%' }} placeholder="Settings" onChange={(val) => handleStDateOption(val)} value={dateOption}>
                <Select.Option value='fixedDate'>Fixed Date</Select.Option>
                <Select.Option value='today'>Today</Select.Option>
                <Select.Option value='daysBefore'>Days Bofore</Select.Option>
                {/* <Select.Option value='weeksBefore'>Weeks Before</Select.Option>
                <Select.Option value='monthsBefore'>Months Before</Select.Option>
                <Select.Option value='yearsBefore'>Years Before</Select.Option>
                <Select.Option value='daysAfter'>Days After</Select.Option>
                <Select.Option value='weeksAfter'>Weeks After</Select.Option>
                <Select.Option value='monthsAfter'>Months After</Select.Option>
                <Select.Option value='yearsAfter'>Years After</Select.Option> */}
            </Select>
            <>

            {(() => {

                switch (dateOption) {
                    case 'fixedDate':
                        return (
                            <DatePicker style={{ width: '30%' }} format={dateFormat} onChange={handleSetFixedDate} value={fixedDate} />
                        )
                    case 'daysBefore':
                        return (
                            <Input type='number' style={{ width: '30%' }} onChange={handleSetTimeUnits} value={timeUnits} />
                        )
                    case 'weeksBefore':
                        return (
                            <Input type='number' style={{ width: '30%' }} onChange={handleSetTimeUnits} value={timeUnits} />
                        )
                    case 'monthsBefore':
                        return (
                            <Input type='number' style={{ width: '30%' }} onChange={handleSetTimeUnits} value={timeUnits} />
                        )
                    case 'yearsBefore':
                        return (
                            <Input type='number' style={{ width: '30%' }} onChange={handleSetTimeUnits} value={timeUnits} />
                        )
                    default:
                        return (
                            null
                        )
                }

            })()}
            </>

            <AutoComplete
                value={dateFormat}
                style={{ width: '40%' }}
                placeholder='Date Format'
                options={dateFormatOptions}
                onChange={handleDateFormatChange}
            />


        </div>
    )
}
