
import React from 'react';
import { Empty } from 'antd';

export default function NotFound() {
    return (

        <div>
            <Empty />
        </div>

    )
}