import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Divider, Row, Col, Select, Alert, Spin, Switch, Mentions } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import Axios from 'axios';
import jwt from 'jsonwebtoken';
import BasicAuthForm from './BasicAuthForm';
import AuthOForm from './AuthOForm';
import SessionBaseAuthForm from './SessionBaseAuthForm';
import JWTAuthForm from './JWTAuthForm';
import { useDispatch } from 'react-redux';
import { getResourcesAsync } from '../../../redux/resourceSlice';
import MaterialDesignIcon from '../../common/MaterialDesignIcon';
import DateConfigSelect from './DateConfigSelect';
// import { testRestApiConnection } from '../../api/connectRestAPI'

const { Option } = Mentions;

const { REACT_APP_CONNECTION_INFO_BASE_URL } = process.env;
const RestApiForm = (props) => {
    const dispatch = useDispatch()
    const [apiName, setApiName] = useState("");
    const [apiNameError, setApiNameError] = useState(false);
    const [apiUrl, setApiUrl] = useState("");
    const [apiTestStatus, setApiTestStatus] = useState("");
    const [apiSaveStatus, setApiSaveStatus] = useState("");
    const [urlParams, setUrlParams] = useState([{ key: '', value: '', dataType: 'string' }]);
    const [headers, setHeaders] = useState([{ key: '', value: '', dataType: 'string' }]);
    const [bodyParams, setBodyParams] = useState([{ key: '', value: '', dataType: 'string' }]);
    const [authMethod, setauthMethod] = useState('none');
    const [httpMethod, setHttpMethod] = useState('get');
    const [authParams, setAuthParams] = useState(null);
    const [isShareable, setIsShareable] = useState(false);
    const [userIdList, setUserIdList] = useState([]);
    const [isWitmegApi, setIsWitmegApi] = useState(true);
    const [isAuthRequired, setIsAuthRequired] = useState(false)
    const [visibleTo, setVisibleTo] = useState('global')
    // const [groupId, setGroupId] = useState(null);
    // const [groupError, setGroupError] = useState(false);
    // const [subGroupId, setSubGroupId] = useState(null);
    // const [auth0Params, setAuth0Params] = useState(null)

    const authOptions = [
        { id: 0, name: 'None', value: 'none' },
        { id: 1, name: 'Basic Auth', value: 'basic' },
        { id: 2, name: 'JWT', value: 'jwt' },
        { id: 3, name: 'Auth0', value: 'auth0' },
        { id: 4, name: 'Cookie', value: 'cookie' }
    ]
    // const onFinish = (values: any) => {
    //     console.log('Success:', values);
    // };

    useEffect(() => {
        if (props.selectedResource && props.selectedResource.connectionData) {
            setApiName(props.selectedResource.name)
            setApiUrl(props.selectedResource.connectionData.baseUrl)
            setHttpMethod(props.selectedResource.connectionData.requestValues.httpMethod)
            setHeaders(props.selectedResource.connectionData.requestValues.requestHeaders && props.selectedResource.connectionData.requestValues.requestHeaders.length > 0 ? props.selectedResource.connectionData.requestValues.requestHeaders : [{ key: '', value: '' }])
            setBodyParams(props.selectedResource.connectionData.requestValues.bodyParams && props.selectedResource.connectionData.requestValues.bodyParams.length > 0 ? props.selectedResource.connectionData.requestValues.bodyParams : [{ key: '', value: '' }])
            setUrlParams(props.selectedResource.connectionData.requestValues.urlParams && props.selectedResource.connectionData.requestValues.urlParams.length > 0 ? props.selectedResource.connectionData.requestValues.urlParams : [{ key: '', value: '' }])
            // setGroupId(props.selectedResource.groupId ? props.selectedResource.groupId : null)
            // setSubGroupId(props.selectedResource.subGroupId ? props.selectedResource.subGroupId : null)
            setauthMethod(props.selectedResource.connectionData.authInfo.method ? props.selectedResource.connectionData.authInfo.method : 'none')
            setAuthParams(props.selectedResource.connectionData.authInfo && props.selectedResource.connectionData.authInfo.parameters ? props.selectedResource.connectionData.authInfo.parameters : null)
            setIsWitmegApi(props.selectedResource?.isWitmegApi)
            setIsAuthRequired(props.selectedResource?.isAuthRequired)
            setVisibleTo(props.selectedResource?.visibleTo)
        }
    }, [props.selectedResource]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const addUrlParam = () => {
        setUrlParams([...urlParams, { key: '', value: '' }])
    };

    const removeUrlParam = (index) => {
        const params = [...urlParams]
        setUrlParams(params.filter((p, i) => index !== i))
    };

    const onChangeUrlParamKey = (event, index, key) => {
        // const params = [...urlParams]
        // params[index][key] = event.target.value;
        const params = urlParams.map((h, i) => {
            return (
                {
                    key: i === index && key === 'key' ? event.target.value : h.key,
                    value: i === index && key === 'value' ? (((event.target.value).trim() === "" ? "" : Number(event.target.value) ? Number(event.target.value) : event.target.value === "0" ? 0 : event.target.value)) : h.value,
                    dataType: i === index && key === 'dataType' ? event : h.dataType,
                    dateConfig: h.dateConfig
                }
            )
        })
        setUrlParams(params)
    }

    const addHeader = () => {
        setHeaders([...headers, { key: '', value: '' }])
    };

    const removeHeader = (index) => {
        const headersList = [...headers]
        setHeaders(headersList.filter((p, i) => index !== i))
    };

    const onChangeHeaderKey = (event, index, key) => {
        // const headersList = [...headers]
        // headersList[index][key] = event.target.value;
        const headersList = headers.map((h, i) => {
            return (
                {
                    key: i === index && key === 'key' ? event.target.value : h.key,
                    value: i === index && key === 'value' ? (((event.target.value).trim() === "" ? "" : Number(event.target.value) ? Number(event.target.value) : event.target.value === "0" ? 0 : event.target.value)) : h.value,
                    dataType: i === index && key === 'dataType' ? event : h.dataType
                }
            )
        })
        setHeaders(headersList)
    }

    const addBodyParams = () => {
        setBodyParams([...bodyParams, { key: '', value: '', dataType: 'string' }])
    };

    const removeBodyParams = (index) => {
        const headersList = [...bodyParams]
        setBodyParams(headersList.filter((p, i) => index !== i))
    };

    const onChangeBodyParamsKey = (event, index, key) => {
        let bodyParamsList = bodyParams.map((bp, i) => {
            return ({
                key: i === index && key === 'key' ? event.target.value : bp.key,
                value: i === index && key === 'value' ? (((event.target.value).trim() === "" ? "" : Number(event.target.value) ? Number(event.target.value) : event.target.value === "0" ? 0 : event.target.value)) : bp.value,
                dataType: i === index && key === 'dataType' ? event : bp.dataType,
                dateConfig: bp.dateConfig?bp.dateConfig:i === index && key === 'dataType' && event==="date"?{
                    dateOption: 'daysBefore',
                    timeUnits: 1,
                    fixedDate: null,
                    dateFormat: 'YYYY-MM-DDTHH:mm:ss.SS[Z]'
                }:null
            })
        })
        setBodyParams(bodyParamsList)
    }

    const testRestApi = async () => {
        setApiTestStatus("pending");
        setApiSaveStatus("");
        if (apiName === "") {
            setApiNameError(true)
        }
        console.log("apiUrl", apiUrl)

        let urlParamSring = ""
        if (urlParams.length > 0) {
            urlParamSring = urlParamSring + '?';
            for (let i = 0; i < urlParams.length; i++) {
                if (urlParams[i]['key'] !== '' && urlParams[i]['value'] !== '') {
                    urlParamSring = urlParamSring + urlParams[i]['key'] + '=' + urlParams[i]['value'] + '&'
                }
            }
            urlParamSring.slice(0, -1)
        }

        let headerObj = {}
        if (headers.length > 0) {
            for (let i = 0; i < headers.length; i++) {
                if (headers[i]['key'] !== '' && headers[i]['value'] !== '') {
                    headerObj[headers[i]['key']] = headers[i]['value']
                }
            }
        }
        let bodyParamObj = {}

        if (bodyParams.length > 0) {
            for (let i = 0; i < bodyParams.length; i++) {
                if (bodyParams[i]['key'] !== '' && bodyParams[i]['value'] !== '') {
                    bodyParamObj[bodyParams[i]['key']] = bodyParams[i]['value']
                }
            }
        }

        let sendUrl = apiUrl + urlParamSring


        if (authParams) {
            console.log("has  authParams");
            if (authMethod === 'jwt') {
                console.log("JWT PROCESS");
                const timestamp = Math.floor(Date.now() / 1000) + 1000;
                const privateKey = authParams.privateKey
                var payload = {
                    "aud": authParams.aud,
                    "exp": timestamp,
                    "iss": authParams.iss
                };
                var signOptions = {
                    "algorithm": "RS256"
                };

                var privateKeyVal = privateKey ? privateKey.replace(/\\n/g, '\n') : null;

                if (privateKeyVal) {
                    try {
                        var token = jwt.sign(payload, privateKeyVal, signOptions);
                        console.log("Generated JWT authParams=====>>", token);
                        // setApiTestStatus("success")

                        const requestOptions = {
                            method: httpMethod,
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ` + token
                            },
                            body: JSON.stringify(bodyParamObj)
                        };
                        await Axios({
                            method: httpMethod,
                            url: sendUrl,
                            data: JSON.parse(JSON.stringify(bodyParamObj)),
                            // data: {
                            //     "OrgID": "5f32109b761a5a4312aa9706",
                            //     "CloudLocationID": "5f32109e761a5a4312aa9708",
                            //     "ProformaStatus": "NotCompleted",
                            //     "Skip": 0,
                            //     "Limit": 20,
                            //     "StartDate": "2021-01-13T07:13:24.574Z",
                            //     "EndDate": "2021-05-17T07:13:24.574Z",
                            //     "CustomerName": "",
                            //     "Barcode": ""
                            // },
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ` + token,
                            }
                        }).then((response) => {
                            console.log(response.data);
                            setApiTestStatus("success");
                        })
                            .catch((error) => {
                                setApiTestStatus("error")
                                console.log(error);
                            });
                        // fetch(`${apiUrl}`, requestOptions)
                        //     .then(response => response.json())
                        //     .then((resp) => {
                        //         if (resp.status >= 200 && resp.status <= 299) {
                        //             try {
                        //                 console.log("resp", resp)
                        //                 setApiTestStatus("success")
                        //             } catch (err) {
                        //                 console.log(err)
                        //                 setApiTestStatus("error")
                        //             }
                        //         } else {
                        //             throw Error(resp);
                        //         }
                        //     });
                    } catch (e) {
                        console.log("JWT authPsign error=====>>", e);
                        setApiTestStatus("error")
                    }


                }


                // return;
            } else {
                bodyParamObj = Object.assign({}, authParams);
                console.log("bodyParamObj=====>>", bodyParamObj);
                try {
                    await Axios({
                        method: httpMethod,
                        url: sendUrl,
                        data: bodyParamObj,
                        headers: headerObj
                    }).then((response) => {
                        console.log(response.data);
                        setApiTestStatus("success");
                    })
                        .catch((error) => {
                            setApiTestStatus("error")
                            console.log(error);
                        });
                } catch (error) {
                    setApiTestStatus("error")
                    console.log(error);
                }
            }
        } else {
            let config = {
                method: httpMethod,
                url: sendUrl,
            }
            if (httpMethod === 'post') {
                config.data = bodyParamObj
            }
            if (headers.length > 0) {
                config.headers = headerObj
            } else {
                config.headers = {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                }
            }

            try {
                await Axios(config).then((response) => {
                    console.log(response.data);
                    setApiTestStatus("success");
                })
                    .catch((error) => {
                        setApiTestStatus("error")
                        console.log(error);
                    });
            } catch (error) {
                setApiTestStatus("error")
                console.log(error);
            }
        }



    }

    const handleSaveRestApi = async () => {
        const API_INFO_URL = props.selectedResource ? `${REACT_APP_CONNECTION_INFO_BASE_URL}/update-resource` : `${REACT_APP_CONNECTION_INFO_BASE_URL}/resource`;
        const JWT_TOKEN = `Bearer ${localStorage.getItem('accessToken')}`;
        setApiTestStatus("");
        setApiSaveStatus("pending");
        if (apiName === "") {
            setApiNameError(true)
            return
        }

        // appList={props.appList} appsMultiSelectEnabled={props.appsMultiSelectEnabled}
        // if (props.appsMultiSelectEnabled ===) {
        //     setApi(true)
        // }
        console.log("apiUrl", apiUrl)

        if (apiName.trim() !== "" && apiUrl !== "" && httpMethod) {
            let postData = {
                name: apiName,
                icon: props.resourceType.icon,
                category: props.resourceType.category,
                description: props.resourceType.description,
                isActive: true,
                isAuthRequired: isAuthRequired,
                isWitmegApi: isWitmegApi,
                resoueceType: 'Rest API',
                userId: localStorage.getItem('userID'),
                appId: !props.appsMultiSelectEnabled ? props.appList[0] : null,
                appIdList: props.appsMultiSelectEnabled ? props.appList : null,
                isShareable: isShareable,
                sharedWith: isShareable ? userIdList : null,
                // groupId: groupId,
                // subGroupId: subGroupId,
                connectionData: {
                    name: apiName,
                    baseUrl: apiUrl,
                    authInfo: {
                        method: authMethod,
                        parameters: authParams ? authParams : {}
                    },
                    requestValues: {
                        httpMethod: httpMethod,
                        urlParams: urlParams.filter(qp => qp.key !== ''),
                        requestHeaders: headers.filter(hp => hp.key !== ''),
                        bodyParams: bodyParams.filter(bp => bp.key !== '')
                    }
                }
            }
            if (isWitmegApi) {
                postData.visibleTo = visibleTo
            }
            if (props.selectedResource) {
                postData.id = props.selectedResource.id
            }

            let reqHeaders = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': JWT_TOKEN,
            }

            console.clear()

            try {
                await Axios.post(API_INFO_URL, postData, { headers: reqHeaders })
                    .then((response) => {
                        console.log(response.data);
                        setApiSaveStatus("success")
                        dispatch(getResourcesAsync())
                        setTimeout(() => {
                            props.handleClose()
                        }, 1500);
                    })
                    .catch((error) => {
                        setApiSaveStatus("error")
                        console.log(error);
                    });
            } catch (error) {
                setApiSaveStatus("error")
                console.log(error);
            }
        }


    }

    // onSelectSharableUsers = e => {
    //     console.log("onSelectSharableUsers===>>", e);
    //     let users = [...this.state.usersList]
    //     users.push(e.value)
    //     this.setState({
    //         usersList: [...new Set(users)]
    //     })

    //     let userIds = [...this.state.userIdList]
    //     userIds.push(e.data.UserDetails.UserID)
    //     this.setState({
    //         userIdList: [...new Set(userIds)]
    //     })
    // }

    const onSelectSharableUsers = (userObj) => {
        console.log("onSelectSharableUsers===>>", userObj);
        let users = [...userIdList]
        users.push(userObj.data.UserDetails.UserID)
        setUserIdList([...new Set(users)])
    }

    const handleSetDateOptions = (index, dateConfig, paramType) => {
        console.log("dateConfig===>>", dateConfig);
        console.log("index===>>", index);
        console.log("paramType===>>", paramType);
        if (paramType === "bodyParams") {
            let bodyParamsList = bodyParams.map((bp, i) => {
                return ({
                    ...bp,
                    dateConfig: i === index ? dateConfig : bp.dateConfig
                })
            })
            console.log("bodyParamsList ====>>>", bodyParamsList)
            setBodyParams(bodyParamsList)
        }

    }


    return (
        <div>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="basic"
                // size="small"
                initialValues={{ visibleTo: visibleTo }}
                onFinish={handleSaveRestApi}
                onFinishFailed={onFinishFailed}
            >


                <div style={{ height: '50vh', overflowY: 'auto' }}>

                    <Form.Item style={{ marginBottom: '8px' }}
                        label="Name"
                        name="apinameName"
                        rules={[{ required: apiName === "", message: 'Please input API name!' }]}
                    >
                        <Input value={apiName} defaultValue={props.selectedResource && props.selectedResource.name ? props.selectedResource.name : ''} onChange={(e) => { setApiName(e.target.value); setApiNameError(false) }} disabled={props.isViewOnly} />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: '8px' }}
                        label="Auth User Required"
                        name="isAuthUserRequired"
                    >
                        <Switch checked={isAuthRequired} onChange={(checked) => setIsAuthRequired(checked)} />
                    </Form.Item>

                    {localStorage.getItem('IsSpecialUser') === 'true' ?
                        <>
                            <Form.Item style={{ marginBottom: '8px' }}
                                label="Witmeg API"
                                name="isWitmegApi"
                            >
                                <Switch checked={isWitmegApi} onChange={(checked) => setIsWitmegApi(checked)} />
                            </Form.Item>

                            {isWitmegApi ?
                                <>

                                    <Form.Item style={{ marginBottom: '8px' }}
                                        label="Visible To"
                                        name="visibleTo"
                                    >
                                        <Select style={{ width: '30%' }} value={visibleTo} placeholder="Visible To" onChange={(e) => setVisibleTo(e)}>
                                            <Select.Option value='global'>Global</Select.Option>
                                            <Select.Option value='internal'>Internal</Select.Option>
                                        </Select>
                                    </Form.Item>

                                </>
                                : null}

                        </>
                        : null}

                    <Divider />

                    < Form.Item style={{ marginBottom: '8px' }}
                        label="Base URL"
                        name="baseURL"
                        rules={[{ required: apiUrl === "", type: 'url', message: 'Please input API URL' }]}
                    >
                        <Input
                            value={apiUrl}
                            onChange={(e) => setApiUrl(e.target.value)}
                            defaultValue={props.selectedResource && props.selectedResource.connectionData ? props.selectedResource.connectionData.baseUrl : ''}
                            disabled={props.isViewOnly}
                        />
                    </Form.Item>


                    <div style={{ marginTop: '16px' }}>
                        {urlParams.map((urlParam, index) => (
                            <Row key={'url_param_' + index} gutter={4}>
                                <Col span={6} style={{ textAlign: 'right' }}>{index === 0 ? "URL Parameters" : ""} : &nbsp;</Col>
                                <Col span={18}>
                                    <div>
                                        <Input.Group compact>
                                            <Input style={{ width: '30%' }} placeholder="Key" value={urlParams[index].key} onChange={(e) => { onChangeUrlParamKey(e, index, 'key') }} disabled={props.isViewOnly} />
                                            <Select style={{ width: '30%' }} placeholder="Data Type" value={urlParams[index].dataType} onChange={(e) => { onChangeUrlParamKey(e, index, 'dataType') }}>
                                                <Select.Option value='number'>Number</Select.Option>
                                                <Select.Option value='string'>String</Select.Option>
                                                <Select.Option value='boolean'>Boolean</Select.Option>
                                                <Select.Option value='date'>Date</Select.Option>
                                            </Select>
                                            {urlParams[index].dataType !== 'date' ?
                                                <Input style={{ width: '40%' }} placeholder="Value" value={urlParams[index].value} onChange={(e) => { onChangeUrlParamKey(e, index, 'value') }} addonAfter={<CloseOutlined onClick={() => removeUrlParam(index)} />} disabled={props.isViewOnly} />
                                                : <>
                                                    <Input style={{ width: '40%' }} disabled addonAfter={<CloseOutlined onClick={() => removeUrlParam(index)} />} />
                                                </>}


                                            {
                                                urlParams[index].dataType === 'date' ?
                                                    <DateConfigSelect handleSetDateOptions={(data) => handleSetDateOptions(index, data, 'urlParams')} dateConfig={urlParam.dateConfig}/>
                                                    : null
                                            }


                                            {index === (urlParams.length - 1) && !props.isViewOnly ?
                                                <Button ype="dashed" onClick={() => addUrlParam()} block icon={<PlusOutlined />}>
                                                    Add new
                                                </Button>
                                                : null}
                                        </Input.Group>

                                    </div>

                                </Col>
                            </Row>
                        ))}
                    </div>


                    <div style={{ marginTop: '16px' }}>
                        {headers.map((header, index) => (
                            <Row key={'header_' + index}>
                                <Col span={6} style={{ textAlign: 'right' }}>{index === 0 ? "Headers" : ""} : &nbsp;</Col>
                                <Col span={18}>
                                    <div>
                                        <Input.Group compact>
                                            <Input style={{ width: '30%' }} placeholder="Key" value={headers[index].key} onChange={(e) => { onChangeHeaderKey(e, index, 'key') }} disabled={props.isViewOnly} />
                                            <Select style={{ width: '30%' }} placeholder="Data Type" value={headers[index].dataType} onChange={(e) => { onChangeHeaderKey(e, index, 'dataType') }}>
                                                <Select.Option value='number'>Number</Select.Option>
                                                <Select.Option value='string'>String</Select.Option>
                                                <Select.Option value='boolean'>Boolean</Select.Option>
                                                <Select.Option value='date'>Date</Select.Option>
                                            </Select>
                                            <Input style={{ width: '40%' }} placeholder="Value" value={headers[index].value} onChange={(e) => { onChangeHeaderKey(e, index, 'value') }} addonAfter={<CloseOutlined onClick={() => removeHeader(index)} />} disabled={props.isViewOnly} />
                                            {
                                                headers[index].dataType === 'date' ?

                                                    <DateConfigSelect handleSetDateOptions={(data) => handleSetDateOptions(index, data, 'headers')} />
                                                    : null
                                            }
                                            {index === (headers.length - 1) && !props.isViewOnly ?
                                                <Button ype="dashed" onClick={() => addHeader()} block icon={<PlusOutlined />}>
                                                    Add new
                                                </Button>
                                                : null}
                                        </Input.Group>

                                    </div>
                                </Col>
                            </Row>
                        ))}

                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Form.Item style={{ marginBottom: '8px' }}
                            label="HTTP Method"
                            name="httpMethodName"
                            rules={[{ required: !httpMethod, message: 'Please input API name!' }]}
                            validateStatus={apiNameError ? "error" : ""}
                        >
                            <Select onChange={(e) => setHttpMethod(e)} value={httpMethod} defaultValue={props.selectedResource && props.selectedResource.connectionData && props.selectedResource.connectionData.requestValues.httpMethod} disabled={props.isViewOnly}>
                                <Select.Option value='get'>GET</Select.Option>
                                <Select.Option value='post'>POST</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>


                    {httpMethod === 'post' ?
                        <div style={{ marginTop: '16px' }}>
                            {bodyParams.map((bp, index) => (
                                <Row key={'bodyParam_' + index} >
                                    <Col span={6} style={{ textAlign: 'right' }}>{index === 0 ? "Extra Body Values" : ""} : &nbsp;</Col>
                                    <Col span={18}>
                                        <div>
                                            <Input.Group compact>
                                                <Input style={{ width: '30%' }} placeholder="Key" value={bodyParams[index].key} onChange={(e) => { onChangeBodyParamsKey(e, index, 'key') }} disabled={props.isViewOnly} />
                                                <Select style={{ width: '30%' }} placeholder="Data Type" value={bodyParams[index].dataType} onChange={(e) => { onChangeBodyParamsKey(e, index, 'dataType') }}>
                                                    <Select.Option value='number'>Number</Select.Option>
                                                    <Select.Option value='string'>String</Select.Option>
                                                    <Select.Option value='boolean'>Boolean</Select.Option>
                                                    <Select.Option value='date'>Date</Select.Option>
                                                </Select>
                                                {bodyParams[index].dataType !== 'date' ?
                                                    <Input style={{ width: '40%' }} placeholder="Value" value={bodyParams[index].value} onChange={(e) => { onChangeBodyParamsKey(e, index, 'value') }} addonAfter={<CloseOutlined onClick={() => removeBodyParams(index)} />} disabled={props.isViewOnly} />
                                                    : <Button><CloseOutlined onClick={() => removeBodyParams(index)} /></Button>}
                                                {
                                                    bodyParams[index].dataType === 'date' ?

                                                        <DateConfigSelect handleSetDateOptions={(data) => handleSetDateOptions(index, data, 'bodyParams')} dateConfig={bp.dateConfig} />
                                                        : null
                                                }

                                                {index === (bodyParams.length - 1) && !props.isViewOnly ?
                                                    <Button type="dashed" onClick={() => addBodyParams()} block icon={<PlusOutlined />}>
                                                        Add new
                                                    </Button>
                                                    : null}
                                            </Input.Group>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                        : null}

                    <div style={{ marginTop: '16px' }}>
                        <Form.Item style={{ marginBottom: '8px' }} label="Authentication" validateFirst={authMethod}>
                            <Select onChange={(e) => { setauthMethod(e); setAuthParams(null) }}
                                defaultValue={props.selectedResource && props.selectedResource.connectionData && props.selectedResource.connectionData.authInfo.method ? props.selectedResource.connectionData.authInfo.method : "none"}
                                disabled={props.isViewOnly} >
                                {authOptions.map((opt) => {
                                    return (
                                        <Select.Option key={'auth_opt_' + opt.id} value={opt.value}>{opt.name}</Select.Option>
                                    )
                                })}

                            </Select>
                        </Form.Item>
                    </div>

                    {(() => {
                        switch (authMethod) {
                            case 'basic':
                                return (
                                    <BasicAuthForm handleChangeValues={setAuthParams} isViewOnly={props.isViewOnly} />
                                );
                            case 'auth0':
                                return (
                                    <AuthOForm handleChangeValues={setAuthParams} isViewOnly={props.isViewOnly} />
                                );
                            case 'cookie':
                                return (
                                    <SessionBaseAuthForm handleChangeValues={setAuthParams} isViewOnly={props.isViewOnly} />
                                );
                            case 'jwt':
                                return (
                                    <JWTAuthForm handleChangeValues={setAuthParams} authData={props.selectedResource && props.selectedResource.connectionData && props.selectedResource.connectionData.authInfo && props.selectedResource.connectionData.authInfo.parameters ? props.selectedResource.connectionData.authInfo.parameters : null} isViewOnly={props.isViewOnly} />
                                );
                            default:
                                return <div></div>
                        }
                    })()}

                    {/* 
                    <Divider />

                    <div style={{ marginTop: '16px' }}>
                        <Row gutter={4}>
                            <Col span={6} style={{ textAlign: 'right' }}>&nbsp;</Col>
                            <Col span={18}>
                                <Radio.Group value={isShareable ? 2 : 1} onChange={(e) => setIsShareable(e.target.value === 2)}  disabled={props.isViewOnly}>
                                    <Radio value={1}><div style={{ display: 'flex', alignItems: 'center' }}><MaterialDesignIcon icon='fingerprint' /> <span>Private</span></div></Radio>
                                    <Radio value={2}><div style={{ display: 'flex', alignItems: 'center' }}><MaterialDesignIcon icon='public' /><span>Public</span></div></Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        {isShareable ?
                            <Row gutter={4}>
                                <Col span={6} style={{ textAlign: 'right' }}>Share With : &nbsp;</Col>
                                <Col span={18}>
                                    <Mentions
                                        style={{ width: '100%' }}
                                        id="users"
                                        onSelect={onSelectSharableUsers}
                                        disabled={props.isViewOnly}
                                    >

                                        {
                                            props.usersData.otherUsers ? (
                                                props.usersData.otherUsers.map(user =>

                                                    <Option data={user} value={user.UserDetails.FirstName}>{user.UserDetails.FirstName} {user.UserDetails.LastName}</Option>
                                                )
                                            ) : (
                                                <></>
                                            )
                                        }


                                    </Mentions>
                                </Col>
                            </Row>
                            : null}
                    </div> */}
                </div>

                {!props.isViewOnly ?
                    <>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <Button htmlType="submit" onClick={testRestApi} loading={apiTestStatus === "pending"}>
                                    Test API
                                </Button>
                                {props.selectedResource ?
                                    <Button type="primary" htmlType="submit">Update Resource</Button>
                                    : <Button type="primary" htmlType="submit" loading={apiSaveStatus === "pending"}>
                                        Create Resource
                                    </Button>
                                }
                            </div>
                            {/* <div>{apiTestStatus === "success" ? 
                                <Alert message="Test Connection Success" type="success" showIcon /> 
                                :apiTestStatus === "error" ? 
                                    <Alert message="Test Connection Failed" type="error" showIcon /> 
                                    : ''}
                                </div> */}
                            {(() => {
                                switch (apiTestStatus) {
                                    case 'success':
                                        return (
                                            <Alert message="Test Connection Success" type="success" showIcon />
                                        );
                                    case 'error':
                                        return (
                                            <Alert message="Test Connection Failed" type="error" showIcon />
                                        );
                                    case 'pending':
                                        return (
                                            <div><Spin /></div>
                                        );
                                    default:
                                        return <div></div>
                                }
                            })()}

                            {(() => {
                                switch (apiSaveStatus) {
                                    case 'success':
                                        return (
                                            <Alert message="Saving API data Success" type="success" showIcon />
                                        );
                                    case 'error':
                                        return (
                                            <Alert message="Saving API data Failed" type="error" showIcon />
                                        );
                                    case 'pending':
                                        return (
                                            <div><Spin /></div>
                                        );
                                    default:
                                        return <div></div>
                                }
                            })()}

                        </div>
                    </>
                    : null}

            </Form >

        </div >
    );
};

export default RestApiForm;
