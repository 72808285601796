import React, { useState } from 'react';
import { Form, Input } from 'antd';

export default function SessionBaseAuthForm(props) {
    const [cookieText, setCookieText] = useState('');
    const [password, setPassword] = useState('');
    const [cookieTextError, setCookieTextError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleChangeCookieText = (e) => {
        setCookieText(e.target.value);
        setCookieTextError(false)

        props.handleChangeValues({
            cookieText: cookieText,
            password: password
        });
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
        props.handleChangeValues({
            cookieText: cookieText,
            password: password
        })
    }

    return (
        <div>
            < Form.Item style={{ marginBottom: '8px' }}
                label="Cookies to forward"
                name="baseAuthCookieTextName"
                rules={[{ required: true, message: 'Please input cookieText!' }]}
                validateStatus={cookieTextError ? "error" : ""}
            >
                <Input value={cookieText} onChange={handleChangeCookieText} />
            </Form.Item>
        </div>
    )
}