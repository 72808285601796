import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import DashboardView from "../DashboardView";
import { Row, Col, Button, Input, Typography, Popover } from "antd";
import { CalendarOutlined, DeleteOutlined, EditOutlined, FileDoneOutlined, MailOutlined, MoreOutlined, ShareAltOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getResourcesAsync,
  getResourceTypesAsync,
} from "../../redux/resourceSlice";
import CustomTable from "../Common/CustomTable";
import CustomSelect from "../Common/CustomSelect";
import "./reports-logs.css"
import Timeline from "../Common/Timeline";
import PopoverMenu from "../Common/PopoverMenu";
import resendIcon from "../../assets/Images/resend-icon.svg";
// import Loading from "../../components/common/Spinners/Loading";

const { Search } = Input;

export default function ScheduleLogsTable() {
  const [data, setData] = React.useState([]);

  const history = useHistory();

  const resources = useSelector((state) => state.resources);
  const groups = useSelector((state) => state.boards.groups);
  const dispatch = useDispatch();

  const onSearchColName = (value) => {
    if (value) {
      let FilteredData = resources.resourceData?.filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.resoueceType.toLowerCase().includes(value.toLowerCase())
      );
      return setData((state) => [...FilteredData]);
    } else {
      return fetchResources();
    }
  };

  const fetchResourceTypes = React.useCallback(() => {
    dispatch(getResourceTypesAsync());
  }, [dispatch]);

  React.useEffect(() => {
    fetchResourceTypes();
  }, []);

  const fetchResources = React.useCallback(() => {
    dispatch(getResourcesAsync());
  }, [dispatch]);

  React.useEffect(() => {
    fetchResources();
  }, []);

  React.useEffect(() => {
    if (resources.resourceData) {
      setData(() => [...resources.resourceData]);
    }
  }, [resources.resourceData]);

  const tableHeader = [
    {
      title: "Report Name",
      dataIndex:"reportName",
      key:"reportName"
    },
    {
      title: "Frequency",
      dataIndex:"frequency",
      key:"frequency"
    },
    {
      title: "File Type",
      dataIndex:"fileType",
      key:"fileType"
    },
    {
      title: "Email Scheduled Date",
      dataIndex:"emailScheduledDate",
      key:"emailScheduledDate"
    },
    {
      title: "Time Zone",
      dataIndex:"timeZone",
      key:"timeZone"
    },
    {
      title: "Status",
      dataIndex:"status",
      key:"status",
      render: (data,row)=>{
        return (
          data === "Succeeded" ? <span style={{color:"#50D63A"}}>{data}</span> : 
          data === "Partially Succeded" ? <span style={{color:"#FD891E"}}>{data}</span> :
          <span> {data} </span> 
        )
      }
    },
    {
      title: "Action",
      dataIndex:"action",
      key:"action",
      render: () => {
        return (
            <div onClick={(e)=> {e.stopPropagation(); navigateToShareReport();}}><ShareAltOutlined style={{fontSize:'16px'}}/></div>
        )
      }
    }
  ];

  const navigateToShareReport = ()=>{
    history.push("/share-schedule-report");
  }

  const innerTableData = {
    tableHeaders : [
    {
      title: "User",
      dataIndex:"user"
    },
    {
      title: "User email",
      dataIndex:"userEmail"
    },
    {
      title: "Schedule Type",
      dataIndex:"scheduleType"
    },
    {
      title: "Delivered Date Time",
      dataIndex:"deliveredDateTime"
    },
    {
      title: "Status",
      dataIndex:"status"
    },
    {
      title: "Action",
      dataIndex:"action"
    } 
  ],
  tableData : [
    {
      user:"Dinojan Varatharajah",
      userEmail:"dinojanvaratharajah@gmail.com",
      scheduleType:"Email",
      deliveredDateTime:"05-03-2023 10:00AM",
      status:"Succeeded"
    },
    {
      user:"Gihan Moonasinghe",
      userEmail:"gihanmoonasinghe@gmail.com",
      scheduleType:"Email",
      deliveredDateTime:"05-03-2023 10:00AM",
      status:"Succeeded"
    }
  ]
}

  const handleInnerTable = ()=>{
    return(
      <table>
        <thead>
          <tr>
            {innerTableData.tableHeaders.map((header)=>{
              return(
                <th>{header.title}</th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {innerTableData.tableData.map((row)=>{
            return(
              <tr>
                <td>{row.user}</td>
                <td> <div style={{display:'flex',alignItems:'center'}}><MailOutlined style={{fontSize:'16px',color:'#FF4A75',marginRight:'10px'}}/> {row.userEmail}</div></td>
                <td>{row.scheduleType}</td>
                <td>{row.deliveredDateTime}</td>
                <td>{row.status}</td>
                <td>
                  <div><img src={resendIcon}/><span style={{color:"#039BE5",marginLeft:"10px"}}>Re-Send</span></div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

    )
  }

  const tableData = [
    {
      key:1,
      reportName: "Daily Sales Reports",
      frequency: "Monthly",
      fileType: "PDF",
      emailScheduledDate: "05-01-2023   10:00AM",
      timeZone:"IST GMT+5:30",
      status: "Succeeded",
      expandedData: handleInnerTable()
    },
    {
      key:2,
      reportName: "Daily Sales Reports 2",
      frequency: "Monthly",
      fileType: "PDF",
      emailScheduledDate: "05-01-2023   10:00AM",
      timeZone:"IST GMT+5:30",
      status: "Succeeded",
      expandedData: handleInnerTable()
    }  
  ];

  const[multiSelectOptions,setMultiSelectOptions] = useState([
    {
      label:'Gihan.Moonasinghe@ecologital.com',
      value:'blue',
      iconName:'',                    
    },
    {
        label:'Akila.Nilakshi@ecologital.com',
        value:'black',
        iconName:''                    
    },
    {
        label:'Chanuga@ecologital.com',
        value:'red',
        iconName:''
    },
    {
        label:'Anupa@ecologital.com',
        value:'green',
        iconName:''
    }
  ]);

  const[selectedMultiOption,setSelectedMultiOption]= useState([]);

  const getMultiSelectedOptions = (e)=>{
    console.log("getMultiSelectedOptions", e);
  }

  const items = [
    {
      children: <>
        <p>05Th of January 2023</p>
        <p>10:00AM</p>
      </>,
      dot: <CalendarOutlined className="timeline-clock-icon" />,
    },
    {
      children: <>
        <p>05Th of January 2023</p>
        <p>10:00AM</p>
      </>,
    }
  ]




  return (
    <DashboardView>
      <Row gutter={[16,32]}>
        <Col span={4}>
          <h2>Schedule Reports</h2>
        </Col>
        <Col span={24}>
          <CustomTable 
            tableClass="custom-table schedule-logs-table"
            tableHeaders={tableHeader} 
            tableData={tableData}
            hasExpandedRows={true}
            />
        </Col>            
      </Row>     
    </DashboardView>
  );
}
