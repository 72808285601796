import React, { Component } from "react";
import { connect } from "react-redux";
import { WidthProvider, Responsive } from "react-grid-layout";
import * as _ from "lodash";
import Axios from "axios";
import {
  Row,
  Col,
  Select,
  Typography,
  Skeleton,
  Input,
  DatePicker,
  Button,
  Tag,
} from "antd";
import jwt from "jsonwebtoken";
import { returnDataArrayObject } from "../../../utils/arrayHelper";
import moment from "moment";
import { customDateTimeFormat } from "../../../utils/filterHelper";
import {
  DATE_WITH_HOUR_FORMAT,
  DATE_WITH_HOUR_MINUTES_FORMAT,
} from "../../../constants/dateConstants";
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const { Option } = Select;
const { Text, Title } = Typography;

class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layouts: null,
      reportRawData: [],
      fieldsToShow: [],
      resources: null,
    };
  }

  componentDidMount() {
    console.log("this.props.board");
    console.log(this.props.board);
    console.log("this.props.board");

    this.setState({
      resources: this.props.board && this.props.board.resources,
    });

    if (this.props.board && this.props.board.resources) {
      let layoutData = this.state.layouts
        ? this.state.layouts
        : this.props.board.resources.map(function (j, key, list) {
            return {
              ...(j.layout || {
                i: 0,
                x: 0,
                y: 2,
                w: 8,
                h: 2,
              }),
            };
          });
      this.props.board.resources.forEach(async (resource, idx) => {
        console.log("resource===>>", resource);
        if (
          resource.connectionData &&
          resource.connectionData &&
          resource.connectionData.resoueceType === "Rest API"
        ) {
          // const response = await axios.get(resource.connectionData.baseUrl);

          try {
            const requestValues =
              resource.connectionData.connectionData.requestValues;
            const authInfo = resource.connectionData.connectionData.authInfo;
            // console.log("requestValues===>>", requestValues)
            let urlParamSring = "";
            if (requestValues.urlParams.length > 0) {
              urlParamSring = urlParamSring + "?";
              for (let i = 0; i < requestValues.urlParams.length; i++) {
                if (
                  requestValues.urlParams[i]["key"] !== "" &&
                  requestValues.urlParams[i]["value"] !== ""
                ) {
                  urlParamSring =
                    urlParamSring +
                    requestValues.urlParams[i]["key"] +
                    "=" +
                    requestValues.urlParams[i]["value"] +
                    "&";
                }
              }
              urlParamSring.slice(0, -1);
            }

            let headerObj = {};
            if (requestValues.requestHeaders.length > 0) {
              for (let i = 0; i < requestValues.requestHeaders.length; i++) {
                if (
                  requestValues.requestHeaders[i]["key"] !== "" &&
                  requestValues.requestHeaders[i]["value"] !== ""
                ) {
                  headerObj[requestValues.requestHeaders[i]["key"]] =
                    requestValues.requestHeaders[i]["value"];
                }
              }
            }

            let bodyParamObj = {};
            // if (requestValues.authParams) {
            //     bodyParamObj = Object.assign({}, authParams);
            // }
            if (requestValues.bodyParams.length > 0) {
              for (let i = 0; i < requestValues.bodyParams.length; i++) {
                if (
                  requestValues.bodyParams[i]["key"] !== "" &&
                  requestValues.bodyParams[i]["value"] !== ""
                ) {
                  bodyParamObj[requestValues.bodyParams[i]["key"]] =
                    requestValues.bodyParams[i]["value"];
                }
              }
            }
            // // await Axios.get(resource.connectionData.baseUrl)

            if (authInfo.method === "jwt") {
              console.log("JWT PROCESS");
              const timestamp = Math.floor(Date.now() / 1000) + 1000;
              const privateKey = authInfo.parameters.privateKey;
              var payload = {
                aud: authInfo.parameters.aud,
                exp: timestamp,
                iss: authInfo.parameters.iss,
                usertoken: localStorage.getItem('accessToken')
              };
              var signOptions = {
                algorithm: "RS256",
              };

              var privateKeyVal = privateKey
                ? privateKey.replace(/\\n/g, "\n")
                : null;

              if (privateKeyVal) {
                try {
                  var token = jwt.sign(payload, privateKeyVal, signOptions);
                  console.log("Generated JWT authParams=====>>", token);
                  // setApiTestStatus("success")

                  await Axios({
                    method:
                      resource.connectionData.connectionData.requestValues
                        .httpMethod,
                    url:
                      resource.connectionData.connectionData.baseUrl +
                      urlParamSring,
                    data: bodyParamObj,
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ` + token,
                    },
                  })
                    .then(async (response) => {
                      // rowDataArr.push(returnDataArrayObject(response.data)
                      layoutData[idx].reportData = {
                        rawData: returnDataArrayObject(response.data),
                        fieldsToShow: resource.availableFields,
                      };
                      this.setState({
                        reportRawData: [
                          ...this.state.reportRawData,
                          returnDataArrayObject(response.data),
                        ],
                        fieldsToShow: [
                          ...this.state.fieldsToShow,
                          resource.availableFields,
                        ],
                        layouts: layoutData,
                      });

                      // setReportDataStatus("success")
                      // setReportDataObject(response.data ? response.data : response)
                    })
                    .catch((error) => {
                      // setReportDataStatus("error")
                      // setReportDataObject(null)
                      console.log(error);
                    });
                } catch (e) {
                  console.log("JWT authPsign error=====>>", e);
                }
              }
            } else {
              await Axios({
                method:
                  resource.connectionData.connectionData.requestValues
                    .httpMethod,
                url:
                  resource.connectionData.connectionData.baseUrl +
                  urlParamSring,
                data: bodyParamObj,
                headers: headerObj,
              })
                .then(async (response) => {
                  // rowDataArr.push(returnDataArrayObject(response.data)
                  layoutData[idx].reportData = {
                    rawData: returnDataArrayObject(response.data),
                    fieldsToShow: resource.availableFields,
                  };
                  this.setState({
                    reportRawData: [
                      ...this.state.reportRawData,
                      returnDataArrayObject(response.data),
                    ],
                    fieldsToShow: [
                      ...this.state.fieldsToShow,
                      resource.availableFields,
                    ],
                    layouts: layoutData,
                  });

                  // setReportDataStatus("success")
                  // setReportDataObject(response.data ? response.data : response)
                })
                .catch((error) => {
                  // setReportDataStatus("error")
                  // setReportDataObject(null)
                  console.log(error);
                });
            }
          } catch (error) {
            // setReportDataStatus("error")
            // setReportDataObject(null)
            console.log(error);
          }
        }
      });
    }
  }

  fetchReportData = async () => {
    let rowDataArr = [];
  };

  // componentDidUpdate() {
  //     let updateRes = this.state.resources.map((crs, i) => {
  //         return (
  //             {
  //                 ...crs,
  //                 dataFilters: crs.connectionData.id === res.connectionData.id ?
  //                     crs.dataFilters.map(cf => {
  //                         return ({
  //                             ...cf,
  //                             value: cf.id === filter.id ? value : cf.value,
  //                             value1: cf.id === filter.id && key === 'value1' ? value : cf.value1,
  //                             value2: cf.id === filter.id && key === 'value2' ? value : cf.value2
  //                         })
  //                     })
  //                     : crs.dataFilters
  //             }
  //         )
  //     })
  //     this.setState({
  //         resources: updateRes
  //     })
  // }

  updateInputValue = (res, filter, value, key) => {
    // console.log("updateInputValue", res, filter, value);
    // console.log("filter",  filter);
    // console.log("value",  value);

    let updateRes = this.state.resources.map((crs, i) => {
      return {
        ...crs,
        dataFilters:
          crs.connectionData.id === res.connectionData.id
            ? crs.dataFilters.map((cf) => {
                return {
                  ...cf,
                  value:
                    cf.id === filter.id
                      ? filter.dataType === "date"
                        ? moment(value).valueOf()
                        : value
                      : cf.value,
                  value1:
                    cf.id === filter.id && key === "value1"
                      ? filter.dataType === "date"
                        ? moment(value).valueOf()
                        : value
                      : cf.value1,
                  value2:
                    cf.id === filter.id && key === "value2"
                      ? filter.dataType === "date"
                        ? moment(value).valueOf()
                        : value
                      : cf.value2,
                };
              })
            : crs.dataFilters,
      };
    });

    this.setState({
      resources: updateRes,
    });
    console.log("updateRes Updated", updateRes);
  };

  handleResetFilterInputs(res) {
    let updateRes = this.state.resources.map((crs, i) => ({
      ...crs,
      dataFilters:
        crs.connectionData.id === res.connectionData.id
          ? crs.dataFilters.map((cf) => ({
              ...cf,
              value:
                cf.dataType === "date" ? moment(new Date()).valueOf() : null,
              value1:
                cf.dataType === "date" ? moment(new Date()).valueOf() : null,
              value2:
                cf.dataType === "date" ? moment(new Date()).valueOf() : null,
            }))
          : crs.dataFilters,
    }));

    this.setState((s) => ({ resources: updateRes }));
    // console.log("AFTER RESET===>>", updateRes)
    console.log("RES===>>", res);

    setTimeout(() => {
      this.props.onResetDataFilter(res);
    }, 1000);
  }

  render() {
    return (
      <>
        {this.props.board ? (
          this.state.resources ? (
            this.state.resources.map((r, i) => {
              return (
                <React.Fragment key={`dataF_r_${i}`}>
                  <div
                    style={{
                      borderBottom: "1px solid #eee",
                      marginBottom: "8px",
                    }}
                  >
                    <b>{r.connectionData.name}</b>
                  </div>
                  {r.dataFilters && r.dataFilters.length > 0 ? (
                    <>
                      {r.dataFilters.map((filter, j) => {
                        return (
                          <Row
                            key={`dataF_r_${i}_f_${j}`}
                            style={{ marginBlock: "2rem" }}
                          >
                            <Col span={3}>
                              <Text>
                                {" "}
                                Field Name:{"  "}
                                <b>
                                  {filter.fieldName.charAt(0).toUpperCase() +
                                    filter.fieldName.slice(1).replace("_", " ")}
                                </b>
                              </Text>
                            </Col>

                            {/* <Col span={3}>
                          <Text>
                              Display Name: {"  "}{filter?.displayName ?? ""}</Text>
                        </Col> */}

                            {/* <Col span={2}>
                          <Text style={{ color: "#aaa" }}>
                            ({filter?.filterType ?? ""})
                          </Text>
                        </Col> */}

                            {(() => {
                              switch (filter.filterType) {
                                case "gt":
                                  return (
                                    <>
                                      <Col
                                        span={3}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Tag color={"blue"}>Grater Than</Tag>
                                      </Col>
                                      {/* <Col span={1}>
                                    <Text style={{ color: "#aaa" }}>
                                      {filter?.value ?? ""}
                                    </Text>
                                  </Col> */}
                                    </>
                                  );
                                case "lt":
                                  return (
                                    <>
                                      <Col
                                        span={3}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Tag color={"lime"}>Less Than</Tag>
                                      </Col>
                                      {/* <Col span={3}>
                                    <Text style={{ color: "#aaa" }}>
                                      {filter?.value ?? ""}
                                    </Text>
                                  </Col> */}
                                    </>
                                  );
                                case "gte":
                                  return (
                                    <>
                                      <Col
                                        span={3}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Tag color={"green"}>
                                          Grater Than or Equal
                                        </Tag>
                                      </Col>
                                      {/* <Col span={3}>
                                    <Text style={{ color: "#aaa" }}>
                                      {filter?.value ?? ""}
                                    </Text>
                                  </Col> */}
                                    </>
                                  );
                                case "lte":
                                  return (
                                    <>
                                      <Col
                                        span={3}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Tag color={"geekblue"}>
                                          Less Than or Equal
                                        </Tag>
                                      </Col>
                                      {/* <Col span={3}>
                                    <Text style={{ color: "#aaa" }}>
                                      {filter?.value ?? ""}
                                    </Text>
                                  </Col> */}
                                    </>
                                  );
                                case "equal":
                                  return (
                                    <>
                                      <Col
                                        span={3}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Tag color={"orange"}>Equal</Tag>
                                      </Col>
                                      {/* <Col span={3}>
                                    <Text style={{ color: "#aaa" }}>
                                      {filter?.value ?? ""}
                                    </Text>
                                  </Col> */}
                                    </>
                                  );
                                case "con":
                                  return (
                                    <>
                                      <Col
                                        span={3}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Tag color={"orange"}>Contains</Tag>
                                      </Col>
                                      {/* <Col span={3}>
                                    <Text style={{ color: "#aaa" }}>
                                      {filter?.value ?? ""}
                                    </Text>
                                  </Col> */}
                                    </>
                                  );
                                case "between":
                                  return (
                                    <>
                                      <Col
                                        span={3}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Tag color={"magenta"}>Between</Tag>
                                      </Col>
                                      {/* <Col span={1}>
                                    <Text>From</Text>
                                  </Col>
                                  <Col span={5}>
                                    <Text style={{ color: "#aaa" }}>
                                      {filter?.value1 ?? ""}
                                    </Text>
                                  </Col>
                                  <Col span={1}>
                                    <Text>To</Text>
                                  </Col>
                                  <Col span={5}>
                                    <Text style={{ color: "#aaa" }}>
                                      {filter?.value2 ?? ""}
                                    </Text>
                                  </Col> */}
                                    </>
                                  );
                                default:
                                  return null;
                              }
                            })()}

                            {filter.filterType !== "between" ? (
                              <Col span={6}>
                                {(() => {
                                  switch (filter.dataType) {
                                    case "number":
                                      return (
                                        <Input
                                          type='number'
                                          onChange={(e) =>
                                            this.updateInputValue(
                                              r,
                                              filter,
                                              e.target.value,
                                              "value"
                                            )
                                          }
                                          size='small'
                                        />
                                      );
                                    case "date":
                                      return (
                                        <DatePicker
                                          size='small'
                                          onChange={(date, dateString) =>
                                            this.updateInputValue(
                                              r,
                                              filter,
                                              date.format("YYYY-MM-DD"),
                                              "value"
                                            )
                                          }
                                          showTime={
                                            customDateTimeFormat(
                                              r.availableFields
                                            ).getDateFormat(filter.fieldName)
                                              .dateAndTimeFormat ===
                                              DATE_WITH_HOUR_FORMAT ||
                                            customDateTimeFormat(
                                              filter.availableFields
                                            ).getDateFormat(filter.fieldName)
                                              .dateAndTimeFormat ===
                                              DATE_WITH_HOUR_MINUTES_FORMAT
                                          }
                                          format={
                                            customDateTimeFormat(
                                              r.availableFields
                                            ).getDateFormat(filter.fieldName)
                                              .dateAndTimeFormat
                                          }
                                        />
                                      );
                                    case "select":
                                      return (
                                        <Select
                                          size='small'
                                          style={{ width: "90%" }}
                                          onChange={(val) =>
                                            this.updateInputValue(
                                              r,
                                              filter,
                                              val,
                                              "value"
                                            )
                                          }
                                        >
                                          {this.state.reportRawData &&
                                            this.state.reportRawData.length >
                                              0 &&
                                            [
                                              ...new Set(
                                                this.state.reportRawData[0].data.map(
                                                  (rd) => rd[filter.fieldName]
                                                )
                                              ),
                                            ].map((o) => {
                                              return (
                                                <Option key={o}>{o}</Option>
                                              );
                                            })}
                                        </Select>
                                      );
                                    default:
                                      return (
                                        <Input
                                          size='small'
                                          onChange={(e) =>
                                            this.updateInputValue(
                                              r,
                                              filter,
                                              e.target.value,
                                              "value"
                                            )
                                          }
                                        />
                                      );
                                  }
                                })()}
                              </Col>
                            ) : (
                              <>
                                {(() => {
                                  switch (filter.dataType) {
                                    case "number":
                                      return (
                                        <>
                                          <Col
                                            span={2}
                                            style={{ textAlign: "center" }}
                                          >
                                            <Text>From</Text>
                                          </Col>

                                          {/* <Col span={3} >
                                        <Text style={{ color: "#aaa" }}>
                                          {filter?.value1 ?? ""}
                                        </Text>
                                      </Col> */}
                                          <Col span={3}>
                                            <Input
                                              type='number'
                                              onChange={(e) =>
                                                this.updateInputValue(
                                                  r,
                                                  filter,
                                                  e.target.value,
                                                  "value1"
                                                )
                                              }
                                              size='small'
                                            />
                                          </Col>
                                        </>
                                      );
                                    case "date":
                                      return (
                                        <>
                                          <Col
                                            span={2}
                                            style={{ textAlign: "center" }}
                                          >
                                            <Text>From</Text>
                                          </Col>
                                          {/* <Col span={3} >
                                        <Text style={{ color: "#aaa" }}>
                                          {filter?.value1 ?? ""}
                                        </Text>
                                      </Col> */}
                                          <Col span={3}>
                                            <DatePicker
                                              size='small'
                                              onChange={(val) =>
                                                this.updateInputValue(
                                                  r,
                                                  filter,
                                                  val.format("YYYY-MM-DD"),
                                                  "value1"
                                                )
                                              }
                                              showTime={
                                                customDateTimeFormat(
                                                  r.availableFields
                                                ).getDateFormat(
                                                  filter.fieldName
                                                ).dateAndTimeFormat ===
                                                  DATE_WITH_HOUR_FORMAT ||
                                                customDateTimeFormat(
                                                  filter.availableFields
                                                ).getDateFormat(
                                                  filter.fieldName
                                                ).dateAndTimeFormat ===
                                                  DATE_WITH_HOUR_MINUTES_FORMAT
                                              }
                                              format={
                                                customDateTimeFormat(
                                                  r.availableFields
                                                ).getDateFormat(
                                                  filter.fieldName
                                                ).dateAndTimeFormat
                                              }
                                            />
                                          </Col>
                                        </>
                                      );
                                    case "select":
                                      return (
                                        <>
                                          <Col
                                            span={2}
                                            style={{ textAlign: "center" }}
                                          >
                                            <Text>From</Text>
                                          </Col>
                                          {/* <Col span={3} >
                                        <Text style={{ color: "#aaa" }}>
                                          {filter?.value1 ?? ""}
                                        </Text>
                                      </Col> */}
                                          <Col span={3}>
                                            <Select
                                              size='small'
                                              style={{ width: "90%" }}
                                              onChange={(val) =>
                                                this.updateInputValue(
                                                  r,
                                                  filter,
                                                  val,
                                                  "value1"
                                                )
                                              }
                                            >
                                              {this.state.reportRawData &&
                                                this.state.reportRawData
                                                  .length > 0 &&
                                                [
                                                  ...new Set(
                                                    this.state.reportRawData[0].data.map(
                                                      (rd) =>
                                                        rd[filter.fieldName]
                                                    )
                                                  ),
                                                ].map((o) => {
                                                  return (
                                                    <Option key={o}>{o}</Option>
                                                  );
                                                })}
                                            </Select>
                                          </Col>
                                        </>
                                      );
                                    default:
                                      return (
                                        <>
                                          <Col
                                            span={2}
                                            style={{ textAlign: "center" }}
                                          >
                                            <Text>From</Text>
                                          </Col>
                                          {/* <Col span={3}>
                                        <Text style={{ color: "#aaa" }}>
                                          {filter?.value1 ?? ""}
                                        </Text>
                                      </Col> */}

                                          <Col span={3}>
                                            <Input
                                              size='small'
                                              onChange={(e) =>
                                                this.updateInputValue(
                                                  r,
                                                  filter,
                                                  e.target.value,
                                                  "value1"
                                                )
                                              }
                                            />
                                          </Col>
                                        </>
                                      );
                                  }
                                })()}
                                <>
                                  {(() => {
                                    switch (filter.dataType) {
                                      case "number":
                                        return (
                                          <>
                                            <Col
                                              span={2}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Text>To</Text>
                                            </Col>
                                            {/* <Col span={3}>
                                          <Text style={{ color: "#aaa" }}>
                                            {filter?.value2 ?? ""}
                                          </Text>
                                        </Col> */}
                                            <Col span={3}>
                                              <Input
                                                type='number'
                                                onChange={(e) =>
                                                  this.updateInputValue(
                                                    r,
                                                    filter,
                                                    e.target.value,
                                                    "value2"
                                                  )
                                                }
                                                size='small'
                                              />
                                            </Col>
                                          </>
                                        );
                                      case "date":
                                        return (
                                          <>
                                            <Col
                                              span={2}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Text>To</Text>
                                            </Col>
                                            {/* <Col span={3}>
                                          <Text style={{ color: "#aaa" }}>
                                            {filter?.value2 ?? ""}
                                          </Text>
                                        </Col> */}
                                            <Col span={3}>
                                              <DatePicker
                                                size='small'
                                                onChange={(val) =>
                                                  this.updateInputValue(
                                                    r,
                                                    filter,
                                                    val.format("YYYY-MM-DD"),
                                                    "value2"
                                                  )
                                                }
                                                showTime={
                                                  customDateTimeFormat(
                                                    r.availableFields
                                                  ).getDateFormat(
                                                    filter.fieldName
                                                  ).dateAndTimeFormat ===
                                                    DATE_WITH_HOUR_FORMAT ||
                                                  customDateTimeFormat(
                                                    filter.availableFields
                                                  ).getDateFormat(
                                                    filter.fieldName
                                                  ).dateAndTimeFormat ===
                                                    DATE_WITH_HOUR_MINUTES_FORMAT
                                                }
                                                format={
                                                  customDateTimeFormat(
                                                    r.availableFields
                                                  ).getDateFormat(
                                                    filter.fieldName
                                                  ).dateAndTimeFormat
                                                }
                                              />
                                            </Col>
                                          </>
                                        );
                                      case "select":
                                        return (
                                          <>
                                            <Col
                                              span={2}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Text>To</Text>
                                            </Col>
                                            {/* <Col span={3} >
                                          <Text style={{ color: "#aaa" }}>
                                            {filter?.value2 ?? ""}
                                          </Text>
                                        </Col> */}
                                            <Col span={3}>
                                              <Select
                                                size='small'
                                                style={{ width: "90%" }}
                                                onChange={(val) =>
                                                  this.updateInputValue(
                                                    r,
                                                    filter,
                                                    val,
                                                    "value2"
                                                  )
                                                }
                                              >
                                                {this.state.reportRawData &&
                                                  this.state.reportRawData
                                                    .length > 0 &&
                                                  [
                                                    ...new Set(
                                                      this.state.reportRawData[0].data.map(
                                                        (rd) =>
                                                          rd[filter.fieldName]
                                                      )
                                                    ),
                                                  ].map((o) => {
                                                    return (
                                                      <Option key={o}>
                                                        {o}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </Col>
                                          </>
                                        );
                                      default:
                                        return (
                                          <>
                                            <Col
                                              span={2}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Text>To</Text>
                                            </Col>
                                            {/* <Col span={3}>
                                          <Text style={{ color: "#aaa" }}>
                                            {filter?.value2 ?? ""}
                                          </Text>
                                        </Col> */}
                                            <Col span={3}>
                                              <Input
                                                size='small'
                                                onChange={(e) =>
                                                  this.updateInputValue(
                                                    r,
                                                    filter,
                                                    e.target.value,
                                                    "value2"
                                                  )
                                                }
                                              />
                                            </Col>
                                          </>
                                        );
                                    }
                                  })()}
                                </>
                              </>
                            )}
                          </Row>
                        );
                      })}
                      <Row gutter={16}>
                        <Col>
                          <Button
                            onClick={() => {
                              this.props.onApplyDataFilter(r);
                            }}
                            type='primary'
                          >
                            Apply
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => {
                              this.handleResetFilterInputs(r);
                            }}
                          >
                            Reset
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : (
            <></>
          )
        ) : (
          <div>
            <Skeleton />
          </div>
        )}
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//     const { boards } = state;
//     return {
//         selectedBoard: boards.selectedBoard,
//     }
// }
// const mapDispatchToProps = (dispatch) => ({
//     getBoardByIdAsync: (data) => dispatch(getBoardByIdAsync(data)),
//     updateSelectedBoard: (data) => dispatch(updateSelectedBoard(data))
// })
export default connect(null, null)(Filters);
