import { Input, Radio, Row, Col, DatePicker, Checkbox } from 'antd'
import React from 'react'
import "../reports-logs.css"
import data from "../data/data.json"

const WeeklyForm = () => {
    const weekList = data.weekdays;
    return (
        <div>
            <Row className='mb-16'>
                <Col span={16}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label className='mr-16 no-word-break'>Recur every</label>
                        <Input className='mr-16 w-20' />
                        <label className='no-word-break'>week(s) on:</label>
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={24}>
                    <div>
                        { weekList.map((week)=>{
                            return(
                                <Checkbox value={week.value}>{week.value.charAt(0).toUpperCase() + week.value.slice(1)}</Checkbox>
                            )
                        })}
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={24}>
                    <h3>Range of Recurrence</h3>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={16}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio value="day" className='mr-16'>End by</Radio>
                        <DatePicker/>
                    </div>
                </Col>
            </Row>
            <Row className='mb-16'>
                <Col span={16}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Radio value="day" className='mr-16'>After</Radio>
                        <Input className='mr-16 w-20'/>
                        <label>messages</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={16}>
                    <Radio>No end date</Radio>
                </Col>
            </Row>
        </div>
    )
}

export default WeeklyForm