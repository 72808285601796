import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Avatar, Image, Modal, Alert } from 'antd';
import { getResourceTypesAsync } from '../../redux/resourceSlice';
import RestApiForm from '../../components/resourceForms/RestApiForm';
import MongoDBForm from '../../components/resourceForms/MongoDBForm';
import GraphQLForm from '../../components/resourceForms/GraphQLForm';

function ResourceTypesList(props) {
    const dispatch = useDispatch();
    const resources = useSelector((state) => state.resources);

    const fetchResourceTypes = useCallback(() => {
        dispatch(getResourceTypesAsync());
    }, [dispatch]);

    useEffect(() => {
        fetchResourceTypes()
    }, [fetchResourceTypes]);

    return (
        <>
            <div style={{ maxWidth: '960px' }}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {resources.resourceTypes && resources.resourceTypes.map((r, i) => {
                        return (
                            <Col key={`resource_li_${i}`} className="gutter-row" span={8} style={{ marginBottom: '24px' }}>
                                <Button block size="large" onClick={() => props.setSelectedResourceTypeForm(r)}>
                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '24px', padding: '4px' }}>
                                        <div style={{ marginRight: '8px' }}>
                                            <Avatar
                                                src={<Image src={process.env.PUBLIC_URL + '/images/resource_icons/' + r.icon + '.png'} />}
                                            />
                                        </div>
                                        <div>{r.name}</div>
                                    </div>
                                </Button>
                            </Col>
                        )
                    })}

                </Row>
            </div>
            
        </>
    )
}

export default ResourceTypesList;