import React, { useState } from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

export default function JWTAuthForm(props) {
    const [aud, setAud] = useState(props.authData && props.authData.aud ? props.authData.aud : '');
    const [iss, setIss] = useState(props.authData && props.authData.iss ? props.authData.iss : '');
    const [privateKey, setPrivateKey] = useState(props.authData && props.authData.privateKey ? props.authData.privateKey : '');
    const [audError, setAudError] = useState(false);
    const [issError, setIssError] = useState(false);
    const [privateKeyError, setPrivateKeyError] = useState(false);


    const handleChangeAud = (e) => {
        setAud(e.target.value);
        setAudError(false)
        props.handleChangeValues({
            aud: e.target.value,
            iss: iss,
            privateKey: privateKey
        })
    }

    const handleChangeIss = (e) => {
        setIss(e.target.value);
        setIssError(false);
        props.handleChangeValues({
            aud: aud,
            iss: e.target.value,
            privateKey: privateKey
        })
    }
    const handleChangePrivateKey = (e) => {
        setPrivateKey(e.target.value);
        setPrivateKeyError(false);
        props.handleChangeValues({
            aud: aud,
            iss: iss,
            privateKey: e.target.value
        })
    }

    // useEffect(() => {
    //     props.handleChangeValues({
    //         aud: aud,
    //         iss: iss,
    //         privateKey: privateKey
    //     })
    // }, [aud, iss, privateKey, props]);

    // const handlePasteVal = (e) => {
    //     console.log("handlePasteVal===>>", e)
    //     props.handleChangeValues({
    //         aud: aud,
    //         iss: iss,
    //         privateKey: privateKey
    //     })
    // }

    return (
        <div>
            < Form.Item style={{ marginBottom: '8px' }}
                label="AUD"
                name="aud"
                size='small'
                rules={[{ required: aud === "", message: 'Please input AUD!' }]}
                validateStatus={audError ? "error" : ""}
            >
                <Input defaultValue={aud} value={aud} onChange={handleChangeAud} onPaste={(e) => { setAud(e.target.innerHTML) }} disabled={props.isViewOnly}/>
            </Form.Item>
            < Form.Item style={{ marginBottom: '8px' }}
                label="ISS "
                name="iss"
                rules={[{ required: iss === "", message: 'Please input ISS!' }]}
                validateStatus={issError ? "error" : ""}
            >
                <Input defaultValue={iss} value={iss} onChange={handleChangeIss} onPaste={(e) => { setIss(e.target.innerHTML) }} disabled={props.isViewOnly}/>
            </Form.Item>

            < Form.Item style={{ marginBottom: '8px' }}
                label="Private Key "
                name="privateKey"
                rules={[{ required: privateKey === "", message: 'Please input PrivateKey!' }]}
                validateStatus={privateKeyError ? "error" : ""}
            >
                <TextArea rows={3} size='small' defaultValue={privateKey} value={privateKey} onChange={handleChangePrivateKey} onPaste={(e) => { setPrivateKey(e.target.innerHTML) }} placeholder="-Add Private Key Here-" disabled={props.isViewOnly}/>
            </Form.Item>
        </div>
    )
}