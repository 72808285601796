import React, { useState } from 'react'
import { Row, Col, Radio, Button, Space } from 'antd'
import DashboardView from '../DashboardView'
import { MailOutlined, MessageOutlined, WhatsAppOutlined } from '@ant-design/icons'
import CustomSelect from '../Common/CustomSelect';
import data from "./data/data.json";

const ShareReportForm = () => {
    const [publishers, setPublishers] = useState([
        {
            label: 'Gihan Moonasinghe',
            value: 'gihanMoonasinghe',
            iconName: ''
        },
        {
            label: 'Akila Nilakshi',
            value: 'akilaNilakshi',
            iconName: ''
        },
        {
            label: 'Chanuga Tharindu',
            value: 'chanugeTharindu',
            iconName: ''
        }
    ]);

    const fileTypesList = data.fileTypes;

    return (
        <DashboardView>
            <div style={{border:'1px solid rgba(0, 0, 0, 0.1)',borderRadius:'4px',padding:'25px'}}>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <h3>Share Daily Sales Report for 05-03-2023 10:00A.M</h3>
                    </Col>
                    <Col span={12}>
                        <Row className='mb-24' gutter={[0, 16]}>
                            <Col span={24}>
                                <label className='form-label'>Delivery Type</label>
                            </Col>
                            <Col span={24}>
                                <Radio.Group>
                                    <Radio value="email"><MailOutlined style={{ color: '#FF4A75', marginRight: '10px', fontSize: '18px' }} />Email</Radio>
                                    <Radio value="whatsapp"><WhatsAppOutlined style={{ color: '#40C351', marginRight: '10px', fontSize: '18px' }} />WhatsApp</Radio>
                                    <Radio value="sms"><MessageOutlined style={{ color: '#8C8AE8', marginRight: '10px', fontSize: '18px' }} />SMS</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row className='mb-24'>
                            <Col span={8}>
                                <label className='form-label'>Publisher</label>
                            </Col>
                            <Col span={16}>
                                <CustomSelect
                                    hasFormatLabel
                                    options={publishers}
                                    getSelectedOptions={(e) => console.log("publishers", e)} />
                            </Col>
                        </Row>
                        <Row className='mb-24'>
                            <Col span={8}>
                                <label className='form-label'>Share with</label>
                            </Col>
                            <Col span={16}>
                                <CustomSelect
                                    isMulti
                                    hasFormatLabel
                                    options={publishers}
                                    getSelectedOptions={(e) => console.log("publishers", e)} />
                            </Col>
                        </Row>
                        <Row className='mb-24'>
                            <Col span={8}>
                                <label className='form-label'>File Type</label>
                            </Col>
                            <Col span={16}>
                                <CustomSelect
                                    hasFormatLabel
                                    options={fileTypesList}
                                    getSelectedOptions={(e) => console.log("publishers", e)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Space size="middle">
                            <Button type="primary">Share</Button>
                            <Button>Cancel</Button>
                        </Space>
                    </Col>
                </Row>
            </div>
        </DashboardView>
    )
}

export default ShareReportForm