import { createSlice } from "@reduxjs/toolkit";
import { v4 } from "uuid";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: [],
  reducers: {
    setSuccessNotification: (state, action) => {
      const id = v4();
      const notification = { id, type: "success", message: action.payload };
      state = state.unshift(notification);
    },
    setInfoNotification: (state, action) => {
      const id = v4();
      const notification = { id, type: "info", message: action.payload };
      state = state.unshift(notification);
    },
    setWarningNotification: (state, action) => {
      const id = v4();
      const notification = { id, type: "warning", message: action.payload };
      state = state.unshift(notification);
    },
    setErrorNotification: (state, action) => {
      const id = v4();
      const notification = { id, type: "error", message: action.payload };
      state = state.unshift(notification);
    },
    removeNotification: (state, action) => {
      state = state.filter((x) => x.id !== action.payload);
    },
  },
});

export const {
  setSuccessNotification,
  setInfoNotification,
  setWarningNotification,
  setErrorNotification,
  removeNotification
} = notificationSlice.actions;

export default notificationSlice.reducer;
