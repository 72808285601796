import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Loading = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;
  return (
    <Spin
      indicator={antIcon}
      style={{
        position: "fixed",
        bottom: "50%",
        left: "48%",
      }}
    ></Spin>
  );
};

export default Loading;
