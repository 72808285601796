import React from "react";
import { Tabs } from "antd";

import ResourceRowData from "./ResourceRowData";
import DataBuilder from "./DataBuilder/";
import RestApiSelector from "./RestApiSelector";
import MultiResourceSelector from "./MultiResourceSelector";
import DataFilter from "./DataFilter";
import Grouping from "./Grouping";
import Chart from "./Chart/Chart";

export default function ResourceView(props) {
  // const [selectedres, setSelectedres] = useState(props.selectedResource && props.selectedResource.source && props.selectedResource.source.id ? props.selectedResource.source.id : null);

  const handleConnectionChange = (resId) => {
    // setSelectedres(resId)
    props.getSelectedConnection(resId);
  };

  const handleSetSelectedResource = data => {
    props.setSelected(data)
  }

  return (
    <div>
      {/* <Tabs items={[
        {
          label:"Resource", 
          key:"rsv_1", 
          children: (
            <>
              {(() => {
                switch (props.connectionType) {
                  case "Rest API":
                    return (
                      <RestApiSelector
                        getSelectedConnection={handleConnectionChange}
                        connections={props.connections.filter(
                          (rd) => rd.resoueceType === "Rest API"
                        )}
                        selectedResource={props.selectedResource}
                        dashboardConfig={props.dashboardConfig}
                        setSelected={handleSetSelectedResource}
                      />
                    );
                  case "Combined Resource":
                    return (
                      <MultiResourceSelector
                        getSelectedConnection={handleConnectionChange}
                        connections={props.connections.filter(
                          (rd) => rd.resoueceType === "Rest API"
                        )}
                        selectedResource={props.selectedResource}
                        dashboardConfig={props.dashboardConfig}
                      />
                    );
                  default:
                    return <div>blank widget</div>;
                }
              })()}
            </>
          )
        },
        {
          label:"Raw Data", 
          key:"rsv_2",
          // widgetName:"all",
          children: (
            <ResourceRowData
            reportDataObject={props.reportDataObject}
            reportDataStatus={props.reportDataStatus}
            selectedResource={props.selectedResource}
          />
          )
        },
        {
          label:"Filters", 
          key:"rsv_3",
          // widgetName:"all",
          children: (
            <DataFilter
              reportDataObject={props.reportDataObject}
              reportDataStatus={props.reportDataStatus}
              selectedResource={props.selectedResource}
              connectionType={props.connectionType}
            />
          )
        },
        {
          label:"Builder", 
          key:"rsv_4", 
          // widgetName:"all",
          children: (
            <DataBuilder
              reportDataObject={props.reportDataObject}
              reportDataStatus={props.reportDataStatus}
              selectedResource={props.selectedResource}
              activeWidgetName={props.activeWidgetName}
              getSelectedConnection={props.getSelectedConnection}
            />
          )
        },
        {
          label:"Grouping", 
          key:"rsv_5",
          // widgetName:"table",
          children: (
            <>
              <Grouping
                reportDataObject={props.reportDataObject}
                reportDataStatus={props.reportDataStatus}
                selectedResource={props.selectedResource}
                updateSelectedFields={props.updateSelectedFields}
                activeWidgetName={props.activeWidgetName}
                getSelectedConnection={props.getSelectedConnection}
              />
            </>
          )
        },
        {
          label:"Chart", 
          key:"rsv_5", 
          // widgetName:"chart",
          children: (
            <>
              <Chart
                reportDataObject={props.reportDataObject}
                reportDataStatus={props.reportDataStatus}
                selectedResource={props.selectedResource}
              />
            </>
          )
        }
      ]} /> */}
      <Tabs defaultActiveKey='rsv_1'>
        <Tabs.TabPane tab='Resource' key='rsv_1'>
          <>
            {(() => {
              switch (props.connectionType) {
                case "Rest API":
                  return (
                    <RestApiSelector
                      getSelectedConnection={handleConnectionChange}
                      connections={props.connections.filter(
                        (rd) => rd.resoueceType === "Rest API"
                      )}
                      selectedResource={props.selectedResource}
                      dashboardConfig={props.dashboardConfig}
                      setSelected={handleSetSelectedResource}
                    />
                  );
                case "Combined Resource":
                  return (
                    <MultiResourceSelector
                      getSelectedConnection={handleConnectionChange}
                      connections={props.connections.filter(
                        (rd) => rd.resoueceType === "Rest API"
                      )}
                      selectedResource={props.selectedResource}
                      dashboardConfig={props.dashboardConfig}
                    />
                  );
                default:
                  return <div>blank widget</div>;
              }
            })()}
          </>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Raw Data' key='rsv_2'>
          <ResourceRowData
            reportDataObject={props.reportDataObject}
            reportDataStatus={props.reportDataStatus}
            selectedResource={props.selectedResource}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Filters' key='rsv_3'>
          <DataFilter
            reportDataObject={props.reportDataObject}
            reportDataStatus={props.reportDataStatus}
            selectedResource={props.selectedResource}
            connectionType={props.connectionType}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab='Builder' key='rsv_4'>
          <DataBuilder
            reportDataObject={props.reportDataObject}
            reportDataStatus={props.reportDataStatus}
            selectedResource={props.selectedResource}
            activeWidgetName={props.activeWidgetName}
            getSelectedConnection={props.getSelectedConnection}
          />
        </Tabs.TabPane>
        {props.activeWidgetName === 'table' ?
          <>
            <Tabs.TabPane tab='Grouping' key='rsv_5'>
              <Grouping
                reportDataObject={props.reportDataObject}
                reportDataStatus={props.reportDataStatus}
                selectedResource={props.selectedResource}
                updateSelectedFields={props.updateSelectedFields}
                activeWidgetName={props.activeWidgetName}
                getSelectedConnection={props.getSelectedConnection}
              />
            </Tabs.TabPane>
          </>
          : null}
        {props.activeWidgetName === 'chart' ?
          <Tabs.TabPane tab='Chart' key='6'>
            <Chart
              reportDataObject={props.reportDataObject}
              reportDataStatus={props.reportDataStatus}
              selectedResource={props.selectedResource}
            />
          </Tabs.TabPane>
          : null}
      </Tabs>

      {/* <Row gutter={6}>
                <Col span={16}><Input type="text" /></Col>
                <Col span={8}>

                </Col>
            </Row>
            <Divider /> */}
    </div>
  );
}
