import React from 'react';
import Select from 'react-select';
import "./common.css"

const CustomSelect = (props) => {

    {/* CustomSelect Props Explained 
        isMultiSelect - */}
    const { options, placeholder, defaultValue, getSelectedOptions, isMultiSelect, hasFormatLabel } = props;

    const generateRandomColor = () => {
        return (Math.floor(Math.random() * 16777215).toString(16));
    }

    const formatOptionLabel = ({ label, iconName }) => (
        <div style={{ display: 'flex', justifyContent: 'left ', alignItems: 'center' }}>
            {iconName == "" ?
                <div className='tag-letter-container' style={{ backgroundColor: `#${generateRandomColor()}` }}>
                    {Array.from(label)[0]}
                </div> : ""}
            <span>{label}</span>
        </div>
    );

    return (
        <Select
            isMulti={isMultiSelect ? true : false}
            defaultValue={defaultValue ? defaultValue : null}
            placeholder={placeholder}
            options={options}
            formatOptionLabel={hasFormatLabel ? formatOptionLabel : ""}
            onChange={(e) => getSelectedOptions(e)}
            {...props}
        />
    )
}

export default CustomSelect;