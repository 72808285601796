import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Input, Select } from 'antd';
import { updateSelectedResourceWidgetName } from '../../../../redux/editorSlice';

const { Option } = Select;

export default function RestApiSelector(props) {
    console.log("props", props)
    const dispatch = useDispatch()
    const [apiList, setApiList] = useState([]);
    const [widgetName, setWidgetName] = useState("");

    const selectedWidget = useSelector(state => state.editor?.selectedResource?.widgetName)

    useEffect(() => {
        if (props.connections && props.dashboardConfig) {
            if(props.dashboardConfig.isGlobal === true) {
                setApiList(props.connections.filter(c => c.isWitmegApi && c.visibleTo==="global").sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)))
            } else {
                setApiList(props.connections.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)))
            }
        }

    }, [props]);

    const handleConnectionChange = (resId) => {
        setWidgetName(() => "")
        return props.getSelectedConnection(resId)
    }

    const onHandleWidgetNameChange = e => {
        setWidgetName(() => e.target.value)
    };

    useEffect(() => {
        if (widgetName) {
            dispatch(updateSelectedResourceWidgetName({ id: props.selectedResource.id, widgetName }));
        }
    }, [widgetName, dispatch])

    return (
        <>
            {props.selectedResource && props.selectedResource.source ?
                < div >

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flexBasis: '30%', marginRight: '16px' }}>Resource</div>
                        {/* <Dropdown overlay={menu} trigger={['click']}>

                            <Input placeholder="Basic usage" onClick={e => e.preventDefault()} />

                        </Dropdown> */}
                        <Select style={{ width: '100%' }} value={props.selectedResource && props.selectedResource.source.id ? props.selectedResource.source.id : ''} onChange={(e) => handleConnectionChange(e)} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {
                                apiList.length && apiList.map((con, j) => {
                                    return (
                                        <Option key={"m_i_" + con.name} value={con.id}>
                                            {con.name}
                                        </Option>
                                    )
                                })
                            }

                        </Select>

                    </div>
                    {props?.dashboardConfig?.boardType === 'dashboard' ? (
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                            <div style={{ flexBasis: '30%', marginRight: '16px' }}>Widget Name</div>
                            <Input value={selectedWidget} placeholder='Enter the widget name..' onChange={(e) => onHandleWidgetNameChange(e)} />

                        </div>
                    ) : null}


                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                        <div style={{ flexBasis: '30%', marginRight: '16px' }}>API URL</div>
                        <div>{props.selectedResource.source && props.selectedResource.source.connectionData && props.selectedResource.source.connectionData.baseUrl}</div>
                    </div>

                </div >
                : null
            }
        </>

    )
}