import React, { useCallback, useEffect, useState } from "react";
import DashboardView from "../DashboardView";
import { Row, Col, Button, Modal, Input, message } from "antd";
import { Link } from "react-router-dom";
import GroupList from "./GroupList";
import AddBoardGroup from "../../components/BoardGoups/AddBoardGroup";
import jwt from "jsonwebtoken";
import { useSelector, useDispatch } from "react-redux";
import { resetGoupSavingState, getGroupsAsync, resetGroupDeletingState } from "../../redux/boardSlice";
import Loading from "../../components/common/Spinners/Loading";
import NoPermission from "../../components/common/NoPermission";

const {
    REACT_APP_OrgRegistryApi,
    REACT_APP_OrgjwtAud,
    REACT_APP_OrgjwtIss,
    REACT_APP_OrgjwtPrivate,
} = process.env;

export default function Groups() {
    const dispatch = useDispatch();
    const [isNewGropModalVisible, setNewGropModalVisibility] = useState(false);
    const [usersList, setUsers] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({});
    const [isEdit, setEdit] = useState(false);
    const [isViewOnly, setIsViewOnly] = useState(false);
    const [data, setData] = useState([]);

    const boards = useSelector((state) => state.boards);
    const user = useSelector((state) => state.user);

    const onSearchGroups = (value) => {
        console.log("Search Value ===>>", value);
        if (value) {
            let FilteredData = boards.groups?.filter(
                (item) =>
                    item.title.toLowerCase().includes(value.toLowerCase()) ||
                    item.groupType.toLowerCase().includes(value.toLowerCase())
            ).sort((a, b) => a.title < b.title);
            return setData((state) => [...FilteredData]);
        } else {
            return fetchGroups();
        }
    };

    const handleGroupSelect = (id) => {
        setSelectedGroup(boards.groups.find((group) => group._id === id));
    };

    const fetchGroups = useCallback(() => {
        dispatch(getGroupsAsync(user?.user?.accessToken));
        dispatch(resetGoupSavingState());
    }, [dispatch]);

    useEffect(() => {
        fetchGroups();
    }, [fetchGroups]);

    useEffect(() => {
        if (boards.groups) {
            setData(() => [...boards.groups]);
        }
    }, [boards.groups]);

    useEffect(() => {
        if (boards.groupDeletingState === "success") {
            message.success('Group has been successfully deleted!');
            dispatch(resetGroupDeletingState());
        }
    }, [boards.groupDeletingState, dispatch]);

    useEffect(() => {
        if (boards.groupSavingState === "success") {
            fetchGroups();
        }
    }, [boards.groupSavingState, fetchGroups]);

    // useEffect(() => {
    //     // if(user.currentAppPermissions.)
    //     if(user.currentAppPermissions && user.currentAppPermissions.indexOf("CreateGroup")===-1) {
    //         history.push('/')
    //     }
    // }, [user.currentAppPermissions])

    const GetUserDetails = async (UserDetails) => {
        const timestamp = Math.floor(Date.now() / 1000) + 1000;

        var payload = {
            aud: REACT_APP_OrgjwtAud,
            exp: timestamp,
            iss: REACT_APP_OrgjwtIss,
            usertoken: localStorage.getItem("accessToken"),
        };

        var signOptions = {
            algorithm: "RS256",
        };

        var privateKey = REACT_APP_OrgjwtPrivate;
        var token = jwt.sign(
            payload,
            privateKey.replace(/\\n/g, "\n"),
            signOptions
        );

        const result = [];

        const requestOptions2 = {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + token,
            },
        };

        //  console.log(UserDetails);

        return await fetch(
            REACT_APP_OrgRegistryApi + "user/" + UserDetails.UserID,
            requestOptions2
        )
            .then((response) => response.json())
            .then((data) => {
                if(data.Status) {
                    result.push({ ...UserDetails, UserDetails: data.Result });
                    return result[0];
                }
            })
            .catch((error) => {
                //window.location.href="/login/logout"
            });
    };

    useEffect(() => {
        // code refactor should be done => setting users in state should move to the redux later.
        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
            aud: REACT_APP_OrgjwtAud,
            exp: timestamp,
            iss: REACT_APP_OrgjwtIss,
            usertoken: localStorage.getItem("accessToken"),
        };

        var signOptions = {
            algorithm: "RS256",
        };

        var privateKey = REACT_APP_OrgjwtPrivate;
        var token = jwt.sign(
            payload,
            privateKey.replace(/\\n/g, "\n"),
            signOptions
        );

        const vrequestOptions = {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + token,
            },
        };

        fetch(
            `${REACT_APP_OrgRegistryApi}registeredusers/` +
            localStorage.getItem("orgID"),
            vrequestOptions
        )
            .then((lresponse) => lresponse.json())
            .then(async (odata) => {
                //  console.log(odata);
                const users = await Promise.all(
                    odata.Result.map(
                        async (UserID) => await GetUserDetails(UserID, odata.Result)
                    )
                );

                setUsers(users);
            });
    }, []);

    const setModalVisibility = (visibility, isEdit) => {
        if (!isEdit) {
            setSelectedGroup({});
        }
        setNewGropModalVisibility(visibility);
        setEdit(isEdit);
    };

    const handleSaveGroup = () => {
        setNewGropModalVisibility(false)
        fetchGroups();
    }

    // if(user.currentAppPermissions && user.currentAppPermissions.indexOf("CreateGroup")===-1) {
    //     return (
    //         <DashboardView>
    //             <NoPermission />
    //         </DashboardView>
    //     )
    // }

    return (
        <DashboardView>
            <Row>
                <Col span={4}>
                    <h2>Groups</h2>
                </Col>
                <Col span={2}></Col>
                <Col span={8}>
                    <Input
                        placeholder='Search Groups By Name OR Type'
                        onChange={(e) => onSearchGroups(e.target.value)}
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={4}></Col>
                <Col span={6}>
                    <Button
                        type='primary'
                        onClick={() => setModalVisibility(true, false)}
                    >
                        Add New
                    </Button>
                </Col>
            </Row>
            <div style={{ marginTop: "1rem" }}>
                {boards.groupsStatus === "pending" ? (
                    <Loading />
                ) : !data.length && boards.groupsStatus === "fulfilled" ? (
                    <div
                        style={{
                            position: "fixed",
                            bottom: "50%",
                            left: "42%",
                        }}
                    >
                        <h3>No Matching Groups Found...</h3>
                    </div>
                ) : (
                    <GroupList
                        groups={data.sort((a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0))}
                        setNewGropModalVisibility={(value) =>
                            setNewGropModalVisibility(value, true)
                        }
                        handleGroupSelect={handleGroupSelect}
                        setIsViewOnly={setIsViewOnly}
                    />
                )}
            </div>
            <Modal
                title='Add New Group'
                visible={isNewGropModalVisible}
                onOk={() => handleSaveGroup}
                onCancel={() => setNewGropModalVisibility(false)}
                footer={null}
                okText='Save Group'
                width={720}
            >
                <AddBoardGroup
                    users={usersList}
                    handleCloseModal={setNewGropModalVisibility}
                    selectedGroup={selectedGroup}
                    isEdit={isEdit}
                    isViewOnly={isViewOnly}
                />
            </Modal>
        </DashboardView>
    );
}
