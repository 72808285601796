import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { Row, Col } from 'antd';
import moment from "moment";
import _ from "lodash";
import { v4 } from "uuid";
import ReportGrid from "../../../components/ReportGrid";
import { getCurrentDate } from "../../../utils/date-time";
import { DATE_FORMAT } from "../../../constants/dateConstants";
import {
  getSumOfGroupByMultiFields,
  groupingByMultipleFields,
} from "../../../utils/group-helpers";

export default function BoardDataTableContainer(props) {
  const user = useSelector((state) => state.user);
  const [displayData, setDisplayData] = useState(
    props?.reportData?.length ? props.reportData : { data: [] }
  );
  const [fieldNames, setFieldNames] = useState();

  useEffect(() => {
    init();
    async function init() {
      if (
        props.aggregation &&
        props.aggregation.row &&
        props.aggregation.row.length > 0
      ) {
        let withAggr = [];
        let reportData = await JSON.parse(JSON.stringify(props.reportData))
          .data;

        Object.preventExtensions(reportData);

        reportData.forEach((cd, i) => {
          props.aggregation.row.forEach((ag, j) => {
            switch (ag.rowAggregation[0].operator) {
              case "add_c":
                if (ag.rowAggregation[0].fieldName) {
                  reportData[i][ag?.displayName] = Number(
                    Number(cd[ag.rowAggregation[0].fieldName]) +
                      Number(ag.rowAggregation[0].const_1)
                  ).toFixed(2);
                  return;
                }
                break;
              case "multiply_c":
                if (ag.rowAggregation[0].fieldName) {
                  reportData[i][ag?.displayName] = (
                    Number(cd[ag.rowAggregation[0].fieldName]) *
                    Number(ag.rowAggregation[0].const_1)
                  ).toFixed(2);
                  return;
                }
                break;
              case "sum":
                if (ag.rowAggregation[0].fieldNamesList) {
                  reportData[i][ag?.displayName] = Number(
                    ag.rowAggregation[0].fieldNamesList &&
                      ag.rowAggregation[0].fieldNamesList.reduce((a, b) => {
                        return Number(a) + Number(reportData[i][b]);
                      }, 0)
                  ).toFixed(2);
                  return;
                }
                break;
              case "avg":
                if (ag.rowAggregation[0].fieldNamesList) {
                  reportData[i][ag?.displayName] = (
                    ag.rowAggregation[0].fieldNamesList.reduce((a, b) => {
                      return a + Number(reportData[i][b]);
                    }, 0) / ag.rowAggregation[0].fieldNamesList.length
                  ).toFixed(2);
                  return;
                }
                break;
              case "func":
                if (ag.rowAggregation[0].calculationStr) {
                  let fcn = ag.rowAggregation[0].calculationStr
                    .replaceAll(" ", "")
                    .replaceAll("@", "Number(cd['")
                    .replaceAll("$", "'])");
                  reportData[i][ag?.displayName] = Number(eval(fcn)).toFixed(2);
                  return;
                }
                break;
              default:
                return reportData[i];
            }
          });
        });

        let availableFieldsSet = props?.availableFields
          ?.filter((x) => x.checked)
          ?.map((af, i) => {
            return {
              title: af.fieldName,
              dataIndex: af.fieldName,
              key: af.fieldName,
              dataType: af.dataType,
              format: af.format,
              fieldDisplayName : af.fieldDisplayName || null
            };
          });

        props?.aggregation?.row?.forEach((a, k) => {
          availableFieldsSet.push({
            title: a.displayName,
            dataIndex: a.displayName,
            key: a.displayName,
            isAggrCol: true,
            className: "aggr-col",
          });
        });

        setFieldNames(availableFieldsSet);
        setDisplayData({ data: reportData });
        withAggr = reportData;

        if (props?.dataFilters?.length > 0 && displayData?.data) {
          let updateData = withAggr;

          for (let i = 0; i < props.dataFilters.length; i++) {
            const selectedFieldName = props.dataFilters[i].fieldName;
            const format  = props?.availableFields?.find(
              (f) => f?.fieldName === selectedFieldName
            )?.format;

            if (props.dataFilters[i].value) {
              if (props.dataFilters[i].filterType === "gt") {
                if (props.dataFilters[i].dataType === "date") {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                      moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                        format || DATE_FORMAT
                      )).isAfter(moment(props.dataFilters[i]["value"]).format(
                        format || DATE_FORMAT
                      ))
                    ),
                  });
                } else {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                        Number(rd[props.dataFilters[i]["fieldName"]]) >
                        Number(props.dataFilters[i]["value"])
                    ),
                  });
                }
              } else if (props.dataFilters[i].filterType === "gte") {
                if (props.dataFilters[i].dataType === "date") {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                      moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                        format || DATE_FORMAT
                      )).isSameOrAfter(moment(props.dataFilters[i]["value"]).format(
                        format || DATE_FORMAT
                      ))
                    ),
                  });
                } else {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                        Number(rd[props.dataFilters[i]["fieldName"]]) >=
                        Number(props.dataFilters[i]["value"])
                    ),
                  });
                }
              } else if (props.dataFilters[i].filterType === "lt") {
                if (props.dataFilters[i].dataType === "date") {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                      moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                        format || DATE_FORMAT
                      )).isBefore(moment(props.dataFilters[i]["value"]).format(
                        format || DATE_FORMAT
                      ))
                    ),
                  });
                } else {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                        Number(rd[props.dataFilters[i]["fieldName"]]) <
                        Number(props.dataFilters[i]["value"])
                    ),
                  });
                }
              } else if (props.dataFilters[i].filterType === "lte") {
                if (props.dataFilters[i].dataType === "date") {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                      moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                        format || DATE_FORMAT
                      )).isSameOrBefore(moment(props.dataFilters[i]["value"]).format(
                        format || DATE_FORMAT
                      ))
                    ),
                  });
                } else {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                        Number(rd[props.dataFilters[i]["fieldName"]]) <=
                        Number(props.dataFilters[i]["value"])
                    ),
                  });
                }
              } else if (props.dataFilters[i].filterType === "between") {
                if (props.dataFilters[i].dataType === "number") {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                        Number(rd[props.dataFilters[i]["fieldName"]]) >
                          Number(props.dataFilters[i]["value1"]) &&
                        Number(rd[props.dataFilters[i]["fieldName"]]) <
                          Number(props.dataFilters[i]["value2"])
                    ),
                  });
                } else if (props.dataFilters[i].dataType === "date") {
                  setDisplayData({
                    data: updateData.filter(
                      (rd) =>
                        moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                          format || DATE_FORMAT
                        )).isBetween(
                            moment(props.dataFilters[i]["value1"]).subtract(1, "days").format(format || DATE_FORMAT),
                            moment(props.dataFilters[i]["value2"]).add(1, "days").format(format || DATE_FORMAT)
                        )
                    ),
                  });
                }
              } else {
                // console.log("PROPS dataFilters Received , eq con", updateData[0][props.dataFilters[i]['fieldName']], props.dataFilters[i]['value'])
                 const getFilterdData = () => {
                  if (props.dataFilters[i].dataType === "date"){
                    return updateData.filter(
                      (rd) =>
                      moment(rd[props.dataFilters[i]["fieldName"]]).format(format || DATE_FORMAT) ===
                      moment(props.dataFilters[i]["value"]).format(format || DATE_FORMAT)
                    );
                  }else{
                    return updateData.filter(
                      (rd) =>
                        String(rd[props.dataFilters[i]["fieldName"]]) ===
                        String(props.dataFilters[i]["value"])
                    );
                  }
                 }
                
               
                setDisplayData({ data: getFilterdData() });
              }
            }
          }
        } else {
          setDisplayData({ data: reportData });
        }
      } else {
        let availableFieldsSet = props?.availableFields
          ?.filter((x) => x.checked)
          ?.map((af, i) => {
            return {
              title: af.fieldName,
              dataIndex: af.fieldName,
              key: af.fieldName,
              dataType: af.dataType,
              format: af.format,
              fieldDisplayName : af.fieldDisplayName || null
            };
          });
        if (
          (props.selectedResource === null ||
            (props.selectedResource.connectionData &&
              props.selectedResource.connectionData.uid ===
                props.connectionData.uid)) &&
          props.dataFilters &&
          props.dataFilters.length > 0 &&
          displayData &&
          displayData.data
        ) {
          let updateData = [...displayData["data"]];

          for (let i = 0; i < props.dataFilters.length; i++) {
            const selectedFieldName = props.dataFilters[i].fieldName;
            const format  = props?.availableFields?.find(
              (f) => f?.fieldName === selectedFieldName
            )?.format;
            if (props.dataFilters[i].value) {
              if (props.dataFilters[i].filterType === "gt") {
                if (props.dataFilters[i].dataType === "date") {
                  updateData = updateData.filter(
                    (rd) =>
                      moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                        format || DATE_FORMAT
                      )).isAfter(moment(props.dataFilters[i]["value"]).format(
                        format || DATE_FORMAT
                      ))
                  );
                  setDisplayData({
                    data: updateData,
                  });
                } else {
                  updateData = updateData.filter(
                    (rd) =>
                      Number(rd[props.dataFilters[i]["fieldName"]]) >
                      Number(props.dataFilters[i]["value"])
                  );
                  setDisplayData({
                    data: updateData,
                  });
                }
              } else if (props.dataFilters[i].filterType === "gte") {
                if (props.dataFilters[i].dataType === "date") {
                  updateData = updateData.filter(
                    (rd) =>
                      moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                        format || DATE_FORMAT
                      )).isSameOrAfter(moment(props.dataFilters[i]["value"]).format(
                        format || DATE_FORMAT
                      ))
                  );
                  setDisplayData({
                    data: updateData,
                  });
                } else {
                  updateData = updateData.filter(
                    (rd) =>
                      Number(rd[props.dataFilters[i]["fieldName"]]) >=
                      Number(props.dataFilters[i]["value"])
                  );
                  setDisplayData({
                    data: updateData,
                  });
                }
              } else if (props.dataFilters[i].filterType === "lt") {
                if (props.dataFilters[i].dataType === "date") {
                  updateData = updateData.filter(
                    (rd) =>
                      moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                        format || DATE_FORMAT
                      )).isBefore(moment(props.dataFilters[i]["value"]).format(
                        format || DATE_FORMAT
                      ))
                  );
                  setDisplayData({
                    data: updateData,
                  });
                } else {
                  updateData = updateData.filter(
                    (rd) =>
                      Number(rd[props.dataFilters[i]["fieldName"]]) <
                      Number(props.dataFilters[i]["value"])
                  );
                  setDisplayData({
                    data: updateData,
                  });
                }
              } else if (props.dataFilters[i].filterType === "lte") {
                if (props.dataFilters[i].dataType === "date") {
                  updateData = updateData.filter(
                    (rd) =>
                      moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                        format || DATE_FORMAT
                      )).isSameOrBefore(moment(props.dataFilters[i]["value"]).format(
                        format || DATE_FORMAT
                      ))
                  );
                  setDisplayData({
                    data: updateData,
                  });
                } else {
                  updateData = updateData.filter(
                    (rd) =>
                      Number(rd[props.dataFilters[i]["fieldName"]]) <=
                      Number(props.dataFilters[i]["value"])
                  );
                  setDisplayData({
                    data: updateData,
                  });
                }
              } else if (props.dataFilters[i].filterType === "between") {
                if (props.dataFilters[i].dataType === "number") {
                  updateData = updateData.filter(
                    (rd) =>
                      Number(rd[props.dataFilters[i]["fieldName"]]) >
                        Number(props.dataFilters[i]["value1"]) &&
                      Number(rd[props.dataFilters[i]["fieldName"]]) <
                        Number(props.dataFilters[i]["value2"])
                  );
                  setDisplayData({
                    data: updateData,
                  });
                } else if (props.dataFilters[i].dataType === "date") {
                  updateData = updateData.filter(
                    (rd) =>
                    moment(moment(rd[props.dataFilters[i]["fieldName"]]).format(
                      format || DATE_FORMAT
                    )).isBetween(
                        moment(props.dataFilters[i]["value1"]).subtract(1, "days").format(format || DATE_FORMAT),
                        moment(props.dataFilters[i]["value2"]).add(1, "days").format(format || DATE_FORMAT)
                      )
                  );
                  setDisplayData({
                    data: updateData,
                  });
                }
              } else if (props.dataFilters[i].filterType === "con") {

                updateData = updateData.filter(
                  (rd) =>
                    rd[props.dataFilters[i]["fieldName"]].toLowerCase().includes(props.dataFilters[i]["value"])
                );

                setDisplayData({
                  data: updateData,
                });
              } else {
                const getFilterdData = () => {
                  if (props.dataFilters[i].dataType === "date"){
                    return updateData.filter(
                      (rd) =>
                      moment(rd[props.dataFilters[i]["fieldName"]]).format(format || DATE_FORMAT) ===
                      moment(props.dataFilters[i]["value"]).format(format || DATE_FORMAT)
                    );
                  }else{
                    return updateData.filter(
                      (rd) =>
                        String(rd[props.dataFilters[i]["fieldName"]]) ===
                        String(props.dataFilters[i]["value"])
                    );
                  }
                 }
                 if (props?.aggGroupBuilders) {
                  const { data, fields } = getGroupedData(getFilterdData());
                  console.log("DATA===>>", data)
                  setDisplayData({data});
                  setFieldNames(availableFieldsSet);
          
                } else {
                  setDisplayData({ data: getFilterdData() });
                  setFieldNames(availableFieldsSet);
                }
              }
            }
          }
        } else if (
          props.selectedResource === null ||
          props.dataFilters.length === 0
        ) {
          // setDisplayData({data: ""});
          if (props?.aggGroupBuilders && props?.aggGroupBuilders.length>0) {
            const { data, fields } = getGroupedData(props?.reportData?.data);
            console.log("DATA===>>", data)
            setDisplayData({data});
            setFieldNames(availableFieldsSet);
    
          } else {
          setDisplayData({data: props.reportData.data});
          setFieldNames(availableFieldsSet);
          }
        }
      }

      // if (props?.aggGroupBuilders) {
      //   const { data, fields } = getGroupedData(displayData.data);
      //   console.log("DATA===>>", data)
      //   setDisplayData({data: data});

      // }
    }
  }, [props]);


  

  const getGroupedData = (reportData) => {
    console.log("REPORT DATA===>>", reportData)
    let groupByKeys = props.aggGroupBuilders?.fieldNamesList ?? [];
    let sumKeys = props.aggGroupBuilders?.sumFieldNamesList ?? [];
    let result = getSumOfGroupByMultiFields(reportData, groupByKeys, sumKeys);
    let count = groupingByMultipleFields(reportData, function (item) {
      return groupByKeys?.map((key) => item[key]);
    });

    result = result?.map((obj) =>
      Object.fromEntries(
        Object.entries(obj)?.filter(
          (o) =>
            props.aggGroupBuilders?.displayFieldNamesList?.includes(o[0]) ||
            props.aggGroupBuilders?.uniqueFieldNamesList?.includes(o[0])
        )
      )
    );

    let columns = Object.keys(result[0]).map((val, i) => ({
      id: i,
      fieldName: val,
      checked:
        props.availableFields?.find(
          (af) => af.fieldName === val
        )?.checked ?? true,
      dataType: props.allFields?.find(
        (af) => af.fieldName === val
      )?.dataType,
      grouped: true,
    }));

    if(props.aggGroupBuilders?.operator === 'sum&count' || props.aggGroupBuilders?.operator === 'count') {
      count = count?.map((c) => c?.length);

      columns.push({
        id: v4(),
        fieldName: "Count",
        checked:
          props.availableFields?.find(
            (af) => af.fieldName === "Count"
          )?.checked ?? true,
        dataType: "number",
        grouped: true,
      });
  
      result = result.map((res, i) => ({ ...res, 'Count': count[i] }))
  
      return {
        data: result?.length ? result : [],
        fields: columns?.length ? columns : [],
      };
    } else {
      return {
        data: result?.length ? result : [],
        fields: columns?.length ? columns : [],
      };
    }
  };
  
  return (
    <div style={{ background: "#fff" }} className='board-datatable-container'>
      <h5 style={{ padding: "20px", fontSize: "1rem" }}>
        {props?.widgetName ?? ""}
      </h5>
      <>
        {
          <div>
            {fieldNames?.length &&
            displayData?.data?.length &&
            props?.availableFields?.length ? (
              !props?.aggRowBuilders?.length ? (
                <ReportGrid
                  reportData={displayData}
                  availableFields={fieldNames}
                  aggGroupBuilders={props.aggGroupBuilders}
                />
              ) : (
                <ReportGrid
                  reportData={displayData}
                  availableFields={fieldNames}
                  aggRowBuilders={props.aggRowBuilders}
                  aggGroupBuilders={props.aggGroupBuilders}
                />
              )
            ) : (
              <div>No Data</div>
            )}
          </div>
        }
      </>
      <div
        style={{
          padding: "20px",
          paddingTop: "8px",
          fontSize: "0.9rem",
          textAlign: "left"
        }}
      >
        {user?.user?.username && (
          <div style={{display:'flex'}}>
            <h5>Created By :&nbsp;</h5>
            <h5>{user?.user?.username ?? ""}</h5>
          </div>
        )}
        {props?.createdAt && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <h5>Created Date</h5>
              <h5>
                {props?.createdAt
                  ? getCurrentDate("-", new Date(props.createdAt))
                  : ""}
              </h5>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <h5>Created Time</h5>
              <h5>
                {props?.createdAt
                  ? new Date(props.createdAt).toLocaleTimeString()
                  : ""}
              </h5>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
