import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import jwt from "jsonwebtoken";
import moment from "moment";
import ResourceList from "./ResourceList";
import ResourceView from "./ResourceView";
import ResourceFilters from "./ResourceFilters";
import { getResourcesAsync } from "../../../redux/resourceSlice";
import {
  addResouece,
  removeResource,
  updateResourceSource,
  updateSelectedResource,
  updateSelectedFields,
  reOrderFields,
} from "../../../redux/editorSlice";
import { useParams } from "react-router";

export default function ResourceEditor(props) {
  const [selectedResource, setSelectedResource] = useState(null);
  const [reportDataStatus, setReportDataStatus] = useState("default");
  const [recordId, setRecordId] = useState(null)
  const dispatch = useDispatch();
  const {selectedId} = useParams();
  const resources = useSelector((state) => state.resources);
  const editorState = useSelector((state) => state.editor);
  const [loadingFields, setLoadingFields] = useState(false)
  // const userData = useSelector(state => state.user);
  const fetchResources = useCallback(() => {
    dispatch(getResourcesAsync());
  }, [dispatch]);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  useEffect(() => {
    if(selectedId && recordId !== selectedId){
      setSelectedResource(editorState.selectedResource)
    }
  }, [selectedId, editorState.selectedResource])

  const handleAddResource = (resourceType) => {
    console.log("resourceType====>>", resourceType);
    dispatch(addResouece(resourceType));
  };

  const getSelectedConnection = async (data) => {
    if (typeof data !== "object") {
      let sellectedRes = Object.assign(
        JSON.parse(JSON.stringify(selectedResource))
      );
      sellectedRes.source = resources.resourceData.find((r) => r.id === data);
      setSelectedResource({
        ...sellectedRes,
        availableFields: sellectedRes?.availableFields?.map((af) => ({
          ...af,
          checked: true,
        })),
      });
      await fetchReportData();
    } else {
      console.log("Resources", data);
    }

    //getSelectedResourcesAsync
    // dispatch(updateResourceSource({ id: sellectedRes.id, type: sellectedRes.type, source: resources.resourceData.find(r => r.id === id) }))
  };

  const handleSetSelectedResource = async (data) => {
    setSelectedResource(data);
    // if (data.source && data.source.id) {
    //     dispatch(updateSelectedResource(data))
    // }
    console.log("handleSetSelectedResource", data);
    dispatch(updateSelectedResource(data));
  };

  async function fetchReportData() {
    console.log(" fetchReportData===>>>>", resources)
    if (
      selectedResource &&
      selectedResource.source &&
      selectedResource.source.resoueceType === "Rest API"
    ) {
      setLoadingFields(true)
      // const response = await axios.get(resources.selectedResource.connectionData.baseUrl);
      // console.log(" fetchReportData===>>>>", selectedResource.source)
      try {
        const requestValues =
          selectedResource.source.connectionData.requestValues;
        const authInfo =
          selectedResource &&
          selectedResource.source.connectionData &&
          selectedResource.source.connectionData.authInfo;
        let urlParamSring = "";
        if (requestValues.urlParams.length > 0) {
          urlParamSring = urlParamSring + "?";
          for (let i = 0; i < requestValues.urlParams.length; i++) {
            if (
              requestValues.urlParams[i]["key"] !== "" &&
              requestValues.urlParams[i]["value"] !== ""
            ) {
              urlParamSring =
                urlParamSring +
                requestValues.urlParams[i]["key"] +
                "=" +
                requestValues.urlParams[i]["value"] +
                "&";
            }
          }
          urlParamSring.slice(0, -1);
        }

        let headerObj = {};
        if (requestValues.requestHeaders.length > 0) {
          for (let i = 0; i < requestValues.requestHeaders.length; i++) {
            if (
              requestValues.requestHeaders[i]["key"] !== "" &&
              requestValues.requestHeaders[i]["value"] !== ""
            ) {
              headerObj[requestValues.requestHeaders[i]["key"]] =
                requestValues.requestHeaders[i]["value"];
            }
          }
        }

        let bodyParamObj = {};
        // if (requestValues.authParams) {
        //     bodyParamObj = Object.assign({}, authParams);
        // }
        if (requestValues.bodyParams.length > 0) {
          for (let i = 0; i < requestValues.bodyParams.length; i++) {
            if (
              requestValues.bodyParams[i]["key"] !== "" &&
              requestValues.bodyParams[i]["value"] !== ""
            ) {
              bodyParamObj[requestValues.bodyParams[i]["key"]] =
                requestValues.bodyParams[i]["value"];
            } else if ( requestValues.bodyParams[i]["key"] !== "" && requestValues.bodyParams[i]["dataType"] === "date") {
              if(requestValues.bodyParams[i]["dateConfig"]) {
                let dateCf = requestValues.bodyParams[i]["dateConfig"];
                if(dateCf.dateOption === "daysBefore") {
                  let dateVal = moment.utc().subtract(dateCf.timeUnits, 'day').startOf('day').format(dateCf.dateFormat);
                  bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
                } else if (dateCf.dateOption === "today") {
                  let dateVal = moment().format(dateCf.dateFormat);
                  bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
                }
              }
            }
          }
        }
        // // await Axios.get(resources.selectedResource.connectionData.baseUrl)
        setReportDataStatus("pending");
        if (authInfo.method === "jwt") {
          let userToken=localStorage.getItem('accessToken');
          console.log("JWT PROCESS");
          console.log("userToken ===>>", userToken);
          const timestamp = (new Date().getTime() + 1000 * 60 * 60 *24 *7) / 1000;
          const privateKey = authInfo.parameters.privateKey;
          var payload = {
            aud: authInfo.parameters.aud,
            exp: timestamp,
            iss: authInfo.parameters.iss,
            userToken: userToken
          };
          var signOptions = {
            algorithm: "RS256",
          };

          var privateKeyVal = privateKey
            ? privateKey.replace(/\\n/g, "\n")
            : null;

          if (privateKeyVal) {
            try {
              var token = jwt.sign(payload, privateKeyVal, signOptions);
              console.log("Generated JWT authParams=====>>", token);
              // setApiTestStatus("success")

              await Axios({
                method: requestValues.httpMethod,
                url:
                  selectedResource.source.connectionData.baseUrl +
                  urlParamSring,
                data: JSON.parse(JSON.stringify(bodyParamObj)),
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ` + token,
                },
              })
                .then((response) => {
                  console.log(response.data);
                  setReportDataStatus("success");
                  const reportData = response.data ? response.data : response;
                  dispatch(
                    updateResourceSource(
                      Object.assign(selectedResource, {
                        reportData: reportData,
                      })
                    )
                  );
                })
                .catch((error) => {
                  setReportDataStatus("error");
                });
            } catch (e) {
              setReportDataStatus("error");
            }
          }
        } else {
          let httpReqConfig = {
            method:
              selectedResource.source.connectionData.requestValues.httpMethod,
            url: selectedResource.source.connectionData.baseUrl + urlParamSring,
          };

          if (requestValues.bodyParams.length > 0) {
            httpReqConfig.data = bodyParamObj;
          }
          if (requestValues.requestHeaders.length > 0) {
            httpReqConfig.headers = headerObj;
          }
          Axios(httpReqConfig)
            .then((response) => {
              // console.log(response);
              setReportDataStatus("success");
              // setReportDataObject(response.data ? response.data : response)
              const reportData = response.data ? response.data : response;
              dispatch(
                updateResourceSource(
                  Object.assign(selectedResource, { reportData: reportData })
                )
              );
            })
            .catch((error) => {
              setReportDataStatus("error");
            });
        }
      } catch (error) {
        setReportDataStatus("error");

      }
    }
  }

  function handleUpdateSelectedFields(data) {
    dispatch(updateSelectedFields(data));
  }

  function handleReOrderFields(data) {
    dispatch(reOrderFields(data));
  }

  function handleRemoveResourceItem(item) {
    dispatch(removeResource(item));
  }

  // useEffect(() => {
  //   fetchReportData();
  // }, [selectedResource]);

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        paddingTop: "8px",
        borderTop: "2px solid #eeeeee",
      }}
    >
      <Row>
        <Col span={4} style={{ borderRight: "1px rgb(238, 238, 238) solid" }}>
          <ResourceList
            resourceList={
              editorState.resourceList ? editorState.resourceList : []
            }
            addNewResource={handleAddResource}
            selectedResource={
              editorState.selectedResource ? editorState.selectedResource : {}
            }
            setSelected={handleSetSelectedResource}
            removeResourceItem={handleRemoveResourceItem}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={12}>
          {selectedResource && selectedResource.id ? (
            <ResourceView
              connections={resources.resourceData}
              connectionType={selectedResource.type}
              selectedResource={
                editorState.selectedResource ? editorState.selectedResource : {}
              }
              dashboardConfig={
                editorState.dashboardConfig ? editorState.dashboardConfig : {}
              }
              getSelectedConnection={getSelectedConnection}
              reportDataObject={editorState.selectedResource.reportData}
              reportDataStatus={reportDataStatus}
              updateSelectedFields={handleUpdateSelectedFields}
              activeWidgetName={props.activeWidgetName}
              setSelected={handleSetSelectedResource}
              selected={selectedResource}
            />
          ) : null}
        </Col>
        <Col span={1}></Col>
        <Col span={6} style={{ borderLeft: "1px rgb(238, 238, 238) solid" }}>
          <ResourceFilters
            reportDataObject={editorState?.selectedResource?.reportData || null}
            availableFields={
              editorState?.selectedResource?.availableFields || null
            }
            updateSelectedFields={handleUpdateSelectedFields}
            reOrderFields={handleReOrderFields}
            reportDataStatus={reportDataStatus}
          />
        </Col>
      </Row>
    </div>
  );
}
