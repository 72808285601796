import React, { useEffect, useState } from "react";
import { v4 } from 'uuid'
import { Row, Col, Select, Button, Input, Typography, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { returnDataArrayObject } from "../../../../../utils/arrayHelper";
import NotFound from "../../../../../components/NotFound";
import { updateAggRowBuilders, updateSelectedFields } from "../../../../../redux/editorSlice";
import ColBuilder from "./ColBuilder";
const { Option } = Select;

export default function DataBuilder(props) {
  const [filters, setFilters] = useState([]);
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();


  const setFilterItem = () => {
    setFilters(f => [...f, {
      id: v4(),
      dataType: "text",
      aggType: "sum",
    }]);
  };

  const deleteFilterItem = (item) => {
    setFilters(filters.filter((f) => f.id !== item.id));
  };

  const updateFilter = (item, key, val) => {
    let updatedFilter = filters.map((f) => {
      return f.id === item.id ? {
        id: f.id,
        displayName: key === "displayName" ? val : f.displayName,
        aggType: key === "aggType" ? val : f.aggType,
        fieldName: key === "fieldName" ? val : f.fieldName,
      } : { ...f };
    });
    setFilters(updatedFilter);
  };

  const applyFilters = (filterArray) => {
    dispatch(updateAggRowBuilders(filterArray));
    message.info("Builders have been successfully applied!");
  };

  const clearBuilder = () => {
    setFilters(s => []);
  }

  useEffect(() => {
    let availableFieldsSet =
      props?.selectedResource?.availableFields?.map((af) => af);

      props?.selectedResource?.aggregation?.row?.forEach((a, k) => {
        availableFieldsSet.push({
          fieldName: a.displayName,
          checked: true,
          id: Math.floor(Math.random() * 10000) + 1,
          dataType: "number"
        });
      });
    setFields(availableFieldsSet);

    if(( props?.selectedResource?.aggregation?.row && props?.selectedResource?.aggregation?.row.length>0)){
      setFilters([]);
    }

    const rows = props.selectedResource?.aggRowBuilders || [];
    if(rows.length>0){
      let currentBuilders = rows.map((bd) => {
        return {
              id: bd.id,
              displayName: bd.displayName,
              fieldName: bd.fieldName,
              aggType: bd.aggType
            };
      });
      setFilters(currentBuilders);
    }
  }, [props]);

  return (
    <div style={{ overflowY: "auto", height: "30vh" }}>
      {props.activeWidgetName === "table" ? (
        <>
          <Typography.Title level={4}>Row Builder</Typography.Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            {returnDataArrayObject(props.reportDataObject) ? (
              <div style={{ width: "100%" }}>
                {filters.map((fi) => {
                  return (
                    <Row
                      key={"fil_" + fi.id}
                      gutter={16}
                      style={{ marginTop: "16px" }}
                    >
                      <Col span={6}>
                        <div>Display Name:</div>
                        <div>
                          <Input
                            onChange={(e) =>
                              updateFilter(fi, "displayName", e.target.value)
                            }
                            value={fi.displayName}
                          />
                        </div>
                      </Col>

                      <Col span={6}>
                        <div>Aggregate Type:</div>
                        <div>
                          <Select
                            style={{ width: "90%" }}
                            placeholder='Select Data Field'
                            defaultValue={'sum'}
                            onChange={(val) => updateFilter(fi, "aggType", val)}
                          >
                            <Option value='sum' key='sum'>
                              Sum
                            </Option>
                            <Option value='average' key='average'>
                              Average
                            </Option>
                            <Option value='minimum' key='minimum'>
                              Minimum
                            </Option>
                            <Option value='maximum' key='maximum'>
                              Maximum
                            </Option>
                            <Option value='count' key='count'>
                              Count
                            </Option>
                            <Option
                              value='standarddeviation'
                              key='standarddeviation'
                            >
                              Standard deviation
                            </Option>
                            <Option value='variance' key='variance'>
                              Variance
                            </Option>
                            <Option value='median' key='median'>
                              Median
                            </Option>
                          </Select>
                        </div>
                      </Col>

                      <Col span={8}>
                        <div>Field Name:</div>
                        <div>
                          <Select
                            showSearch
                            style={{ width: "90%" }}
                            placeholder='Select Data Field'
                            value={fi.fieldName}
                            onChange={(val) =>
                              updateFilter(fi, "fieldName", val)
                            }
                          >
                            {props.selectedResource &&
                              props.selectedResource.availableFields &&
                              fields?.map((af, j) => {
                                return (
                                  <Option
                                    value={af.fieldName}
                                    key={fi.id + "_" + j}
                                  >
                                    {af.fieldName}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </Col>

                      <Col span={1}>
                        <div>&nbsp;</div>
                        <div>
                          <Button
                            type='text'
                            icon={<CloseOutlined />}
                            onClick={() => deleteFilterItem(fi)}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })}

                <Row
                  style={{ marginTop: "16px", marginBottom: "32px" }}
                  gutter={16}
                >
                  <Col span={4}>
                    <Button onClick={setFilterItem}>Add New +</Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      type='primary'
                      onClick={() => applyFilters(filters)}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <NotFound />
              </div>
            )}
          </div>

          <hr />
        </>
      ) : null}

      <Typography.Title level={4}>Column Builder</Typography.Title>
      <div>
        <ColBuilder
          reportDataObject={props.reportDataObject}
          selectedResource={props.selectedResource}
          availableFields={fields}
          updateAggRowBuilders={updateAggRowBuilders}
          resetBuilders={clearBuilder}
        />
      </div>
     
    </div>
  );
}
