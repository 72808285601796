import React, { Component } from "react";
import { DatabaseOutlined, GlobalOutlined } from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReportWidjets from "./ReportWidjets";
import Filters from "./Filters/index";
import {
  Row,
  Col,
  Select,
  Typography,
  Skeleton,
  Tabs,
  Button,
  Popconfirm,
  Space,
  Popover,
  message,
  Collapse
} from "antd";
import ReactFullscreen from "react-easyfullscreen";
// import BottomNavigation from '@material-ui/core/BottomNavigation';
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { addDashboardConfig } from '../../redux/editorSlice';
import { getResourcesAsync } from "../../redux/resourceSlice";
import {
  getBoardsAsync,
  updateSelectedBoard,
  getBoardByIdAsync,
  deleteBoardByIdAsync,
  resetBoardData,
  resetAllBoardData,
} from "../../redux/boardSlice";

import CustomFilters from "./Filters/CustomFilters";
import "./dashboardStyles.css";
import NotFound from "../../components/NotFound";
import DashboardView from "../DashboardView";
import MaterialDesignIcon from "../../components/common/MaterialDesignIcon";
const { Option } = Select;
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: [],
      value: "",
      timce: 100000000,
      anchorEl: null,
      setAnchorEl: null,
      visible: "invisible",
      selectedResource: null,
    };
    this.handleBannerChange = this.handleBannerChange.bind(this);

    //  this.handleClose = this.handleClose.bind(this);
  }

  handleClose(event, newValue) {
    this.setState({
      visible: "invisible",
      value: "",
    });
  }

  handleBannerChange(event, newValue) {
    //  console.log(event.currentTarget)
    if (newValue === "fullscreen") {
      this.setState({
        visible: "visible",
        value: newValue,
      });
    } else if (newValue === "refresh") {
      this.setState({
        anchorEl: true,
        // setAnchorEl: event.currentTarget
      });
    }
  }

  componentDidMount() {
    // console.log(this.state.time)

    // if (!this.props.boards.selectedBoard) {
    this.props.getBoardByIdAsync({ id: this.props.match.params.boardId });
    // }

    // this.props.getResources()

    this.setState({
      visible: "invisible",
    });

    if (localStorage.getItem("setTimeRefresh")) {
      setInterval(
        () => window.location.reload(false),
        localStorage.getItem("setTimeRefresh")
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.match.params.boardId) {
    //   this.props.getBoardByIdAsync({ id: this.props.match.params.boardId });
    // }
    if (prevProps.match.params.boardId !== this.props.match.params.boardId) {
      this.props.getBoardByIdAsync({ id: this.props.match.params.boardId });
    }
    if (this.props.boards && this.props.boards.boardDeleteState === "success") {
      this.props.resetBoardData();
      this.props.history.push("/");
    }
  }

  componentWillUnmount() {
    console.log("UNMOUNTING===>>", this.props.match);
    // this.props.resetAllBoardData()
  }

  onApplyFilterToResource = (resource) => {
    this.props.updateSelectedBoard({
      ...this.props.boards.selectedBoard,
      resources: [resource],
    });
    console.log("APPLY FILTERS TO ====>>>", resource);
    setTimeout(() => {
      this.setState({
        selectedResource: resource,
      });
    }, 100);

  };

  onApplyDataFilterToResource = (resource) => {
    console.log("APPLY DATA FILTERS TO ====>>>", resource);
    this.setState({
      selectedResource: null,
    });
    setTimeout(() => {
      this.setState({
        selectedResource: resource,
      });
      message.info("Filters have been applied!")
    }, 100);
  };

  onResetFilterToResource = (resource) => {
    console.log("APPLY DATA FILTERS TO ====>>>", resource);
    this.setState({
      selectedResource: { ...resource, dataFilters: [] },
    });
    message.info("Filters have been removed!")
  };

  handleLogOut = () => {
    this.props.logOut();
  };

  confirmDeleteBoard = () => {
    this.props
      .deleteBoardById(this.props.match.params.boardId)
      .then(() => message.success("Board deleted successfully!"))
      .catch((err) => message.error("Oops, Board is not deleted!"));
  };

  render() {
    const handleInterval = (_event) => {
        console.log("_event.target.value", _event.target.dataset)
      localStorage.setItem("setTimeRefresh", Number(_event.target.dataset.value) * 1000);
      if (localStorage.getItem("setTimeRefresh")) {
        setInterval(
          () => window.location.reload(false),
          Number(localStorage.getItem("setTimeRefresh"))
        );
      } else {
        setInterval(
          () => window.location.reload(false),
          Number(_event.target.dataset.value) * 1000
        );
      }

      this.setState({
        anchorEl: false,
      });
    };

    return (
      <DashboardView>
        <>
          <Row>
            <Col span={20}>
              <Title level={5}>
                {this.props.boards.selectedBoard &&
                  this.props.boards.selectedBoard.name}
              </Title>
            </Col>

            <Col span={4}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row-reverse",
                  textAlign: "right",
                }}
              >
                <Button
                  type='link'
                  onClick={() => {
                    window.history.back();
                  }}
                  style={{ marginLeft: "12px" }}
                >
                  Back
                </Button>
                {localStorage.getItem("IsSpecialUser")==="true" ? (
                  <>
                    <Button
                      type='link'
                      onClick={async () => {
                        this.props.history.push(
                          "/editor/dashboard/" + this.props.match.params.boardId
                        );
                      }}
                      style={{ marginLeft: "12px" }}
                      disabled={
                        !localStorage.getItem("IsSpecialUser") &&
                        this.props.currentAppPermissions &&
                        this.props.currentAppPermissions.indexOf(
                          "CreateDashboard"
                        ) === -1
                      }
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title={`Are you sure to delete this ${this.props.boards.selectedBoard &&
                        this.props.boards.selectedBoard.configDetails
                          .boardType === "reports"
                        ? "Report"
                        : "Dashboard"
                        } ?`}
                      placement='bottom'
                      onConfirm={this.confirmDeleteBoard}
                      okText='Yes'
                      cancelText='No'
                      disabled={
                        !localStorage.getItem("IsSpecialUser") &&
                        this.props.currentAppPermissions &&
                        this.props.currentAppPermissions.indexOf(
                          "CreateDashboard"
                        ) === -1
                      }
                    >
                      <Button
                        type='link'
                        disabled={
                          !localStorage.getItem("IsSpecialUser") &&
                          this.props.currentAppPermissions &&
                          this.props.currentAppPermissions.indexOf(
                            "CreateDashboard"
                          ) === -1
                        }
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div>
            <ReactFullscreen>
              {({ ref, onRequest, onExit }) => (
                <div
                  ref={ref}
                  className='view ant-col'
                  style={{ backgroundColor: "white" }}
                >
                  <button
                    onClick={() => {
                      onExit();
                      this.handleClose();
                    }}
                    className={this.state.visible}
                  >
                    Exit
                  </button>
                  <Row>
                    <Col span={24}>
                      {/* {this.state.anchorEl ?
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={this.state.anchorEl}
                                                    keepMounted
                                                    open={Boolean(this.state.anchorEl)}
                                                    onClose={this.state.setAnchorEl}
                                                >
                                                    <MenuItem value="1000000" onClick={handleInterval}>Never</MenuItem>
                                                    <MenuItem value="10" onClick={handleInterval}>10 Seconds</MenuItem>
                                                    <MenuItem value="60" onClick={handleInterval}>1 Minute</MenuItem>
                                                    <MenuItem value="900" onClick={handleInterval}>15 Minutes</MenuItem>
                                                    <MenuItem value="3600" onClick={handleInterval}>1 Hour</MenuItem>
                                                </Menu>
                                                : ('')
                                            } */}
                      {this.props.boards && this.props.boards.selectedBoard && this.props.boards.selectedBoard.properties? (
                        <>
                          {this.props.boards.selectedBoard.properties
                            .autorefresh ||
                            this.props.boards.selectedBoard.properties
                              .fullscreen ||
                            this.props.boards.selectedBoard.properties
                              .csvdownload ||
                            this.props.boards.selectedBoard.properties.refresh ? (
                            <>
                              {/* <BottomNavigation value={this.state.value} onChange={this.handleBannerChange}>
                                                                        {!this.props.boards.selectedBoard.properties.autorefresh ? <BottomNavigationAction label="Refresh" value="refresh" icon={<MaterialDesignIcon icon="refresh" />} /> : null}
                                                                        {!this.props.boards.selectedBoard.properties.fullscreen ? <BottomNavigationAction label="Auto Run" value="autorun" icon={<MaterialDesignIcon icon="directions_run" />} /> : null}
                                                                        {this.props.boards.selectedBoard.properties.csvdownload ? <BottomNavigationAction label="Export" value="reload" icon={<MaterialDesignIcon icon="import_export" />} /> : null}
                                                                        {this.props.boards.selectedBoard.properties.refresh ? <BottomNavigationAction onClick={() => window.location.reload(false)} label="Reload Page" value="reload" icon={<MaterialDesignIcon icon="reply" />} /> : null}
                                                                        {this.props.boards.selectedBoard.properties.fullscreen ? <BottomNavigationAction onClick={() => onRequest()} label="Full Screen" value="fullscreen" icon={<MaterialDesignIcon icon="fullscreen" />} /> : null}
                                                                    </BottomNavigation> */}
                              <div
                                style={{
                                  margin: "auto",
                                  textAlign: "center",
                                  padding: "12px",
                                }}
                              >
                                <Space>
                                  {this.props.boards.selectedBoard.properties
                                    .autorefresh ? (
                                    <Popover
                                      content={
                                        <>
                                          {/* <a onClick={() => this.setState({ anchorEl: null, setAnchorEl: null })}>Close</a> */}
                                          <a
                                            data-value='1000000'
                                            onClick={handleInterval}
                                          >
                                            Never
                                          </a>
                                          <br />
                                          <a
                                            data-value='30'
                                            onClick={handleInterval}
                                          >
                                            30 Seconds
                                          </a>
                                          <br />
                                          <a
                                            data-value='60'
                                            onClick={handleInterval}
                                          >
                                            1 Minute
                                          </a>
                                          <br />
                                          <a
                                            data-value='900'
                                            onClick={handleInterval}
                                          >
                                            15 Minutes
                                          </a>
                                          <br />
                                          <a
                                            data-value='3600'
                                            onClick={handleInterval}
                                          >
                                            1 Hour
                                          </a>
                                        </>
                                      }
                                      title='Refresh Interval'
                                      trigger='click'
                                      visible={this.state.anchorEl}
                                      onVisibleChange={this.handleVisibleChange}
                                      placement='bottom'
                                    >
                                      <Button
                                        type='link'
                                        onClick={(e) =>
                                          this.handleBannerChange(e, "refresh")
                                        }
                                        icon={
                                          <MaterialDesignIcon icon='refresh' />
                                        }
                                      />
                                    </Popover>
                                  ) : null}
                                  {this.props.boards.selectedBoard.properties
                                    .fullscreen ? (
                                    <Button
                                      type='link'
                                      onClick={(e) =>
                                        this.handleBannerChange(e, "autorun")
                                      }
                                      value=''
                                      icon={
                                        <MaterialDesignIcon icon='directions_run' />
                                      }
                                    />
                                  ) : null}
                                  {this.props.boards.selectedBoard.properties
                                    .csvdownload ? (
                                    <Button
                                      type='link'
                                      onClick={(e) =>
                                        this.handleBannerChange(e, "export")
                                      }
                                      icon={
                                        <MaterialDesignIcon icon='import_export' />
                                      }
                                    />
                                  ) : null}
                                  {this.props.boards.selectedBoard.properties
                                    .refresh ? (
                                    <Button
                                      type='link'
                                      onClick={() =>
                                        window.location.reload(false)
                                      }
                                      icon={<MaterialDesignIcon icon='reply' />}
                                    />
                                  ) : null}
                                  {this.props.boards.selectedBoard.properties
                                    .fullscreen ? (
                                    <Button
                                      type='link'
                                      onClick={() => onRequest()}
                                      label='Full Screen'
                                      value='fullscreen'
                                      icon={
                                        <MaterialDesignIcon icon='fullscreen' />
                                      }
                                    />
                                  ) : null}
                                </Space>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : this.props.boards.boardsStatus === "pending" ? (
                        <div>
                          <Skeleton />
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Collapse>
                    <Panel header="Filters" key="1">

                      <div>
                        <Tabs defaultActiveKey='1'>
                          <TabPane
                            tab={
                              <span>
                                <DatabaseOutlined />
                                Data Filters
                              </span>
                            }
                            key='1'
                          >
                            {this.props.boards &&
                              this.props.boards.selectedBoard ? (

                              <Filters
                                board={this.props.boards.selectedBoard}
                                onApplyDataFilter={this.onApplyDataFilterToResource}
                                onResetDataFilter={this.onResetFilterToResource}
                              />

                            ) : this.props.boards.boardsStatus === "pending" ? (
                              <div>
                                <Skeleton />
                              </div>
                            ) : null}
                          </TabPane>
                          <TabPane
                            tab={
                              <span>
                                <GlobalOutlined />
                                Global Filters
                              </span>
                            }
                            key='2'
                          >
                            {this.props.boards &&
                              this.props.boards.selectedBoard ? (
                              <>
                                <CustomFilters
                                  selectedBoard={this.props.boards.selectedBoard}
                                  onApplyFilter={this.onApplyFilterToResource}
                                />
                                {/* <ReportWidjets board={this.props.boards.selectedBoard} selectedResource={this.state.selectedResource} /> */}
                              </>
                            ) : this.props.boards.boardsStatus === "pending" ? (
                              <div>
                                <Skeleton />
                              </div>
                            ) : null}
                          </TabPane>
                        </Tabs>
                      </div>
                    </Panel>
                  </Collapse>

                  {this.props.boards.boardsStatus === "pending" ? (
                    <div>
                      <Skeleton />
                    </div>
                  ) : this.props.boards && this.props.boards.selectedBoard ? (
                    <div id='report-div'>
                      <ReportWidjets
                        board={this.props.boards.selectedBoard}
                        selectedResource={this.state.selectedResource}
                      />
                    </div>
                  ) : (
                    null
                  )}
                </div>
              )}
            </ReactFullscreen>
          </div>
        </>
      </DashboardView>
    );
  }
}

const mapStateToProps = (state) => {
  const { boards, user } = state;
  return {
    boards: boards,
    currentAppPermissions: user.currentAppPermissions
      ? user.currentAppPermissions
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    getBoardByIdAsync: (data) => dispatch(getBoardByIdAsync(data)),
    updateSelectedBoard: (data) => dispatch(updateSelectedBoard(data)),
    deleteBoardById: (id) => dispatch(deleteBoardByIdAsync(id)),
    resetBoardData: () => dispatch(resetBoardData()),
    resetAllBoardData: () => dispatch(resetAllBoardData()),
    getResources: () => dispatch(getResourcesAsync()),
    // addSelectedBoard: (board) => dispatch(updateSelectedBoard(board)),
    // setOtherUsers: (usersList) => dispatch(setOtherUsers(usersList)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
