
import React from 'react';
import { Row, Col, Divider, Button, Popconfirm, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGroupAsync, resetGroupDeletingState } from '../../redux/boardSlice'
// import { useHistory } from 'react-router-dom'

import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";

export default function GroupList(props) {
  // const history = useHistory();
  const dispatch = useDispatch();

  //   const groups = useSelector((state) => state.boards.groups);

  const confirmDelete = (e, res) => {
    console.log(e);
    dispatch(deleteGroupAsync(res));
  };

  const handleEditClick = (id) => {
    props.handleGroupSelect(id);
    props.setNewGropModalVisibility(true);
    props.setIsViewOnly(false);
  };

  const handleEditView = (id) => {
    props.handleGroupSelect(id);
    props.setNewGropModalVisibility(true);
    props.setIsViewOnly(true);
  };

  return (
    <div>
      <Row>
        <Col span={8}>
          <div>
            <b>Group Name</b>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <b>Group Type</b>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <b>Number of Sub Groups</b>
          </div>
        </Col>
        {localStorage.getItem("IsSpecialUser") ? (
          <Col span={4}>
            <div>
              <b>Action</b>
            </div>
          </Col>
        ) : null}
      </Row>
      <Divider />
      <>
        {props?.groups?.map((gp) => {
          return (
            <Row>
              <Col span={8}>
                <div>{gp.title}</div>
              </Col>
              <Col span={6}>
                <div>{gp.groupType}</div>
              </Col>
              <Col span={6}>
                <div>{gp.children.length}</div>
              </Col>
              {localStorage.getItem("IsSpecialUser") ? (
                <Col span={4}>
                  <div>
                    <Button
                      icon={<EditOutlined />}
                      type='link'
                      onClick={(e) => handleEditClick(gp._id)}
                      disabled={gp.isGlobal && (localStorage.getItem('IsSpecialUser')==='false')}
                    ></Button>
                    <Button
                      icon={<EyeOutlined />}
                      type='link'
                      onClick={(e) => handleEditView(gp._id)}
                    ></Button>
                    <Popconfirm
                      title='Are you sure to delete this Group?'
                      onConfirm={(e) => confirmDelete(e, gp)}
                      okText='Yes'
                      cancelText='No'
                      disabled={gp.isGlobal && (localStorage.getItem('IsSpecialUser')==='false')}
                    >
                      <Button icon={<DeleteOutlined />} disabled={gp.isGlobal && (localStorage.getItem('IsSpecialUser')==='false')} type='link'></Button>
                    </Popconfirm>
                  </div>
                </Col>
              ) : null}
            </Row>
          );
        })}
      </>
    </div>
  );
}
