import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
const { REACT_APP_CONNECTION_INFO_BASE_URL, REACT_APP_APP_REGISTRY_API, REACT_APP_APP_REGISTRY_ISS, REACT_APP_APP_REGISTRY_AUD, REACT_APP_APP_REGISTRY_PRIVATE_KEY, REACT_APP_APP_ID } = process.env;

// import jwt_decode from "jwt-decode";

export const loginAsync = createAsyncThunk(
    'user/login',
    async (payload) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: payload.username,
                password: payload.password
            })
        };
        const response = await fetch(`${REACT_APP_CONNECTION_INFO_BASE_URL}/signin`, requestOptions)
        const data = await response.json();
        return data;
    }
)

export const getAppsAsync = createAsyncThunk(
    'user/getApps',
    async () => {
        const appsTimestamp = Math.floor(Date.now() / 1000) + 1000;
        var appsPayload = {
            "aud": REACT_APP_APP_REGISTRY_AUD,
            "exp": appsTimestamp,
            "iss": REACT_APP_APP_REGISTRY_ISS,
            "usertoken": localStorage.getItem('accessToken')
        };

        var appsPrivateKey = REACT_APP_APP_REGISTRY_PRIVATE_KEY.replace(/\\n/g, '\n');

        var appsSignOptions = {
            "algorithm": "RS256"
        };


        var appsToken = jwt.sign(appsPayload, appsPrivateKey, appsSignOptions);

        console.log("JWT  appsToken==>>", appsToken);

        const requestOptions = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + appsToken,
            }
        };

        const response = await fetch(`${REACT_APP_APP_REGISTRY_API}`, requestOptions)
        const data = await response.json();
        console.log("APPS resp===>>", data)
        return data;


        // fetch(`${REACT_APP_APP_REGISTRY_API}`, requestOptions)
        //     .then(response => response.json())
        //     .then((resp) => {
        //         console.log("APPS resp===>>", resp)
        //     });

    }
)

export const getCurrentUserAsync = createAsyncThunk(
    'user/getCurrent',
    async () => {
        const resp = localStorage.getItem("currentUser");
        // console.log("resp===>>", resp)
        if (resp) {
            const userObj = JSON.parse(resp)
            return userObj;
        } else {
            return {}
        }
    }
);

export const setCurrentUserAsync = createAsyncThunk(
    'user/getCurrent',
    async (payload) => {
        const userObj = JSON.stringify(payload)
        const resp = await localStorage.setItem("currentUser", userObj);
        if (resp) {
            return userObj;
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {
            isSigned: false
        },
        otherUsers: [],
        appDetails: {
            CurrentAppID:REACT_APP_APP_ID
        }
    },
    reducers: {
        signin: (state, action) => {
            // const userdata = action.payload;
            localStorage.setItem("currentUser", JSON.stringify(action.payload));
            state.user = action.payload;
        },
        signout: (state) => {
            localStorage.clear()
            state.user = {};
        },
        setOtherUsers: (state, action) => {
            localStorage.setItem("otherUsers", JSON.stringify(action.payload));
            state.otherUsers = action.payload
        }
    }, extraReducers: {
        [getCurrentUserAsync.fulfilled]: (state, action) => {
            state.user = action.payload;
            state.currentAppPermissions = action.payload.permissions && action.payload.permissions.find(pl => pl.AppID === state.appDetails.CurrentAppID).AccessPermissions.filter(ap => ap.Permission===1).map(p => p.AccessName)
        },
        [getAppsAsync.pending]: (state) => {
            state.appsState = 'loading';
            state.apps = null
        }, [getAppsAsync.fulfilled]: (state, action) => {
            state.appsState = 'success'
            state.apps = action.payload
        },
        [getAppsAsync.rejected]: (state, action) => {
            state.appsState = 'error';
            state.apps = null
        }
    },
});

export const { signin, signout, setOtherUsers } = userSlice.actions;

export default userSlice.reducer;