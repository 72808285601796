
import React from 'react';
import DashboardView from '../DashboardView';

export default function Home() {
    return (
        <DashboardView>
            <div>
                <h2>Home</h2>
            </div>
        </DashboardView>
    )
}