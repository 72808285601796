import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Input,
  DatePicker,
  message,
  TimePicker,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
// import Axios from "axios";
// import jwt from "jsonwebtoken";
import * as _ from 'lodash';
import { returnDataArrayObject } from "../../../../utils/arrayHelper";
import {
  DATE_FORMAT,
  DATE_WITH_HOUR_FORMAT,
  DATE_WITH_HOUR_MINUTES_FORMAT,
} from "../../../../constants/dateConstants";
import NotFound from "../../../../components/NotFound";
import {
  updateDataFilters,
  updateResourceSourceAndFilter,
  deleteFilters,
} from "../../../../redux/editorSlice";
import moment from "moment";
import { customDateTimeFormat } from "../../../../utils/filterHelper";
const { Option } = Select;

export default function DataFilter(props) {
  const [dataFilters, setDataFilters] = useState([]);
  const [definedFilters, setDefinedFilters] = useState([]);
  const [fields, setFields] = useState([]);
  // const [selectedResource, setSelectedResource] = useState(null);
  // const [reportDataStatus, setReportDataStatus] = useState('default');
  const dateTimeFormatChange = customDateTimeFormat(
    props.selectedResource.availableFields
  );

  const dispatch = useDispatch();

  const setDataFiltertem = () => {
    let currentFilters = [...dataFilters];
    currentFilters.push({
      id: Date.now(),
      dataType: "string",
      filterType: "equal",
    });
    setDataFilters(currentFilters);
  };

  const deleteDataFilterItem = (item) => {
    setDataFilters(dataFilters.filter((f) => f.id !== item.id));
    dispatch(
      deleteFilters({
        id: item.id,
        filterType: "dataFilters",
      })
    );
  };

  // useEffect(() => {
  //   let currentFilters = dataFilters.map((df) => {
  //     return {
  //       id: df.id,
  //       fieldName: df.fieldName,
  //       displayName: df.displayName,
  //       dataType: df.dataType,
  //       filterType: df.dataType==='string'? 'equal' : df.filterType,
  //       value: df.value ? df.value : null,
  //       value1: df.value1 ? df.value1 : null,
  //       value2: df.value2 ? df.value2 : null,
  //     };
  //   })
  //   setDataFilters(currentFilters)
  // }, [dataFilters])

  useEffect(() => {
    console.log("availableFields changed", props.selectedResource.dataFilters);
    let currentFilters = (props?.selectedResource?.dataFilters || []).map(
      (df) => {
        return {
          id: df.id,
          fieldName: df.fieldName,
          displayName: df.displayName,
          dataType: df.dataType
            ? df.dataType
            : props.selectedResource.availableFields &&
              props.selectedResource.availableFields.find(
                (af) => af.fieldName === df.fieldName
              )["dataType"]
              ? props.selectedResource.availableFields.find(
                (af) => af.fieldName === df.fieldName
              )["dataType"]
              : "text",
          filterType: df.filterType,
          value: df.value ? df.value : null,
          value1: df.value1 ? df.value1 : null,
          value2: df.value2 ? df.value2 : null,
        };
      }
    );

    const currentDefinedFilters = (
      props?.selectedResource?.customFilters || []
    ).map((df) => {
      return {
        id: df.id,
        parameterType: df.parameterType,
        parameterName: df.parameterName,
        dataType: df.dataType,
        value: df.value,
        config :  df.dataType==="text"?
        {maxLength: 20}
        :df.dataType ==="number"?
        {min: 0, max: 10000}
        : df.dataType ==="date"?
        {range: 'dynamic', value:'LastMonth'}
        :df.config
      };
    });

    setDefinedFilters(currentDefinedFilters);
    setDataFilters(currentFilters);
  }, [props.selectedResource.availableFields]);

  const updateDataFilter = (item, key, val) => {
    // let currentFilters = [...dataFilters];
    // let updatedFilter = Object.assign(item, { [key]: val });

    // // updatedFilter[key] = val
    // currentFilters[currentFilters.indexOf(f => f.id === item.id)] = updatedFilter
    let currentFilters = dataFilters.map((df) => {
      return df?.id === item?.id
        ? {
          id: df.id,
          fieldName: key === "fieldName" ? val : df?.fieldName,
          displayName: key === "displayName" ? val : df?.displayName,
          dataType:
            key === "dataType"
              ? val
              : key === "fieldName"
                ? props?.selectedResource?.availableFields?.find(
                  (af) => af?.fieldName === val
                )?.dataType ??
                props?.selectedResource?.allFields?.find(
                  (af) => af?.fieldName === val
                )?.dataType
                : df.dataType,
          filterType: key === "filterType" ? val : df.filterType,
          value: key === "value" ? val : df.value ? df.value : null,
          value1: key === "value1" ? val : df.value1 ? df.value1 : null,
          value2: key === "value2" ? val : df.value2 ? df.value2 : null,
        }
        : { ...df };
    });

    setDataFilters(currentFilters);
  };

  const setDefinedFiltertem = () => {
    let currentFilters = [...definedFilters];
    currentFilters.push({
      id: Date.now(),
      parameterType: "body",
      parameterName: "",
      dataType: "text",
      config: {}
    });
    setDefinedFilters(currentFilters);
  };

  const deleteDefinedFilterItem = (item) => {
    setDefinedFilters(definedFilters.filter((f) => f.id !== item.id));
    dispatch(
      deleteFilters({
        id: item.id,
        filterType: "customFilters",
      })
    );
  };

  const updateDefinedFilter = (item, key, val) => {
    // let currentFilters = [...definedFilters];
    // let updatedFilter = Object.assign(item, { [key]: val });

    // // updatedFilter[key] = val
    // currentFilters[currentFilters.indexOf(f => f.id === item.id)] = updatedFilter

    let currentFilters = definedFilters.map((df) => {
      return {
        id: df.id,
        parameterType:
          df.id === item.id && key === "parameterType" ? val : df.parameterType,
        parameterName:
          df.id === item.id && key === "parameterName" ? val : df.parameterName,
        dataType: df.id === item.id && key === "dataType" ? val : df.dataType,
        // value:
        //   df.id === item.id && key === "value"
        //     ? df.dataType === "number"
        //       ? Number(val)
        //       : df.dataType === "date" ?
        //         moment(val).format("yyyy-MM-DD[T]HH:mm:ss.SSSZ")
        //         : val
        //     : df.dataType === "number"
        //       ? Number(df.value)
        //       : df.value,
        config : _.isEmpty(df.config)?
          df.id === item.id && key === "dataType" && val==="text"?
          {maxLength: 100}
          : df.id === item.id && key === "dataType" && val==="number"?
          {min: 0, max: 10000}
          : df.id === item.id && key === "dataType" && val==="date"?
          {range: 'dynamic', value:'Last2Weeks'}
          :{maxLength: 20}
          :df.dataType==="date"? 
          df.id === item.id && key ==="dynamicDateRange"?
          {range: val, value:'Last2Weeks'}:
          df.id === item.id && key ==="dynamicDateRangeValue"?
          {range: df.config.range, value:val}
          :{range: 'dynamic', value:'Last2Weeks'}
          :df.config
      };
    });
    setDefinedFilters(currentFilters);
  };

  // useEffect(() => {
  //     dispatch(updateDataFilters(dataFilters))
  // }, [dataFilters]);

  const saveFilters = (filterArray, customFilters) => {
    console.log("Save FILTERS", customFilters);
    if (customFilters && customFilters.length > 0) {
      console.log("APPLY FILTERS customFilters");
      fetchReportData(filterArray, customFilters)
        .then((res) => message.info("Filters successfully saved!"))
        .catch((err) => console.log(err));
      // dispatch(updateDataFilters({ definedFilters: definedFilters, filterArray: filterArray }))
    } else {
      dispatch(updateDataFilters({ dataFilters: filterArray, applyFilters: true }));
      message.info("Filters successfully saved!");
    }
  };

  const clearFilters = () => {
    dispatch(updateDataFilters({ dataFilters: [], customFilters: [], applyFilters: false }));
    setDataFilters([])
    message.info("Filters successfully cleared!");
    fetchReportData([], [])
    //     .then((res) => message.info("Filters successfully cleared!"))
    //     .catch((err) => console.log(err));
  };

  async function fetchReportData(filterArray, customFilters) {
    // console.log(" fetchReportData===>>>>", resources.selectedResource)
    const selectedResource = props.selectedResource;

    const payload = {
      selectedResource: Object.assign(selectedResource),
      // reportData: reportData,
      customFilters: customFilters,
      dataFilters: filterArray,
    };

    dispatch(updateResourceSourceAndFilter(payload));
    // if (
    //   selectedResource &&
    //   selectedResource.source &&
    //   selectedResource.source.resoueceType === "Rest API"
    // ) {
    //   // const response = await axios.get(resources.selectedResource.connectionData.baseUrl);
    //   // console.log(" fetchReportData===>>>>", selectedResource.source)
    //   try {
    //     const requestValues =
    //       selectedResource.source.connectionData.requestValues;
    //     const authInfo =
    //       selectedResource &&
    //       selectedResource.source.connectionData &&
    //       selectedResource.source.connectionData.authInfo;
    //     let urlParamSring = "";
    //     if (requestValues.urlParams.length > 0) {
    //       urlParamSring = urlParamSring + "?";
    //       for (let i = 0; i < requestValues.urlParams.length; i++) {
    //         if (
    //           requestValues.urlParams[i]["key"] !== "" &&
    //           requestValues.urlParams[i]["value"] !== ""
    //         ) {
    //           urlParamSring =
    //             urlParamSring +
    //             requestValues.urlParams[i]["key"] +
    //             "=" +
    //             requestValues.urlParams[i]["value"] +
    //             "&";
    //         }
    //       }
    //       urlParamSring.slice(0, -1);
    //     }

    //     let headerObj = {};
    //     if (requestValues.requestHeaders.length > 0) {
    //       for (let i = 0; i < requestValues.requestHeaders.length; i++) {
    //         if (
    //           requestValues.requestHeaders[i]["key"] !== "" &&
    //           requestValues.requestHeaders[i]["value"] !== ""
    //         ) {
    //           headerObj[requestValues.requestHeaders[i]["key"]] =
    //             requestValues.requestHeaders[i]["value"];
    //         }
    //       }
    //     }

    //     let bodyParamObj = {};
    //     // if (requestValues.authParams) {
    //     //     bodyParamObj = Object.assign({}, authParams);
    //     // }
    //     if (requestValues.bodyParams.length > 0) {
    //       for (let i = 0; i < requestValues.bodyParams.length; i++) {
    //         if (
    //           requestValues.bodyParams[i]["key"] !== "" &&
    //           requestValues.bodyParams[i]["value"] !== ""
    //         ) {
    //           bodyParamObj[requestValues.bodyParams[i]["key"]] =
    //             requestValues.bodyParams[i]["value"];
    //         }  else if ( requestValues.bodyParams[i]["key"] !== "" && requestValues.bodyParams[i]["dataType"] === "date") {
    //           if(requestValues.bodyParams[i]["dateConfig"]) {
    //             let dateCf = requestValues.bodyParams[i]["dateConfig"];
    //             if(dateCf.dateOption === "daysBefore") {
    //               let dateVal = moment.utc().subtract(dateCf.timeUnits, 'day').startOf('day').format(dateCf.dateFormat);
    //               bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
    //             } else if (dateCf.dateOption === "today") {
    //               let dateVal = moment().format(dateCf.dateFormat);
    //               bodyParamObj[requestValues.bodyParams[i]["key"]] = dateVal;
    //             }
    //           }
    //         }
    //       }
    //     }
    //     // // await Axios.get(resources.selectedResource.connectionData.baseUrl)
    //     if (customFilters.length > 0) {
    //       customFilters.forEach((element) => {
    //         if (element.parameterName !== "") {
    //           if (element.parameterType === "query") {
    //             if (urlParamSring === "") {
    //               urlParamSring = urlParamSring + "?";
    //             }
    //             urlParamSring =
    //               urlParamSring +
    //               element.parameterName +
    //               "=" +
    //               element.value +
    //               "&";
    //           } else {
    //             bodyParamObj[element.parameterName] = element.value;
    //           }
    //         }
    //       });
    //     }
    //     // setReportDataStatus("pending")
    //     if (authInfo.method === "jwt") {
    //       console.log("JWT PROCESS");
    //       const timestamp = Math.floor(Date.now() / 1000) + 1000;
    //       const privateKey = authInfo.parameters.privateKey;
    //       let userToken=localStorage.getItem('accessToken');
    //       var payload = {
    //         aud: authInfo.parameters.aud,
    //         exp: timestamp,
    //         iss: authInfo.parameters.iss,
    //         userToken: userToken
    //       };
    //       var signOptions = {
    //         algorithm: "RS256",
    //       };

    //       var privateKeyVal = privateKey
    //         ? privateKey.replace(/\\n/g, "\n")
    //         : null;

    //       if (privateKeyVal) {
    //         try {
    //           var token = jwt.sign(payload, privateKeyVal, signOptions);
    //           console.log("Generated JWT authParams=====>>", token);
    //           // setApiTestStatus("success")

    //           await Axios({
    //             method: requestValues.httpMethod,
    //             url:
    //               selectedResource.source.connectionData.baseUrl +
    //               urlParamSring,
    //             data: JSON.parse(JSON.stringify(bodyParamObj)),
    //             headers: {
    //               "Access-Control-Allow-Origin": "*",
    //               "Content-Type": "application/json",
    //               Authorization: `Bearer ` + token,
    //             },
    //           })
    //             .then((response) => {
    //               console.log("CUSTOM FILTER response.data", response);
    //               // setReportDataStatus("success")
    //               const reportData = response.data ? response.data : response;
    //               const payload = {
    //                 selectedResource: Object.assign(selectedResource),
    //                 reportData: reportData,
    //                 customFilters: customFilters,
    //                 dataFilters: filterArray,
    //               };

    //               dispatch(updateResourceSourceAndFilter(payload));
    //               // dispatch(updateResourceSource(Object.assign(selectedResource, { reportData: reportData })))
    //               // dispatch(updateDataFilters({ definedFilters: customFilters, filterArray: filterArray }))
    //             })
    //             .catch((error) => {
    //               // setReportDataStatus("error")

    //               console.log(error);
    //             });
    //         } catch (e) {
    //           console.log("JWT authPsign error=====>>", e);
    //           // setReportDataStatus("error")
    //         }
    //       }
    //     } else {
    //       Axios({
    //         method:
    //           selectedResource.source.connectionData.requestValues.httpMethod,
    //         url: selectedResource.source.connectionData.baseUrl + urlParamSring,
    //         data: bodyParamObj,
    //         headers: headerObj,
    //       })
    //         .then(async (response) => {
    //           console.log("CUSTOM FILTER response.data", response);
    //           // setReportDataStatus("success")
    //           // setReportDataObject(response.data ? response.data : response)
    //           const reportData = response.data ? response.data : response;
    //           const payload = {
    //             selectedResource: Object.assign(selectedResource),
    //             reportData: reportData,
    //             customFilters: customFilters,
    //             dataFilters: filterArray,
    //           };

    //           dispatch(updateResourceSourceAndFilter(payload));
    //           // dispatch(updateDataFilters({ dataFilters: filterArray }))
    //         })
    //         .catch((error) => {
    //           // setReportDataStatus("error")
    //           // setReportDataObject(null)
    //           // console.log(error);
    //         });
    //     }
    //   } catch (error) {
    //     // setReportDataStatus("error")
    //     // setReportDataObject(null)
    //     // console.log(error);
    //   }
    // }
  }

  useEffect(() => {
    let availableFieldsSet = props?.selectedResource?.availableFields?.map(
      (af) => af
    );

    props?.selectedResource?.aggregation?.row?.forEach((a, k) => {
      availableFieldsSet.push({
        fieldName: a.displayName,
        checked: true,
        id: Math.floor(Math.random() * 10000) + 1,
        dataType: "number",
      });
    });
    setFields(availableFieldsSet);
  }, [props]);

  return (
    <div style={{ overflowY: "auto", height: "30vh" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {returnDataArrayObject(props.reportDataObject) ? (
          <div style={{ width: "100%" }}>
            {props.connectionType === "Rest API" ?
              <>
                <Row>
                  <Col>
                    <b>Global Filters</b>
                  </Col>
                </Row>
                <>
                  {definedFilters.map((defltr) => {
                    return (
                      <Row
                        key={"defltr_" + defltr.id}
                        gutter={16}
                        style={{ marginTop: "16px" }}
                      >
                        <Col span={5}>
                          <div>Parameter Type:</div>
                          <div>
                            <Select
                              style={{ width: "90%" }}
                              placeholder='Select Parameter Type'
                              onChange={(val) =>
                                updateDefinedFilter(defltr, "parameterType", val)
                              }
                              value={defltr.parameterType}
                            >
                              <Option key='cdtqf_1' value='body'>
                                Body Parameter
                              </Option>
                              <Option key='cdtqf_2' value='query'>
                                Query Parameter
                              </Option>
                            </Select>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div>Parameter Name:</div>
                          <div>
                            <Input
                              onChange={(e) =>
                                updateDefinedFilter(
                                  defltr,
                                  "parameterName",
                                  e.target.value
                                )
                              }
                              value={defltr.parameterName}
                            />
                          </div>
                        </Col>
                        <Col span={4}>
                          <div>Data Type:</div>
                          <div>
                            <Select
                              placeholder='Data Type'
                              style={{ width: "90%" }}
                              defaultValue='text'
                              onChange={(val) =>
                                updateDefinedFilter(defltr, "dataType", val)
                              }
                              value={defltr.dataType}
                            >
                              <Option key='cdt_1' value='text'>
                                Text
                              </Option>
                              <Option key='cdt_2' value='number'>
                                Number
                              </Option>
                              <Option key='cdt_3' value='date'>
                                Date
                              </Option>
                              <Option key='cdt_5' value='strArray'>
                                String Array
                              </Option>
                              <Option key='cdt_6' value='numArray'>
                                Number Array
                              </Option>
                            </Select>
                          </div>
                        </Col>
                        {
                          defltr.dataType === 'number' ?
                            <>
                              <Col span={3}>
                                <div>Min</div>
                                <div>
                                  <Input
                                    type='number'
                                    value={defltr.config.min}
                                    min={0}
                                  />
                                </div>
                              </Col>
                              <Col span={3}>
                                <div>Max</div>
                                <div>
                                  <Input
                                    type='number'
                                    value={defltr.config.max}
                                    min={0}
                                  />
                                </div>
                              </Col>
                            </>
                            : defltr.dataType === 'date' ?
                              <>
                              <Col span={5}>
                                <div>Range</div>
                                <div>
                                  <Select style={{ width: "90%" }} defaultValue={defltr?.config?.range|| 'dynamic'} onChange={(val) => updateDefinedFilter(defltr, "dynamicDateRange", val)}>
                                    <Option  value='dynamic'>
                                      Dynamic Range
                                    </Option>
                                    <Option  value='fixedBefore'>
                                      Before Fixed Date
                                    </Option>
                                    <Option  value='fixedAfter'>
                                      After Fixed Date
                                    </Option>
                                  </Select>
                                </div>
                              </Col>
                              { defltr.config.range==="dynamic"?
                                <Col span={4}>
                                  <div>Days Between</div>
                                  <div>
                                    <Select style={{ width: "90%" }} value={defltr.config.value || 'LastMonth'} onChange={(val) => updateDefinedFilter(defltr, "dynamicDateRangeValue", val)}>
                                      <Option value='LastWeek'>Last Week</Option>
                                      <Option value='Last2Weeks'>Last 2 Weeks</Option>
                                      <Option value='LastMonth'>Last Month</Option>
                                      <Option value='LastQuarter'>Last Quarter</Option>
                                      <Option value='Last6Months'>Last 6 Months</Option>
                                      <Option value='LastYear'>Last Year</Option>
                                      <Option value='Any'>Any</Option>
                                    </Select>
                                  </div>
                                </Col>
                                :<Col span={4}>
                                <div>Select Date</div>
                                <div>
                                  <DatePicker onChange={(val) => updateDefinedFilter(defltr, "dynamicDateRangeValue", val)} />
                                </div>
                              </Col>
                              }

                              </>
                              : <Col span={3}>
                                <div>Max Length</div>
                                <div>
                                  <Input
                                    type='number'
                                    value={defltr.config.maxLength}
                                  />
                                </div>
                              </Col>

                        }

                        {/* {(() => {
                        switch (defltr.dataType) {
                          case "number":
                            return (
                              <>
                                <Col span={2}>
                                  <div>Min</div>
                                  <div>
                                    <Input
                                      type='number'
                                    // value={defltr.value}
                                    />
                                  </div>
                                </Col>
                                <Col span={2}>
                                  <div>Max</div>
                                  <div>
                                    <Input
                                      type='number'
                                    // value={defltr.value}
                                    />
                                  </div>
                                </Col>
                              </>
                            );
                          case "date":
                            return (
                              <>
                                <Col span={2}>
                                  <div>Min</div>
                                  <div>
                                    <Input
                                      type='number'
                                    // value={defltr.value}
                                    />
                                  </div>
                                </Col>
                                <Col span={2}>
                                  <div>Max</div>
                                  <div>
                                    <Input
                                      type='number'
                                    // value={defltr.value}
                                    />
                                  </div>
                                </Col>
                              </>
                            );
                          default:
                            return (
                              <Col span={2}>
                                <div>Max Length</div>
                                <div>
                                  <Input
                                    type='number'
                                  // value={defltr.value}
                                  />
                                </div>
                              </Col>
                            );
                        }
                      })} */}

                        {/* <Col span={4}>
                        <div>Value</div>
                        {(() => {
                          switch (defltr.dataType) {
                            case "number":
                              return (
                                <div>
                                  <Input
                                    type='number'
                                    onChange={(e) =>
                                      updateDefinedFilter(
                                        defltr,
                                        "value",
                                        e.target.value
                                      )
                                    }
                                    value={defltr.value}
                                  />
                                </div>
                              );
                            case "date":
                              return (
                                <div>
                                  <DatePicker
                                    onChange={(date, dateString) =>
                                      updateDefinedFilter(
                                        defltr,
                                        "value",
                                        dateString
                                      )
                                    }
                                    value={moment(defltr.value)}
                                  />
                                </div>
                              );
                            case "strArray":
                              return (
                                <div>
                                  <Input
                                    onChange={(e) =>
                                      updateDefinedFilter(
                                        defltr,
                                        "value",
                                        e.target.value.split(",")
                                      )
                                    }
                                    // value={e.target.value}
                                  />
                                </div>
                              );
                            case "numArray":
                              return (
                                <div>
                                  <Input
                                    onChange={(e) =>
                                      updateDefinedFilter(
                                        defltr,
                                        "value",
                                        e.target.value.split(",").filter(x=>x!=="").map(y=>Number(y))
                                      )
                                    }
                                    // value={e.target.value}
                                  />
                                </div>
                              );
                            default:
                              return (
                                <div>
                                  <Input
                                    onChange={(e) =>
                                      updateDefinedFilter(
                                        defltr,
                                        "value",
                                        e.target.value
                                      )
                                    }
                                    value={defltr.value}
                                  />
                                </div>
                              );
                          }
                        })()}
                      </Col> */}

                        <Col span={1}>
                          <div>&nbsp;</div>
                          <div>
                            <Button
                              type='text'
                              icon={<CloseOutlined />}
                              onClick={() => deleteDefinedFilterItem(defltr)}
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </>

                <Row
                  style={{ marginTop: "16px", marginBottom: "32px" }}
                  gutter={16}
                >
                  <Col span={5}>
                    <Button onClick={setDefinedFiltertem}>Add New +</Button>
                  </Col>
                </Row>

                <hr />
              </>
              : null}
            <Row>
              <Col>
                <b>Data Filters</b>
              </Col>
            </Row>
            {dataFilters.map((fi, index) => {
              return (
                <Row
                  key={"fil_" + fi.id}
                  gutter={16}
                  style={{ marginTop: "16px" }}
                >
                  <Col span={5}>
                    <div>Field Name:</div>
                    <div>
                      <Select
                        showSearch
                        style={{ width: "90%" }}
                        placeholder='Select Data Field'
                        value={fi.fieldName}
                        onChange={(val) =>
                          updateDataFilter(fi, "fieldName", val)
                        }
                      >
                        {fields?.map((af, j) => {
                          return (
                            <Option value={af.fieldName} key={fi.id + "_" + j}>
                              {af.fieldName}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Col>

                  <Col span={4}>
                    <div>Field Display Name:</div>
                    <div>
                      <Input
                        onChange={(e) =>
                          updateDataFilter(fi, "displayName", e.target.value)
                        }
                        value={fi.displayName}
                      />
                    </div>
                  </Col>

                  <Col span={4}>
                    {/* <div>Data Type:</div>
                    <div>
                      <Input disabled value={fi.dataType} />
                    </div> */}
                    <div>Data Type:</div>
                    <div>
                      <Select
                        placeholder='Select Data Type'
                        style={{ width: "90%" }}
                        defaultValue='string'
                        onChange={(val) =>
                          updateDataFilter(fi, "dataType", val)
                        }
                        value={fi.dataType}
                      >
                        <Option key='cdt_1' value='string'>
                          Srting
                        </Option>
                        <Option key='cdt_3' value='number'>
                          Number
                        </Option>
                        <Option key='cdt_4' value='date'>
                          Date
                        </Option>
                      </Select>
                    </div>
                  </Col>

                  <Col span={5}>
                    <div>Filter Type:</div>
                    <div>
                      <Select
                        placeholder='Select Filter Type'
                        style={{ width: "90%" }}
                        onChange={(val) =>
                          updateDataFilter(fi, "filterType", val)
                        }
                        defaultValue='equal'
                        value={fi.filterType}
                      >
                        {fi.dataType === "string" ? (
                          <>
                            <Option key='ft_1' value='equal'>
                              Equal to
                            </Option>
                            <Option key='ft_7' value='con'>
                              Contains
                            </Option>
                          </>
                        ) : (
                          <>
                            <Option key='ft_1' value='equal'>
                              Equal to
                            </Option>
                            <Option key='ft_2' value='lt'>
                              Less Than
                            </Option>
                            <Option key='ft_3' value='gt'>
                              Grater Than
                            </Option>
                            <Option key='ft_4' value='lte'>
                              Less Than or Equal
                            </Option>
                            <Option key='ft_5' value='gte'>
                              Grater Than or Equal
                            </Option>
                            <Option key='ft_6' value='between'>
                              Between
                            </Option>
                          </>
                        )}
                      </Select>
                    </div>
                  </Col>
                  {fi.filterType !== "between" ? (
                    <Col span={3}>
                      <div>Value</div>
                      {(() => {
                        switch (fi.dataType) {
                          case "number":
                            return (
                              <div>
                                <Input
                                  type='number'
                                  onChange={(e) =>
                                    updateDataFilter(
                                      fi,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            );
                          case "date":
                            return (
                              <div style={{ display: "inline" }}>
                                <DatePicker
                                  onChange={(date, dateString) =>
                                    updateDataFilter(fi, "value", dateString)
                                  }
                                  showTime={
                                    dateTimeFormatChange.getDateFormat(
                                      fi.fieldName
                                    ).dateAndTimeFormat ===
                                    DATE_WITH_HOUR_FORMAT ||
                                    dateTimeFormatChange.getDateFormat(
                                      fi.fieldName
                                    ).dateAndTimeFormat ===
                                    DATE_WITH_HOUR_FORMAT
                                  }
                                  format={
                                    dateTimeFormatChange.getDateFormat(
                                      fi.fieldName
                                    ).dateAndTimeFormat
                                  }
                                  value={fi.value ? moment(fi.value) : moment()}
                                />
                              </div>
                            );
                          case "select":
                            return (
                              <div>
                                <Select
                                  style={{ width: "90%" }}
                                  onChange={(e) =>
                                    updateDataFilter(fi, "value", e)
                                  }
                                  value={fi.value}
                                >
                                  {props.reportDataObject &&
                                    props.reportDataObject.length > 0 &&
                                    [
                                      ...new Set(
                                        props.reportDataObject.map(
                                          (rd) => rd[fi.fieldName]
                                        )
                                      ),
                                    ].map((o) => {
                                      return <Option key={o}>{o}</Option>;
                                    })}
                                </Select>
                              </div>
                            );
                          default:
                            return (
                              <div>
                                <Input
                                  onChange={(e) =>
                                    updateDataFilter(
                                      fi,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                  value={fi.value}
                                />
                              </div>
                            );
                        }
                      })()}
                    </Col>
                  ) : (
                    <>
                      <Col span={4}>
                        <div>From Value</div>
                        {(() => {
                          switch (fi.dataType) {
                            case "number":
                              return (
                                <div>
                                  <Input
                                    type='number'
                                    onChange={(e) =>
                                      updateDataFilter(
                                        fi,
                                        "value1",
                                        e.target.value
                                      )
                                    }
                                    value={fi.value1}
                                  />
                                </div>
                              );
                            case "date":
                              return (
                                <div>
                                  <DatePicker
                                    onChange={(date, dateString) =>
                                      updateDataFilter(fi, "value1", dateString)
                                    }
                                    showTime={
                                      dateTimeFormatChange.getDateFormat(
                                        fi.fieldName
                                      ).dateAndTimeFormat ===
                                      DATE_WITH_HOUR_FORMAT ||
                                      dateTimeFormatChange.getDateFormat(
                                        fi.fieldName
                                      ).dateAndTimeFormat ===
                                      DATE_WITH_HOUR_FORMAT
                                    }
                                    format={
                                      dateTimeFormatChange.getDateFormat(
                                        fi.fieldName
                                      ).dateAndTimeFormat
                                    }
                                    value={
                                      fi.value1 ? moment(fi.value1) : moment()
                                    }
                                  />
                                </div>
                              );
                            case "select":
                              return (
                                <div>
                                  <Select
                                    style={{ width: "90%" }}
                                    onChange={(e) =>
                                      updateDataFilter(fi, "value1", e)
                                    }
                                    value={fi.value1}
                                  >
                                    {props.reportDataObject &&
                                      props.reportDataObject.length > 0 &&
                                      [
                                        ...new Set(
                                          props.reportDataObject.map(
                                            (rd) => rd[fi.fieldName]
                                          )
                                        ),
                                      ].map((o) => {
                                        return <Option key={o}>{o}</Option>;
                                      })}
                                  </Select>
                                </div>
                              );
                            default:
                              return (
                                <div>
                                  <Input
                                    onChange={(e) =>
                                      updateDataFilter(
                                        fi,
                                        "value1",
                                        e.target.value
                                      )
                                    }
                                    value={fi.value1}
                                  />
                                </div>
                              );
                          }
                        })()}
                      </Col>
                      <Col span={4}>
                        <div>To Value</div>
                        {(() => {
                          switch (fi.dataType) {
                            case "number":
                              return (
                                <div>
                                  <Input
                                    type='number'
                                    onChange={(e) =>
                                      updateDataFilter(
                                        fi,
                                        "value2",
                                        e.target.value
                                      )
                                    }
                                    value={fi.value2}
                                  />
                                </div>
                              );
                            case "date":
                              return (
                                <div>
                                  <DatePicker
                                    onChange={(date, dateString) =>
                                      updateDataFilter(fi, "value2", dateString)
                                    }
                                    showTime={
                                      dateTimeFormatChange.getDateFormat(
                                        fi.fieldName
                                      ).dateAndTimeFormat ===
                                      DATE_WITH_HOUR_FORMAT ||
                                      dateTimeFormatChange.getDateFormat(
                                        fi.fieldName
                                      ).dateAndTimeFormat ===
                                      DATE_WITH_HOUR_FORMAT
                                    }
                                    format={
                                      dateTimeFormatChange.getDateFormat(
                                        fi.fieldName
                                      ).dateAndTimeFormat
                                    }
                                    value={
                                      fi.value2 ? moment(fi.value2) : moment()
                                    }
                                  />
                                </div>
                              );
                            case "select":
                              return (
                                <div>
                                  <Select
                                    style={{ width: "90%" }}
                                    onChange={(e) =>
                                      updateDataFilter(fi, "value2", e)
                                    }
                                    value={fi.value2}
                                  >
                                    {props.reportDataObject &&
                                      props.reportDataObject.length > 0 &&
                                      [
                                        ...new Set(
                                          props.reportDataObject.map(
                                            (rd) => rd[fi.fieldName]
                                          )
                                        ),
                                      ].map((o) => {
                                        return <Option key={o}>{o}</Option>;
                                      })}
                                  </Select>
                                </div>
                              );
                            default:
                              return (
                                <div>
                                  <Input
                                    onChange={(e) =>
                                      updateDataFilter(
                                        fi,
                                        "value2",
                                        e.target.value
                                      )
                                    }
                                    value={fi.value2}
                                  />
                                </div>
                              );
                          }
                        })()}
                      </Col>
                    </>
                  )}

                  <Col span={1}>
                    <div>&nbsp;</div>
                    <div>
                      <Button
                        type='text'
                        icon={<CloseOutlined />}
                        onClick={() => deleteDataFilterItem(fi)}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}

            <Row
              style={{ marginTop: "16px", marginBottom: "32px" }}
              gutter={16}
            >
              <Col span={4}>
                <Button onClick={setDataFiltertem}>Add New +</Button>
              </Col>
            </Row>
            <hr />
            <Row style={{ marginTop: "16px", marginBottom: "32px" }}>
              <Col span={4}>
                <Button
                  type='primary'
                  onClick={() => saveFilters(dataFilters, definedFilters)}
                >
                  Save Filters
                </Button>
              </Col>
              <Col span={4}>
                <Button onClick={() => clearFilters()}>Reset</Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <NotFound />
          </div>
        )}
      </div>
    </div>
  );
}
