import React from "react";
import DashboardView from "../DashboardView";
import { Row, Col, Button, Input } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ResourceList from "./ResouceList";
import {
  getResourcesAsync,
  getResourceTypesAsync,
} from "../../redux/resourceSlice";
import Loading from "../../components/common/Spinners/Loading";

const { Search } = Input;

export default function Resources() {
  const [data, setData] = React.useState([]);

  const resources = useSelector((state) => state.resources);
  const groups = useSelector((state) => state.boards.groups);
  const dispatch = useDispatch();

  const onSearchColName = (value) => {
    if (value) {
      let FilteredData = resources.resourceData?.filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.resoueceType.toLowerCase().includes(value.toLowerCase())
      );
      return setData((state) => [...FilteredData]);
    } else {
      return fetchResources();
    }
  };

  const fetchResourceTypes = React.useCallback(() => {
    dispatch(getResourceTypesAsync());
  }, [dispatch]);

  React.useEffect(() => {
    fetchResourceTypes();
  }, []);

  const fetchResources = React.useCallback(() => {
    dispatch(getResourcesAsync());
  }, [dispatch]);

  React.useEffect(() => {
    fetchResources();
  }, []);

  React.useEffect(() => {
    if (resources.resourceData) {
      setData(() => [...resources.resourceData]);
    }
  }, [resources.resourceData]);

  return (
    <DashboardView>
      <Row>
        <Col span={4}>
          <h2>Resources</h2>
        </Col>
        <Col span={2}></Col>
        <Col span={8}>
          <Input
            placeholder='Search Resources By Name OR Type'
            onChange={(e) => onSearchColName(e.target.value)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={4}></Col>
        <Col span={6}>
          <Button type='primary'>
            <Link to='add-resource'>Add New</Link>
          </Button>
        </Col>
      </Row>
      <div>
        {resources.resourceStatus === "pending" ||
        resources.resourceTypeStatus === "pending" ? (
          <Loading />
        ) : !data.length && resources.resourceStatus === "fulfilled" ? (
          <div
            style={{
              position: "fixed",
              bottom: "50%",
              left: "42%",
            }}
          >
            <h3>No Matching Resources Found...</h3>
          </div>
        ) : (
          <ResourceList
            resourceData={data.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0))}
            resourceTypes={resources.resourceTypes}
            groups={groups}
          />
        )}
      </div>
    </DashboardView>
  );
}
