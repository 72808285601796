import React, { useMemo, useState, useEffect } from 'react'
import _ from 'lodash';
import { getSumOfGroupByMultiFields, groupingByMultipleFields } from '../../utils/group-helpers';
import "./customTable.css";

export default function AdvancedGroupingTable(props) {
    const { columns, dataSource, summaryData, tableID, groupConfig } = props

    const [columsSet, setColumsSet] = useState([])
    const [reportData, setReportData] = useState([])

    useEffect(() => {
        if (dataSource.length > 0) {
            let uniqueCols = _.uniq(dataSource.map(x => x[groupConfig.selectedColumns[0].fieldName])).map((col, i) => {
                return {
                    checked: true,
                    key: `gp_c_key_${i}`,
                    dataIndex: col,
                    title: col,
                    isGroupCol: true,
                    dataType: 'number'
                }
            })
            let gTotalCol=[]
            if(groupConfig.selectedColumns.length > 0 && groupConfig.selectedColumns[0].config.showTotals) {
                gTotalCol=[{
                    checked: true,
                    key: `gp_GT_c_key`,
                    dataIndex: 'GrandTotal',
                    title: 'Grand Total',
                    isGroupCol: true,
                    dataType: 'number'
                }]
            }
            setColumsSet([
                ...columns.filter(x => groupConfig.selectedRows.findIndex(y => y.fieldName === x.dataIndex) > -1),
                ...uniqueCols,
                ...gTotalCol
            ])

            let gp = dataSource.map(dc => {
                let obj = {}
                uniqueCols.forEach(uc => {
                    obj[uc.dataIndex] = dc[groupConfig.selectedColumns[0].fieldName] === uc.dataIndex ? dc[groupConfig.selectedValues[0].fieldName] : 0;
                    

                })


                if(groupConfig.selectedColumns.length > 0 && groupConfig.selectedColumns[0].config.showTotals) {
                    obj.GrandTotal=uniqueCols.reduce((a,b) => { return a + dc[groupConfig.selectedValues[0].fieldName] }, 0)
                }
                return { ...dc, ...obj }

            })

            let qqq = getGroupedData(gp)

            if(groupConfig.selectedColumns.length > 0 && groupConfig.selectedColumns[0].config.showTotals) {
                setReportData(qqq.map(dc => {
                    let obj={}
                    obj.GrandTotal=_.uniq(dataSource.map(x => x[groupConfig.selectedColumns[0].fieldName])).reduce((a,b) => { return a + dc[b] }, 0)
                    return { ...dc, ...obj }
                }))
                //
            } else {
            setReportData(qqq)
            }

        }
    }, [columns, groupConfig, dataSource])

    const getGroupedData = (rd) => {
        let groupByKeys = groupConfig.fieldNamesList ? groupConfig.fieldNamesList : [];
        let sumKeys = _.uniq(dataSource.map(x => x[groupConfig.selectedColumns[0].fieldName]));
        let result = getSumOfGroupByMultiFields(rd, groupByKeys, sumKeys);

        let count = groupingByMultipleFields(rd, function (item) {
            return groupByKeys?.map((key) => item[key]);
        });
        // result = result?.map((obj) =>
        //     Object.fromEntries(
        //         Object.entries(obj)?.filter(
        //             (o) =>
        //                 [...groupByKeys, ...sumKeys]?.includes(o[0]) //||
        //                 // groupConfig?.uniqueFieldNamesList?.includes(o[0])
        //         )
        //     )
        // );
        // let columns = Object.keys(result[0]).map((val, i) => ({
        //     id: i,
        //     fieldName: val,
        //     checked:
        //       resource.availableFields?.find(
        //         (af) => af.fieldName === val
        //       )?.checked ?? true,
        //     dataType: resource.allFields?.find(
        //       (af) => af.fieldName === val
        //     )?.dataType,
        //     grouped: true,
        //   }));



        if (groupConfig?.operator === 'sum&count' || groupConfig?.operator === 'count') {
            count = count?.map((c) => c?.length);

            // columns.push({
            //   id: v4(),
            //   fieldName: "Count",
            //   checked:
            //   resource.availableFields?.find(
            //       (af) => af.fieldName === "Count"
            //     )?.checked ?? true,
            //   dataType: "number",
            //   grouped: true,
            // });

            result = result.map((res, i) => Object.assign(res, { 'Count': count[i] }));
            return result
        } else {
            return result
        }


        //     return {
        //       data: result?.length ? result : [],
        //       fields: columns?.length ? columns : [],
        //     };
        //   } else {
        //     return {
        //       data: result?.length ? result : [],
        //       fields: columns?.length ? columns : [],
        //     };
        //   }

    };

    const memorizedRows = useMemo(() => {
        if (columsSet) {
            if (dataSource.length > 0) {
                return reportData.map((ds, i) => {
                    return (
                        <tr key={`tr_${i}`} style={{ borderBottom: '1px solid #eee' }}>

                            {(summaryData && summaryData.length > 0) || (groupConfig && groupConfig.advanced && groupConfig.selectedColumns.length > 0) ?
                                <td className="summary-data-space">&nbsp;</td>
                                : null}
                            {columsSet.map((dsc, j) => {
                                return (
                                    <td key={`td_${i}_${j}`}>{dsc.render ? dsc.render(ds[dsc.dataIndex]) : ds[dsc.dataIndex] ? dsc.dataType === 'number' ? Number(ds[dsc.dataIndex]).toFixed(2) : ds[dsc.dataIndex] : ''}</td>
                                )
                            })}
                        </tr>
                    )
                })
            } else {
                return (
                    <tr>
                        <td colspan="100%" >
                            <div style={{ width: '100%', textAlign: 'center', padding: '24px' }}>
                                Data Not Available
                            </div>
                        </td>
                    </tr>
                )
            }
        }
    }, [reportData, columsSet, summaryData]);

    const getMemorizedTotal = (fieldName) => {
        return reportData.reduce((a, b) => { return a + Number(b[fieldName]) }, 0).toFixed(2)
    }

    const matched = (dataArr, a) => {
        return dataArr?.filter((b) => a === b?.fieldName);
    };

    const onCalculationValue = (aggrigationtype, field) => {
        if (aggrigationtype === "sum") {
            return reportData
                ?.reduce((a, curr) => Number(a) + Number(curr[field]), 0)
                ?.toFixed(2);
        } else if (aggrigationtype === "average") {
            return (
                reportData?.reduce(
                    (a, curr) => Number(a) + Number(curr[field]),
                    0
                ) / reportData?.length
            )?.toFixed(2);
        } else if (aggrigationtype === "minimum") {
            return Math.min(
                ...reportData?.map((o) => Number(o[field]))
            );
        } else if (aggrigationtype === "maximum") {
            return Math.max(
                ...reportData?.map((o) => Number(o[field]))
            );
        } else if (aggrigationtype === "count") {
            return reportData?.length;
        } 
        // else if (aggrigationtype === "standarddeviation") {
        //     return dev(reportData?.map((o) => Number(o[field])));
        // } else if (aggrigationtype === "variance") {
        //     return ver(reportData?.map((o) => Number(o[field])));
        // } else if (aggrigationtype === "median") {
        //     return median(reportData?.map((o) => Number(o[field])));
        // }
        return null;
    };


    return (
        <div className="widget-table-container">
            {columns ?
                <table className="widget-table" id={`widgetTable${tableID}`}>
                    <thead>
                        <tr>
                            {(summaryData && summaryData.length > 0) || (groupConfig && groupConfig.advanced && groupConfig.selectedColumns.length > 0) ?
                                <th className='summary-data-space custom-table-th'>&nbsp;</th>
                                : null}
                            {columsSet.map((c, k) => {
                                return (
                                    <th key={`th_${k}`} className="custom-table-th">{c.title}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody style={{ borderBottom: '1px solid #aaa', borderTop: '1px solid #aaa' }}>
                        {memorizedRows}
                    </tbody>
                    <tfoot>
                        {summaryData && summaryData.length > 0 ?
                            <>
                                {summaryData.map((field, index) => (
                                    <tr>
                                        {field ? <td className='summary-data-space'> <b>{field?.displayName ?? ""}</b></td> : <td></td>}

                                        {dataSource.length > 0 && Object.keys(dataSource[0]) &&
                                            columns
                                                ?.map((a, id) => {
                                                    return field ? (
                                                        matched([{ ...field }], a.dataIndex).length ? (
                                                            <td index={id}>
                                                                <b>
                                                                    {onCalculationValue(
                                                                        matched([{ ...field }], a.dataIndex)[0]
                                                                            .aggType,
                                                                        matched([{ ...field }], a.dataIndex)[0]
                                                                            .fieldName
                                                                    )}
                                                                </b>
                                                            </td>
                                                        ) : (
                                                            <td
                                                                index={id}
                                                            ></td>
                                                        )
                                                    ) : null;
                                                })}
                                    </tr>
                                ))}
                            </> : null}

                        <tr className='build-talals-row'>
                            {
                                groupConfig && groupConfig.advanced && groupConfig.selectedRows.length > 0 && groupConfig.selectedRows[0].config.showTotals ?
                                    <>
                                        <td className='build-talals-item'>Grand Total</td>

                                        {reportData.length > 0 && Object.keys(reportData[0]) &&
                                            columsSet
                                                ?.map((a, id) => (
                                                    <td className='build-talals-item' index={id}>
                                                        {_.uniq(dataSource.map(x => x[groupConfig.selectedColumns[0].fieldName])).findIndex(x => x === a.dataIndex) > -1 ? getMemorizedTotal(a.dataIndex) : a.dataIndex==="GrandTotal"? getMemorizedTotal('GrandTotal'):''}
                                                    </td>
                                                ))}

                                    </>

                                    : null
                            }
                        </tr>

                    </tfoot>


                </table>
                : null}
        </div>
    )

}
