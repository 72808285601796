import { DATE_FORMAT } from "../constants/dateConstants";

export const customDateTimeFormat = (availableFields) => {

    return {
        getDateFormat: (fieldName) => {
            const availableField = (availableFields || []).find(availableField => availableField.fieldName === fieldName);

            if (availableField?.format) {

                const [dateFormat, timeFormat] = availableField.format.split();
                return {
                    dateAndTimeFormat: availableField?.format || DATE_FORMAT,
                    dateFormat: dateFormat || DATE_FORMAT,
                    timeFormat: timeFormat
                }
            }
            return {
                dateAndTimeFormat: DATE_FORMAT
            }
        }
    }
}